import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";
import DateTimePicker from "@generic/DateTimePicker";
import CollapsibleGroup from "@ui/CollapsibleGroup";
import ContentList from "features/forms/MultiContentForm/Components/ContentList";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // ! TO DO
    // * BOOLEANS need to be yup.bool()
    visitNumber: yup.string().required("This field is required"),
    engineerRefId: yup.string().required("This field is required"),
    loggedAt: yup.string().required("This field is required"),
    scheduledFor: yup.string().required("This field is required"),
    estimatedArrivalTime: yup.string().required("This field is required"),
    actualArrivalTime: yup.string().required("This field is required"),
    departureTime: yup.string().required("This field is required"),
    visitStateRefId: yup.string().required("This field is required"),
    // completedAt: yup.string().required("This field is required"),
    // completed_flag: yup.string().required("This field is required"),

    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function ViewVisit({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
  setFormState,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });
  const [contentListData, setContentListData] = useState([]);

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      visitNumber: "",
      engineerRefId: "",
      loggedAt: "",
      scheduledFor: "",
      estimatedArrivalTime: "",
      actualArrivalTime: "",
      departureTime: "",
      visitStateRefId: "",
      completedAt: "",
      completed_flag: "",
    },
  });

  // const { openModal } = useModal();
  const { data: stateData } = state;

  const sidePanelId = Hooks.getSidePanelData().id;
  const sidePanelData = Hooks.getSidePanelData();

  // * UseEffect Hooks: Tracking
  useEffect(() => {
    console.log("stateData:", stateData);
    // console.log("dropdownData:", dropdownData);
  }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  //* Fill form via state
  useEffect(() => {
    if (contentListData && contentListData.length > 0) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        // * BOOLEANS need || "" removed!
        id: contentListData[0].id,
        jobRefId: contentListData[0].jobRefId,
        visitNumber: contentListData[0].visitNumber || "",
        engineerRefId: contentListData[0].engineerRefId || "",
        loggedAt: contentListData[0].loggedAt || "",
        scheduledFor: contentListData[0].scheduledFor || "",
        estimatedArrivalTime:
          Hooks.formatTime(contentListData[0].estimatedArrivalTime) || "",
        actualArrivalTime:
          Hooks.formatTime(contentListData[0].actualArrivalTime) || "",
        departureTime: Hooks.formatTime(contentListData[0].departureTime) || "",
        visitStateRefId: contentListData[0].visitStateRefId || "",
        completedAt: contentListData[0].completedAt || "",
        completed_flag: contentListData[0].completed_flag,
      });
    }
  }, [contentListData, reset]);

  // * UseEffect Hooks: Form/API
  // Dropdown List Data
  useEffect(() => {
    Hooks.getData(
      `${API_ENV}/contractsAndEngineers?contractIds=${sidePanelData.contractId}&showarchive=false`,
      (data) => {
        // console.log("useEffect API 1 data:", data);
        setDropdownData((prevData) => ({
          ...prevData,
          dropdownData1: data,
        }));
      }
    );
    Hooks.getData(`${API_ENV}/visitState?showarchive=false`, (data) => {
      // console.log("useEffect API 2 data:", data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData2: data,
      }));
    });
  }, [sidePanelData.contractId]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          // cleanURL();

          setIsButtonDisabled(false);
        }

        // Handle completedDate
        const completedAt =
          data.completedAt === "" ? null : Hooks.dateToISO(data.completedAt);

        const dataObject = {
          ...data,
          completedAt,
          estimatedArrivalTime: Hooks.combineDateTime(
            data.scheduledFor,
            data.estimatedArrivalTime
          ),
          actualArrivalTime: Hooks.combineDateTime(
            data.scheduledFor,
            data.actualArrivalTime
          ),
          departureTime: Hooks.combineDateTime(
            data.scheduledFor,
            data.departureTime
          ),
        };

        // console.log("handleSubmitForm data:", data);
        // console.log("handleSubmitForm dataObject:", dataObject);

        Hooks.sendData(
          dataObject,
          `${API_ENV}/visits/${data.id}`,
          "PUT",
          thenFunction
        );
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable]
  );

  const handleArchive = useCallback(() => {
    archive(stateData.id);
  }, [archive, stateData.id]);

  const handleChangeForm = useCallback(() => {
    setFormState({ type: "addvisit", data: {} });
  }, [setFormState]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Update Visit Details",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      { text: "Add New Visit To Job", click: handleChangeForm },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    handleChangeForm,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  //. For Content List - Delete if not needed
  useEffect(() => {
    // API GET contentList data
    Hooks.getData(`${API_ENV}/visits?jobIDs=${sidePanelId}`, (data) => {
      // console.log("contentList data", data);
      setContentListData(data);
    });
  }, [sidePanelId]);

  function contentListItemClicked(contentListItem) {
    // Directly use the passed item object to update the form
    // No need to parse eventData from the event.target.getAttribute
    // console.log("targetClicked item: ", item);

    reset({
      // * BOOLEANS need || "" removed!
      // * Date values & others need to be null if there is no data!
      id: contentListItem?.id,
      jobRefId: contentListItem?.jobRefId,
      visitNumber: contentListItem?.visitNumber || "",
      engineerRefId: contentListItem?.engineerRefId || "",
      loggedAt: contentListItem?.loggedAt || "",
      scheduledFor: contentListItem?.scheduledFor || "",
      estimatedArrivalTime:
        Hooks.formatTime(contentListItem?.estimatedArrivalTime) || "",
      actualArrivalTime:
        Hooks.formatTime(contentListItem?.actualArrivalTime) || "",
      departureTime: Hooks.formatTime(contentListItem?.departureTime) || "",
      visitStateRefId: contentListItem?.visitStateRefId || "",
      completedAt: contentListItem?.completedAt || "",
      completed_flag: contentListItem?.completed_flag || "",
    });
  }
  //. For Content List - Delete if not needed

  return (
    <Form>
      <div className="contentListContainer">
        <ContentList
          data={contentListData}
          type={"visit"}
          clicked={contentListItemClicked}
        />
      </div>

      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <Input
              label={"Visit Number"}
              name="visitNumber"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Engineer"}
              name="engineerRefId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
              optionNames={["forename", "surname"]}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Logged</label>
            <Controller
              name="loggedAt"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Scheduled Date</label>
            <Controller
              name="scheduledFor"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={true}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Estimated Arrival Time"}
              name="estimatedArrivalTime"
              type="time"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Actual Arrival Time"}
              name="actualArrivalTime"
              type="time"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Departure Time"}
              name="departureTime"
              type="time"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Visit State"}
              name="visitStateRefId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData2}
              optionName="description"
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Completed Date</label>
            <Controller
              name="completedAt"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center readOnly"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                  readOnly
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Complete?"}
              name="completed_flag"
              type="checkbox"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>
        </div>
      </CollapsibleGroup>
    </Form>
  );
}
