import React, { useRef } from "react";
import "./deliveryLines.scss";

import Hooks from "../../../Hooks";
import API_ENV from "@config";
import SearchBar from "@ui/SearchBar";

export default function AddLine({ updatePopup }) {
  const description = useRef(null);
  const quantity = useRef(null);
  const price = useRef(null);

  // commented out as not currently being used
  // const part = useRef(null);

  const setValue = {}; //! This is only here to not cause error. Really this component needs to be refactored with React Hook Form (RHF)

  function add() {
    const line = {
      desc: description?.current.value,
      deliveryNoteId: Hooks.getSidePanelData().id,
      quantityReceived: quantity?.current.value,
      unitPrice: price?.current.value,
      partsRefId: document
        .querySelector("#typeSearch input")
        .getAttribute("dataid"),
    };

    Hooks.sendData(line, `${API_ENV}/dnLine`, "POST", () => {
      updatePopup();
      document.querySelector(".currentTab").click();
    });
  }

  return (
    <div id="addLinePopup">
      <div id="addLineHeader">
        <div className="title">Add New Line</div>
        <div
          className="close"
          onClick={() => {
            updatePopup();
          }}
        >
          Close
        </div>
      </div>
      <div id="addLineForm">
        <div className="addLineItem">
          <div className="addLineKey">Part</div>
          {/* Old Way */}
          {/* <TypeSearchOldNonRHF type="part" popup={true} /> */}

          {/* New Way //! Needs Setting up */}
          <SearchBar title={"Property"} type={"property"} setValue={setValue} />
        </div>
        <div className="addLineItem">
          <div className="addLineKey">Quantity Received</div>
          <input ref={quantity} type="number" />
        </div>
        <div className="addLineItem">
          <div className="addLineKey">Unit Price</div>
          <input ref={price} type="text" />
        </div>

        <div className="addLineItem">
          <div className="addLineKey">Description</div>
          <input ref={description} type="text" />
        </div>
      </div>
      <div id="addLineFooter">
        <div className="confirm" onClick={add}>
          Confirm
        </div>
      </div>
    </div>
  );
}
