import { HiArrowRightOnRectangle } from "react-icons/hi2";
import { useAuth } from "../context/AuthContext";

import ButtonIcon from "./ButtonIcon";
import SpinnerMini from "./SpinnerMini";

function LogoutButton() {
  const { logout, isLoading } = useAuth();

  return (
    <ButtonIcon disabled={isLoading} onClick={logout}>
      {!isLoading ? <HiArrowRightOnRectangle /> : <SpinnerMini />}
    </ButtonIcon>
  );
}

export default LogoutButton;
