import "../styles/ui/SORItem.scss";
import Button from "./Button";

export default function SORItem({ data, onDelete }) {
  return (
    <div className="SORItemContainer">
      {data.length > 0 ? (
        data.map((sor, index) => (
          <div
            key={index}
            className={`SORItem ${sor.stagingType || ""}`.trim()}
          >
            <div className="SORDetails">
              <p>
                Change type:{" "}
                {sor.stagingType.charAt(0).toUpperCase() +
                  sor.stagingType.slice(1)}
              </p>
              <p>
                SOR Code: {sor?.sor?.sorCode} | UOM: {sor?.sor?.uom.name}
              </p>
              <p>Short Description: {sor?.sor?.shortDesc}</p>
              <p>
                Quantity: {sor.qty} | Rate: {sor?.sor?.rate} | Sub Total:{" "}
                {sor.subTotal}
              </p>
            </div>

            <Button
              type="danger"
              onClick={(e) => {
                e.preventDefault();
                onDelete(index);
              }}
            >
              Delete
            </Button>
          </div>
        ))
      ) : (
        <p>No SORs added yet.</p>
      )}
    </div>
  );
}
