import API_ENV from "@config";
import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import CollapsibleGroup from "@ui/CollapsibleGroup";
import SearchBar from "@ui/SearchBar";
import { useParams } from "react-router-dom";
import Button from "@ui/Button";
import SORItem from "@ui/SORItem";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    // sorCode: yup.string().required("This field is required"),
    // shortDesc: yup.string().required("This field is required"),
    // qty: yup
    //   .number()
    //   .required("This field is required")
    //   .typeError("Quantity must be a number"),
    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function AddSORToJob({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  setFormState,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // const [dropdownData, setDropdownData] = useState({
  //   dropdownData1: [],
  // });

  const [sorQueue, setSorQueue] = useState([]);

  const { tableRowId } = useParams();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    // register,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      sorCode: "",
      shortDesc: "",
      uomId: "",
      uomName: "",
      rate: "",
      qty: "",
      subTotal: 0,
    },
  });

  const qty = watch("qty");
  const rate = watch("rate");

  // Calculate subTotal whenever qty or rate changes
  useEffect(() => {
    const subTotal = qty && rate ? qty * rate : 0;
    setValue("subTotal", subTotal);
  }, [qty, rate, setValue]);

  // const { openModal } = useModal();

  useEffect(() => {
    console.log("sorQueue:", sorQueue);
  }, [sorQueue]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) =>
  //     console.log("Form values:", values)
  //   );
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  // useEffect(() => {
  //   // ! TO DO
  //   Hooks.getData(`${API_ENV}/`, (data) => {
  //     // console.log("useEffect API 1 data:", data);
  //     setDropdownData((prevData) => ({
  //       ...prevData,
  //       dropdownData1: data,
  //     }));
  //   });
  // }, []);

  const deleteSOR = (indexToDelete) => {
    setSorQueue((prevQueue) =>
      prevQueue.filter((_, index) => index !== indexToDelete),
    );
  };

  const addSORToQueue = async (e) => {
    e.preventDefault();

    // Manually validate the qty field
    const qtyValue = getValues("qty");

    if (!qtyValue || isNaN(qtyValue)) {
      // Set the error for qty using setError from react-hook-form
      setError("qty", {
        type: "manual",
        message: "Quantity is required and must be a valid number.",
      });
      return;
    }

    // Clear any previous errors on qty
    clearErrors("qty");

    // Get the current form values
    const sorData = {
      sorCode: getValues("sorCode"),
      shortDesc: getValues("shortDesc"),
      uomName: getValues("uomName"),
      rate: getValues("rate"),
      sorId: getValues("id"),
      qty: qtyValue,
      subTotal: getValues("subTotal"),
    };

    // Add the SOR data to the queue
    setSorQueue((prevQueue) => [...prevQueue, sorData]);

    // Optionally reset the form fields here if needed
    reset({
      sorCode: "",
      shortDesc: "",
      uomName: "",
      rate: "",
      qty: "",
      subTotal: 0,
    });
  };

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          // cleanURL();
          reset();
          Hooks.getData(`${API_ENV}/jobSor/job/${tableRowId}`, (data) => {
            // console.log(data);
            setFormState({ type: "jobsors", data: { data } });
          });

          setIsButtonDisabled(false);
        }

        const dataObject = sorQueue.map((sor) => ({
          sorId: sor.sorId,
          qty: sor.qty,
        }));

        // console.log("handleSubmitForm data:", data);
        console.log("handleSubmitForm dataObject:", dataObject);

        Hooks.sendData(
          dataObject,
          `${API_ENV}/jobsor/masscreateupdate/${tableRowId}`,
          "POST",
          thenFunction,
        );
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, tableRowId, reset, setFormState, sorQueue],
  );

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Submit Form",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SearchBar title={"SOR"} type={"jobsor"} setValue={setValue} />

          <SidePanelFormRow>
            <Input
              label={"SOR Code"}
              name="sorCode"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Short Description"}
              name="shortDesc"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"UOM"}
              name="uomName"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Rate"}
              name="rate"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Quantity"}
              name="qty"
              type="number"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Sub Total"}
              name="subTotal"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow type="full">
            <Button size="small" onClick={addSORToQueue}>
              Add Job SOR
            </Button>
          </SidePanelFormRow>
        </div>
      </CollapsibleGroup>

      <CollapsibleGroup title="SOR's to Add">
        <div>
          <SORItem data={sorQueue} onDelete={deleteSOR} />
        </div>
      </CollapsibleGroup>
    </Form>
  );
}
