import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import DropdownList from "@ui/DropdownList";
import CollapsibleGroup from "@ui/CollapsibleGroup";
import Button from "@ui/Button";
import ListItem from "@ui/ListItem";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    // field1: yup.string().required("This field is required"),
    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function AddAssignedStaffToContract({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  setFormState,
}) {
  const [saveQueue, setSaveQueue] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // field1: "",
    },
  });

  // const { openModal } = useModal();

  // * UseEffect Hooks: Tracking
  // useEffect(() => {
  //   console.log("saveQueue:", saveQueue);
  // }, [saveQueue]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks: Form/API
  // Dropdown List Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/staff`, (data) => {
      // console.log("useEffect API 1 data:", data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });
  }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          // cleanURL();
          reset();

          Hooks.getData(
            `${API_ENV}/contractsAndEngineers?contractIds=${
              Hooks.getSidePanelData().id
            }`,
            (data) => {
              // console.log("useEffect API 1 data:", data);
              setFormState({ type: "staffassigned", data });
            },
          );

          setIsButtonDisabled(false);
        }
        // console.log("handleSubmitForm data:", data);

        const dataObject = saveQueue.map((item) => item.id);

        Hooks.sendData(
          dataObject,
          `${API_ENV}/contractsAndEngineers/masscreateupdate/${
            Hooks.getSidePanelData().id
          }`,
          "POST",
          thenFunction,
        );
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, reset, saveQueue, setFormState],
  );

  // const handleChangeForm = useCallback(() => {
  //   setFormState({ type: "formNameHere", data: {} });
  // }, [setFormState]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Add Engineers To Contract",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      // { text: "Change Form", click: handleChangeForm },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  //* Add item to queue
  const addToSaveQueue = (e) => {
    e.preventDefault();
    const selectedItem = control._formValues.engineerId; //. CHANGE ID HERE
    const selectedItemData = dropdownData.dropdownData1.find(
      (item) => item.id === selectedItem,
    );

    if (selectedItemData) {
      // Check if the item is already in the queue by checking their ID
      const isItemInQueue = saveQueue.some(
        (item) => item.id === selectedItemData.id,
      );

      // Only add the item if they are not already in the queue
      if (!isItemInQueue) {
        setSaveQueue((prevQueue) => [...prevQueue, selectedItemData]);
      } else {
        // Optionally, display a message or perform another action if the item is already in the queue
        console.log("This item is already in the queue.");
      }
    }
  };

  //* Delete item from the queue
  const deleteFromSaveQueue = (indexToDelete) => {
    setSaveQueue((prevQueue) =>
      prevQueue.filter((_, index) => index !== indexToDelete),
    );
  };

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <DropdownList
              label={"Engineer"}
              name="engineerId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
              optionNames={["forename", "surname"]}
            />
          </SidePanelFormRow>

          <SidePanelFormRow type="full">
            <div className="buttonsGroup">
              <Button size="small" onClick={addToSaveQueue}>
                Add Engineer
              </Button>
            </div>
          </SidePanelFormRow>
        </div>
      </CollapsibleGroup>

      <CollapsibleGroup title="Engineers to Add">
        <div>
          <ListItem
            data={saveQueue}
            type={"engineer"}
            onDelete={deleteFromSaveQueue}
          />
        </div>
      </CollapsibleGroup>
    </Form>
  );
}
