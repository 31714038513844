import React, { useEffect, useState } from "react";

import ContentListItem from "./ContentListItem";

export default function ContentList({ data, type, clicked }) {
  const [selectedItem, setSelectedItem] = useState(null);

  // console.log("ContentList data:", data);

  useEffect(() => {
    if (data && data.length > 0) {
      setSelectedItem(data[0]);
    }
  }, [data]);

  const handleListItemClick = (itemData) => {
    const item = JSON.parse(itemData);
    // console.log("item:", item);
    // console.log("selectedItem:", selectedItem);

    // Check if the clicked item has the same id as the selected item
    if (item.id === selectedItem?.id) {
      setSelectedItem(null); // Deselect the item
    } else {
      setSelectedItem(item); // Select the clicked item
    }

    // Assuming 'clicked' expects the raw item data
    if (clicked) clicked(item);
  };

  const getEmptyMessage = () => {
    switch (type) {
      case "residents":
        return "No residents assigned to this property.";
      case "jobs":
        return "No jobs assigned to this property.";
      case "contractjobtypes":
        return "No Job Types assigned to this Contract.";
      case "jobsors":
        return "No SORs assigned to this Job.";
      case "assignedusers":
        return "No Users assigned to this Role.";
      case "equipment":
        return "No equipment assigned to this property.";
      case "visit":
        return "No visits assigned to this job.";
      case "jobparts":
        return "No parts assigned to this job.";
      default:
        return "No values could be found.";
    }
  };

  return (
    <div id="contentList">
      {Array.isArray(data) && data.length > 0 ? (
        data.map((item, index) => (
          <ContentListItem
            key={index}
            item={item}
            type={type}
            onListItemClick={(itemData) => handleListItemClick(itemData)}
            isSelected={item.id === selectedItem?.id}
          />
        ))
      ) : (
        <p className="contentInfo">{getEmptyMessage()}</p>
      )}
    </div>
  );
}
