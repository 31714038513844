import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";
import DateTimePicker from "@generic/DateTimePicker";
import CollapsibleGroup from "@ui/CollapsibleGroup";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    // engineerRefId: yup.string().required("This field is required"),
    jobTypeRefId: yup.string().required("This field is required"),
    jobStateRefId: yup.string().required("This field is required"),
    reference: yup.string().required("This field is required"),
    // priorityId: yup.string().required("This field is required"),
    targetDate: yup.string().required("This field is required"),
    scheduledDate: yup.string().required("This field is required"),
    scheduledStartTime: yup.string().required("This field is required"),
    scheduledEndTime: yup.string().required("This field is required"),

    // completedDate: yup.string().required("This field is required"),
    // completed_flag: yup.bool().required("This field is required"),
    // qcDate: yup.string().required("This field is required"),
    // qcStatusRefId: yup.string().required("This field is required"),
    invoiced: yup.bool().required("This field is required"),

    // For conditional fields which only appear based on another fields value
    invoiceNo: yup.string().when("invoiced", {
      is: true,
      then: () => yup.string().required("This field is required"),
      otherwise: () => yup.string().notRequired(),
    }),
  })
  .required();

export default function AddSubJob({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  setFormState,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    jobTypeData: [],
    jobStateData: [],
    contractsData: [],
    engineerData: [],
    priorityData: [],
    qcStatusData: [],
    cancelReasonData: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      //* Hidden values
      parentJobId: "", // Take from Parent
      propertyId: "", // Take from Parent
      contractId: "", // Take from Parent

      //* Displayed values
      engineerRefId: "",
      jobTypeRefId: "",
      jobStateRefId: "",
      reference: "",
      priorityId: "",
      targetDate: "",
      scheduledDate: "",
      scheduledStartTime: "",
      scheduledEndTime: "",

      qcDate: "",
      qcStatusRefId: 0,
      completedDate: "",
      completed_flag: "",
      invoiced: "",
      invoiceNo: "",

      // userRefId: "",
      // jobStatusMessage: "",
      // qcStaffRefId: "",
    },
  });

  // const clientRefId = watch("clientRefId");
  // const contractId = watch("contractId");
  const jobTypeRefId = watch("jobTypeRefId");
  const jobStateRefId = watch("jobStateRefId");

  const parentJob = Hooks.getSidePanelData();
  const parentContractId = parentJob.contractId;
  // const parentJobTypeRefId = parentJob.jobTypeRefId;
  // const parentJobStateRefId = parentJob.jobStateRefId;

  // const { openModal } = useModal();

  // useEffect(() => {
  //   // console.log("dropdownData:", dropdownData);
  //   console.log("parentJob:", parentJob);
  // }, [parentJob]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) =>
  //     console.log("Form values:", values)
  //   );
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      //* Contract
      // if (clientRefId) {
      //   Hooks.getData(
      //     `${API_ENV}/contracts?ClientId=${clientRefId}`,
      //     (data) => {
      //       // console.log("contracts", data);
      //       setDropdownData((prevData) => ({
      //         ...prevData,
      //         contractsData: data,
      //       }));
      //     }
      //   );
      // }

      //* Engineer
      if (parentContractId) {
        Hooks.getData(
          `${API_ENV}/engineer/contract/${parentContractId}`,
          (data) => {
            // console.log("staff", data);
            setDropdownData((prevData) => ({
              ...prevData,
              engineerData: data,
            }));
          },
        );
      }

      //* Job Type
      if (parentContractId) {
        Hooks.getData(
          `${API_ENV}/jobType?ContractId=${parentContractId}&canBeSubJob=true`,
          (data) => {
            console.log("jobType", data);
            setDropdownData((prevData) => ({
              ...prevData,
              jobTypeData: data,
            }));
          },
        );
      }

      //* Job State
      if (jobTypeRefId) {
        Hooks.getData(
          `${API_ENV}/jobState/jobType/${jobTypeRefId}/0`,
          (data) => {
            // console.log("jobState", data);
            setDropdownData((prevData) => ({
              ...prevData,
              jobStateData: data,
            }));
          },
        );
      }

      //* Priority
      Hooks.getData(`${API_ENV}/priority`, (data) => {
        // console.log("priority", data);
        if (data.length > 0) {
          setDropdownData((prevData) => ({
            ...prevData,
            priorityData: data,
          }));
        }
      });

      //* QC Status
      Hooks.getData(`${API_ENV}/qcStatus`, (data) => {
        // console.log("priority", data);
        if (data.length > 0) {
          setDropdownData((prevData) => ({
            ...prevData,
            qcStatusData: data,
          }));
        }
      });

      //* Cancel Reason
      if (jobStateRefId === 8) {
        Hooks.getData(
          `${API_ENV}/contractsAndCancelReason?contractIds=${parentContractId}&showarchive=false`,
          (data) => {
            // console.log("cancelReason", data);
            setDropdownData((prevData) => ({
              ...prevData,
              cancelReasonData: data,
            }));
          },
        );
      }
    }

    return () => {
      isMounted = false;
    };
  }, [parentContractId, jobTypeRefId, jobStateRefId]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          cleanURL();
          reset();

          setIsButtonDisabled(false);
        }
        const parentJob = Hooks.getSidePanelData();

        // Handle completedDate
        const completedDate =
          data.completedDate === ""
            ? null
            : Hooks.dateToISO(data.completedDate);
        const completedFlag = data.completed_flag ? true : false;
        const qcDate = data.qcDate ? Hooks.dateToISO(data.qcDate) : null;

        const dataObject = {
          ...data,
          parentJobId: parentJob.id,
          clientRefId: parentJob.clientRefId,
          propertyId: parentJob.propertyId,
          contractId: parentJob.contractId,

          completedDate,
          completed_flag: completedFlag,
          qcDate,
          scheduledStartTime: Hooks.combineDateTime(
            data.scheduledDate,
            data.scheduledStartTime,
          ),
          scheduledEndTime: Hooks.combineDateTime(
            data.scheduledDate,
            data.scheduledEndTime,
          ),
        };

        // console.log("handleSubmitForm parentJob:", parentJob);
        // console.log("handleSubmitForm data:", data);
        console.log("handleSubmitForm dataObject:", dataObject);

        Hooks.sendData(dataObject, `${API_ENV}/jobs`, "POST", thenFunction);
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, cleanURL, reset],
  );

  // const handleChangeForm = useCallback(() => {
  //   setFormState({ type: "formNameHere", data: {} });
  // }, [setFormState]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Submit Form",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      // { text: "Change Form", click: handleChangeForm },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <DropdownList
              label={"Engineer"}
              name="engineerRefId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.engineerData}
              optionNames={["forename", "surname"]}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Job Type"}
              name="jobTypeRefId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.jobTypeData}
              optionName="description"
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Job State"}
              name="jobStateRefId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.jobStateData}
              optionName="description"
            />
          </SidePanelFormRow>

          {jobStateRefId === 8 && (
            <SidePanelFormRow>
              <DropdownList
                label={"Cancel Reason"}
                name="cancelReasonRefId"
                control={control}
                errors={errors}
                disabled={!jobStateRefId}
                dropdownData={dropdownData.cancelReasonData}
              />
            </SidePanelFormRow>
          )}

          <SidePanelFormRow>
            <Input
              label={"WO"}
              name="reference"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Priority"}
              name="priorityId"
              firstOptionValue={0}
              control={control}
              errors={errors}
              dropdownData={dropdownData.priorityData}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Target Date</label>
            <Controller
              name="targetDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Scheduled Date</label>
            <Controller
              name="scheduledDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Start Time"}
              name="scheduledStartTime"
              type="time"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"End Time"}
              name="scheduledEndTime"
              type="time"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"QC Status"}
              name="qcStatusRefId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.qcStatusData}
              optionName="description"
              firstOptionValue={0}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>QC Date</label>
            <Controller
              name="qcDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Completed Date"}
              name="completedDate"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Complete?"}
              name="completed_flag"
              type="checkbox"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Invoiced?"}
              name="invoiced"
              control={control}
              errors={errors}
              dropdownData={[
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Invoice No"}
              name="invoiceNo"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          {/* <SidePanelFormRow>
          <Button
            size={"small"}
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal();
            }}
          >
            Open Modal
          </Button>
        </SidePanelFormRow> */}
        </div>
      </CollapsibleGroup>

      {/* <Modal name="Example" title={"Title"}>
       
      </Modal> */}
    </Form>
  );
}
