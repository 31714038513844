import React, { useState, useEffect, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utcToZonedTime } from "date-fns-tz";

export default forwardRef(function DateTimePickerOldNonRHF(
  { id, classValue, value, hideTime },
  ref,
) {
  const [startDate, setStartDate] = useState();

  // Define the format based on whether time is hidden
  const format = hideTime ? "MMMM dd yyyy" : "MMMM dd yyyy, h:mm a";

  // Correcting the special case date to a default value
  if (value === "0001-01-01T00:00:00") {
    value = "2000-01-01T00:00:01";
  }

  useEffect(() => {
    if (value) {
      // Parse the incoming value as UTC and convert to the browser's timezone
      const date = new Date(value);
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const zonedDate = utcToZonedTime(date, timeZone);
      setStartDate(zonedDate);
    } else {
      setStartDate(null);
    }
  }, [value]);

  function onChange(date) {
    setStartDate(date);
    // Here, convert the date back to UTC or your preferred timezone before sending to the API
  }

  return (
    <DatePicker
      ref={ref}
      key={Math.random()}
      id={id}
      wrapperClassName="dateWrapper"
      className={classValue}
      selected={startDate}
      showTimeSelect={!hideTime}
      timeIntervals={15}
      dateFormat={format}
      onChange={onChange}
    />
  );
});
