import Hooks from "../../../Hooks";
import React, { useState, useEffect } from "react";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function addDaysToDate(date, int) {
  return new Date(
    new Date(date).setDate(new Date(date).getDate() + int),
  ).toDateString();
}

export default function ArrowSelector({ date, type, leftClick, rightClick }) {
  const [display, updateDisplay] = useState(date.toDateString());
  useEffect(() => {
    Hooks.waitForElement("#arrowSelectorTitle").then((value) => {
      if (value === "success") {
        let update = true,
          dateTest = new Date(date);
        // console.log(type);
        switch (type) {
          case "day":
            updateDisplay(date.toDateString());
            break;
          case "fullweek":
            try {
              while (update) {
                if (
                  new Date(dateTest)
                    .toDateString()
                    .toLowerCase()
                    .includes("mon")
                ) {
                  update = false;
                  updateDisplay(
                    dateTest.toDateString() +
                      " to " +
                      addDaysToDate(dateTest, 6),
                  );
                }
                dateTest = addDaysToDate(dateTest, -1);
              }
            } catch (e) {}
            break;
          case "fourweeks":
            updateDisplay(
              date.toDateString() +
                " to " +
                addDaysToDate(date.toDateString(), 27),
            );
            break;
          case "workweek":
            try {
              while (update) {
                dateTest = addDaysToDate(dateTest, -1);
                if (dateTest.toLowerCase().includes("mon")) {
                  update = false;
                  updateDisplay(dateTest + " to " + addDaysToDate(dateTest, 4));
                }
              }
            } catch (e) {}
            break;
          case "month":
            updateDisplay(months[date.getMonth()]);
            break;
          default:
            break;
        }
      }
    });
  }, [type, date]);

  return (
    <div id="arrowSelector" date={date}>
      <div id="leftArrow" className="arrow" onClick={leftClick}>
        ⪻
      </div>
      <div id="arrowSelectorTitle">{display}</div>
      <div id="rightArrow" className="arrow" onClick={rightClick}>
        ⪼
      </div>
    </div>
  );
}
