import React, { useEffect, useRef, useState } from "react";

export default function SubTab({ data, clicked }) {
  const [content, updateContent] = useState([]);
  const subTabs = useRef(null);

  function tabClicked(tab) {
    if (!subTabs || !subTabs.current) {
      console.error("There was a problem clicking on this tab");
      return;
    }

    subTabs.current
      .querySelector(".selectedSubTab")
      .classList.remove("selectedSubTab");
    tab.classList.add("selectedSubTab");
    if (clicked) {
      clicked(tab);
    }
  }

  function tabComponent(data, index) {
    const className = index === 0 ? "subTab selectedSubTab" : "subTab";
    return (
      <div className={className} value={data.id}>
        {data.text}
      </div>
    );
  }

  useEffect(() => {
    if (!data || !data.length || typeof data === "string") {
      return;
    }

    updateContent(
      data.map((tab, index) => {
        return tabComponent(tab, index);
      }),
    );
  }, [data, clicked]);

  return (
    <div
      id="subTabs"
      ref={subTabs}
      onClick={(event) => {
        tabClicked(event.target);
      }}
    >
      {content}
    </div>
  );
}
