import API_ENV from "@config";
import React, { useCallback, useEffect, useState } from "react";
import Hooks from "../../../Hooks";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import EditColumn from "../../../features/filters/EditColumn";
import Form from "../../../ui/Form";
import SidePanelFormRow from "../../../ui/SidePanelFormRow";
import { Modal, useModal } from "../../../ui/Modal";
import FileInput from "../../../ui/FileInput";
import Button from "../../../ui/Button";
import Input from "../../../ui/Input";
import DropdownList from "../../../ui/DropdownList";

const schema = yup
  .object({
    name: yup.string().required("Report Name is required"),
    runReportTypeId: yup.string().required("Report Type is required"),
    fileNamePrefix: yup.string().required("File Name Prefix is required"),
    queryTable: yup.string().required("Query Table is required"),
    // genFilePath: yup.string().required("Gen File Path is required"),
    // templatePath: yup.string().required("Template File Path is required"),
    apiGroupId: yup.string().required("API Group is required"),
  })
  .required();

export default function ViewDefineReport({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    apiGroupData: [],
    reportTypeData: [],
  });

  // This replaces formData useState hook
  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      runReportTypeId: "",
      fileNamePrefix: "",
      queryTable: "",
      genFilePath: "",
      templatePath: "",
      apiGroupId: "",
      allowToRunOnline: "",
      allowToRunAsFile: "",
      allowToChangeParameter: "",
      fileAttachment: "",
    },
  });

  const { data: stateData } = state;
  const { openModal } = useModal();

  const templatePath = watch("templatePath");

  // useEffect(() => {
  //   // console.log("dropdownData:", dropdownData);
  //   // console.log("data:", data);
  //   console.log("templatePath:", templatePath);
  // }, [templatePath]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) =>
  //     console.log("FormData", values)
  //   );
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  useEffect(() => {
    if (stateData) {
      reset({
        id: stateData.id || "",
        name: stateData.name || "",
        runReportTypeId: stateData.runReportTypeId || "",
        fileNamePrefix: stateData.fileNamePrefix || "",
        queryTable: stateData.queryTable || "",
        genFilePath: stateData.genFilePath || "",
        templatePath: stateData.templatePath || "",
        apiGroupId: stateData.apiGroupId || "",
        allowToRunOnline: stateData.allowToRunOnline,
        allowToRunAsFile: stateData.allowToRunAsFile,
        allowToChangeParameter: stateData.allowToChangeParameter,
      });
    }
  }, [stateData, reset]);

  // API Group
  useEffect(() => {
    Hooks.getData(`${API_ENV}/ApiGroup?isRootLevel=true`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        apiGroupData: data,
      }));
    });
  }, []);

  // HANDLE Functions
  // Update
  const handleUpdate = useCallback(async () => {
    setIsButtonDisabled(true);

    // console.log("handleUpdate is called");
    const formData = getValues();

    const sendData = {
      id: formData.id,
      name: formData.name,
      fileNamePrefix: formData.fileNamePrefix,
      apiGroupId: formData.apiGroupId,
      runReportTypeId: formData.runReportTypeId,
      queryTable: formData.queryTable,

      genFilePath: formData.genFilePath,
      templatePath: formData.templatePath,
      allowToRunOnline: formData.allowToRunOnline,
      allowToRunAsFile: formData.allowToRunAsFile,
      allowToChangeParameter: formData.allowToChangeParameter,
    };

    function thenFunction(response) {
      refreshTable();
      cleanURL();

      setIsButtonDisabled(false);
    }

    // API call for Input Fields
    Hooks.sendData(
      sendData,
      `${API_ENV}/runReportList/${formData.id}`,
      "PUT",
      thenFunction,
    );

    const fileData = new FormData();
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput.files[0]) {
      fileData.append("file", fileInput.files[0]);

      Hooks.sendFileAttachment(
        fileData,
        `${API_ENV}/runReportList/uploadtemplate/${formData.id}`,
        "PUT",
      );
    } else {
      console.log("No file selected.");
    }
  }, [refreshTable, cleanURL, getValues]);

  // GenerateColumn
  const handleGenerateColumn = useCallback(
    async (formData) => {
      setIsButtonDisabled(true);

      function thenFunction(response) {
        refreshTable();

        setIsButtonDisabled(false);
      }

      Hooks.sendData(
        formData,
        `${API_ENV}/runReportList/GenerateColumn/${stateData.id}`,
        "PUT",
        thenFunction,
      );
    },
    [refreshTable, stateData.id],
  );

  // EditColumn
  const handleEditColumn = useCallback(async () => {
    openModal("EditColumn");
  }, [openModal]);

  const handleArchive = useCallback(async () => {
    setIsButtonDisabled(true);
    function thenFunction(response) {
      refreshTable();
      setIsButtonDisabled(false);
    }

    Hooks.sendData(
      null,
      `${API_ENV}/runReportList/${stateData.id}`,
      "DELETE",
      thenFunction,
    );
  }, [stateData.id, refreshTable]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  useEffect(() => {
    setFooterState([
      {
        text: "Update",
        click: handleSubmit(handleUpdate),
        disabled: isButtonDisabled,
      },
      {
        text: "Generate Columns",
        click: handleSubmit(handleGenerateColumn),
        disabled: isButtonDisabled,
      },
      { text: "Edit Columns", click: handleEditColumn },
      { text: "Archive", click: handleArchive },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    handleSubmit,
    handleUpdate,
    handleGenerateColumn,
    handleEditColumn,
    handleArchive,
    closeFunction,
    isButtonDisabled,
  ]);

  const handleDownloadTemplate = useCallback(
    async (e) => {
      try {
        // console.log("handleDownloadTemplate");

        const apiUrl = `${API_ENV}/runReportlist/downloadtemplate/${stateData.id}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${Hooks.getCookie("jwt")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // console.log("response", response);

        const blob = await response.blob(); // Get the binary data of the file
        // console.log("blob", blob);

        // Extract the file name from the path
        const fileName = stateData.templatePath.split("\\").pop();

        // Create a download link for the file
        const url = URL.createObjectURL(blob);
        const a = Object.assign(document.createElement("a"), {
          href: url,
          download: fileName, // Use the extracted file name for the download attribute
        });
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a); // Clean up by removing the anchor element
      } catch (error) {
        console.error(error);
      }
    },
    [stateData.id, stateData.templatePath],
  );

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <Input
            label={"Report Name"}
            name="name"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Report Type"}
            name="runReportTypeId"
            control={control}
            errors={errors}
            dropdownData={[
              { id: 1, name: "PDF" },
              { id: 2, name: "CSV" },
              { id: 3, name: "Excel" },
            ]}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <Input
            label={"File Name Prefix"}
            name="fileNamePrefix"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <Input
            label={"Query Table"}
            name="queryTable"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <Input
            label={"Gen File Path"}
            name="genFilePath"
            readOnly
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"API Group"}
            name="apiGroupId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.apiGroupData}
            optionName={"groupName"}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <Input
            label={"Allow Run Online"}
            name="allowToRunOnline"
            type="checkbox"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <Input
            label={"Allow Run as File"}
            name="allowToRunAsFile"
            type="checkbox"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <Input
            label={"Allow Change Parameter"}
            name="allowToChangeParameter"
            type="checkbox"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow></SidePanelFormRow>

        <SidePanelFormRow>
          <Input
            label={"Template Path"}
            name="templatePath"
            readOnly
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label>Upload Template</label>
          <FileInput {...register("fileAttachment")} />
        </SidePanelFormRow>

        {templatePath && (
          <SidePanelFormRow>
            <label>Download Current Template</label>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleDownloadTemplate();
              }}
              style={{ marginTop: "-1.2rem", padding: "0.75rem" }}
            >
              Download
            </Button>
          </SidePanelFormRow>
        )}
      </div>

      <Modal name="EditColumn" title={"Edit Columns"} size="maxWidth">
        <EditColumn
          reportId={stateData.id}
          // reportType={formData.dependencies.runReportTypeId}
        />
      </Modal>
    </Form>
  );
}
