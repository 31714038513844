import API_ENV from "@config";
import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";
import AddressInput from "features/forms/AddressInput";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    storeTypeId: yup.string().required("This field is required"),
    name: yup.string().required("This field is required"),

    // address1: yup.string().required("This field is required"),
    // town: yup.string().required("This field is required"),
    // county: yup.string().required("This field is required"),
    // postCode: yup
    //   .string()
    //   .required("This field is required")
    //   .matches(
    //     /^([A-Z]{1,2}[0-9][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}|GIR\s?0AA)$/i,
    //     "Invalid UK postcode"
    //   ),
  })
  .required();

export default function ViewWarehouse({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      storeTypeId: "",
      name: "",

      address1: "",
      address2: "",
      address3: "",
      address4: "",
      town: "",
      county: "",
      postCode: "",
    },
  });

  // const { openModal } = useModal();
  const { data: stateData } = state;

  useEffect(() => {
    console.log("data:", stateData);
    // console.log("dropdownData:", dropdownData);
  }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log(values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/storeType?showarchive=false`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });
  }, []);

  // Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: stateData.id,
        // * BOOLEANS need || "" removed!
        storeTypeId: stateData.storeTypeId,
        name: stateData.name || "",
        address1: stateData.address1 || "",
        address2: stateData.address2 || "",
        address3: stateData.address3 || "",
        address4: stateData.address4 || "",
        town: stateData.town || "",
        county: stateData.county || "",
        postCode: stateData.postCode || "",
      });
    }
  }, [stateData, reset]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          cleanURL();
          refreshTable();

          setIsButtonDisabled(false);
        }

        // console.log("apiUrl", apiUrl);
        // console.log("handleSubmitForm data:", data);

        Hooks.sendData(
          data,
          `${API_ENV}/${apiUrl}/${data.id}`,
          "PUT",
          thenFunction,
        );
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, cleanURL, apiUrl],
  );

  const handleArchive = useCallback(() => {
    archive();
  }, [archive]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Submit Form",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <DropdownList
            label={"Store Type"}
            name="storeTypeId"
            control={control}
            errors={errors}
            disabled
            dropdownData={dropdownData.dropdownData1}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <Input label={"Name"} name="name" control={control} errors={errors} />
        </SidePanelFormRow>

        {stateData.storeTypeId !== 2 && (
          <AddressInput
            fields={[
              { label: "Address 1", name: "address1" },
              { label: "Address 2", name: "address2" },
              { label: "Address 3", name: "address3" },
              { label: "Address 4", name: "address4" },
              { label: "Town", name: "town" },
              { label: "County", name: "county" },
              { label: "Postcode", name: "postCode" },
            ]}
            register={register}
            errors={errors}
          />
        )}
      </div>

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
