import "../styles/ui/AppLayout.scss";

import { Outlet, useNavigate } from "react-router-dom";
import Hooks from "../Hooks";
import { useEffect, useState } from "react";

import Header from "./Header";
import Sidebar from "./Sidebar";
import SessionExpired from "./SessionExpired";
import ErrorBoundary from "context/ErrorBoundary";

function mainClicked() {
  Hooks.setCookie("signedIn", "true", 1);
}

export default function AppLayout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const navigate = useNavigate();
  const [sessionExpired, setSessionExpired] = useState(false);

  // Checks if the user's session has expired.
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        Hooks.getCookie("signedIn") !== "true" &&
        document.querySelectorAll("#loginForm").length === 0 &&
        document.querySelectorAll("#sessionPopup").length === 0
      ) {
        setSessionExpired(true);
      }
    }, 10000);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`appLayout ${isSidebarOpen ? "open" : "closed"}`}>
      {sessionExpired ? (
        <SessionExpired
          buttonClicked={() => {
            Hooks.deleteCookies();
            navigate("/login");
          }}
        />
      ) : null}

      <Header />

      <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />

      <main className="main" onClick={mainClicked}>
        <div className="container">
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </div>
      </main>
    </div>
  );
}
