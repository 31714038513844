import "../styles/ui/Logo.scss";
import Heading from "./Heading";

export default function Logo() {
  const src = "/vanguardLogo.png";

  return (
    <div className="logo">
      <img src={src} alt="Logo" className="logoImg" />
      <Heading as="h3">Vanguard</Heading>
    </div>
  );
}
