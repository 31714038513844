import API_ENV from "@config";
import "./inspectionQuestions.scss";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Hooks from "../../../../Hooks";

export default function Question({ data, modifyClicked, index }) {
  const [answer, updateAnswer] = useState();

  function getSelectAnswers(options) {
    if (options && options[1]) {
      let array = [];
      for (const value in options) {
        array.push(<option key={options[value]}>{options[value]}</option>);
      }
      return array;
    } else {
      return <option>No options set.</option>;
    }
  }

  useEffect(() => {
    if (data && data.answerType) {
      switch (Hooks.conditionalString(data.answerType)) {
        case "freetext":
          updateAnswer(
            <input className="answer" type="text" value="Free Text" readOnly />,
          );
          break;
        case "yes/no":
        case "dropdownlist":
        case "tickbox":
          updateAnswer(
            <select className="answer dropdownAnswer">
              {getSelectAnswers(data.answerChoice)}
            </select>,
          );
          break;
        case "nil":
        default:
          updateAnswer(
            <input
              className="answer"
              type="text"
              value={"No choice available"}
              readOnly
            />,
          );
          break;
      }
    }
  }, [data]);

  function deleteClicked(target) {
    Swal.fire({
      title: "Delete question?",
      text: "Are you sure you wish to delete this question?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        function thenFunction(response) {
          if (response.ok) {
            target.remove();
          }
        }

        const questionId = JSON.parse(target.getAttribute("data")).id;
        Hooks.sendData(
          {},
          `${API_ENV}/inspectionQuestion/${questionId}`,
          "DELETE",
          thenFunction,
        );
      }
    });
  }

  return (
    <div className="questionContainer" data={JSON.stringify(data)}>
      <div className="questionNumber">Question {index}</div>
      <input
        type="text"
        className="description"
        value={data.question}
        readOnly
      />
      {answer}

      <div className="questionFooter">
        <div
          className="questionModify questionButton"
          onClick={(e) => modifyClicked(e.target)}
        >
          Modify
        </div>

        <div
          className="questionDelete questionButton"
          onClick={(e) => {
            deleteClicked(e.target.parentElement.parentElement);
          }}
        >
          Delete
        </div>
      </div>
    </div>
  );
}
