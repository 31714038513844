import "../../../styles/features/addPopup.scss";

import React, { useEffect, useState } from "react";

import API_ENV from "@config";
import Hooks from "@hooksFile";

export default function AddPopup({ type, confirmClick }) {
  const [form, updateForm] = useState([]);

  useEffect(() => {
    if (type && type.length) {
      if (type === "notes") {
        Hooks.getData(`${API_ENV}/actionevent?limit=15`, (data) => {
          if (data && data.length) {
            let array = [];
            data.forEach((action) => {
              array.push(
                <option value={action.id} key={action.id}>
                  {action.name}
                </option>,
              );
            });
            updateForm(
              <React.Fragment>
                <select id="actionSelect" className="select">
                  {array}
                </select>

                <textarea
                  id="noteInput"
                  className="input left"
                  placeholder="Enter new note..."
                  rows={6}
                ></textarea>
              </React.Fragment>,
            );
          }
        });
      } else if (type === "todo") {
        updateForm(
          <textarea
            id="todoInput"
            className="input left"
            placeholder="Enter new todo..."
            rows={6}
          ></textarea>,
        );
      }
    }
  }, [type, confirmClick]);

  function closePopup() {
    document.querySelector("#addPopup").style.display = "none";
  }

  return (
    <div id="addPopup">
      <div id="addPopupTitle">
        <div className="popupTitle">Add new {type}</div>

        <div className="closePopup" onClick={closePopup}>
          X
        </div>
      </div>

      <div id="addPopupContent">{form}</div>

      <div id="addPopupFooter">
        <div
          id="addPopupConfirm"
          onClick={() => {
            confirmClick(type);
          }}
        >
          Confirm
        </div>
      </div>
    </div>
  );
}
