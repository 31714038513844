import React, { useEffect, useCallback } from "react";
import Form from "@ui/Form";
import AssignedItems from "features/forms/Jobs/AssignedItems";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
// const schema = yup
//   .object({
//     // ! TO DO
//     // * BOOLEANS need to be yup.bool()
//     field1: yup.string().required("This field is required"),

//     // For conditional fields which only appear based on another fields value
//     // field2: yup.string().when("field1", {
//     //   is: "value",
//     //   then: () => yup.string().required("This field is required"),
//     //   otherwise: () => yup.string().notRequired(),
//     // }),
//   })
//   .required();
export default function ViewAssignedStaffToContract({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
  setFormState,
}) {
  // const [dropdownData, setDropdownData] = useState({
  //   dropdownData1: [],
  // });

  // const [contentListData, setContentListData] = useState([]);
  // const { tableRowId } = useParams();

  // const {
  //   // control,
  //   // handleSubmit,
  //   // setValue,
  //   reset,
  //   // register,
  //   // watch,
  //   // formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(schema),
  //   defaultValues: {
  //     // ! TO DO
  //     field1: "",
  //   },
  // });

  // const sorRefId = watch("sorRefId");

  // const { openModal } = useModal();
  const { data: stateData } = state;

  // * UseEffect Hooks: Tracking
  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // Fill form via state
  // useEffect(() => {
  //   if (contentListData && contentListData.length > 0) {
  //     reset({
  //       // ID here is needed for PUT call in handleSubmitForm
  //       id: contentListData[0].id,
  //       // ! TO DO
  //       // * BOOLEANS need || "" removed!
  //       field1: contentListData[0]?.field1 || "",
  //     });
  //   }
  // }, [contentListData, reset]);

  // * UseEffect Hooks: Form/API
  // contentListData
  // useEffect(() => {
  //   // API GET contentList data
  //   // jobType/contract/${Hooks.getSidePanelData().id}
  //   Hooks.getData(`${API_ENV}/jobType/contract/${tableRowId}`, (data) => {
  //     // console.log("contentListData", data);
  //     setContentListData(data);
  //   });
  // }, [tableRowId]);

  // Dropdown List Data
  // useEffect(() => {
  //   // ! TO DO
  //   Hooks.getData(`${API_ENV}/`, (data) => {
  //     // console.log("useEffect API 1 data:", data);
  //     setDropdownData((prevData) => ({
  //       ...prevData,
  //       dropdownData1: data,
  //     }));
  //   });
  // }, []);

  // HANDLE Functions
  // const handleSubmitForm = useCallback(
  //   async (data) => {
  //     function thenFunction(response) {
  //       refreshTable();
  //       // cleanURL();
  //       Hooks.getData(`${API_ENV}/jobSor/job/${tableRowId}`, (data) => {
  //         setContentListData(data);
  //       });

  // setIsButtonDisabled(false);
  //     }

  //     const dataObject = {};

  //     // console.log("handleSubmitForm data:", data);
  //     // console.log("handleSubmitForm dataObject:", dataObject);

  //     Hooks.sendData(
  //       dataObject,
  //       `${API_ENV}/jobsor/${data.id}`,
  //       "PUT",
  //       thenFunction
  //     );
  //   },
  //   [refreshTable, tableRowId]
  // );

  const handleAddStaffToContract = useCallback(() => {
    setFormState({ type: "assignstaff", data: {} });
  }, [setFormState]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      // { text: "Submit Form", click: handleSubmit(handleSubmitForm), disabled: isButtonDisabled, },
      { text: "Add Staff To Contract", click: handleAddStaffToContract },
      // { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleAddStaffToContract,
    // handleSubmit,
    // handleSubmitForm,
    // handleArchive,
    // handleOpenModal,
    // isButtonDisabled,
  ]);

  return (
    <Form>
      <div className="contentListContainer">
        <AssignedItems
          data={stateData}
          type={state?.type}
          key={state?.type}
          setFormState={setFormState}
        />
      </div>
    </Form>
  );
}
