import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
// import DropdownList from "@ui/DropdownList";
import DateTimePicker from "@generic/DateTimePicker";
import ContentList from "features/forms/MultiContentForm/Components/ContentList";
import Textarea from "@ui/Textarea";
import CollapsibleGroup from "@ui/CollapsibleGroup";
import Tags from "@ui/Tags";
import AttributesDisplay from "@ui/AttributesDisplay";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    // equipmentTypeRefId: yup.string().required("This field is required"),
    // name: yup.string().required("This field is required"),
    // anniversaryDate: yup.string().required("This field is requireds
  })
  .required();

export default function ViewEquipmentDetails({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
  setFormState,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // const [dropdownData, setDropdownData] = useState({
  //   dropdownData1: [],
  // });
  const [contentListData, setContentListData] = useState([]);

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      equipmentTypeRefId: "",
      name: "",
      make: "",
      model: "",
      lastService: "",
      nextService: "",
      anniversaryDate: "",
      location: "",
      contractReadOnly: "",
      nextJobRefId: "",
      notes: "",
    },
  });

  // const { openModal } = useModal();
  const { data: stateData } = state;
  const sidePanelId = Hooks.getSidePanelData().id;

  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("sidePanelId:", sidePanelId);
  //   // console.log("objectDocId:", objectDocId);
  //   // console.log("objectDocTypeId:", objectDocTypeId);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) =>
  //     console.log("Form values:", values)
  //   );
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // Fill form via contentList data
  useEffect(() => {
    if (contentListData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: contentListData[0]?.id,
        // * BOOLEANS need || "" removed!
        // * Date values & others need to be null if there is no data!
        name: contentListData[0]?.name || "",
        lastService:
          Hooks.formatISODateToText(contentListData[0]?.lastService) || "",
        nextService:
          Hooks.formatISODateToText(contentListData[0]?.nextService) || "",
        notes: contentListData[0]?.notes || "",
        anniversaryDate: contentListData[0]?.anniversaryDate || "",
        location: contentListData[0]?.location || "",
        equipmentTypeRefId: contentListData[0]?.equipmentTypeRefId || "",
        propertyRefId: contentListData[0]?.propertyRefId || "",

        // Data only to be displayed but not sent via POST & PUT
        equipmentType: contentListData[0]?.equipmentType.model || "",
        make: contentListData[0]?.equipmentType?.make || "",
        model: contentListData[0]?.equipmentType?.model || "",
        contractReadOnly: contentListData[0]?.contract?.name || "",
        nextJobRefId: contentListData[0]?.nextJobRefId || "",
      });
    }
  }, [reset, contentListData]);

  // * UseEffect Hooks
  useEffect(() => {
    // console.log("sidePanelId", sidePanelId);

    // API GET contentList data
    Hooks.getData(`${API_ENV}/equipment/property/${sidePanelId}`, (data) => {
      // console.log(data);
      setContentListData(data);
    });

    // Hooks.getData(
    //   `${API_ENV}/equipmentType/property/${sidePanelId}`,
    //   (data) => {
    //     // console.log("equipmentType/property/{sidePanelId}", data);
    //     setDropdownData((prevData) => ({
    //       ...prevData,
    //       dropdownData1: data,
    //     }));
    //   }
    // );
  }, [stateData.id, sidePanelId]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      setIsButtonDisabled(true);

      function thenFunction(response) {
        Hooks.getData(
          `${API_ENV}/equipment/property/${sidePanelId}`,
          (data) => {
            // console.log(data);
            setContentListData(data);
          },
        );

        setIsButtonDisabled(false);
      }

      // Destructure the properties to remove them from the new object
      const {
        // id,
        // name,
        // lastService,
        // nextService,
        // notes,
        // anniversaryDate,
        // location,
        // equipmentTypeRefId,
        // propertyRefId,
        equipmentType,
        contractReadOnly,
        make,
        model,
        ...filteredData
      } = data;

      // console.log("handleSubmitForm data:", data);
      // console.log("handleSubmitForm filteredData:", filteredData);

      Hooks.sendData(
        filteredData,
        `${API_ENV}/equipment/${data.id}`,
        "PUT",
        thenFunction,
      );
    },
    [sidePanelId],
  );

  const handleArchive = useCallback(() => {
    archive(stateData.id);
  }, [archive, stateData.id]);

  const handleAddEquipment = useCallback(() => {
    function getDate(id) {
      try {
        return new Date(document.querySelector(id).value).toISOString();
      } catch (e) {
        return null;
      }
    }
    function checkNumber(string) {
      if (parseInt(string)) {
        return parseInt(string);
      } else {
        return 0;
      }
    }
    function checkText(string) {
      if (string && string.length) {
        return string;
      } else {
        return null;
      }
    }
    function sendEquipment() {
      const loadedData = Hooks.getSidePanelData();

      const data = {
        name: checkText(document.querySelector("#nameInput").value),
        equipmentTypeRefId: checkNumber(
          document.querySelector("#equiptypeInput").getAttribute("data"),
        ),
        propertyRefId: checkNumber(loadedData.id),
        notes: checkText(document.querySelector("#equipmentnotesInput").value),
        location: checkText(document.querySelector("#locationInput").value),
        anniversaryDate: getDate("#anniversarydateInput"),
      };

      function thenFunction(response) {
        if (response.ok) {
          document.querySelector(".currentTab").click();
        }
        setIsButtonDisabled(false);
      }

      Hooks.sendData(data, `${API_ENV}/equipment`, "POST", thenFunction);
    }

    setFormState({ type: "addequipment", data: {} });
    setFooterState([
      { text: "Add Equipment", click: sendEquipment },
      { text: "Close", click: cleanURL },
    ]);
  }, [setFormState, cleanURL, setFooterState]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Submit Form",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      { text: "Add Equipment", click: handleAddEquipment },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    handleAddEquipment,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  function targetClicked(event, item) {
    // Directly use the passed item object to update the form
    // No need to parse eventData from the event.target.getAttribute
    // console.log("targetClicked item: ", item);

    reset({
      // ID here is needed for PUT call in handleSubmitForm
      id: item?.id,
      // * BOOLEANS need || "" removed!
      // * Date values & others need to be null if there is no data!
      name: item?.name || "",
      lastService: Hooks.formatISODateToText(item?.lastService) || "",
      nextService: Hooks.formatISODateToText(item?.nextService) || "",
      notes: item?.notes || "",
      anniversaryDate: item?.anniversaryDate || "",
      location: item?.location || "",
      equipmentTypeRefId: item?.equipmentTypeRefId || "",
      propertyRefId: item?.propertyRefId || "",

      // Data only to be displayed but not sent via POST & PUT
      equipmentType: item?.equipmentType.model || "",
      make: item?.equipmentType?.make || "",
      model: item?.equipmentType?.model || "",
      contractReadOnly: item?.contract?.name || "",
      nextJobRefId: item?.nextJobRefId || "",
    });
  }

  return (
    <Form>
      <div className="contentListContainer">
        <ContentList
          data={contentListData}
          type={"equipmentdetails"}
          clicked={targetClicked}
        />
      </div>

      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          {/* <SidePanelFormRow>
          <DropdownList
            label={"Equipment Type"}
            name="equipmentTypeRefId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
            optionName="model"
          />
        </SidePanelFormRow> */}

          <SidePanelFormRow>
            <Input
              label={"Name"}
              name="name"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Equipment Type"}
              name="equipmentType"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Make"}
              name="make"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>
          <SidePanelFormRow>
            <Input
              label={"Model"}
              name="model"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>
          <SidePanelFormRow>
            <Input
              label={"Last Service"}
              name="lastService"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>
          <SidePanelFormRow>
            <Input
              label={"Next Service"}
              name="nextService"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Anniversary Date</label>
            <Controller
              name="anniversaryDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Location"}
              name="location"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Contract Read Only"}
              name="contractReadOnly"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Scheduled Job"}
              name="nextJobRefId"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow type="full">
            <Textarea
              label={"Notes"}
              name="notes"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>
        </div>
      </CollapsibleGroup>

      {/* //. Tags  */}
      <CollapsibleGroup title="Tags">
        <Tags
          // objectDocTypeId =  1 = Job
          // objectDocTypeId =  2 = Equipment
          // objectDocTypeId =  3 = Property
          // objectDocTypeId =  4 = Staff
          // objectDocId =  ID of Job/Equipment/Property/Staff
          objectDocTypeId={2}
          objectDocId={sidePanelId}
        />
      </CollapsibleGroup>

      {/* //. Attributes  */}
      <CollapsibleGroup title="Attributes">
        <AttributesDisplay
          // objectDocTypeId =  1 = Job
          // objectDocTypeId =  2 = Equipment
          // objectDocTypeId =  3 = Property
          // objectDocTypeId =  4 = Staff
          // objectDocId =  ID of Job/Equipment/Property/Staff
          objectDocTypeId={2}
          objectDocId={sidePanelId}
        />
      </CollapsibleGroup>
    </Form>
  );
}
