import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import Hooks from "../../../../Hooks";

export default function QuestionPopup({ data, applyClicked }) {
  const [answers, updateAnswers] = useState([]);
  const [question, updateQuestion] = useState("");
  const [types, updateTypes] = useState(
    <option value={0} key={0}>
      No value
    </option>,
  );

  useEffect(() => {
    if (data) {
      if (data.question) {
        updateQuestion(data.question);
      }

      if (data.answerChoice) {
        const answerType = Hooks.conditionalString(data.answerType);

        if (answerType === "dropdownlist" || answerType === "tickbox") {
          let array = [];
          for (let answer in data.answerChoice) {
            if (data.answerChoice[answer]) {
              array.push(
                <div className="questionMultiInput" key={Math.random()}>
                  <input
                    className="answerInput"
                    type="text"
                    defaultValue={data.answerChoice[answer]}
                  />
                  <div
                    className="deleteInput"
                    onClick={(e) => {
                      deleteInputClicked(e.target);
                    }}
                  >
                    X
                  </div>
                </div>,
              );
            }
          }

          updateAnswers(array);
          document.querySelector("#addQuestionButton").style.display = "flex";
        } else if (answerType.toLowerCase() === "yes/no") {
          document.querySelector("#addQuestionButton").style.display = "none";
          updateAnswers([
            <select id="answerDropdown" key={Math.random()}>
              <option>Yes</option>
              <option>No</option>
            </select>,
          ]);
        } else if (answerType === "freetext") {
          document.querySelector("#addQuestionButton").style.display = "none";
          updateAnswers([
            <input
              className="answerInput"
              type="text"
              value="Free Text"
              readOnly
            />,
          ]);
        }
      }

      const questionId = data.answerTypeRefId,
        questionType = data.answerType;

      Hooks.getData(
        `${API_ENV}/answerType?InspectionFlag=true&showarchive=false`,
        (data) => {
          if (data && data.length && questionId) {
            let array = [
              <option value={questionId} key={Math.random()}>
                {questionType}
              </option>,
            ];
            data.forEach((type) => {
              if (type.desc !== questionType) {
                array.push(
                  <option value={type.id} key={Math.random()}>
                    {type.desc}
                  </option>,
                );
              }
            });
            updateTypes(
              <select
                id="typeSelect"
                key={Math.random()}
                onChange={(e) => {
                  typeChanged(e.target);
                }}
              >
                {array}
              </select>,
            );
          }
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function typeChanged(typeSelect) {
    if (typeSelect) {
      const selectedIndex = typeSelect.selectedIndex;
      switch (
        Hooks.conditionalString(
          typeSelect.querySelectorAll("option")[selectedIndex].textContent,
        )
      ) {
        case "yes/no":
          document.querySelector("#addQuestionButton").style.display = "none";
          updateAnswers([
            <select key={Math.random()}>
              <option>Yes</option>
              <option>No</option>
            </select>,
          ]);
          break;
        case "tickbox":
        case "dropdownlist":
          updateAnswers([
            <div className="questionMultiInput" key={Math.random()}>
              <input className="answerInput" type="text" placeholder="Answer" />
            </div>,
          ]);
          document.querySelector("#addQuestionButton").style.display = "flex";
          break;
        case "freetext":
          document.querySelector("#addQuestionButton").style.display = "none";
          updateAnswers([
            <input
              className="answerInput"
              key={Math.random()}
              type="text"
              value="Free Text"
              readOnly
            />,
          ]);
          break;
        default:
          break;
      }
    }
  }

  function deleteInputClicked(element) {
    try {
      if (element && element.parentElement) {
        element.parentElement.remove();
      }
    } catch (e) {
      console.log(e);
    }
  }

  function closeClicked() {
    document.querySelector("#questionPopup").style.display = "none";
    // Reset the form to the default state:
    updateAnswers([]);
    updateQuestion("");
    updateTypes(<option value={0}>No value</option>);
  }

  function addAnswer() {
    const selectedIndex = document.querySelector("#typeSelect").selectedIndex;
    const answerType = Hooks.conditionalString(
      document.querySelector("#typeSelect").querySelectorAll("option")[
        selectedIndex
      ].textContent,
    );

    if (answerType === "dropdownlist" || answerType === "tickbox") {
      updateAnswers(
        answers.concat(
          <div className="questionMultiInput" key={Math.random()}>
            <input
              className="answerInput"
              type="text"
              placeholder="Answer"
              key={Math.random()}
            />
            <div
              className="deleteInput"
              onClick={(e) => {
                deleteInputClicked(e.target);
              }}
            >
              X
            </div>
          </div>,
        ),
      );
    }
  }

  return (
    <div id="questionPopup">
      <div id="popupHeader">
        <h3>Modify Question</h3>
      </div>

      <div id="popupContent">
        <div className="popupRow">
          <div className="popupLabel">Question Type</div>
          {types}
        </div>

        <div className="popupRow">
          <div className="popupLabel">Question</div>
          <textarea
            id="popupQuestion"
            rows="3"
            type="text"
            defaultValue={question}
          />
        </div>

        <div className="popupRow">
          <div className="popupLabel">Answers</div>
          <div id="addQuestionButton" onClick={addAnswer}>
            Add
          </div>

          {answers}
        </div>
      </div>

      <div id="popupFooter">
        <div
          id="popupApply"
          onClick={() => {
            applyClicked(data);
          }}
        >
          Apply
        </div>

        <div id="popupClose" onClick={closeClicked}>
          Close
        </div>
      </div>
    </div>
  );
}
