import API_ENV from "@config";
import React, { useState } from "react";

import Hooks from "../../../Hooks";
import "./partsearch.scss";
import {
  HiOutlineEye,
  HiOutlineMinusCircle,
  HiOutlinePlusCircle,
} from "react-icons/hi2";

export default function PartCatItem({
  //id,
  //name,
  //withLeaf,
  //archived,
  itemData,
  CatLevel,
  select,
  leaf,
  //partno,
  partsCategoryId,
}) {
  const [IsOpen, setIsOpen] = useState(false);
  const [Res, setRes] = useState(null);
  const [ResPart, setResPart] = useState(null);

  CatLevel = CatLevel + 1 || 1;

  const Toggle = (e) => {
    e.stopPropagation();
    if (CatLevel === 1 && itemData?.withLeaf === true) {
      if (!Res) {
        Hooks.getData(
          `${API_ENV}/partsSubCategory${CatLevel}?PartsCategoryID=${itemData?.id}`,
          (res) => {
            setRes(res);

            Hooks.getData(
              `${API_ENV}/parts/getpartleaf?partcategoryid=${itemData?.id}`,
              (partRes) => {
                setResPart(partRes);

                setIsOpen((open) => !open);
              },
            );
          },
        );
      } else {
        setIsOpen((open) => !open);
      }
    } else if (CatLevel === 2 && itemData?.withLeaf === true) {
      if (!Res) {
        Hooks.getData(
          `${API_ENV}/partsSubCategory${CatLevel}?PartsCategoryID=${itemData?.partsCategoryId}`,
          (res) => {
            setRes(res);

            Hooks.getData(
              `${API_ENV}/parts/getpartleaf?partcategoryid=${itemData?.partsCategoryId}&partsubcategory1id=${itemData?.id}`,
              (partRes) => {
                setResPart(partRes);

                setIsOpen((open) => open);
              },
            );
          },
        );
      } else {
        setIsOpen((open) => !open);
      }
    } else if (CatLevel === 3 && itemData?.withLeaf === true) {
      if (!Res) {
        Hooks.getData(
          `${API_ENV}/partsSubCategory${CatLevel}?PartsCategoryID=${itemData?.partsCategoryId}`,
          (res) => {
            setRes(res);

            Hooks.getData(
              `${API_ENV}/parts/getpartleaf?partcategoryid=${partsCategoryId}&partsubcategory2id=${itemData?.id}`,
              (partRes) => {
                setResPart(partRes);

                setIsOpen((open) => open);
              },
            );
          },
        );
      } else {
        setIsOpen((open) => !open);
      }
    } else if (CatLevel === 4 && itemData?.withLeaf === true) {
      if (!Res) {
        Hooks.getData(
          `${API_ENV}/partsSubCategory${CatLevel}?PartsSubCategory2ID=${itemData?.partsCategoryId}`,
          (res) => {
            setRes(res);

            Hooks.getData(
              `${API_ENV}/parts/getpartleaf?partcategoryid=${partsCategoryId}&partsubcategory3id=${itemData?.id}`,
              (partRes) => {
                setResPart(partRes);

                setIsOpen((open) => open);
              },
            );
          },
        );
      } else {
        setIsOpen((open) => !open);
      }
    } else if (CatLevel === 5 && itemData?.withLeaf === true) {
      if (!Res) {
        Hooks.getData(
          `${API_ENV}/partsSubCategory${CatLevel}?PartsCategoryID=${partsCategoryId}`,
          (res) => {
            setRes(res);

            Hooks.getData(
              `${API_ENV}/parts/getpartleaf?partcategoryid=${partsCategoryId}&partsubcategory4id=${itemData?.id}`,
              (partRes) => {
                setResPart(partRes);

                setIsOpen((open) => open);
              },
            );
          },
        );
      } else {
        setIsOpen((open) => !open);
      }
    } else {
      setIsOpen((open) => !open);
    }
  };

  const renderIcon = () => {
    if (itemData?.withLeaf) {
      return IsOpen ? (
        <HiOutlineMinusCircle aria-label="Collapse" />
      ) : (
        <HiOutlinePlusCircle aria-label="Expand" />
      );
    } else {
      return <HiOutlineEye aria-label="View" />;
    }
  };

  return (
    <div className="partListItem PartCatItem" onClick={Toggle}>
      <div className="itemHeader">
        <div className="headerTitle">
          <div className="partExpand">{renderIcon()}</div>
          {itemData?.name}
          {itemData?.partno}
        </div>
        {itemData?.partno && ( // Only render the "Select" button if partno exists
          <div
            className="partSelect"
            onClick={(e) => {
              e.stopPropagation();

              select(itemData);
            }}
          >
            Select
          </div>
        )}
      </div>
      <div className="partsHierarchy" hidden={!IsOpen}>
        {Res &&
          Res.map &&
          Res.map((part, index) => (
            <PartCatItem
              key={index}
              itemData={part}
              CatLevel={CatLevel}
              partsCategoryId={itemData?.partsCategoryId}
              select={select}
            />
          ))}
      </div>
      <div className="partsHierarchy" hidden={!IsOpen}>
        {ResPart &&
          ResPart.map &&
          ResPart.map((part, index) => (
            <PartCatItem
              key={index}
              itemData={part}
              CatLevel={CatLevel}
              partsCategoryId={itemData?.partsCategoryId}
              select={select}
            />
          ))}
      </div>
    </div>
  );
}
