import API_ENV from "@config";
import "../../styles/features/SidePanel.scss";

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import Hooks from "../../Hooks";
import Swal from "sweetalert2";
import CancelJobPopup from "../forms/Jobs/CancelJobPopup";
import GenerateForm from "../forms/GenerateForm";
import SidePanelTabs from "./SidePanelComponents/SidePanelTabs";
import FooterButtons from "./SidePanelComponents/FooterButtons";
import HR from "@ui/HR";

export default forwardRef(function SidePanel(
  {
    type,
    data,
    sidePanelDataObject,
    refreshTable,
    search,
    pageTitle,
    searchId,
    validateSet,
    createDeliveryOrder,
    showStockPopup,
    partSearch,
    selectedPart,
    updatePopup,
    showSidePanel,
    setShowSidePanel,
  },
  ref,
) {
  const [sidePanelKey, setSidePanelKey] = useState("");
  const [title, setTitle] = useState("");

  const [tabState, setTabState] = useState([]);
  const [formState, setFormState] = useState({});
  const [footerState, setFooterState] = useState();
  const [sidePanelData, updateSidePanelData] = useState({});

  const page = useRef();
  const sidePanelTitle = useRef(null);
  const sidePanelTabs = useRef(null);

  const { tableRowId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Check state changes here, disable or remove once done
  // useEffect(() => {
  //   // console.log("type: ", type);
  //   // console.log("data: ", data);
  //   // console.log("sidePanelDataObject: ", sidePanelDataObject);
  //   // console.log("tabState", tabState);
  //   console.log("formState: ", formState);
  //   // console.log("footerState:", footerState);
  //   // console.log("sidePanelData:", sidePanelData);
  //   // console.log("sidePanelTitle:", sidePanelTitle);
  //   // console.log("title:", title);
  //   // console.log("pageTitle:", pageTitle.current.innerText);
  // }, [formState]);

  useEffect(() => {
    if (sidePanelKey !== "") {
      setShowSidePanel(true);
    }
  }, [sidePanelKey, setShowSidePanel]);

  function refresh() {
    setFormState({ type: "empty", data: {} });
    setFooterState([]);
  }

  //! Temp Zone Pending Deletion: Forms Refactor

  //! End of Temp Zone

  // Loads on page load & when clicking a table row
  const getDefaultTab = useCallback(() => {
    setCurrentTabIndex(0);
    // console.log("getDefaultTab");

    // THIS IS WHERE TO ADD PAGE TO CLOSE SIDEPANEL!
    // Determines what tabs should be loaded by mapping them to a key.
    // KEEP KEY/VALUES PAIRS DURING FORMS REFACTOR
    const tabs = {
      clients: [{ text: "Client Details", click: tabClicked }],
      contracts: [
        { text: "Contract Details", click: tabClicked },
        { text: "Assigned Staff", click: tabClicked },
        { text: "Scheduling", click: tabClicked },
        { text: "SOR Lists", click: tabClicked },
        { text: "Job Types", click: tabClicked },
        { text: "Cancel Reasons", click: tabClicked },
        { text: "Parts", click: tabClicked },
      ],
      properties: [
        { text: "Property Details", click: tabClicked },
        { text: "Assigned Jobs", click: tabClicked },
        { text: "Resident", click: tabClicked },
        { text: "Assigned Contracts", click: tabClicked },
        { text: "Equipment Details", click: tabClicked },
      ],
      jobs: [
        { text: "Job Details", click: tabClicked },
        { text: "View Visits", click: tabClicked },
        { text: "Attachments", click: tabClicked },
        { text: "Inspections", click: tabClicked },
        { text: "Job Parts", click: tabClicked },
        { text: "Job SORs", click: tabClicked },
      ],
      staff: [
        { text: "Staff Details", click: tabClicked },
        { text: "Manage Role", click: tabClicked },
        { text: "Contract Information", click: tabClicked },
        { text: "Certifications", click: tabClicked },
      ],
      parts: [
        { text: "Part Details", click: tabClicked },
        { text: "Parts & Suppliers", click: tabClicked },
      ],
      warehouses: [
        { text: "Warehouse Details", click: tabClicked }, // Show for all
        // { text: "Stock location", click: tabClicked }, // Only Warehouse
        // { text: "Van Engineers", click: tabClicked }, // Only Vans
      ],
      suppliers: [
        { text: "Supplier Details", click: tabClicked },
        { text: "Supplier Parts", click: tabClicked },
      ],
      kits: [{ text: "Kit Details", click: tabClicked }],
      equipmenttype: [{ text: "Type Details", click: tabClicked }],
      equipmentcategory: [{ text: "Category Details", click: tabClicked }],
      // equipmentsubcategory1: [
      //   { text: "Subcategory Details", click: tabClicked },
      // ],
      // equipmentsubcategory2: [
      //   { text: "Subcategory Details", click: tabClicked },
      // ],
      inspectionsets: [{ text: "Set Details", click: tabClicked }],
      jobstate: [{ text: "State Details", click: tabClicked }],
      jobtype: [
        { text: "Type Details", click: tabClicked },
        { text: "Inspection Sets", click: tabClicked },
      ],
      // KEEP KEY/VALUES PAIRS DURING FORMS REFACTOR
      cancelreason: [{ text: "Reason", click: tabClicked }],
      priority: [{ text: "Priority", click: tabClicked }],
      qcstatus: [{ text: "QC Status", click: tabClicked }],
      stafftype: [{ text: "Staff Type Details", click: tabClicked }],
      visitstate: [{ text: "Visit State Details", click: tabClicked }],
      processes: [{ text: "Process Message", click: tabClicked }],
      jobcategory: [{ text: "Job Category Details", click: tabClicked }],
      stock: [{ text: "Stock Details", click: tabClicked }],
      sor: [{ text: "SOR Details", click: tabClicked }],
      certifications: [{ text: "Certification Details", click: tabClicked }],
      role: [
        { text: "Role Details", click: tabClicked },
        { text: "Assigned APIs", click: tabClicked },
        { text: "Assigned Report", click: tabClicked },
        { text: "Assigned Users", click: tabClicked },
      ],
      apigroup: [{ text: "API Group Details", click: tabClicked }],
      api: [{ text: "API Details", click: tabClicked }],
      actionevent: [{ text: "Event Details", click: tabClicked }],
      documenttag: [{ text: "Tag Details", click: tabClicked }],
      partscategory: [{ text: "Part Category Details", click: tabClicked }],
      purchaserequests: [
        { text: "Purchase Request Details", click: tabClicked },
      ],
      purchaseorder: [{ text: "Purchase Order Details", click: tabClicked }],
      deliveries: [{ text: "Delivery Note Details", click: tabClicked }],
      costtype: [{ text: "Cost Type Details", click: tabClicked }],
      costcode: [{ text: "Cost Code Details", click: tabClicked }],
      partssubcategory1: [
        { text: "Parts Subcategory 1", click: tabClicked },
        { text: "Parts By SubCategory Level", click: tabClicked },
      ],
      partssubcategory2: [
        { text: "Parts Subcategory 2", click: tabClicked },
        { text: "Parts By SubCategory Level", click: tabClicked },
      ],
      partssubcategory3: [
        { text: "Parts Subcategory 3", click: tabClicked },
        { text: "Parts By SubCategory Level", click: tabClicked },
      ],
      partssubcategory4: [
        { text: "Parts Subcategory 4", click: tabClicked },
        { text: "Parts By SubCategory Level", click: tabClicked },
      ],
      stockcyclecount: [{ text: "Cycle Count", click: tabClicked }],
      attributeunit: [
        { text: "Attribute Unit", click: tabClicked },
        { text: "Dropdown List Choices", click: tabClicked },
      ],

      // KEEP KEY/VALUES PAIRS DURING FORMS REFACTOR
    };

    let toReturn = null;

    if (sidePanelDataObject && Object.keys(sidePanelDataObject).length > 0) {
      const lastPage = Hooks.getCookie("last_page");
      const tabConfig = Object.entries(tabs).find(([key]) => key === lastPage);

      if (tabConfig) {
        const [key, tabOptions] = tabConfig;
        // console.log("key", key);
        // console.log("sidePanelDataObject", sidePanelDataObject);

        if (key === "warehouses") {
          if (sidePanelDataObject?.storeTypeId === 1) {
            toReturn = [
              { text: "Warehouse Details", click: tabClicked }, // WARHOUSE
              { text: "Stock location", click: tabClicked }, // Only Warehouse
            ];
          } else if (sidePanelDataObject?.storeTypeId === 2) {
            toReturn = [
              { text: "Van Details", click: tabClicked }, // VAN
              { text: "Van Engineers", click: tabClicked }, // Only Vans
            ];
          } else if (sidePanelDataObject?.storeTypeId === 3) {
            toReturn = [{ text: "Locker Details", click: tabClicked }]; // LOCKER
          }
        } else {
          toReturn = tabOptions;
        }
      }
    }

    // console.log("toReturn", toReturn);
    return toReturn;
    // eslint-disable-next-line
  }, [sidePanelDataObject]);

  // Gets the default footer
  function getDefaultFooter() {
    const liveButton = document.querySelector("#liveButton");
    // DELETE KEY/VALUES FOR FORMS REFACTOR
    if (
      (liveButton &&
        Hooks.conditionalString(liveButton.textContent).includes("archived")) ||
      document.cookie.includes("processes") ||
      document.cookie.includes("certifications")
    ) {
      return [{ text: "Close", click: closeClicked }];
    } else {
      // Temporary = add function to get value from cookie already is in hooks
      if (document.cookie.includes("last_page=stock")) {
        return [
          {
            text: "Confirm",
            click: () => {
              const dropdown =
                document.querySelector("#stockactionDropdown") ||
                document.querySelector("#stockactionvanDropdown");
              if (!dropdown || !dropdown.getAttribute("data")) {
                Hooks.displayError("Please select a stock action.");
                return;
              }
              showStockPopup(dropdown.getAttribute("data"));
            },
          },
          { text: "Close", click: closeClicked },
        ];
      } else if (document.cookie.includes("deliveries")) {
        let lineNo = "";
        if (
          document.querySelector(".selectedLine") &&
          document.querySelector(".selectedLine").getAttribute("lineno")
        ) {
          lineNo = document
            .querySelector(".selectedLine")
            .getAttribute("lineno");
        }
        return [
          { text: "Apply Changes", click: updateDelivery },
          {
            text: "Add Detail",
            click: () => {
              addDeliveryDetail(lineNo);
            },
          },
          {
            text: "Validate",
            click: () => {
              validateSet("delivery");
            },
          },
          { text: "Import To Stock", click: importToStock },
          { text: "Return to Supplier", click: returnToSupplier },
          { text: "Archive", click: archive },
          { text: "Close", click: closeClicked },
        ];
        // DELETE KEY/VALUES FOR FORMS REFACTOR
      } else if (document.cookie.includes("purchaseorder")) {
        return [
          { text: "Apply Changes", click: updatePo },
          { text: "Add Detail", click: addPoDetail },
          { text: "Create Delivery Order", click: createDeliveryOrder },
          {
            text: "Validate PO",
            click: () => {
              validateSet("po");
            },
          },
          { text: "Print PO", click: "" },
          { text: "Archive", click: archive },
          { text: "Close", click: closeClicked },
        ];
      } else if (document.cookie.includes("purchaserequests")) {
        const data = Hooks.getSidePanelData();
        if (data && data.purchaseRequestStateId === 2) {
          return [
            { text: "Apply Changes", click: updatePr },
            { text: "Reject PR", click: rejectPr },
            { text: "Close", click: closeClicked },
          ];
        } else {
          return [
            { text: "Reject PR", click: rejectPr },
            { text: "Close", click: closeClicked },
          ];
        }
        // DELETE KEY/VALUES FOR FORMS REFACTOR
      } else if (document.cookie.includes("inspection")) {
        return [
          { text: "Apply Changes", click: modifyInspection },
          { text: "Add Question", click: addInspectionQuestion },
          { text: "Archive", click: archive },
          { text: "Close", click: closeClicked },
        ];
      }

      // This is for sidebar when clicking on a table row
      // Also need to not have anything inside or this will be used when disabling footer @ else if (title === "runreport")
      // DELETE KEY/VALUES FOR FORMS REFACTOR
      else if (
        document.cookie.includes("runreportlist") ||
        document.cookie.includes("runreportasfileoremail") ||
        document.cookie.includes("runreportproc") ||
        document.cookie.includes("runcustomprocess")
      ) {
        return [];
      } else {
        //* If Footer Buttons are still acting weird, add page name to refactoredPages
        //! Do I even need this anymore? Should only be affecting 1st form on load (Add or View form) Which all pages now have Footer Buttons set within form itself
        // const refactoredPages = ["jobs", "properties", "ticket"];
        // if (refactoredPages.includes(Hooks.getCookie("last_page"))) {
        //   return [];
        // } else {
        //   return [
        //     { text: "Apply Changes", click: applyClicked },
        //     { text: "Archive", click: archive },
        //     { text: "Close", click: closeClicked },
        //   ];
        // }
      }
    }
  }

  useEffect(() => {
    if (formState && formState.type) {
      page.current = formState.type;
    }
    updateSidePanelData(Hooks.getSidePanelData());
  }, [formState, tabState, footerState]);

  useEffect(() => {
    // Add a hooks function to get the title.
    if (pageTitle?.current?.textContent) {
      let title = Hooks.conditionalString(pageTitle?.current?.textContent);
      // console.log("title", title);
      if (type && type.includes("add")) {
        const addObject = {
          // KEEP KEY/VALUES PAIRS DURING FORMS REFACTOR
          properties: "property",
          jobs: "job",
          clients: "client",
          contracts: "contract",
          warehouses: "warehouses",
          kits: "kit",
          parts: "part",
          staff: "staff",
          equipmenttype: "equipmenttype",
          equipmentcategory: "equipmentcategory",
          inspectionsets: "inspectionset",
          priority: "priority",
          jobstate: "jobstate",
          jobtype: "jobtype",
          cancelreason: "cancelreason",
          suppliers: "supplier",
          stafftype: "stafftype",
          qcstatus: "qcstatus",
          jobstatetransitions: "jobstatetransition",
          visitstatetransitions: "visitstatetransition",
          stock: "stock",
          stockmovements: "stockmovements",
          equipmentsubcategory1: "equipmentsubcategory1",
          equipmentsubcategory2: "equipmentsubcategory2",
          jobcategory: "jobcategory",
          sor: "sor",
          sorlist: "sorlist",
          certifications: "certification",
          // KEEP KEY/VALUES PAIRS DURING FORMS REFACTOR
          role: "role",
          actionevent: "actionevent",
          apigroup: "apigroup",
          api: "api",
          documenttags: "documenttag",
          visitstate: "visitstate",
          purchaserequests: "purchaserequest",
          purchaseorders: "purchaseorder",
          partcategories: "partscategory",
          partssubcategory1: "partssubcategory1",
          partssubcategory2: "partssubcategory2",
          partssubcategory3: "partssubcategory3",
          partssubcategory4: "partssubcategory4",
          deliveries: "delivery",
          costcodes: "costcode",
          costtypes: "costtype",

          // The key name needs to match the return value from Hooks.formatKey switch statement (IE Page Title)...
          definereports: "runreportlist",
          // reportonline: "runreportasonlinelog",
          reportonlinecsvxls: "runreportasonlinecsvxls",
          reportonlinepdf: "runreportasonlinepdf",
          reportasfileoremail: "runreportasfileoremail",
          viewreportprocess: "runreportproc",
          customprocess: "runcustomprocess",
          letterprintsetup: "letterprintsetup",
          viewallprogramprocess: "viewallprogramprocess",
          viewschedulerprocess: "viewschedulerprocess",

          ticket: "ticket",
          tickettype: "tickettype",
          answertype: "answertype",
          objectdocumenttype: "objectdocumenttype",
          attributecategory: "attributecategory",
          attributeunit: "attributeunit",
          tags: "tags",
          documentworkflow: "documentworkflow",

          // KEEP KEY/VALUES PAIRS DURING FORMS REFACTOR
        };

        ref.current.setAttribute("data", "");

        setTitle(`Add New ${Hooks.formatKey(addObject[title])}`);

        // console.log("title", title);
        // console.log("addObject[title]", addObject[title]);
        // console.log(
        //   "Hooks.formatKey(addObject[title])",
        //   Hooks.formatKey(addObject[title])
        // );

        // console.log(sidePanelTitle.current.textContent)
        if (sidePanelTabs.current) {
          sidePanelTabs.current.style.display = "block";
        }

        // formState initially set here for header Add button
        setFormState({ type: `addnew${addObject[title]}`, data: {} });

        if (title === "purchaserequests") {
          setFooterState([
            { text: "Submit", click: addNewPr },
            { text: "Add More", click: addNewPr },
            { text: "Close", click: closeClicked },
          ]);
        } else if (title === "runreport") {
          // We have to make an entry so it doesnt use the default buttons but leave it blank because the footer buttons should be using state from the form component, rather then javascript querying fields inside SidePanel.js to then send to the API....
        } else {
          // This seems to be the default Add & Close buttons from the Add Form
          setFooterState([
            {
              text: "Add",
              click: () => {
                addNewEntry(addObject[title]);
              },
            },
            { text: "Close", click: closeClicked },
          ]);
        }

        setShowSidePanel(true);
      }
    } else if (type === "equipment") {
      setFormState({ type: "equipment", data: {} });
      setShowSidePanel(true);
    } else if (type === "addkit") {
      sidePanelTitle.current.textContent = "Add new kit";
      sidePanelTabs.current.style.display = "none";
      setFormState({ type: "addkit", data: {} });
      setFooterState([
        {
          text: "Add",
          click: () => {
            addNewEntry("kits");
          },
        },
        { text: "Close", click: closeClicked },
      ]);
      setShowSidePanel(true);
    } else if (type === "mainjobview") {
      sidePanelTitle.current.textContent = "Modify Job";
      setFooterState([
        { text: "Apply", click: () => {} },
        { text: "Close", click: closeClicked },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  function getDate(id) {
    try {
      return new Date(document.querySelector(id).value).toISOString();
    } catch (e) {
      return null;
    }
  }

  function checkNumber(string) {
    if (parseInt(string)) {
      return parseInt(string);
    } else {
      return 0;
    }
  }

  function checkText(string) {
    if (string && string.length) {
      return string;
    } else {
      return null;
    }
  }

  function updatePr() {
    const purchaseRequestId = Hooks.getSidePanelData().id;

    if (!purchaseRequestId) {
      return;
    }

    Hooks.getData(
      `${API_ENV}/purchaseRequest/${purchaseRequestId}`,
      (purchaseRequest) => {
        if (!purchaseRequest) {
          return;
        }

        purchaseRequest.reason = checkText(
          document.querySelector("#reasonInput").value,
        );
        purchaseRequest.quantity = checkNumber(
          document.querySelector("#quantityInput").value,
        );
        purchaseRequest.targetJobId = checkNumber(
          document.querySelector("#targetjobSearch").getAttribute("dataid"),
        );
        purchaseRequest.targetSupplierId = checkNumber(
          document
            .querySelector("#preferredsupplierDropdown")
            .getAttribute("data"),
        );
        purchaseRequest.targetWarehouseId = checkNumber(
          document
            .querySelector("#preferredwarehouseDropdown")
            .getAttribute("data"),
        );

        Hooks.sendData(
          purchaseRequest,
          `${API_ENV}/purchaseRequest/${purchaseRequestId}`,
          "PUT",
          (response) => {
            if (response.ok) {
              refreshTable(refresh);
            }
          },
        );
      },
    );
  }

  function addNewPr() {
    let supplierId = 0;
    const supplier = document.querySelector("#preferredsupplierDropdown");

    if (supplier && supplier.getAttribute("data")) {
      supplierId = supplier.getAttribute("data");
    }

    const purchaseRequest = {
      reason: checkText(document.querySelector("#reasonInput").value),
      partsRefId: checkNumber(
        document.querySelector("#partnumber").getAttribute("dataid"),
      ),
      quantity: checkNumber(document.querySelector("#quantityInput").value),
      targetDate: getDate("#targetdateInput"),
      targetJobId: checkNumber(
        document.querySelector("#jobSearch").getAttribute("dataid"),
      ),
      targetSupplierId: checkNumber(supplierId),
      targetWarehouseId: checkNumber(
        document
          .querySelector("#preferredwarehouseDropdown")
          .getAttribute("data"),
      ),
    };

    if (!purchaseRequest.targetDate) {
      Hooks.displayError("Please enter a target date.");
      return;
    }

    Hooks.sendData(
      purchaseRequest,
      `${API_ENV}/purchaseRequest`,
      "POST",
      (response) => {
        if (response.ok) {
          refreshTable(refresh);
        }
      },
    );
  }

  function addDeliveryDetail(lineno) {
    function confirm() {
      if (!document.querySelector(".selectedLocation")) {
        Hooks.displayError("Please select a stock location.");
        return;
      }

      const detail = {
        desc: checkText(document.querySelector("#descriptionInput").value),
        deliveryNoteId: checkNumber(Hooks.getSidePanelData()?.id),
        destinationStockLocationId: checkNumber(
          document.querySelector(".selectedLocation").getAttribute("dataid"),
        ),
        destinationWarehouseId: checkNumber(
          document.querySelector("#warehouseSelect select").value,
        ),
        deliveryNoteLineId: document
          .querySelector("#sidePanelContainer")
          .getAttribute("lineno"),
        poDetailId: checkNumber(
          document.querySelector("#purchaseorderDropdown").getAttribute("data"),
        ),
      };

      Hooks.sendData(detail, `${API_ENV}/dnDetail`, "POST", (response) => {
        if (response.ok) {
          document.querySelector(".currentTab").click();
        }
      });
    }

    setFormState({ type: "adddeliverydetail", data: {} });
    setFooterState([
      { text: "Attach to Delivery Line", click: confirm },
      { text: "Close", click: closeClicked },
    ]);
  }

  function updateDelivery() {
    const deliveryId = Hooks.getSidePanelData()?.id;
    if (!deliveryId) {
      return;
    }

    const url = `${API_ENV}/deliveryNote/${deliveryId}`;

    Hooks.getData(url, (delivery) => {
      if (!delivery) {
        return;
      }

      if (!document.querySelector(".selectedLocation")) {
        Hooks.displayError("Please select a stock location");
        return;
      }

      delivery.supplierId = checkNumber(
        document.querySelector("#supplierDropdown").getAttribute("data"),
      );
      delivery.deliveryNoteNumber = checkText(
        document.querySelector("#deliverynotenumberInput").value,
      );
      delivery.deliveryDate = getDate("#deliverydateInput");
      delivery.defaultWarehouseId = checkNumber(
        document.querySelector("#warehouseSelect select").value,
      );
      delivery.defaultStockLocationId = checkNumber(
        document.querySelector(".selectedLocation").getAttribute("dataid"),
      );

      Hooks.sendData(delivery, url, "PUT", (response) => {
        if (response.ok) {
          refreshTable(refresh);
        }
      });
    });
  }

  function returnToSupplier() {
    const deliveryId = Hooks.getSidePanelData()?.id;
    if (!deliveryId) {
      return;
    }

    Hooks.sendData(
      {},
      `${API_ENV}/deliveryNote/returnToSupplier/${deliveryId}`,
      "PUT",
      (response) => {
        if (response.ok) {
          refreshTable(refresh);
        }
      },
    );
  }

  function importToStock() {
    setFormState({ type: "importtostock", data: {} });
    setFooterState([
      {
        text: "Confirm",
        click: () => {
          validateSet("importstock");
        },
      },
      { text: "Close", click: closeClicked },
    ]);
  }

  function rejectPr() {
    const id = Hooks.getSidePanelData().id;
    const url = `${API_ENV}/purchaseRequest/${id}`;

    Hooks.getData(url, (data) => {
      if (data) {
        Swal.fire({
          title: "Reject Purchase Request?",
          text: "Are you sure you wish to reject this Purchase Request?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            data.purchaseRequestStateId = 7;

            Hooks.sendData(data, url, "PUT", (response) => {
              if (response.ok) {
                refreshTable(refresh);
              }
            });
          }
        });
      }
    });
  }

  function addPartToKit() {
    function confirm() {
      const data = {
        kitsId: checkNumber(Hooks.getSidePanelData().id),
        partsId: checkNumber(document.querySelector("#partid").value),
        quantity: checkNumber(document.querySelector("#quantityInput").value),
      };

      Hooks.sendData(data, `${API_ENV}/kitsParts`, "POST", (response) => {
        if (response.ok) {
          document.querySelector(".currentTab").click();
        }
      });
    }

    setFormState({ type: "addparttokit", data: {} });
    setFooterState([
      { text: "Add", click: confirm },
      { text: "Close", click: closeClicked },
    ]);
  }

  // Function for adding new items from the side panel.
  // This is what the "Add" button from the default "Add" button on the sidePanel form does
  // ! This should eventually be deleted with new Form refactoring
  function addNewEntry(type) {
    // console.log("type:", type);

    let dataToSend = {};
    // DELETE KEY/VALUES FOR FORMS REFACTOR
    if (type === "costtype") {
      dataToSend = {
        name: checkText(document.querySelector("#nameInput").value),
      };
    } else if (type === "costcode") {
      dataToSend = {
        name: checkText(document.querySelector("#nameInput").value),
        description: checkText(
          document.querySelector("#descriptionInput").value,
        ),
        costTypeId: checkNumber(
          document.querySelector("#costtypeDropdown").getAttribute("data"),
        ),
      };
      // DELETE KEY/VALUES FOR FORMS REFACTOR
    } else if (type === "purchaseorder") {
      dataToSend = {
        supplierRefId: checkNumber(
          document.querySelector("#supplierDropdown").getAttribute("data"),
        ),

        targetDate: getDate("#targetdateInput"),
      };
      if (!dataToSend.targetDate) {
        Hooks.displayError("Please enter a target date.");
        return;
      }
    } else if (type === "delivery") {
      dataToSend = {
        supplierId: checkNumber(
          document.querySelector("#supplierDropdown").getAttribute("data"),
        ),
        deliveryNoteNumber: checkText(
          document.querySelector("#deliverynotenumberInput").value,
        ),
        deliveryDate: getDate("#deliverydateInput"),
        defaultStockLocationId: checkNumber(
          document.querySelector(".selectedLocation").getAttribute("dataid"),
        ),
        defaultWarehouseId: checkNumber(
          document.querySelector("#warehouseSelect select").value,
        ),
      };
    } else if (type === "partscategory") {
      dataToSend = {
        name: checkText(document.querySelector("#nameInput").value),
      };
    } else if (type === "documenttag") {
      dataToSend = {
        name: checkText(document.querySelector("#nameInput").value),
        description: checkText(
          document.querySelector("#descriptionInput").value,
        ),
      };
      // DELETE KEY/VALUES FOR FORMS REFACTOR
    } else if (type === "part") {
      dataToSend = {
        partno: checkText(document.querySelector("#partnoInput").value),
        description: checkText(
          document.querySelector("#descriptionInput").value,
        ),
        partsCategoryId: checkNumber(
          document.querySelector("#partscategoryDropdown").getAttribute("data"),
        ),
        partsSubCategory1Id: checkNumber(
          document
            .querySelector("#partssubcategory1Dropdown")
            .getAttribute("data"),
        ),
        partsSubCategory2Id: checkNumber(
          document
            .querySelector("#partssubcategory2Dropdown")
            .getAttribute("data"),
        ),
        partsSubCategory3Id: checkNumber(
          document
            .querySelector("#partssubcategory3Dropdown")
            .getAttribute("data"),
        ),
        partsSubCategory4Id: checkNumber(
          document
            .querySelector("#partssubcategory4Dropdown")
            .getAttribute("data"),
        ),
      };
    } else if (type === "kit") {
      dataToSend = {
        name: checkText(document.querySelector("#nameInput").value),
        quantityInStock: checkNumber(
          document.querySelector("#quantityinstockInput").value,
        ),
      };
      // DELETE KEY/VALUES FOR FORMS REFACTOR
    } else if (type === "job") {
      // TEMP FIX
      dataToSend = {
        propertyId: checkNumber(
          document.querySelector("#propertyInput").getAttribute("data"),
        ),
        reference: checkText(document.querySelector("#referenceInput").value),
        priorityId: checkNumber(
          document.querySelector("#priorityDropdown").getAttribute("data"),
        ),
        engineerRefId: checkNumber(
          document.querySelector("#engineerDropdown").getAttribute("data"),
        ),
        jobStateRefId: checkNumber(
          document.querySelector("#jobstateDropdown").getAttribute("data"),
        ),
        jobTypeRefId: checkNumber(
          document.querySelector("#jobtypeDropdown").getAttribute("data"),
        ),
        targetDate: getDate("#targetInput"),
        scheduledDate: getDate("#scheduledInput"),
        // contractId: checkNumber(
        //   document.querySelector("#contractDropdown").getAttribute("data")
        // ),
        contractId: checkNumber(
          document.querySelector("#jobcontract2Dropdown").getAttribute("data"),
        ),
        scheduledStartTime: Hooks.sendTime(
          document.querySelector("#starttimeInput").value,
        ),
        scheduledEndTime: Hooks.sendTime(
          document.querySelector("#endtimeInput").value,
        ),
        parentJobId:
          checkText(document.querySelector("#parentjobnumberInput").value) || 0,
      };
    } else if (type === "property") {
      dataToSend = {
        uprn: checkText(document.querySelector("#uprnInput").value),
        clientRefId: checkNumber(
          document.querySelector("#clientDropdown").getAttribute("data"),
        ),
        address1: checkText(document.querySelector("#address1Input").value),
        address2: checkText(document.querySelector("#address2Input").value),
        address3: checkText(document.querySelector("#address3Input").value),
        address4: checkText(document.querySelector("#address4Input").value),
        town: checkText(document.querySelector("#townInput").value),
        county: checkText(document.querySelector("#countyInput").value),
        postCode: checkText(document.querySelector("#postcodeInput").value),
        parentPropertyId: checkNumber(
          document
            .querySelector("#parentPropertyIdDropdown")
            .getAttribute("data"),
        ),
      };
      // DELETE KEY/VALUES FOR FORMS REFACTOR
    } else if (type === "contract") {
      dataToSend = {
        name: checkText(document.querySelector("#nameInput").value),
        startDate: getDate("#startdateInput"),
        endDate: getDate("#enddateInput"),
        clientIdRefId: checkNumber(
          document.querySelector("#clientDropdown").getAttribute("data"),
        ),
        equipmentCategoryRefId: checkNumber(
          document
            .querySelector("#equipmentcategoryDropdown")
            .getAttribute("data"),
        ),
        jobTypeRefId: checkNumber(
          document.querySelector("#jobtypeDropdown").getAttribute("data"),
        ),
        contactName: checkText(
          document.querySelector("#contactnameInput").value,
        ),
        contactEmail: checkText(
          document.querySelector("#contactemailInput").value,
        ),
        contactPhone: checkText(
          document.querySelector("#contactphoneInput").value,
        ),
        contactNotes: checkText(
          document.querySelector("#contactnotesInput").value,
        ),
      };
    } else if (type === "client") {
      dataToSend = {
        name: checkText(document.querySelector("#nameInput").value),
        address1: checkText(document.querySelector("#address1Input").value),
        address2: checkText(document.querySelector("#address2Input").value),
        address3: checkText(document.querySelector("#address3Input").value),
        address4: checkText(document.querySelector("#address4Input").value),
        town: checkText(document.querySelector("#townInput").value),
        county: checkText(document.querySelector("#countyInput").value),
        postCode: checkText(document.querySelector("#postcodeInput").value),
        email: checkText(document.querySelector("#emailInput").value),
        phone: checkText(document.querySelector("#phoneInput").value),
      };
      // DELETE KEY/VALUES FOR FORMS REFACTOR
    } else if (type === "warehouses") {
      dataToSend = {
        isVan:
          document.querySelector("#isvanDropdown").getAttribute("data") ===
          "true",
        name: checkText(document.querySelector("#nameInput").value),
        address1: checkText(document.querySelector("#address1Input").value),
        address2: checkText(document.querySelector("#address2Input").value),
        address3: checkText(document.querySelector("#address3Input").value),
        address4: checkText(document.querySelector("#address4Input").value),
        town: checkText(document.querySelector("#townInput").value),
        county: checkText(document.querySelector("#countyInput").value),
        postCode: checkText(document.querySelector("#postcodeInput").value),
      };
    } else if (type === "kit") {
      dataToSend = {
        name: checkText(document.querySelector("#nameInput").value),
        quantityInStock: checkNumber(
          document.querySelector("#quantityinstockInput").value,
        ),
      };
    } else if (
      type === "jobstate" ||
      type === "stafftype" ||
      type === "qcstatus" ||
      type === "jobcategory"
    ) {
      dataToSend = {
        description: checkText(
          document.querySelector("#descriptionInput").value,
        ),
      };
    } else if (type === "cancelreason") {
      dataToSend = {
        reason: checkText(document.querySelector("#reasonInput").value),
      };
      // DELETE KEY/VALUES FOR FORMS REFACTOR
    } else if (type === "supplier") {
      dataToSend = {
        name: checkText(document.querySelector("#nameInput").value),
        address1: checkText(document.querySelector("#address1Input").value),
        address2: checkText(document.querySelector("#address2Input").value),
        address3: checkText(document.querySelector("#address3Input").value),
        address4: checkText(document.querySelector("#address4Input").value),
        town: checkText(document.querySelector("#townInput").value),
        county: checkText(document.querySelector("#countyInput").value),
        postCode: checkText(document.querySelector("#postcodeInput").value),
        email: checkText(document.querySelector("#emailInput").value),
        phone: checkText(document.querySelector("#phoneInput").value),
      };
    } else if (type === "stock") {
      dataToSend = {
        warehouseRefId: checkNumber(
          document.querySelector("#warehouseSelect select").value,
        ),
        partsRefId: checkNumber(
          document.querySelector("#partid").getAttribute("data"),
        ),
        qty: checkNumber(document.querySelector("#quantityInput").value),
        stockLocationId: checkNumber(
          document.querySelector(".selectedLocation").getAttribute("dataid"),
        ),
        arrivalDate: getDate("#arrivaldateInput"),
      };
    } else if (type === "jobstatetransition") {
      dataToSend = {
        jobTypeRefId: checkNumber(
          document.querySelector("#jobtypeDropdown").getAttribute("data"),
        ),
        currJobStateId: checkNumber(
          document
            .querySelector("#currentjobstateInputContainer")
            .getAttribute("data"),
        ),
        nextJobStateId: checkNumber(
          document
            .querySelector("#nextjobstateInputContainer")
            .getAttribute("data"),
        ),
      };
      // DELETE KEY/VALUES FOR FORMS REFACTOR
    } else if (type === "visitstatetransition") {
      dataToSend = {
        jobTypeRefId: checkNumber(
          document.querySelector("#jobtypeInputContainer").getAttribute("data"),
        ),
        currVisitStateId: checkNumber(
          document
            .querySelector("#currentvisitstateDropdown")
            .getAttribute("data"),
        ),
        nextVisitStateId: checkNumber(
          document
            .querySelector("#nextvisitstateDropdown")
            .getAttribute("data"),
        ),
      };
    } else if (type === "stockmovements") {
      dataToSend = {
        sourceWarehouseRefId: checkNumber(
          document
            .querySelector("#sourcewarehouseInputContainer")
            .getAttribute("data"),
        ),
        destinationWarehouseRefId: checkNumber(
          document
            .querySelector("#destinationwarehouseInputContainer")
            .getAttribute("data"),
        ),
        partsRefId: checkNumber(
          document.querySelector("#partsInputContainer").getAttribute("data"),
        ),
        quantity: checkNumber(document.querySelector("#quantityInput").value),
        date: getDate("#dateInput"),
      };
      // DELETE KEY/VALUES FOR FORMS REFACTOR
    } else if (type === "certification") {
      dataToSend = {
        name: checkText(document.querySelector("#nameInput").value),
        description: checkText(
          document.querySelector("#descriptionInput").value,
        ),
        dateFrom: getDate("#datefromInput"),
        dateExpire: getDate("#dateexpireInput"),
        certnumber: checkText(document.querySelector("#certnumberInput").value),
        engineerId: checkNumber(
          document.querySelector("#engineerDropdown").getAttribute("data"),
        ),
      };
      // DELETE KEY/VALUES FOR FORMS REFACTOR
    } else if (type === "visitstate") {
      dataToSend = {
        description: checkText(
          document.querySelector("#descriptionInput").value,
        ),
      };
    } else if (type === "priority") {
      dataToSend = {
        name: checkText(document.querySelector("#nameInput").value),
      };
    }

    try {
      // Todo, change this to be more efficient. (reset key hook)
      const typeLookup = {
        // DELETE KEY/VALUES FOR FORMS REFACTOR
        cancelreason: "cancelReason",
        jobstate: "jobState",
        // DELETE KEY/VALUES FOR FORMS REFACTOR
        qcstatus: "qcStatus",
        jobcategory: "jobCategory",
        api: "Api",
        delivery: "deliveryNote",
        purchaseorder: "purchaseOrder",
      };
      // DELETE KEY/VALUES FOR FORMS REFACTOR

      type = typeLookup[type] || Hooks.resetKey(type);

      function thenFunction(response) {
        if (response.ok) {
          refreshTable();
          closeClicked();
        }
      }

      console.log("Apply Button API (POST):", type);
      // API call to send data
      Hooks.sendData(
        // Here
        dataToSend,
        `${API_ENV}/${type}`,
        "POST",
        thenFunction,
      );
    } catch (e) {
      console.log(e);
    }
  }

  function getData(data, url, title) {
    const keys = {
      // DELETE KEY/VALUES FOR FORMS REFACTOR
      warehouses: [
        "id",
        "isVan",
        "name",
        "address1",
        "address2",
        "address3",
        "address4",
        "postCode",
      ],
      // DELETE KEY/VALUES FOR FORMS REFACTOR
      priority: ["name"],
      cancelreason: ["reason"],
      jobstate: ["description"],
      stafftype: ["description"],
      qcstatus: ["description"],
      visitstate: ["description"],
      jobstatetransitions: ["jobType", "currJobState", "nextJobState"],
      jobcategory: ["description"],
      stock: [
        "warehouseRefId",
        "partsRefId",
        "qty",
        "stockLocationId",
        "arrivalDate",
      ],
      visitstatetransitions: [
        "jobTypeRefId",
        "currVisitStateId",
        "nextVisitStateId",
      ],
      stockmovements: [
        "sourceWarehouseRefId",
        "destinationWarehouseRefId",
        "partsRefId",
        "quantity",
        "date",
      ],
      certifications: [
        "name",
        "description",
        "dateFrom",
        "dateExpire",
        "certnumber",
      ],
      deliveries: ["supplierId", "deliveryNoteNumber", "deliveryDate"],
      costtypes: ["name"],
      costcodes: ["name", "description", "costTypeId"],
      partssubcategory1: ["name", "partsCategoryId"],
      partssubcategory2: ["name", "partsSubCategory1Id"],
      partssubcategory3: ["name", "partsSubCategory2Id"],
      partssubcategory4: ["name", "partsSubCategory3Id"],
      // DELETE KEY/VALUES FOR FORMS REFACTOR
    };

    const keyIds = {
      reference: "woInput",
      contractId: "contractDropdown",
      invoiced: "invoicedDropdown",
      priorityId: "priorityDropdown",
      engineerRefId: "engineerDropdown",
      jobStateRefId: "jobstateDropdown",
      jobTypeRefId: "jobtypeDropdown",
      scheduledDate: "scheduleddateInput",
      targetDate: "targetdateInput",
      completedDate: "completeddateInput",
      address1: "address1Input",
      address2: "address2Input",
      address3: "address3Input",
      address4: "address4Input",
      town: "townInput",
      county: "countyInput",
      postCode: "postcodeInput",
      phone: "phoneInput",
      email: "emailInput",
      name: "nameInput",
      clientId: "clientDropdown",
      clientRefId: "clientDropdown",
      clientIdRefId: "clientDropdown",
      costTypeId: "costtypeDropdown",
      forename: "forenameInput",
      surname: "surnameInput",
      mobile: "mobileInput",
      uprn: "uprnInput",
      parentPropertyId: "parentPropertyIdDropdown",
      equipmentCategoryRefId: "equipmentcategoryDropdown",
      startDate: "startdateInput",
      endDate: "enddateInput",
      make: "makeeditInput",
      model: "modeleditInput",
      reason: "reasonInput",
      description: "descriptionInput",
      quantityInStock: "quantityinstockInput",
      invoiceNo: "invoicenoInput",
      propertyId: "uprnInput",
      cancelReasonRefId: "cancelreasonInputContainer",
      qcStatusRefId: "qcstatusDropdown",
      qcDate: "qcdateInput",
      isVan: "isvanDropdown",
      currJobState: "currentjobstateInputContainer",
      nextJobState: "nextjobstateInputContainer",
      equipmentSubCategoryRefId: "equipmentsubcategoryDropdown",
      chargeable: "chargeableInputContainer",
      jobCategoryRefId: "jobcategoryInputContainer",
      warehouseRefId: "warehouseInputContainer",
      quantity: "quantityInput",
      completed_flag: "completeflagInput",
      currVisitStateId: "currentvisitstateDropdown",
      nextVisitStateId: "nextvisitstateDropdown",
      username: "usernameInput",
      sourceWarehouseRefId: "sourcewarehouseInputContainer",
      destinationWarehouseRefId: "destinationwarehouseInputContainer",
      date: "dateInput",
      sorCode: "sorcodeInput",
      contractRefId: "contractDropdown",
      dateFrom: "datefromInput",
      dateExpire: "dateexpireInput",
      certnumber: "certnumberInput",
      groupName: "groupnameInput",
      allowToReadDefault: "allowedtoreadInputContainer",
      allowToUpdateDefault: "allowedtoupdateInputContainer",
      allowToCreateDefault: "allowedtocreateInputContainer",
      allowToDeleteDefault: "allowedtodeleteInputContainer",
      staffTypeRefId: "stafftypeDropdown",
      smsUpdate: "smsupdateDropdown",
      partno: "partnoInput",
      scheduledStartTime: "starttimeInput",
      scheduledEndTime: "endtimeInput",
      stockLocationId: "stocklocationSearch",
      partsRefId: "partsSearch",
      arrivalDate: "arrivaldateInput",
      qty: "quantityInput",
      supplierId: "supplierDropdown",
      deliveryNoteNumber: "deliverynotenumberInput",
      deliveryDate: "deliverydateInput",
      partsCategoryId: "partscategoryDropdown",
      partsSubCategory1Id: "partssubcategory1Dropdown",
      partsSubCategory2Id: "partssubcategory2Dropdown",
      partsSubCategory3Id: "partssubcategory3Dropdown",
      partsSubCategory4Id: "partssubcategory4Dropdown",
      contactName: "contactnameInput",
      contactEmail: "contactemailInput",
      contactPhone: "contactphoneInput",
      contactNotes: "contactnotesInput",
    };

    data = JSON.parse(data);
    url += data.id;
    let updatedData = { id: data.id };

    // Update the object with the new values.
    if (!keys[title]) {
      console.error("Could not find keys in apply clicked function.");
      return;
    }

    keys[title].forEach((key) => {
      const formattedKey = Hooks.conditionalString(key);
      let inputElement = document.querySelector("#" + keyIds[key]);

      if (
        formattedKey.includes("contractid") &&
        document.querySelector("#jobcontractDropdown")
      ) {
        inputElement = document.querySelector("#jobcontractDropdown");
      }
      if (!inputElement) {
        return;
      }

      if (formattedKey.includes("time")) {
        updatedData[key] = Hooks.sendTime(inputElement.value);
        return;
      }

      if (["stocklocationid", "partsrefid"].includes(formattedKey)) {
        updatedData[key] = inputElement.getAttribute("dataid");
      } else if (formattedKey === "completed_flag") {
        updatedData[key] = inputElement.checked;
      } else if (
        !formattedKey.includes("allowtoupdatedefault") &&
        formattedKey.includes("date") &&
        !formattedKey.includes("sms")
      ) {
        try {
          updatedData[key] = new Date(inputElement.value).toISOString();
        } catch (e) {
          updatedData[key] = null;
        }
      } else if (
        formattedKey.includes("category") ||
        formattedKey.includes("refid") ||
        (key.includes("allow") && key.includes("Default")) ||
        formattedKey.includes("stateid") ||
        [
          "contractid",
          "isvan",
          "nextjobstate",
          "currjobstate",
          "chargeable",
          "warehouse",
          "parts",
          "priorityid",
          "invoiced",
          "smsupdate",
          "clientid",
          "supplierid",
          "costtypeid",
        ].includes(formattedKey)
      ) {
        if (inputElement.getAttribute("data")) {
          if (
            (key.includes("allow") && key.includes("Default")) ||
            key.includes("sms") ||
            key.includes("invoiced") ||
            formattedKey.includes("van") ||
            formattedKey.includes("chargeable") ||
            formattedKey.includes("completed_flag")
          ) {
            updatedData[key] = inputElement.getAttribute("data") === "true";
          } else {
            updatedData[key] = checkNumber(inputElement.getAttribute("data"));
          }
        }
      } else if (inputElement.value) {
        updatedData[key] = inputElement.value;
      }
    });

    function thenFunction(response) {
      closeClicked();
    }

    Hooks.sendData(updatedData, url, "PUT", thenFunction, (response) => {
      if (response.ok) {
        refreshTable();
      }
    });
  }

  const closeClicked = useCallback(() => {
    // console.log("closeClicked");

    // Removes the "/:tableRowId?" from the URL on sidePanel close
    const currentPage = location.pathname;
    const newPath = currentPage
      .replace(/\/\d+$/, "")
      .replace(/\/undefined$/, ""); // Removes the last numeric segment & /undefined
    navigate(newPath);

    setShowSidePanel(false);
  }, [location.pathname, navigate, setShowSidePanel]);

  function applyClicked() {
    const title = Hooks.conditionalString(pageTitle?.current?.textContent);
    if (!title || !title.length) {
      return;
    }

    if (
      title === "jobs" &&
      document.querySelector("#jobstateInputContainer") &&
      Hooks.conditionalString(
        document.querySelector("#jobstateInputContainer").textContent,
      ) === "cancelled"
    ) {
      document.querySelector("#cancelJobPopup").style.display = "flex";
    } else {
      // console.log(Hooks.resetKey(title));
      // console.log("getData Test");
      // console.log(
      //   `ref.current.getAttribute("data")`,
      //   JSON.parse(ref.current.getAttribute("data"))
      // );

      getData(
        ref.current.getAttribute("data"),
        `${API_ENV}/${Hooks.resetKey(title)}/`,
        title,
      );
    }
  }

  const archive = useCallback(
    (newTableRowId) => {
      if (ref.current && ref.current.getAttribute("data")) {
        Swal.fire({
          title: "Archive item?",
          text: "Are you sure you wish to archive this item? This cannot be undone.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            function thenFunction(response) {
              // if (response.ok) {
              //   document.querySelectorAll(".headerButton")[1]?.click();
              // }
              // timeout needed otherwise horizontal sidepanel issue occurs
              setTimeout(() => {
                closeClicked();

                setTimeout(() => {
                  refreshTable();
                }, 100);
              }, 100);
            }

            let path = Hooks.getApiFromTitle(
              Hooks.conditionalString(pageTitle?.current?.textContent),
            );

            if (path === "inspectionsets") {
              path = "inspectionSet";
            } else if (path === "jobstatetransitions") {
              path = "jobStateTransition";
            } else if (path === "visitstatetransitions") {
              path = "visitStateTransition";
            } else if (path === "objectdocumenttype") {
              path = "objectDocType";
            } else if (path === "partcategories") {
              path = "partsCategory";
            } else if (path === "attributeunit") {
              if (formState.type === "attributelistchoice") {
                path = "attributelistchoice";
              } else {
                path = "attributeunit";
              }
            }

            // . Check Archive URL here
            console.log("archive path", path);

            let id;

            if (!newTableRowId || typeof newTableRowId === "object") {
              id = Hooks.getSidePanelData().id;
            } else {
              id = newTableRowId;
            }

            if (!id) {
              id = tableRowId;
            }

            // console.log("id", id);
            // console.log("newTableRowId", newTableRowId);

            Hooks.sendData(
              {},
              `${API_ENV}/${path}/${id}`,
              "DELETE",
              thenFunction,
            );
          }
        });
      }
    },
    [ref, pageTitle, closeClicked, refreshTable, formState.type, tableRowId],
  );

  function changeSupplierPart() {
    const selected = document.querySelector(".selected");

    if (!selected) {
      return;
    }

    //Todo change to data id attribute
    const url = `${API_ENV}/partsAndSupplier/${
      JSON.parse(selected.getAttribute("data")).id
    }`;

    Hooks.getData(url, (data) => {
      if (!data) {
        return;
      }

      data.supplierPartNo = checkText(
        document.querySelector("#supplierpartnoInput").value,
      );
      data.unitCost = checkText(document.querySelector("#unitcostInput").value);
      data.inventoryQty = checkNumber(
        document.querySelector("#quantityInput").value,
      );

      Hooks.sendData(data, url, "PUT");
    });
  }

  // *Need to put this inside ViewInspectionSet form & then delete
  const addInspectionQuestion = useCallback(() => {
    function getAnswerChoices() {
      const typeNode = document.querySelector("#answertypeInput");
      const selected = typeNode.selectedIndex;
      const type = Hooks.conditionalString(
        typeNode.querySelectorAll("option")[selected].textContent,
      );

      switch (type) {
        case "dropdownlist":
        case "tickbox":
          let object = {};
          if (document.querySelector(".answerInput")) {
            document.querySelectorAll(".answerInput").forEach((node, index) => {
              object[index] = node.value || null;
            });
          }
          return object;
        case "freetext":
          return { 1: document.querySelector("#answerInput").value };
        case "yes/no":
          return { 1: "Yes", 2: "No" };
        default:
          break;
      }
    }

    function addQuestion() {
      const question = {
        question: checkText(document.querySelector("#questionInput").value),
        inspectionSetId: checkNumber(Hooks.getSidePanelData()?.id),
        answerTypeRefId: checkNumber(
          document.querySelector("#answertypeInput").value,
        ),
        answerChoice: getAnswerChoices(),
      };

      function thenFunction(response) {
        // if (response.ok) {
        //   document.querySelector(".selectedRow").click();
        // }
      }

      // console.log("question: ", question);

      Hooks.sendData(
        question,
        `${API_ENV}/inspectionQuestion`,
        "POST",
        thenFunction,
      );
    }
    setFormState({ type: "addinspectionquestion", data: {} });
    setFooterState([
      { text: "Confirm", click: addQuestion },
      { text: "Close", click: closeClicked },
    ]);
  }, [setFooterState, setFormState, closeClicked]);

  function updatePo() {
    const poId = Hooks.getSidePanelData().id;

    if (!poId) {
      return;
    }

    Hooks.getData(`${API_ENV}/purchaseOrder/${poId}`, (data) => {
      data.supplierRefId = checkNumber(
        document.querySelector("#supplierDropdown").getAttribute("data"),
      );
      data.orderDate = getDate("#orderdateInput");
      data.targetDate = getDate("#targetdateInput");

      Hooks.sendData(
        data,
        `${API_ENV}/purchaseOrder/${poId}`,
        "PUT",
        (response) => {
          if (response.ok) {
            refreshTable(refresh);
          }
        },
      );
    });
  }

  function addPoDetail() {
    function addDetail() {
      let stocklocation = 0;
      const stockInput = document.querySelector(".selectedLocation");

      if (stockInput && stockInput.getAttribute("dataid")) {
        stocklocation = stockInput.getAttribute("dataid");
      }

      const data = {
        purchaseOrderId: Hooks.getSidePanelData()?.id,
        orderQuantity: checkNumber(
          document.querySelector("#quantityInput").value,
        ),
        targetJobId: checkNumber(
          document.querySelector("#targetjobSearch").getAttribute("dataid"),
        ),
        partsRefId: checkNumber(
          document.querySelector("#partnumber").getAttribute("dataid"),
        ),
        destinationWarehouseId: checkNumber(
          document.querySelector("#warehouseSelect select").value,
        ),
        destinationStockLocationId: stocklocation,
        deliveryDate: getDate("#deliverydateInput"),
        unitPrice: checkNumber(document.querySelector("#pounitcost").value),
      };

      if (!data.orderQuantity) {
        Hooks.displayError("Please enter a quantity.");
        return;
      }

      Hooks.sendData(data, `${API_ENV}/poDetail`, "POST", (response) => {
        if (response.ok) {
          document.querySelector(".currentTab").click();
        }
      });
    }

    setFormState({ type: "addpodetail", data: {} });
    setFooterState([
      { text: "Confirm", click: addDetail },
      { text: "Close", click: closeClicked },
    ]);
  }

  function addJobToProperty() {
    setFormState({ type: "addjobtoproperty", data: {} });

    function addJob() {
      const jobData = {
        propertyId: Hooks.getSidePanelData().id,
        contractId: document
          .querySelector("#contractDropdown")
          .getAttribute("data"),
        engineerRefId: document
          .querySelector("#engineerDropdown")
          .getAttribute("data"),
        jobStateRefId: document
          .querySelector("#jobstateInputContainer")
          .getAttribute("data"),
        jobTypeRefId: document
          .querySelector("#jobtypeDropdown")
          .getAttribute("data"),
        priorityId: document
          .querySelector("#priorityInputContainer")
          .getAttribute("data"),
        invoiced:
          document
            .querySelector("#invoicedInputContainer")
            .getAttribute("data") === 1
            ? true
            : false,
        targetDate: new Date(
          document.querySelector("#targetdateInput").value,
        ).toISOString(),
        scheduledDate: new Date(
          document.querySelector("#scheduleddateInput").value,
        ).toISOString(),
        reference: checkText(document.querySelector("#referenceInput").value),
        userRefId: 1, // needs to be 1 until the api is updated to take other values
      };

      function thenFunction(response) {
        if (response.ok) {
          document.querySelector(".currentTab").click();
        }
      }

      Hooks.sendData(jobData, `${API_ENV}/jobs`, "POST", thenFunction);
    }

    setFooterState([
      { text: "Confirm Job", click: addJob },
      { text: "Close", click: closeClicked },
    ]);
  }

  function addAllContracts() {
    Swal.fire({
      title: "Assign all contracts?",
      text: "Are you sure you wish to assign all contracts to this staff member?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        function thenFunction(response) {
          if (response.ok) {
            document.querySelector(".currentTab").click();
          }
        }

        const staffId = Hooks.getSidePanelData().id;
        const url = `${API_ENV}/contractsAndEngineers/bulbcreate/${staffId}?ApplyToAllContract=true`;
        Hooks.sendData({}, url, "POST", thenFunction);
      }
    });
  }

  // Edits the individual contract
  function modifyStaffContract() {
    const selected = document.querySelector(".selected");
    if (selected && selected.getAttribute("data")) {
      let selectedData = JSON.parse(selected.getAttribute("data"));

      delete selectedData.client;
      delete selectedData.equipmentCategory;

      selectedData.name = checkText(document.querySelector("#nameInput").value);
      selectedData.startDate = getDate("#startdateInput");
      selectedData.endDate = getDate("#enddateInput");
      selectedData.clientRefId = checkNumber(
        document.querySelector("#clientDropdown").getAttribute("data"),
      );
      selectedData.contactName = checkText(
        document.querySelector("#contactnameInput").value,
      );
      selectedData.contactEmail = checkText(
        document.querySelector("#contactEmailInput").value,
      );
      selectedData.contactPhone = checkText(
        document.querySelector("#contactPhonenput").value,
      );
      selectedData.contactNotes = checkText(
        document.querySelector("#contactNotesInput").value,
      );
      selectedData.equipmentCategoryRefId = checkNumber(
        document
          .querySelector("#equipmentcategoryDropdown")
          .getAttribute("data"),
      );

      Hooks.sendData(
        selectedData,
        `${API_ENV}/contracts/${selectedData.id}`,
        "PUT",
      );
    }
  }

  // Assigns contracts to staff.
  function updateStaffContract() {
    function thenFunction(response) {
      if (response.ok) {
        document.querySelector(".currentTab").click();
      }
    }

    function addContracts() {
      const staffId = Hooks.getSidePanelData().id;
      let url = `${API_ENV}/contractsAndEngineers/bulbcreate/${staffId}?`;
      let contracts = [];
      document.querySelectorAll(".contractRow").forEach((row, index) => {
        if (index > 0) {
          contracts.push(
            "contractIDs=" + row.querySelector(".contractField").textContent,
          );
        }
      });
      url = url + contracts.join("&");
      Hooks.sendData({}, url, "POST", thenFunction);
    }

    setFormState({ type: "addstaffcontract", data: {} });
    setFooterState([
      { text: "Add Contracts", click: addContracts },
      { text: "Close", click: closeClicked },
    ]);
  }

  function modifyInspection() {
    const id = checkNumber(Hooks.getSidePanelData().id);

    function getFunction(data) {
      let equipmentCategory = 0,
        jobType = 0;

      if (document.querySelector("#equipmentcategoryDropdown")) {
        equipmentCategory = checkNumber(
          document
            .querySelector("#equipmentcategoryDropdown")
            .getAttribute("data"),
        );
      }

      if (document.querySelector("#jobtypeContainer")) {
        jobType = checkNumber(
          document.querySelector("#jobtypeContainer").getAttribute("data"),
        );
      }

      const inspectionData = {
        id: data.id,
        name: checkText(document.querySelector("#nameInput").value),
        inspectionTypeRefId: checkNumber(
          document.querySelector("#settypeDropdown").getAttribute("data"),
        ),
        compulsory_flag:
          document
            .querySelector("#compulsory_flagInputContainer")
            .getAttribute("data") === "true",
        jobTypeRefId: jobType || data.jobTypeRefId,
        equipmentCategoryRefId:
          equipmentCategory || data.equipmentCategoryRefId,
        inspectionQuestion: data.inspectionQuestion,
      };

      function thenFunction(response) {
        if (response.ok) {
          refreshTable(refresh);
        }
      }

      Hooks.sendData(
        inspectionData,
        `${API_ENV}/inspectionSet/${Hooks.getSidePanelData().id}`,
        "PUT",
        thenFunction,
      );
    }

    Hooks.getData(`${API_ENV}/inspectionSet/${id}`, getFunction);
  }

  // Modified job already assigned to a property.
  function modifyJob() {
    const jobData = JSON.parse(
      document
        .querySelector(".selected")
        ?.querySelector("[data-item]")
        ?.getAttribute("data-item") ?? "{}",
    );

    const updatedData = {
      id: jobData.id,
      contractId: checkNumber(
        document.querySelector("#contractDropdown").getAttribute("data"),
      ),
      priorityId: checkNumber(
        document.querySelector("#priorityInputContainer").getAttribute("data"),
      ),
      engineerRefId: checkNumber(
        document.querySelector("#engineerDropdown").getAttribute("data"),
      ),
      jobStateRefId: checkNumber(
        document.querySelector("#jobstateDropdown").getAttribute("data"),
      ),
      jobTypeRefId: checkNumber(
        document.querySelector("#jobtypeDropdown").getAttribute("data"),
      ),
      scheduledDate: getDate("#scheduleddateInput"),
      targetDate: getDate("#targetdateInput"),
      scheduledStartTime: Hooks.sendTime(
        document.querySelector("#starttimeInput").value,
      ),
      scheduledEndTime: Hooks.sendTime(
        document.querySelector("#endtimeInput").value,
      ),
      completedDate: getDate("#completedInput"),
      completed_flag: document.querySelector("#completeflagInput").checked,
      reference: checkText(document.querySelector("#referenceInput").value),
      qcDate: jobData.qcDate || null,
      qcStatusRefId: jobData.qcStatusRefId,
    };

    Hooks.sendData(updatedData, `${API_ENV}/jobs/${jobData.id}`, "PUT");
  }

  function addEngineer() {
    function confirm() {
      const data = {
        engineerId: checkNumber(
          document.querySelector("#engineerDropdown").getAttribute("data"),
        ),
        vanId: checkNumber(Hooks.getSidePanelData().id),
      };

      Hooks.sendData(data, `${API_ENV}/engineersAndVan`, "POST", (response) => {
        if (response.ok) {
          document.querySelector(".currentTab").click();
        }
      });
    }
    setFormState({ type: "assignEngineer", data: {} });
    setFooterState([
      { text: "Add", click: confirm },
      { text: "Close", click: closeClicked },
    ]);
  }

  function uploadClicked() {
    setFormState({ type: "uploadfile", data: {} });
  }

  function addStaff() {
    function assign() {
      const object = {
        engineerId: checkNumber(
          document.querySelector("#engineerDropdown").getAttribute("data"),
        ),
        contractId: checkNumber(Hooks.getSidePanelData().id),
      };

      if (object.engineerId) {
        function thenFunction(response) {
          if (response.ok) {
            document.querySelector(".currentTab").click();
          }
        }
        Hooks.sendData(
          object,
          `${API_ENV}/contractsAndEngineers`,
          "POST",
          thenFunction,
        );
      }
    }
    setFormState({ type: "assignstaff", data: {} });
    setFooterState([
      { text: "Add", click: assign },
      { text: "Close", click: closeClicked },
    ]);
  }

  function createjobsform() {
    const contractId = Hooks.getSidePanelData().id;

    Hooks.sendData(
      {},
      `${API_ENV}/jobschedule/contract/${contractId}`,
      "POST",
      (response) => {
        if (response.ok) {
          document.querySelector(".currentTab").click();
        }
      },
    );
  }

  function createSchedule(type) {
    function create() {
      function calculateStart(day) {
        const row = document.querySelector(`#${day}Row`);

        for (let i = 0; i < row.querySelectorAll(".timeBlock").length; i++) {
          if (
            row.querySelectorAll(".timeBlock")[i].style.backgroundColor ===
            "rgb(195, 217, 30)"
          ) {
            return i * 100;
          }
        }
        return 0;
      }

      function calculateEnd(day) {
        const row = document.querySelector(`#${day}Row`);

        for (
          let i = row.querySelectorAll(".timeBlock").length - 1;
          i > 0;
          i--
        ) {
          if (
            row.querySelectorAll(".timeBlock")[i].style.backgroundColor ===
            "rgb(195, 217, 30)"
          ) {
            return i * 100;
          }
        }
        return 0;
      }

      const scheduleObject = {
        description: document.querySelector("#descriptionInput").value,
        mot:
          document.querySelector("#motContainer").getAttribute("data") ===
          "true"
            ? "y"
            : "n",
        period: checkNumber(document.querySelector("#periodInput input").value),
        periodUnit: document
          .querySelector("#periodInput .dropdown")
          .getAttribute("data"),
        lookAhead: checkNumber(
          document.querySelector("#lookaheadInput input").value,
        ),
        lookAheadUnit: document
          .querySelector("#lookaheadInput .dropdown")
          .getAttribute("data"),
        window: checkNumber(
          document.querySelector("#flexibilityInput input").value,
        ),
        windowUnit: document
          .querySelector("#flexibilityInput .dropdown")
          .getAttribute("data"),
        monStart: calculateStart("monday"),
        monEnd: calculateEnd("monday"),
        tueStart: calculateStart("tuesday"),
        tueEnd: calculateEnd("tuesday"),
        wedStart: calculateStart("wednesday"),
        wedEnd: calculateEnd("wednesday"),
        thurStart: calculateStart("thursday"),
        thurEnd: calculateEnd("thursday"),
        friStart: calculateStart("friday"),
        friEnd: calculateEnd("friday"),
        satStart: calculateStart("saturday"),
        satEnd: calculateEnd("saturday"),
        sunStart: calculateStart("sunday"),
        sunEnd: calculateEnd("sunday"),
        jobTypeRefId: checkNumber(
          document
            .querySelector("#jobtypescheduleDropdown")
            .getAttribute("data"),
        ),
        contractId: Hooks.getSidePanelData().id,
      };

      if (document.querySelector(".selected")) {
        const scheduleId = JSON.parse(
          document
            .querySelector(".selected")
            ?.querySelector("[data-item]")
            ?.getAttribute("data-item") ?? "{}",
        ).id;

        scheduleObject.id = scheduleId;
        // console.log("scheduleObject", scheduleObject);
        Hooks.sendData(
          scheduleObject,
          `${API_ENV}/schedule/${scheduleId}`,
          "PUT",
          (response) => {
            if (response.ok) {
              document.querySelector(".currentTab").click();
            }
          },
        );
      } else {
        // console.log("scheduleObject", scheduleObject);
        Hooks.sendData(
          scheduleObject,
          `${API_ENV}/schedule`,
          "POST",
          (response) => {
            if (response.ok) {
              document.querySelector(".currentTab").click();
            }
          },
        );
      }
    }

    if (type === "modify") {
      create();
    } else if (type === "add") {
      setFormState({ type: "addscheduling", data: {} });
      setFooterState([
        { text: "Confirm", click: create },
        { text: "Close", click: closeClicked },
      ]);
    }
  }

  function addContractToProperty() {
    function confirmClicked() {
      const object = {
        contractId: checkNumber(
          document
            .querySelector("#contracttopropertyDropdown")
            .getAttribute("data"),
        ),
        propertyId: checkNumber(Hooks.getSidePanelData().id),
      };

      function thenFunction(response) {
        if (response.ok) {
          document.querySelector(".currentTab").click();
        }
      }

      Hooks.sendData(
        object,
        `${API_ENV}/contractsAndProperties`,
        "POST",
        thenFunction,
      );
    }

    setFormState({ type: "addcontracttoproperty", data: {} });
    setFooterState([
      { text: "Confirm", click: confirmClicked },
      { text: "Close", click: closeClicked },
    ]);
  }

  function clearSchedule() {
    if (document.querySelector(".timeBlock")) {
      document.querySelectorAll(".blockContainer").forEach((block, index) => {
        if (index > 0) {
          block.querySelectorAll(".timeBlock").forEach((item) => {
            item.style.backgroundColor = "transparent";
          });
        }
      });
    }
  }

  function archiveMultiSelect(type) {
    const selected = document.querySelector(".selected");
    if (selected && selected.getAttribute("data")) {
      Swal.fire({
        title: `Archive ${type}?`,
        text: `Are you sure you wish to archive this ${type}? This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          function thenFunction(response) {
            if (response.ok) {
              document.querySelector(".currentTab").click();
            }
          }

          if (type === "resident") {
            type = "residents";
          }

          Hooks.sendData(
            {},
            `${API_ENV}/${type}/${
              JSON.parse(selected.getAttribute("data")).id
            }`,
            "DELETE",
            thenFunction,
          );
        }
      });
    }
  }

  function sendResident(type) {
    const resident = {
      propertyId: checkNumber(Hooks.getSidePanelData().id),
      title: checkText(document.querySelector("#titleInput").value),
      forename: checkText(document.querySelector("#forenameInput").value),
      surname: checkText(document.querySelector("#surnameInput").value),
      mobile: checkText(document.querySelector("#mobileInput").value),
      email: checkText(document.querySelector("#emailInput").value),
      landline: checkText(document.querySelector("#landlineInput").value),
      primary:
        document.querySelector("#primaryDropdown").getAttribute("data") ===
        "true",
    };

    if (type === "add") {
      function thenFunction(response) {
        if (response.ok) {
          document.querySelector(".currentTab").click();
        }
      }
      Hooks.sendData(resident, `${API_ENV}/residents`, "POST", thenFunction);
    } else if (type === "modify") {
      function thenFunction(response) {
        if (response.ok) {
          if (resident?.primary) {
            document.querySelectorAll(".contentListItem").forEach((item) => {
              const div = item.querySelectorAll("div")[1];
              div.textContent = div.textContent.replace("(Primary)", "").trim();
            });
            document
              .querySelector(".selected")
              .querySelectorAll("div")[1].textContent += " (Primary)";
          }
        }
      }

      const id = JSON.parse(
        document
          .querySelector(".selected")
          ?.querySelector("[data-item]")
          ?.getAttribute("data-item") ?? "{}",
      ).id;

      resident.id = id;
      Hooks.sendData(
        resident,
        `${API_ENV}/residents/${id}`,
        "PUT",
        thenFunction,
      );
    }
  }

  function addResident() {
    setFormState({ type: "addresident", data: {} });
    setFooterState([
      {
        text: "Add Resident",
        click: () => {
          sendResident("add");
        },
      },
      { text: "Close", click: closeClicked },
    ]);
  }

  function answerSet() {
    const array = [];
    if (document.querySelector(".answeredQuestion")) {
      document.querySelectorAll(".answeredQuestion").forEach((node) => {
        const nodeData = JSON.parse(node.getAttribute("questiondata"));

        function getAnswers(node) {
          let answers = {};

          if (node.querySelector(".answerInput")) {
            node.querySelectorAll(".answerInput").forEach((input, index) => {
              if (input.getAttribute("type") === "checkbox") {
                answers[index + 1] = input.checked;
              } else {
                answers[index + 1] = input.value;
              }
            });
          }

          return answers;
        }

        array.push({
          id: nodeData.id,
          userAnswer: getAnswers(node),
        });
      });
    }

    function thenFunction(response) {
      if (response.ok) {
        document.querySelector(".currentInspectionTab").click();
      }
    }

    Hooks.sendData(
      array,
      `${API_ENV}/inspectionQuestionWithAnswer/bulbcommit`,
      "PUT",
      thenFunction,
    );
  }

  function reloadSet(jobId) {
    if (jobId) {
      Swal.fire({
        title: "Reload Set?",
        text: "Are you sure you wish to reload this set? This will clear all the answers.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          Hooks.sendData(
            {},
            `${API_ENV}/jobs/reloadinspection/${jobId}`,
            "PUT",
          );
        }
      });
    }
  }

  function addCancelReason() {
    function addReason() {
      const data = {
        cancelReasonId: checkNumber(
          document
            .querySelector("#cancelreasonInputContainer")
            .getAttribute("data"),
        ),
        contractId: Hooks.getSidePanelData()?.id,
      };

      // console.log("data", data);

      Hooks.sendData(
        data,
        `${API_ENV}/contractAndCancelReason`,
        "POST",
        (response) => {
          if (response.ok) {
            document.querySelector(".currentTab").click();
          }
        },
      );
    }

    setFormState({ type: "assigncancelreason", data: {} });
    setFooterState([
      { text: "Confirm", click: addReason },
      { text: "Close", click: closeClicked },
    ]);
  }

  function assignInspectionSet() {
    function assign() {
      const jobType = Hooks.getSidePanelData();

      if (jobType) {
        let data = {
          inspectionSetRefId: checkNumber(
            document
              .querySelector("#inspectionsetInputContainer")
              .getAttribute("data"),
          ),
          jobTypeRefId: checkNumber(jobType.id),
        };

        function thenFunction(response) {
          if (response.ok) {
            document.querySelector(".currentTab").click();
          }
        }

        Hooks.sendData(
          data,
          `${API_ENV}/jobTypeAndInspectionSet`,
          "POST",
          thenFunction,
        );
      }
    }
    setFormState({ type: "assigninspectionset", data: {} });
    setFooterState([
      { text: "Confirm", click: assign },
      { text: "Close", click: closeClicked },
    ]);
  }

  //EXAMPLE OF REFACTORING : REFACTOR
  function addPartToJob() {
    // All commented out = is handled by invdividual form now
    // function addPart() {
    //   const data = {
    //     jobRefId: Hooks.getSidePanelData()?.id,
    //     partsRefId: checkNumber(
    //       document.querySelector("#partnumber").getAttribute("dataid")
    //     ),
    //   };
    //   console.log("addPart data:", data);

    //   Hooks.sendData(
    //    data,
    //     `${API_ENV}/jobPartsUsed`,
    //     "POST",
    //     (response) => {
    //       if (response.ok) {
    //         document.querySelector(".currentTab").click();
    //       }
    //     }
    //   );
    // }

    setFormState({ type: "addparttojob", data: {} });

    // setFooterState([
    //   { text: "Add Part", click: addPart },
    //   { text: "Close", click: closeClicked },
    // ]);
  }

  function updateJobPart() {
    const selected = JSON.parse(
      document
        .querySelector(".selected")
        ?.querySelector("[data-item]")
        ?.getAttribute("data-item") ?? "{}",
    );

    const data = {
      id: selected.id,
      jobRefId: selected.jobRefId,
      partsRefId: selected.partsRefId,
      qty: checkNumber(document.querySelector("#quantityInput").value),
      engineerRefId: checkNumber(
        document.querySelector("#engineerDropdown").getAttribute("data"),
      ),
    };

    Hooks.sendData(data, `${API_ENV}/jobPartsUsed/${selected.id}`, "PUT");
  }

  function archivePart() {
    let id;
    try {
      id = JSON.parse(
        document
          .querySelector(".selected")
          ?.querySelector("[data-item]")
          ?.getAttribute("data-item") ?? "{}",
      ).id;
    } catch (e) {
      console.log(e);
    }
    if (id) {
      function thenFunction(response) {
        if (response.ok) {
          document.querySelector(".currentTab").click();
        }
      }

      Hooks.sendData(
        {},
        `${API_ENV}/jobPartsUsed/${id}`,
        "DELETE",
        thenFunction,
      );
    }
  }

  function assignCertification() {
    function assign() {
      const object = {
        name: checkText(document.querySelector("#nameInput").value),
        description: checkText(
          document.querySelector("#descriptionInput").value,
        ),
        dateFrom: getDate("#datefromInput"),
        dateExpire: getDate("#dateexpireInput"),
        engineerId: checkNumber(Hooks.getSidePanelData().id),
        certnumber: checkText(document.querySelector("#certnumberInput").value),
      };

      function thenFunction(response) {
        if (response.ok) {
          document.querySelector(".currentTab").click();
        }
      }
      Hooks.sendData(object, `${API_ENV}/certification`, "POST", thenFunction);
    }

    setFormState({ type: "certifications", data: {} });
    setFooterState([
      { text: "Assign Certification", click: assign },
      { text: "Close", click: closeClicked },
    ]);
  }

  function updateCertification() {
    const selected = document.querySelector(".selected");
    if (selected && selected.getAttribute("data")) {
      let selectedData = JSON.parse(selected.getAttribute("data"));
      selectedData.name = checkText(document.querySelector("#nameInput").value);
      selectedData.description = checkText(
        document.querySelector("#descriptionInput").value,
      );
      selectedData.dateFrom = getDate("#datefromInput");
      selectedData.dateExpire = getDate("#dateexpireInput");
      selectedData.certnumber = checkText(
        document.querySelector("#certnumberInput").value,
      );

      Hooks.sendData(
        selectedData,
        `${API_ENV}/certification/${selectedData.id}`,
        "PUT",
        (response) => {
          if (response.ok) {
            document.querySelector(".currentTab").click();
          }
        },
      );
    }
  }

  function addNewRole() {
    function assign() {
      const role = {
        staffId: Hooks.getSidePanelData().id,
        roleId: checkNumber(
          document.querySelector("#roleDropdown").getAttribute("data"),
        ),
      };

      Hooks.sendData(role, `${API_ENV}/staffToRole`, "POST", (response) => {
        if (response.ok) {
          document.querySelector(".currentTab").click();
        }
      });
    }

    setFormState({ type: "assignrole", data: {} });
    setFooterState([
      { text: "Confirm", click: assign },
      { text: "Close", click: closeClicked },
    ]);
  }

  // Todo merge these.
  function archiveSchedule() {
    try {
      const id = JSON.parse(
        document
          .querySelector(".selected")
          ?.querySelector("[data-item]")
          ?.getAttribute("data-item") ?? "{}",
      ).id;
      Hooks.sendData({}, `${API_ENV}/schedule/${id}`, "DELETE", (response) => {
        if (response.ok) {
          document.querySelector(".currentTab").click();
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  function archiveCertification() {
    try {
      const id = JSON.parse(
        document
          .querySelector(".selected")
          ?.querySelector("[data-item]")
          ?.getAttribute("data-item") ?? "{}",
      ).id;
      Hooks.sendData(
        {},
        `${API_ENV}/certification/${id}`,
        "DELETE",
        (response) => {
          if (response.ok) {
            document.querySelector(".currentTab").click();
          }
        },
      );
    } catch (e) {
      console.log(e);
    }
  }

  function addNewDocumentTag() {
    function addTag() {
      const data = {
        jobTypeId: checkNumber(Hooks.getSidePanelData().id),
        documentTagId: checkNumber(
          document.querySelector("#documenttagDropdown").getAttribute("data"),
        ),
      };

      Hooks.sendData(
        data,
        `${API_ENV}/jobTypeAndDocumentTag`,
        "POST",
        (response) => {
          if (response.ok) {
            document.querySelector(".currentTab").click();
          }
        },
      );
    }
    setFormState({ type: "adddocumenttag", data: {} });
    setFooterState([
      { text: "Confirm", click: addTag },
      { text: "Close", click: closeClicked },
    ]);
  }

  function modifyStockLocation() {
    const selected = document.querySelector(".selected");

    if (!selected) {
      return;
    }

    const id = JSON.parse(selected.getAttribute("data")).id;

    Hooks.getData(`${API_ENV}/stockLocation/${id}`, (data) => {
      if (!data) {
        return;
      }

      data.aisle = checkText(document.querySelector("#aisleInput").value);
      data.rack = checkText(document.querySelector("#rackInput").value);
      data.level = checkText(document.querySelector("#levelInput").value);

      Hooks.sendData(
        data,
        `${API_ENV}/stockLocation/${id}`,
        "PUT",
        (response) => {
          if (response.ok) {
            document.querySelector(".currentTab").click();
          }
        },
      );
    });
  }

  function addStockToWarehouse() {
    function confirm() {
      const aisleInput = document.querySelector("#aisleInput");
      const rackInput = document.querySelector("#rackInput");
      const levelInput = document.querySelector("#levelInput");

      const data = {
        aisle: checkText(aisleInput.value),
        rack: checkText(rackInput.value),
        level: checkText(levelInput.value),
        warehouseId: Hooks.getSidePanelData()?.id,
      };

      Hooks.sendData(data, `${API_ENV}/stockLocation`, "POST", (response) => {
        if (response.ok) {
          document.querySelector(".currentTab").click();
        }
      });
    }

    setFormState({ type: "addstocktowarehouse", data: {} });
    setFooterState([
      { text: "Confirm", click: confirm },
      { text: "Close", click: closeClicked },
    ]);
  }

  function addSupplierToPart() {
    function confirm() {
      const data = {
        partsId: checkNumber(Hooks.getSidePanelData().id),
        supplierId: checkNumber(
          document.querySelector("#suppliernameInput").getAttribute("data"),
        ),
        supplierPartNo: checkText(
          document.querySelector("#supplierpartnoInput").value,
        ),
        unitCost: checkText(document.querySelector("#unitcostInput").value),
      };

      Hooks.sendData(
        data,
        `${API_ENV}/partsAndSupplier`,
        "POST",
        (response) => {
          if (response.ok) {
            document.querySelector(".currentTab").click();
          }
        },
      );
    }
    setFormState({ type: "addpartsupplier", data: {} });
    setFooterState([
      { text: "Confirm", click: confirm },
      { text: "Close", click: closeClicked },
    ]);
  }

  function addPartToSupplier() {
    function confirm() {
      const data = {
        partsId: checkNumber(document.querySelector("#partid").value),
        supplierId: checkNumber(Hooks.getSidePanelData().id),
        supplierPartNo: checkText(
          document.querySelector("#supplierpartnoInput").value,
        ),
        unitCost: checkText(document.querySelector("#unitcostInput").value),
        inventoryQty: checkNumber(
          document.querySelector("#quantityInput").value,
        ),
      };

      Hooks.sendData(
        data,
        `${API_ENV}/partsAndSupplier`,
        "POST",
        (response) => {
          if (response.ok) {
            document.querySelector(".currentTab").click();
          }
        },
      );
    }
    setFormState({ type: "addsupplierpart", data: {} });
    setFooterState([
      { text: "Confirm", click: confirm },
      { text: "Close", click: closeClicked },
    ]);
  }

  const [currentTabIndex, setCurrentTabIndex] = useState(null);

  // This function controls the logic whenever the user clicks on a tab at the top of the side panel.
  function tabClicked(element, index) {
    setCurrentTabIndex(index);

    // console.log("tabClicked");
    // console.log("element", element);

    setSidePanelKey(Math.random());

    if (document.querySelector("#jobTab")) {
      document.querySelector("#jobTab").click();
    }

    function getFooterState(footer) {
      if (
        Hooks.conditionalString(
          document.querySelector("#liveButton").textContent,
        ).includes("archived")
      ) {
        return [{ text: "Close", click: closeClicked }];
      } else {
        return footer;
      }
    }

    function handleFormStateChange(urlParams, formType, footerButtons) {
      Hooks.getData(`${API_ENV}/${urlParams}`, (data) => {
        // console.log("handleFormStateChange data", data);
        // console.log("handleFormStateChange formType", formType);
        setFormState({ type: formType, data: data, key: data.id });
        setFooterState(getFooterState(footerButtons));
      });
    }

    const textContent = Hooks.conditionalString(element.textContent);
    console.log("textContent:", textContent);

    const loadedData = Hooks.getSidePanelData();
    // console.log("loadedData", loadedData);

    // Each case is unique to a button on the tabs.
    // Add new entries here to make tabs spit out unique "formattedType" for GenerateForm.js to add forms to.
    // THE FIRST TAB SHOULD BE THE FORM WHICH OPENS UPON CLICKING A TABLE ROW! Check "formattedType" for normal table row opening & set the type for the tab's setFormState to this.
    // formState is the state that is sent to GenerateForm > Form
    switch (textContent) {
      // KEEP CASES BUT REMOVE FOOTER BUTTONS PAIRS DURING FORMS REFACTOR
      case "jobsors":
        handleFormStateChange(`jobSor/job/${loadedData.id}`, "jobsors");
        break;
      case "parts":
        handleFormStateChange(
          `ContractAndParts?contractIds=${loadedData.id}&showarchive=false`,
          "contractparts",
        );
        break;
      case "partssubcategory1":
        setFormState({
          type: "partssubcategory1",
          data: loadedData,
        });
        break;
      case "partssubcategory2":
        setFormState({
          type: "partssubcategory2",
          data: loadedData,
        });
        break;
      case "partssubcategory3":
        setFormState({
          type: "partssubcategory3",
          data: loadedData,
        });
        break;
      case "partssubcategory4":
        setFormState({
          type: "partssubcategory4",
          data: loadedData,
        });
        break;
      case "partsbysubcategorylevel":
        setFormState({
          type: "partsbysubcategorylevel",
          data: loadedData,
        });
        break;
      case "dropdownlistchoices":
        setFormState({
          type: "attributelistchoice",
          data: loadedData,
        });
        break;
      case "attributeunit":
        setFormState({
          type: "attributeunit",
          data: loadedData,
        });
        break;
      case "category1details":
        handleFormStateChange(
          `partsAndSupplier?PartsID=${loadedData.id}`,
          "category1details",
          [
            { text: "Apply Changes", click: addSupplierToPart },
            { text: "Add", click: addSupplierToPart },
            { text: "Close", click: closeClicked },
          ],
        );
        break;
      case "addvisit":
        setFormState({ type: "addvisit", data: {} });
        break;
      case "assignedapis":
        handleFormStateChange(
          `roleToApi?RoleId=${loadedData.id}`,
          "assignedapis",
          [{ text: "Close", click: closeClicked }],
        );
        break;
      case "assignedcontracts":
        handleFormStateChange(
          `contract/properties/${loadedData.id}`,
          "assignedcontracts",
          [
            { text: "Add Contract", click: addContractToProperty },
            { text: "Close", click: closeClicked },
          ],
        );
        break;
      // KEEP CASES BUT REMOVE FOOTER BUTTONS PAIRS DURING FORMS REFACTOR
      case "assignedjobs":
        setFooterState(
          getFooterState([
            { text: "Apply Changes", click: modifyJob },
            { text: "Add Job", click: addJobToProperty },
            { text: "Archive", click: archive },
            { text: "Close", click: closeClicked },
          ]),
        );
        Hooks.getData(
          `${API_ENV}/jobs?PropertyId=${loadedData.id}&limit=10`,
          (data) => {
            setFormState({ type: "assignedjobs", data: data || {} });
          },
        );
        break;
      case "assignedreport":
        handleFormStateChange(
          `roletoRunReportList?RoleId=${loadedData.id}`,
          "assignedreport",
          [{ text: "Close", click: closeClicked }],
        );
        break;
      case "assignedstaff":
        handleFormStateChange(
          `contractsAndEngineers?contractIds=${loadedData?.id}`,
          "staffassigned",
          [
            { text: "Add Staff", click: addStaff },
            { text: "Close", click: closeClicked },
          ],
        );
        break;
      case "assignstafftocontract":
        setFormState({ type: "assignstaff", data: loadedData });
        break;
      case "assigntocontract":
        setFooterState(
          getFooterState([
            { text: "Assign", click: "" },
            { text: "Close", click: closeClicked },
          ]),
        );
        break;
      case "attachments":
        setFormState({ type: "attachments", data: {} });
        setFooterState(
          getFooterState([
            { text: "Upload file", click: uploadClicked },
            { text: "Close", click: closeClicked },
          ]),
        );
        break;
      // KEEP CASES BUT REMOVE FOOTER BUTTONS PAIRS DURING FORMS REFACTOR
      case "cancelreasons":
        handleFormStateChange(
          `contractAndCancelReason?contractIds=${loadedData?.id}`,
          "cancelreasoncontracts",
          [
            { text: "Add Reason", click: addCancelReason },
            { text: "Close", click: closeClicked },
          ],
        );
        break;
      case "certifications":
        setFormState({ type: "staffcertifications", data: {} });
        setFooterState(
          getFooterState([
            { text: "Apply Changes", click: updateCertification },
            { text: "Assign Certification", click: assignCertification },
            { text: "Archive", click: archiveCertification },
            { text: "Close", click: closeClicked },
          ]),
        );
        break;
      case "contractdetails":
        setFormState({ type: "contracts", data: loadedData });
        setFooterState(
          getFooterState([
            { text: "Apply Changes", click: applyClicked },
            { text: "Archive", click: archive },
            { text: "Close", click: closeClicked },
          ]),
        );
        break;
      case "contractinformation":
        setFormState({ type: "staffcontract", data: data });
        setFooterState(
          getFooterState([
            { text: "Apply Changes", click: modifyStaffContract },
            { text: "Add Contracts", click: updateStaffContract },
            { text: "Add All Contracts", click: addAllContracts },
            { text: "Close", click: closeClicked },
          ]),
        );
        break;
      // KEEP CASES BUT REMOVE FOOTER BUTTONS PAIRS DURING FORMS REFACTOR
      case "currentvisits":
        const propertyId = Hooks.getSidePanelData()?.id;

        function getFunction(data) {
          if (!data || !data.length) {
            return;
          }

          const jobIds = data.map((job) => {
            return "jobIDs=" + job?.id;
          });

          function subGetFunction(data) {
            setFormState({ type: "visit", data: data });
          }
          Hooks.getData(
            `${API_ENV}/visits?${jobIds.join("&")}`,
            subGetFunction,
          );
        }

        Hooks.getData(
          `${API_ENV}/jobs?PropertyId=${propertyId}&limit=10`,
          getFunction,
        );

        setFooterState([
          {
            text: "Add Visit",
            click: () => {
              setFormState({ type: "addvisitproperty", data: {} });
            },
          },
          { text: "Close", click: closeClicked },
        ]);
        break;
      // KEEP CASES BUT REMOVE FOOTER BUTTONS PAIRS DURING FORMS REFACTOR
      case "deliverynotedetails":
        setFormState({ type: "deliveries", data: loadedData });
        setFooterState(
          getFooterState([
            { text: "Apply Changes", click: updateDelivery },
            {
              text: "Add Detail",
              click: () => {
                addDeliveryDetail(
                  document
                    .querySelector(".selectedLine")
                    .getAttribute("lineno"),
                );
              },
            },
            {
              text: "Validate",
              click: () => {
                validateSet("delivery");
              },
            },
            { text: "Import To Stock", click: importToStock },
            { text: "Return to Supplier", click: returnToSupplier },
            { text: "Archive", click: archive },
            { text: "Close", click: closeClicked },
          ]),
        );
        break;
      case "documenttags":
        handleFormStateChange(
          `documentTag/jobtype/${loadedData.id}`,
          "documenttags",
          [
            { text: "Add Tag", click: addNewDocumentTag },
            { text: "Close", click: closeClicked },
          ],
        );
        break;
      case "equipmentdetails":
        handleFormStateChange(
          `contract/properties/${loadedData.id}`,
          "equipmentdetails",
        );
        break;
      // KEEP CASES BUT REMOVE FOOTER BUTTONS PAIRS DURING FORMS REFACTOR
      case "goodsreceipt":
        break;
      case "inspections":
        Hooks.getData(
          `${API_ENV}/inspectionQuestionWithAnswer?InspectionTypeID=1&JobId=${loadedData.id}`,
          (data) => {
            if (data && data.length) {
              let answered = false;
              for (let i = 0; i < data.length; i++) {
                if (data[i].answered) {
                  answered = true;
                  break;
                }
              }
              setFormState({ type: "inspections", data: data });
              setFooterState(
                getFooterState([
                  {
                    text: "Confirm",
                    click: () => {
                      answerSet(answered);
                    },
                  },
                  {
                    text: "Reload Set",
                    click: () => {
                      reloadSet(loadedData.id);
                    },
                  },
                  { text: "Close", click: closeClicked },
                ]),
              );
            } else {
              setFormState({ type: "inspections", data: {} });
              setFooterState(
                getFooterState([
                  {
                    text: "Confirm",
                    click: () => {
                      answerSet(false);
                    },
                  },
                  {
                    text: "Reload Set",
                    click: () => {
                      reloadSet(loadedData.id);
                    },
                  },
                  { text: "Close", click: closeClicked },
                ]),
              );
            }
          },
        );
        break;
      // KEEP CASES BUT REMOVE FOOTER BUTTONS PAIRS DURING FORMS REFACTOR
      case "inspectionsets":
        handleFormStateChange(
          `inspectionSet/jobtype/${loadedData.id}`,
          "viewinspectionsets",
          [
            { text: "Assign Set", click: assignInspectionSet },
            { text: "Close", click: closeClicked },
          ],
        );
        break;
      case "jobdetails":
        setFormState({ type: "jobs", data: loadedData });
        // setFooterState(
        //   getFooterState([
        //     { text: "Update Job", click: updateJob },
        //     { text: "Clone Job", click: clone },
        //     { text: "Archive", click: archive },
        //     { text: "Close", click: closeClicked },
        //   ])
        // );
        break;
      case "jobparts":
        setFormState({ type: "jobparts", data: loadedData });
        setFooterState(
          getFooterState([
            { text: "Apply Changes", click: updateJobPart },
            { text: "Add Part To Job", click: addPartToJob },
            { text: "Archive", click: archivePart },
            { text: "Close", click: closeClicked },
          ]),
        );
        break;
      case "jobtypes":
        handleFormStateChange(
          `jobType/contract/${Hooks.getSidePanelData().id}`,
          "contractjobtypes",
          [{ text: "Add Job Type" }, { text: "Close", click: closeClicked }],
        );
        break;
      case "kitdetails":
        handleFormStateChange(`kits/${loadedData.id}`, "kits", [
          { text: "Apply Changes", click: applyClicked },
          { text: "Add part to kit", click: addPartToKit },
          { text: "Close", click: closeClicked },
        ]);
        break;
      case "managerole":
        handleFormStateChange(
          `staffToRole?StaffId=${loadedData.id}`,
          "managerole",
          [
            { text: "Assign Role", click: addNewRole },
            { text: "Close", click: closeClicked },
          ],
        );
        break;
      // KEEP CASES BUT REMOVE FOOTER BUTTONS PAIRS DURING FORMS REFACTOR
      case "partdetails":
        handleFormStateChange(`parts/${loadedData.id}`, "parts");
        break;
      case "parts&suppliers":
        handleFormStateChange(
          `partsAndSupplier?PartsID=${loadedData.id}`,
          "partsupplier",
        );
        break;
      case "propertydetails":
        setFormState({ type: "properties", data: loadedData });
        break;
      // KEEP CASES BUT REMOVE FOOTER BUTTONS PAIRS DURING FORMS REFACTOR
      case "purchaseorderdetails":
        setFormState({ type: "purchaseorders", data: loadedData });
        setFooterState(
          getFooterState([
            { text: "Apply Changes", click: updatePo },
            { text: "Add Detail", click: addPoDetail },
            { text: "Create Delivery Order", click: createDeliveryOrder },
            {
              text: "Validate PO",
              click: () => {
                validateSet("po");
              },
            },
            { text: "Print PO", click: "" },
            { text: "Archive", click: archive },
            { text: "Close", click: closeClicked },
          ]),
        );
        break;
      case "resident":
        handleFormStateChange(
          `residents/property/${loadedData.id}`,
          "resident",
          [
            {
              text: "Apply Changes",
              click: () => {
                sendResident("modify");
              },
            },
            { text: "Add Resident", click: addResident },
            {
              text: "Archive",
              click: () => {
                archiveMultiSelect("resident");
              },
            },
            { text: "Close", click: closeClicked },
          ],
        );
        break;
      case "roledetails":
        setFormState({ type: "role", data: loadedData });
        setFooterState(getDefaultFooter());
        break;
      case "assignedusers":
        // setFormState({ type: "assignedusers" });
        handleFormStateChange(
          `staffToRole?RoleId=${loadedData.id}`,
          "assignedusers",
          [],
        );
        break;
      // KEEP CASES BUT REMOVE FOOTER BUTTONS PAIRS DURING FORMS REFACTOR
      case "scheduling":
        handleFormStateChange(
          `schedule/contract/${loadedData.id}`,
          "scheduling",
          [
            {
              text: "Apply Changes",
              click: () => {
                createSchedule("modify");
              },
            },
            {
              text: "Add Schedule",
              click: () => {
                createSchedule("add");
              },
            },
            { text: "Clear Schedule", click: clearSchedule },
            { text: "Archive", click: archiveSchedule },
            { text: "Create Jobs", click: createjobsform },
            { text: "Close", click: closeClicked },
          ],
        );
        break;
      case "sorlists":
        handleFormStateChange(
          `contractsAndSorList?contractIds=${loadedData.id}`,
          "sorlists",
          [
            // { text: "Add SOR", click: addSorToContract },
            // { text: "Close", click: closeClicked },
          ],
        );
        break;
      case "staffdetails":
        setFormState({ type: "staff", data: loadedData });
        setFooterState(
          getFooterState([
            { text: "Apply Changes", click: applyClicked },
            { text: "Close", click: closeClicked },
          ]),
        );
        break;
      case "stocklocation":
        handleFormStateChange(
          `stockLocation?WarehouseID=${loadedData.id}`,
          "stocklocation",
          [
            { text: "Apply Changes", click: modifyStockLocation },
            { text: "Add", click: addStockToWarehouse },
            { text: "Close", click: closeClicked },
          ],
        );
        break;
      // KEEP CASES BUT REMOVE FOOTER BUTTONS PAIRS DURING FORMS REFACTOR
      case "supplierdetails":
        setFormState({ type: "suppliers", data: loadedData });
        setFooterState(
          getFooterState([
            { text: "Apply Changes", click: applyClicked },
            { text: "Close", click: closeClicked },
          ]),
        );
        break;
      case "supplierparts":
        setFormState({ type: "supplierparts", data: loadedData });
        setFooterState(
          getFooterState([
            { text: "Apply Changes", click: changeSupplierPart },
            { text: "Add Part", click: addPartToSupplier },
            { text: "Close", click: closeClicked },
          ]),
        );
        break;
      case "typedetails":
        setFormState({ type: "jobtype", data: loadedData });
        setFooterState(getDefaultFooter());
        break;
      case "vanengineers":
        handleFormStateChange(
          `engineersAndVan?vanIds=${loadedData.id}`,
          "vanengineers",
          [
            { text: "Add Engineer", click: addEngineer },
            { text: "Close", click: closeClicked },
          ],
        );
        break;
      case "viewvisits":
        handleFormStateChange(`visits?jobIDs=${loadedData.id}`, "visit", [
          // { text: "Apply Changes", click: updateVisit },
          // { text: "Add Visit", click: addVisit },
          // { text: "Archive", click: archiveVisit },
          // { text: "Close", click: closeClicked },
        ]);
        break;
      case "warehousedetails":
        handleFormStateChange(`warehouses/${loadedData.id}`, "warehouses", [
          { text: "Apply Changes", click: applyClicked },
          { text: "Close", click: closeClicked },
        ]);
        break;
      // KEEP CASES BUT REMOVE FOOTER BUTTONS PAIRS DURING FORMS REFACTOR
      default:
        break;
    }
  }

  // Tab issue fix: if a table record is open & you refresh, the sidepanel opens without the tabs
  useEffect(() => {
    // console.log("type", type);
    if (type.includes("add")) {
      // console.log("inlcudes add");
      setTabState([]);
    } else {
      // console.log("no add");
      setTabState(getDefaultTab());
    }

    // setFooterState(getDefaultFooter());
  }, [type, getDefaultTab]);

  useEffect(() => {
    // setTabState(getDefaultTab());
    //! Is this conflicting with setFooterState? Something to look into
    setFooterState(getDefaultFooter());

    if (sidePanelTabs.current) {
      sidePanelTabs.current.style.display = "block";
    }

    let dataObject = {};

    // Check if data is parsed or unparsed & then to parse if not.
    if (typeof data === "string") {
      // data = InfoDisplay's sidePanelData
      dataObject = JSON.parse(data);
    } else if (typeof data === "object") {
      dataObject = data;
    }

    // Sets the page title for side panel when clicking on table row
    if (
      pageTitle.current &&
      sidePanelTitle.current &&
      dataObject &&
      // The below to be here otherwise will cause crashes to individual forms, ie AddReport, ViewReport style forms rather than the multiform setup
      ((Array.isArray(dataObject) &&
        dataObject.some((item) => item && item.id)) ||
        dataObject.id)
    ) {
      // Object to determine what keys from the data we want to use as part of the title.
      const formTypeToTitleMap = {
        // KEEP KEY/VALUES PAIRS DURING FORMS REFACTOR
        contracts: ({ id, name, client }) =>
          `Contract (${id}), ${name} for ${client.name}`,
        properties: ({ uprn, address1, postCode }) =>
          `Property ${uprn ? `(${uprn}),` : ""} ${address1}, ${postCode}`,
        jobs: ({ id, jobType, property }) =>
          `Job (${id}), ${jobType.description} at ${property.address1}`,
        staff: ({ id, forename, surname }) =>
          `Staff (${id}), ${forename} ${surname}`,
        parts: ({ partno }) => partno,
        clients: ({ name }) => `${name}`,
        warehouses: ({ name }) => `${name}`,
        suppliers: ({ name }) => `${name}`,
        kits: ({ name }) => `${name}`,
        equipmentcategory: ({ name }) => `${name}`,
        equipmentsubcategory1: ({ name }) => `${name}`,
        equipmentsubcategory2: ({ name }) => `${name}`,
        partcategories: ({ name }) => `${name}`,
        partssubcategory1: ({ name }) => `Subcategory 1: ${name}`,
        partssubcategory2: ({ name }) => `Subcategory 2: ${name}`,
        partssubcategory3: ({ name }) => `Subcategory 3: ${name}`,
        partssubcategory4: ({ name }) => `Subcategory 4: ${name}`,
        inspectionsets: ({ name }) => `${name}`,
        priority: ({ name }) => `${name}`,
        api: ({ name }) => `${name}`,
        actionevent: ({ name }) => `${name}`,
        documenttags: ({ name }) => `${name}`,
        equipmenttype: ({ model }) => `${model}`,
        jobstate: ({ description }) => `${description}`,
        // KEEP KEY/VALUES PAIRS DURING FORMS REFACTOR
        jobtype: ({ description }) => `${description}`,
        qcstatus: ({ description }) => `${description}`,
        stafftype: ({ description }) => `${description}`,
        jobcategory: ({ description }) => `${description}`,
        cancelreason: ({ reason }) => `${reason}`,
        visitstatetransitions: ({ id }) => `State ${id}`,
        jobstatetransitions: ({ id }) => `State ${id}`,
        processes: ({ id, status }) => `Process (${id}), (${status})`,
        stock: ({ warehouse }) => warehouse.name,
        stockmovements: ({ sourceWarehouse, destinationWarehouse }) =>
          `${sourceWarehouse.name} to ${destinationWarehouse.name}`,
        sor: ({ sorCode, id }) => `SOR (${id}): ${sorCode}`,
        sorlist: ({ name, id }) => `SOR List (${id}): ${name}`,
        certifications: ({ name, certnumber }) => `${name}, ${certnumber}`,
        role: ({ name, description }) => `${name}, ${description}`,
        apigroup: ({ groupName }) => groupName,
        purchaserequests: ({ id }) => `Purchase Request #${id}`,
        purchaseorders: ({ id }) => `Purchase Order #${id}`,
        deliveries: ({ deliveryNoteNumber }) => `#${deliveryNoteNumber}`,
        costtypes: ({ name }) => `${name}`,
        costcodes: ({ name }) => `${name}`,
        stockcyclecount: ({ name }) => `${name}`,

        definereports: ({ id, name }) => `Report: ${id} | ${name}`,
        // reportonline: ({ id, name }) => `View Report Online`,
        reportonlinecsvxls: ({ id, name }) => `View Report Online (CSV / XLS)`,
        reportonlinepdf: ({ id, name }) => `View Report Online (PDF)`,
        reportasfileoremail: ({ id, runReportList }) =>
          `Proc ID: ${id} | ${runReportList.name}`,
        viewreportprocess: ({ id, runReportList }) =>
          `Proc ID: ${id} | ${runReportList.name}`,
        letterprintsetup: ({ id, letterName }) =>
          `Letter: ${id} | ${letterName}`,
        viewletterprintprocess: ({ id, letterName }) =>
          `Letter: ${id} | ${letterName}`,
        viewallprogramprocess: ({ id, name }) => `View Program Process`,
        viewschedulerprocess: ({ id, name }) => `Proc ID: ${id}`,
        customprocess: ({ id, name }) => `View Custom Process`,
        ticket: ({ id, issueDetail }) => `Ticket: ${id} | ${issueDetail}`,
        tickettype: ({ id, name }) => `Ticket Type: ${id} | ${name}`,

        answertype: ({ id, desc }) => `Answer Type: ${id} | ${desc}`,
        objectdocumenttype: ({ id, docTypeName }) =>
          `Object Doc Type: ${id} | ${docTypeName}`,
        attributecategory: ({ id, catName }) =>
          `Attribute Category: ${id} | ${catName}`,
        attributeunit: ({ id, unitName }) =>
          `Attribute Unit: ${id} | ${unitName}`,
        tags: ({ id, name }) => `Tag: ${id} | ${name} `,
        documentworkflow: ({ id }) => `Document Workflow: ${id} `,
        // KEEP KEY/VALUES PAIRS DURING FORMS REFACTOR
      };

      // Gets and formats the pageTitle ref.
      const formType = Hooks.conditionalString(pageTitle.current.textContent);
      // console.log("formType", formType);

      // console.log("FORMTYPE 1", formType);
      // console.log("pageTitle 1", pageTitle.current);

      if (formType in formTypeToTitleMap) {
        // console.log("dataObject", dataObject); //* Check this to see what formTypeToTitleMap keys are
        const textContent = formTypeToTitleMap[formType](dataObject);
        setTitle(textContent);
      }

      //* THIS IS WHERE formState IS SET!!!!
      setFormState({ type: formType, data: dataObject });
    } else if (document.querySelector("#kitsTitle h2")) {
      sidePanelTitle.current.textContent = dataObject.name;
      setFormState({ type: "kits", data: dataObject });
    }

    // const formType = Hooks.conditionalString(pageTitle.current.textContent);
    // console.log("FORMTYPE 2", formType);
    // setFormState({ type: formType, data: dataObject });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, pageTitle]);

  return (
    <div
      id="sidePanelContainer"
      className={showSidePanel ? "open" : "closed"}
      ref={ref}
      data={data}
      key={sidePanelKey}
    >
      <div id="sidePanelPopups">
        <CancelJobPopup data={sidePanelData} />
      </div>

      <div id="sidePanelHeader">
        <div id="sidePanelTitle" ref={sidePanelTitle}>
          {title}
        </div>
      </div>

      {tabState && tabState.length > 0 ? (
        <>
          <div id="sidePanelTabs" ref={sidePanelTabs}>
            <SidePanelTabs tabs={tabState} currentTabIndex={currentTabIndex} />
          </div>
          <HR />
        </>
      ) : null}

      <div id="sidePanelContent">
        <GenerateForm
          refresh={() => {
            refreshTable(refresh);
          }}
          refreshTable={refreshTable}
          state={formState}
          setFormState={setFormState}
          searchClicked={search}
          searchId={searchId}
          partSearch={partSearch}
          selectedPart={selectedPart}
          updatePopup={updatePopup}
          setFooterState={setFooterState}
          cleanURL={closeClicked}
          type={type}
          archive={archive}
          addInspecQuesFunc={addInspectionQuestion} // Need to come back & put this inside form itself, then delete
        />
      </div>

      <div id="sidePanelFooter">
        <FooterButtons state={footerState} />
      </div>
    </div>
  );
});
