import "../styles/ui/JobSORHistory.scss";

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import DropdownList from "@ui/DropdownList";
import FormRow from "@ui/FormRow";
import API_ENV from "@config";
import { useParams } from "react-router-dom";
import ListItem from "./ListItem";
import SidePanelFormRow from "./SidePanelFormRow";

const schema = yup
  .object({
    // ! TO DO
    // * BOOLEANS need to be yup.bool()
    // field1: yup.string().required("This field is required"),
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function JobSORHistory({
  state,
  cleanURL,
  refreshTable,
  apiUrl,
}) {
  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
    dropdownData2: [],
    dropdownData3: [],
  });

  const { tableRowId } = useParams();

  const {
    control,
    // handleSubmit,
    // setValue,
    // reset,
    // register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      versionNum: "",
      sorId: "",
    },
  });

  const versionNum = watch("versionNum");
  const sorId = watch("sorId");

  // const { data: stateData } = state;

  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(
      `${API_ENV}/jobsor/getversionlist/job/${tableRowId}`,
      (data) => {
        // console.log("useEffect API 1 data:", data);

        const transformedData = data.map((item) => ({
          id: item,
          name: `Version: ${item}`,
        }));

        setDropdownData((prevData) => ({
          ...prevData,
          dropdownData1: transformedData,
        }));
      },
    );

    Hooks.getData(`${API_ENV}/jobsor/getsorlist/job/${tableRowId}`, (data) => {
      // console.log("useEffect API 2 data:", data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData2: data,
      }));
    });

    if (versionNum && sorId) {
      Hooks.getData(
        `${API_ENV}/logJobSor?VersionNum=${versionNum}&SorId=${sorId}&JobId=${tableRowId}&showarchive=false`,
        (data) => {
          // console.log("useEffect API 3 data:", data);
          setDropdownData((prevData) => ({
            ...prevData,
            dropdownData3: data,
          }));
        },
      );
    }
  }, [tableRowId, versionNum, sorId]);
  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <DropdownList
            label={"Version Action"}
            name="versionNum"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"SOR Code"}
            name="sorId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData2}
            optionName="sorCode"
          />
        </SidePanelFormRow>

        <FormRow type="full">
          <ListItem data={dropdownData.dropdownData3} type="jobSorHistory" />
        </FormRow>
      </div>
    </Form>
  );
}
