import "../styles/ui/Select.scss";

import { useEffect, useState } from "react";
import Hooks from "../Hooks";

export default function DropdownListOldNonRHF({
  label,
  size = "medium",

  dropdownData,
  formData,
  setFormData,

  customKeyName,
  customOnChange,

  optionKey = "id",
  optionValue = "id",
  optionName = "name",
  firstOption = "Select option",
  firstOptionValue = "",
  disableFirstOption = false,
  ...props
}) {
  const inputId = `${Hooks.camelCaseString(label)}Select`;
  const [keyName, setKeyName] = useState();

  // useEffect(() => {
  //   // console.log("optionValue", optionValue);
  //   console.log("keyName", keyName);
  // }, [optionValue, keyName]);

  useEffect(() => {
    if (customKeyName === undefined) {
      setKeyName(Hooks.camelCaseString(label));
    } else {
      setKeyName(customKeyName);
    }
  }, [customKeyName, label]);

  const onChangeHandler = customOnChange
    ? customOnChange
    : (e) => {
        setFormData((prevData) => ({
          ...prevData,
          [keyName]: e.target.value,
        }));
      };

  return (
    <>
      <label htmlFor={inputId}>{label}</label>
      <select
        {...props}
        id={inputId}
        className={`select ${size}`}
        value={formData[keyName]}
        onChange={onChangeHandler}
      >
        {!disableFirstOption && (
          <option value={firstOptionValue}>{firstOption}</option>
        )}
        {Array.isArray(dropdownData) &&
          dropdownData.map((item) => (
            <option
              key={item[optionKey]}
              value={item[optionValue]}
              className={"option"}
            >
              {item[optionName]}
            </option>
          ))}
      </select>
    </>
  );
}
