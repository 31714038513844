import "../styles/ui/SubJobs.scss";

import { useEffect, useState } from "react";
import Hooks from "@hooksFile";
import API_ENV from "@config";
import SidePanelFormRow from "./SidePanelFormRow";

export default function SubJobs({ parentJobId }) {
  const [subJobs, setSubJobs] = useState([]);
  const [expandedSubJobId, setExpandedSubJobId] = useState(null);

  // useEffect(() => {
  //   console.log("subJobs", subJobs);
  // }, [subJobs]);

  useEffect(() => {
    Hooks.getData(`${API_ENV}/jobs/subjobs/${parentJobId}`, (data) => {
      setSubJobs(data);
    });
  }, [parentJobId]);

  const toggleSubJob = (jobId) => {
    setExpandedSubJobId((prevId) => (prevId === jobId ? null : jobId));
  };

  return (
    <div className="subJobsContainer">
      {subJobs.map((job) => (
        <div key={job.id}>
          {/* SubJob display */}
          <div
            className={`subJobItem ${
              expandedSubJobId === job.id ? "expanded" : ""
            }`}
            onClick={() => toggleSubJob(job.id)}
          >
            <div className="subJobName">
              Sub Job ID: {job.id} | Property: {job.property.addrInfo}
            </div>
          </div>

          {/* Conditionally render details when the job is clicked */}
          {expandedSubJobId === job.id && (
            <div className="subJobDetails">
              <div className="sidePanelFormRowsContainer">
                <SidePanelFormRow type="full">
                  <label>Property</label>
                  <input
                    type="text"
                    className="input medium readOnly center"
                    value={job.property.addrInfo}
                    readOnly
                  />
                </SidePanelFormRow>

                <SidePanelFormRow>
                  <label>Client</label>
                  <input
                    type="text"
                    className="input medium readOnly center"
                    value={job.client.name}
                    readOnly
                  />
                </SidePanelFormRow>

                <SidePanelFormRow>
                  <label>Contract</label>
                  <input
                    type="text"
                    className="input medium readOnly center"
                    value={job.contract.name}
                    readOnly
                  />
                </SidePanelFormRow>

                <SidePanelFormRow>
                  <label>Engineer</label>
                  <input
                    type="text"
                    className="input medium readOnly center"
                    value={`${job.engineer.forename} ${job.engineer.surname}`}
                    readOnly
                  />
                </SidePanelFormRow>

                <SidePanelFormRow>
                  <label>Job Type</label>
                  <input
                    type="text"
                    className="input medium readOnly center"
                    value={job.jobType.description}
                    readOnly
                  />
                </SidePanelFormRow>

                <SidePanelFormRow>
                  <label>Job State</label>
                  <input
                    type="text"
                    className="input medium readOnly center"
                    value={job.jobState.description}
                    readOnly
                  />
                </SidePanelFormRow>

                <SidePanelFormRow>
                  <label>Cancel Reason</label>
                  <input
                    type="text"
                    className="input medium readOnly center"
                    value={job.cancelReason.reason}
                    readOnly
                  />
                </SidePanelFormRow>

                <SidePanelFormRow>
                  <label>WO</label>
                  <input
                    type="text"
                    className="input medium readOnly center"
                    value={job.reference}
                    readOnly
                  />
                </SidePanelFormRow>

                <SidePanelFormRow>
                  <label>Priority</label>
                  <input
                    type="text"
                    className="input medium readOnly center"
                    value={job.priority.name}
                    readOnly
                  />
                </SidePanelFormRow>

                <SidePanelFormRow>
                  <label>Target Date</label>
                  <input
                    type="text"
                    className="input medium readOnly center"
                    value={Hooks.formatISODateToText(job.targetDate, false)}
                    readOnly
                  />
                </SidePanelFormRow>
                <SidePanelFormRow>
                  <label>Schedule Date</label>
                  <input
                    type="text"
                    className="input medium readOnly center"
                    value={Hooks.formatISODateToText(job.scheduledDate, false)}
                    readOnly
                  />
                </SidePanelFormRow>
                <SidePanelFormRow>
                  <label>Schedule Start Time</label>
                  <input
                    type="text"
                    className="input medium readOnly center"
                    value={Hooks.formatTime(job.scheduledStartTime)}
                    readOnly
                  />
                </SidePanelFormRow>
                <SidePanelFormRow>
                  <label>Schedule End Time</label>
                  <input
                    type="text"
                    className="input medium readOnly center"
                    value={Hooks.formatTime(job.scheduledEndTime)}
                    readOnly
                  />
                </SidePanelFormRow>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
