import React, { useEffect, useRef, useState } from "react";
import Hooks from "../../../Hooks";
import API_ENV from "@config";

export default function RowItem({ type, data, selected, submitted, onClick }) {
  const row = useRef(null);
  const input = useRef(null);

  function rowClicked(input) {
    if (!input || !input.current) {
      return;
    }

    input.current.checked = input.current.checked ? false : true;

    onClick(input, input.current.checked);
  }

  let isChecked = false;
  // commenting out "suppliers" & "warehouses" as they arent used & to remove unused-vars build warning
  const [, /*suppliers*/ updateSuppliers] = useState([]);
  const [, /*warehouses*/ updateWarehouses] = useState([]);

  if (
    (selected && selected.includes(data?.id)) ||
    (submitted.current && submitted.current.includes(data?.id))
  ) {
    isChecked = true;
  }

  const selectedSupplier = data?.targetSupplier;
  const selectedWarehouse = data?.targetWarehouse;

  useEffect(() => {
    Hooks.getData(`${API_ENV}/suppliers`, (data) => {
      const supplierList = [
        <option value={selectedSupplier?.id}>{selectedSupplier?.name}</option>,
      ];

      if (!data || !data.length) {
        return;
      }

      data.forEach((supplier) => {
        if (supplier?.name !== selectedSupplier?.name) {
          supplierList.push(
            <option value={supplier?.id}>{supplier?.name}</option>,
          );
        }
      });

      updateSuppliers(supplierList);
    });

    Hooks.getData(`${API_ENV}/warehouses`, (data) => {
      const warehouseList = [
        <option value={selectedWarehouse?.id}>
          {selectedWarehouse?.name}
        </option>,
      ];

      if (!data || !data.length) {
        return;
      }

      data.forEach((warehouse) => {
        if (warehouse?.name !== selectedWarehouse?.name) {
          warehouseList.push(
            <option value={warehouse?.id}>{warehouse?.name}</option>,
          );
        }
      });

      updateWarehouses(warehouseList);
    });
    // eslint-disable-next-lin
  }, [
    data,
    selectedSupplier?.id,
    selectedSupplier?.name,
    selectedWarehouse?.id,
    selectedWarehouse?.name,
  ]);
  // added 'selectedSupplier?.id', 'selectedSupplier?.name', 'selectedWarehouse?.id', 'selectedWarehouse?.name'
  // to dependency array, appears to be working fine without causing infinite loop

  if (type === "createdelivery") {
    const stockLocation = data?.destinationStockLocation;
    const stockLocationText = stockLocation
      ? `${stockLocation?.aisle} | ${stockLocation?.rack} | ${stockLocation?.level}`
      : "No Location Found.";
    return (
      <div
        className="submittedPrRow"
        onClick={() => {
          rowClicked(input);
        }}
      >
        <div>
          <input
            className="submittedPrSelect"
            type="checkbox"
            dataid={data?.id}
            defaultChecked={isChecked}
          />
        </div>
        <div>{data?.id}</div>
        <div>{data?.parts?.partno}</div>
        <div>{data?.orderQuantity}</div>
        <div>{`£${data?.unitPrice}`}</div>
        <div>{stockLocationText}</div>
      </div>
    );
  } else {
    return (
      <div
        className="submittedPrRow"
        ref={row}
        onClick={() => {
          rowClicked(input);
        }}
      >
        <div>
          <input
            ref={input}
            className="submittedPrSelect"
            type="checkbox"
            dataid={data?.id}
            defaultChecked={isChecked}
          />
        </div>
        <div>{data?.id}</div>
        <div>{data?.parts?.partno}</div>
        <div>{data?.quantity}</div>
        <div>{data?.targetSupplier?.name}</div>
        <div>{data?.targetWarehouse?.name}</div>
        <div>{data?.targetJobId}</div>
        <div>{`${data?.requestStaff?.forename} ${data?.requestStaff?.surname}`}</div>
        <div>{new Date(data?.requestDate).toDateString()}</div>
        <div>{data?.targetSupplier?.name}</div>
        <div>{new Date(data?.targetDate).toDateString()}</div>
      </div>
    );
  }
}
