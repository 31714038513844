import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import Hooks from "../../../../Hooks";
import PopupModal from "../../../generic/PopupModal/PopupModal";
import UpdatePartsSubCategory from "../UpdatePartsSubCategory";
import DropdownListOldNonRHF from "@ui/DropdownListOldNonRHF";

const initialFormValues = {
  partsCategoryId: "",
  partsSubCategory1Id: "",
  partsSubCategory2Id: "",
  partsSubCategory3Id: "",
  partsSubCategory4Id: "",

  // partsCategoryName: "",
  // partsSubCategory1Name: "",
  // partsSubCategory2Name: "",
  // partsSubCategory3Name: "",
  // partsSubCategory4Name: "",
  depedencies: {
    depedency1: null,
  },
};

const initialDropdownValues = {
  partsCategoryDD: [],
  partsSubCategory1DD: [],
  partsSubCategory2DD: [],
  partsSubCategory3DD: [],
  partsSubCategory4DD: [],
};

export default function ViewPartSubCategory({
  setFooterState,
  cleanURL,
  state,
  refreshTable,
}) {
  const [formData, setFormData] = useState(initialFormValues);
  const [dropdownData, setDropdownData] = useState(initialDropdownValues);

  const [popupContent, setPopupContent] = useState();

  const { data } = state;

  useEffect(() => {
    // console.log("state:", state);
    // console.log("data:", data);
    // console.log("formData:", formData);
  }, [state, data, formData]);

  // UseEffect Hooks
  // Dropdown Changes Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/partsCategory`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        partsCategoryDD: data,
      }));
    });

    if (formData.partsCategoryId) {
      Hooks.getData(
        `${API_ENV}/partsSubCategory1?PartsCategoryID=${formData.partsCategoryId}`,
        (data) => {
          // console.log(data);
          setDropdownData((prevData) => ({
            ...prevData,
            partsSubCategory1DD: data,
          }));
        },
      );
    }
    if (formData.partsSubCategory1Id) {
      Hooks.getData(
        `${API_ENV}/partsSubCategory2?PartsSubCategory1ID=${formData.partsSubCategory1Id}`,
        (data) => {
          // console.log(data);
          setDropdownData((prevData) => ({
            ...prevData,
            partsSubCategory2DD: data,
          }));
        },
      );
    }
    if (formData.partsSubCategory2Id) {
      Hooks.getData(
        `${API_ENV}/partsSubCategory3?PartsSubCategory2ID=${formData.partsSubCategory2Id}`,
        (data) => {
          // console.log(data);
          setDropdownData((prevData) => ({
            ...prevData,
            partsSubCategory3DD: data,
          }));
        },
      );
    }
    if (formData.partsSubCategory3Id) {
      Hooks.getData(
        `${API_ENV}/partsSubCategory4?PartsSubCategory3ID=${formData.partsSubCategory3Id}`,
        (data) => {
          // console.log(data);
          setDropdownData((prevData) => ({
            ...prevData,
            partsSubCategory4DD: data,
          }));
        },
      );
    }
  }, [
    formData.partsCategoryId,
    formData.partsSubCategory1Id,
    formData.partsSubCategory2Id,
    formData.partsSubCategory3Id,
  ]);

  // Fill form via state
  useEffect(() => {
    if (data) {
      setFormData((prevData) => ({
        ...prevData,
        partsCategoryId:
          data.partsCategoryId === null ? "" : data.partsCategoryId,
        partsSubCategory1Id:
          data.partsSubCategory1Id === null ? "" : data.partsSubCategory1Id,
        partsSubCategory2Id:
          data.partsSubCategory2Id === null ? "" : data.partsSubCategory2Id,
        partsSubCategory3Id:
          data.partsSubCategory3Id === null ? "" : data.partsSubCategory3Id,
        partsSubCategory4Id:
          data.partsSubCategory4Id === null ? "" : data.partsSubCategory4Id,

        // partsCategoryName:
        //   data.partsCategoryName === null ? "" : data.partsCategoryName,
        // partsSubCategory1Name:
        //   data.partsSubCategory1Name === null ? "" : data.partsSubCategory1Name,
        // partsSubCategory2Name:
        //   data.partsSubCategory2Name === null ? "" : data.partsSubCategory2Name,
        // partsSubCategory3Name:
        //   data.partsSubCategory3Name === null ? "" : data.partsSubCategory3Name,
        // partsSubCategory4Name:
        //   data.partsSubCategory4Name === null ? "" : data.partsSubCategory4Name,
      }));
    }
  }, [data]);

  // HANDLE Functions
  const handleButton1 = useCallback(async () => {
    let dataToSend = {};

    function thenFunction() {
      refreshTable();
      cleanURL();
    }

    Hooks.sendData(dataToSend, `${API_ENV}/`, "PUT", thenFunction, (data) => {
      // console.log(data);
    });
  }, [refreshTable, cleanURL]);

  const handleButton2 = useCallback(async () => {}, []);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      // { text: "Button1", click: handleButton1 },
      // { text: "Button2", click: handleButton2 },
      { text: "Close", click: closeFunction },
    ]);
  }, [setFooterState, closeFunction, handleButton1, handleButton2]);

  function handleModalClose() {
    setPopupContent();
  }

  function handleFilter(category) {
    setPopupContent(
      <UpdatePartsSubCategory
        state={state}
        onClose={handleModalClose}
        cleanURL={cleanURL}
        refreshTable={refreshTable}
        category={category}
      />,
    );
  }
  return (
    <>
      <div className="formContainer">
        <div className="formRow">
          <DropdownListOldNonRHF
            label={"Parts Category"}
            dropdownData={dropdownData.partsCategoryDD}
            formData={formData}
            setFormData={setFormData}
            customKeyName={"partsCategoryId"}
            disableFirstOption={true}
          />
        </div>

        <div className="formRow">
          <button
            className="formRowButton"
            onClick={() => handleFilter("PartsCategory")}
          >
            Maintain
          </button>
        </div>

        <div className="formRow">
          <DropdownListOldNonRHF
            label={"Parts Sub Category 1"}
            dropdownData={dropdownData.partsSubCategory1DD}
            formData={formData}
            setFormData={setFormData}
            customKeyName={"partsSubCategory1Id"}
            firstOption="None"
          />
        </div>

        <div className="formRow">
          <button
            className="formRowButton"
            onClick={() => handleFilter("SubCategory1")}
          >
            Maintain
          </button>
        </div>

        <div className="formRow">
          <DropdownListOldNonRHF
            label={"Parts Sub Category 2"}
            dropdownData={dropdownData.partsSubCategory2DD}
            formData={formData}
            setFormData={setFormData}
            customKeyName={"partsSubCategory2Id"}
            firstOption="None"
          />
        </div>

        <div className="formRow">
          <button
            className="formRowButton"
            onClick={() => handleFilter("SubCategory2")}
          >
            Maintain
          </button>
        </div>

        <div className="formRow">
          <DropdownListOldNonRHF
            label={"Parts Sub Category 3"}
            dropdownData={dropdownData.partsSubCategory3DD}
            formData={formData}
            setFormData={setFormData}
            customKeyName={"partsSubCategory3Id"}
            firstOption="None"
          />
        </div>

        <div className="formRow">
          <button
            className="formRowButton"
            onClick={() => handleFilter("SubCategory3")}
          >
            Maintain
          </button>
        </div>

        <div className="formRow">
          <DropdownListOldNonRHF
            label={"Parts Sub Category 4"}
            dropdownData={dropdownData.partsSubCategory4DD}
            formData={formData}
            setFormData={setFormData}
            customKeyName={"partsSubCategory4Id"}
            firstOption="None"
          />
        </div>

        <div className="formRow">
          <button
            className="formRowButton"
            onClick={() => handleFilter("SubCategory4")}
          >
            Maintain
          </button>
        </div>

        <PopupModal onClose={handleModalClose}>{popupContent}</PopupModal>
      </div>
    </>
  );
}
