import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "../../../Hooks";
import Form from "../../../ui/Form";
import SidePanelFormRow from "../../../ui/SidePanelFormRow";
import Input from "../../../ui/Input";
import AddressInput from "../../../features/forms/AddressInput";
import API_ENV from "@config";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    name: yup.string().required("This field is required"),
    email: yup.string().required("This field is required"),
    address1: yup.string().required("This field is required"),
    town: yup.string().required("This field is required"),
    county: yup.string().required("This field is required"),
    postCode: yup
      .string()
      .required("This field is required")
      .matches(
        /^([A-Z]{1,2}[0-9][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}|GIR\s?0AA)$/i,
        "Invalid UK postcode",
      ),
    // phone: yup.string().required("This field is required"),
  })
  .required();

export default function ViewClient({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // const [dropdownData, setDropdownData] = useState({
  //   dropdownData1: [],
  // });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      town: "",
      county: "",
      postCode: "",
      phone: "",
    },
  });

  // const { openModal } = useModal();
  const { data: stateData } = state;

  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log(values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // UseEffect Hooks
  // Dropdown list 1 Data
  // useEffect(() => {
  //   Hooks.getData(`${API_ENV}/`, (data) => {
  //     // console.log(data);
  //     setDropdownData((prevData) => ({
  //       ...prevData,
  //       dropdownData1: data,
  //     }));
  //   });
  // }, []);

  // Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: stateData.id,
        name: stateData.name,
        email: stateData.email,
        address1: stateData.address1,
        address2: stateData.address2,
        address3: stateData.address3,
        address4: stateData.address4,
        town: stateData.town,
        county: stateData.county,
        postCode: stateData.postCode,
        phone: stateData.phone,
      });
    }
  }, [stateData, reset]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          cleanURL();

          setIsButtonDisabled(false);
        }

        // console.log("dataSubmit", data);
        // console.log("apiUrl", apiUrl);
        Hooks.sendData(
          data,
          `${API_ENV}/${apiUrl}/${data.id}`,
          "PUT",
          thenFunction,
        );
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, cleanURL, apiUrl],
  );

  const handleArchive = useCallback(() => {
    archive();
  }, [archive]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Submit Form",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleSubmit(handleOpenModal) },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <Input label={"Name"} name="name" control={control} errors={errors} />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <Input
            label={"Email"}
            name="email"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <AddressInput register={register} errors={errors} readOnly={false} />
      </div>

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
