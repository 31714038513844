import API_ENV from "@config";
import React, { useEffect, useRef, useState } from "react";

import "./poStyles.scss";
import Swal from "sweetalert2";
import PurchaseReqRow from "./PurchaseReqRow";
import Hooks from "../../../Hooks";
import NewDropdown from "../../generic/NewDropdown";

export default function CreatePO({ type, data, onClose, changeSelection }) {
  const [content, updateContent] = useState([]);
  const [poSupplier, updatePoSupplier] = useState();
  const [suppliers, updateSuppliers] = useState([]);
  const [warehouses, updateWarehouses] = useState([]);
  const [topSection, updateTopSection] = useState();
  const [subHeader, updateSubHeader] = useState();
  const [footer, updateFooter] = useState();
  const [locationUrl, updateLocationUrl] = useState();

  const preferredWarehouse = useRef(null);
  const preferredSupplier = useRef(null);
  const targetDateRef = useRef(null);
  const descriptionRef = useRef(null);
  const deliveryNumberRef = useRef(null);

  function changeSupplier() {
    const supplierId = preferredSupplier.current.value;

    if (isNaN(supplierId)) {
      Hooks.displayError("Please select a supplier.");

      return;
    }

    updatePoSupplier(supplierId);
  }

  useEffect(() => {
    Hooks.getData(`${API_ENV}/suppliers`, (data) => {
      const array = [<option>Select PO Supplier</option>];
      if (data && data.length) {
        data.forEach((item) => {
          array.push(<option value={item.id}>{item.name}</option>);
        });
        updateSuppliers(array);
      }
    });

    Hooks.getData(`${API_ENV}/warehouses?isVan=false`, (data) => {
      const array = [<option>Select PO Warehouse</option>];
      if (data && data.length) {
        data.forEach((item) => {
          array.push(<option value={item.id}>{item.name}</option>);
        });

        updateWarehouses(array);
      }
    });
  }, []);

  function changeWarehouse(warehouse) {
    updateLocationUrl(`${API_ENV}/stockLocation?WarehouseID=${warehouse?.id}`);
  }

  useEffect(() => {
    updateContent([]);

    if (type === "createpo") {
      updateTopSection(
        <div id="topSection">
          <select id="supplierSelect" ref={preferredSupplier}>
            {suppliers}
          </select>
          <div id="changeSelection" onClick={changeSupplier}>
            Update Supplier
          </div>
          <select id="warehouseSelect" ref={preferredWarehouse}>
            {warehouses}
          </select>
          <div>
            <label style={{ marginRight: "10px" }}>Delivery Date</label>
            <input ref={targetDateRef} style={{ height: "25px" }} type="date" />
          </div>
          <input type="text" placeholder="Description" ref={descriptionRef} />
        </div>,
      );

      updateSubHeader(
        <div id="createPoSubHeader">
          <div>User Preferences</div>
          <div>Supplier Values</div>
        </div>,
      );

      updateFooter(
        <div id="createPoFooter">
          <div id="backButton" onClick={backClicked}>{`<<< Back`}</div>
          <div id="createPoButton" onClick={createPo}>
            Create Purchase Order
          </div>
        </div>,
      );
    } else if (type === "createdelivery") {
      updateTopSection(
        <div id="topSection">
          <input
            type="text"
            placeholder="Delivery Number"
            ref={deliveryNumberRef}
          />
          <NewDropdown
            type="preferredwarehouse"
            topValue={{ value: 0, label: "Select Warehouse" }}
            onChange={changeWarehouse}
          />
          <NewDropdown
            type="stocklocation"
            topValue={{ value: 0, label: "Select Stock Location" }}
            customUrl={locationUrl}
          />
          <div>
            <label style={{ marginRight: "10px" }}>Target Date</label>
            <input ref={targetDateRef} style={{ height: "25px" }} type="date" />
          </div>
        </div>,
      );

      updateFooter(
        <div id="createPoFooter">
          <div id="backButton" onClick={backClicked}>{`<<< Back`}</div>
          <div id="createPoButton" onClick={createDelivery}>
            Create Delivery
          </div>
        </div>,
      );
    }

    if (!data || !data.length) {
      return;
    }

    const headers = {
      createpo: [
        "PR ID",
        "Part",
        "Quantity",
        "Preferred Supplier",
        "Warehouse",
        "Unit Cost",
        "Inventory Qty",
        "Remove PR",
      ],
      createdelivery: [
        "Detail ID",
        "Part",
        "Quantity",
        "Unit Cost",
        "Target Warehouse",
        "Target Stock Location",
        "Remove Detail",
      ],
    };

    const array = [
      <div className="purchaseReqRow">
        {headers[type].map((header) => (
          <div>{header}</div>
        ))}
      </div>,
    ];

    data.forEach((purchaseRequest) => {
      array.push(
        <PurchaseReqRow
          type={type}
          poSupplier={poSupplier}
          key={purchaseRequest?.id}
          data={purchaseRequest}
          purchaseReqId={purchaseRequest?.id}
        />,
      );
    });

    updateContent(array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, data, poSupplier, warehouses, suppliers, locationUrl]);

  function createDelivery() {
    Swal.fire({
      title: "Create Delivery?",
      text: "Are you sure you wish to create a Delivery?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const purchaseDetailIds = [];

        for (
          let i = 1;
          i < document.querySelectorAll(".purchaseReqRow").length;
          i++
        ) {
          const row = document.querySelectorAll(".purchaseReqRow")[i];
          if (row && row.getAttribute("dataid")) {
            purchaseDetailIds.push(
              `purchaseOrderDetIDs=${row.getAttribute("dataid")}`,
            );
          }
        }

        const supplierId = Hooks.getSidePanelData()?.supplierRefId;
        const deliveryNoteNumber = deliveryNumberRef?.current?.value;
        const warehouseId =
          "DefaultWarehouseId=" +
          document
            .querySelector("#preferredwarehouseDropdown")
            .getAttribute("data");
        const stockLocationId =
          "DefaultStockLocationId=" +
          document.querySelector("#stocklocationDropdown").getAttribute("data");
        let deliveryDate = targetDateRef?.current?.value;

        if (!deliveryNoteNumber) {
          Hooks.displayError("Please enter a delivery note number.");
          return;
        }

        if (!warehouseId) {
          Hooks.displayError("Please select a warehouse. ");
          return;
        }

        if (!stockLocationId) {
          Hooks.displayError("Please select a stock location.");
          return;
        }

        if (!deliveryDate) {
          Hooks.displayError("Please select a delivery date.");
          return;
        }

        // commented out as not currently being used
        // let date = new Date(deliveryDate);

        let dateFormat = "2023-05-26";
        //  date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();

        deliveryDate = "DeliveryDate=" + dateFormat;

        const ending = deliveryDate + "&" + warehouseId + "&" + stockLocationId;

        const url = `${API_ENV}/deliveryNote/bulbcreatefromPODetails/${supplierId}/${deliveryNoteNumber}?${ending}&${purchaseDetailIds.join(
          "&",
        )}`;
        console.log(url);
        const options = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjIiLCJuYmYiOjE2ODU1MjUyODcsImV4cCI6MTY4NjEzMDA4NywiaWF0IjoxNjg1NTI1Mjg3fQ.ufZGjJyAiXOm6C6AY1wgV73UTor4HgGmX_81r5eU6bM`,
          },
          body: JSON.stringify(data),
        };
        fetch(url, options)
          .then((response) => {
            if (response.ok) {
              Swal.fire({
                title: "Success",
                text: "Success.",
                icon: "success",
                confirmButtonText: "Ok",
              });
            } else {
              Hooks.displayError(response.status);
            }
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  }

  function createPo() {
    Swal.fire({
      title: "Create Purchase Order?",
      text: "Are you sure you wish to create a Purchase Order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (
          !preferredWarehouse ||
          !preferredWarehouse.current ||
          !preferredSupplier ||
          !preferredSupplier.current ||
          !targetDateRef ||
          !targetDateRef.current ||
          !descriptionRef ||
          !descriptionRef.current
        ) {
          console.error(
            "There is a missing element on the Create PO component",
          );
          return;
        }

        const warehouseId = preferredWarehouse.current.value;
        const supplierId = preferredSupplier.current.value;
        const targetDate = targetDateRef.current.value;
        const description = descriptionRef.current.value;

        if (!targetDate) {
          Hooks.displayError("Please enter a target date.");
          return;
        }

        if (!description) {
          Hooks.displayError("Please enter a description.");
          return;
        }

        if (isNaN(supplierId)) {
          Hooks.displayError("Please select a supplier.");
          return;
        }

        const purchaseReqIds = [];

        for (
          let i = 1;
          i < document.querySelectorAll(".purchaseReqRow").length;
          i++
        ) {
          const row = document.querySelectorAll(".purchaseReqRow")[i];
          if (row && row.getAttribute("dataid")) {
            purchaseReqIds.push(
              `purchaseRequestIDs=${row.getAttribute("dataid")}`,
            );
          }
        }

        const url = `${API_ENV}/purchaseRequest/bulbtoPO/${supplierId}/${warehouseId}?${purchaseReqIds.join(
          "&",
        )}&Desc=${description}`;

        Hooks.sendData({}, url, "PUT", (response) => {
          if (response.ok && typeof onClose === "function") {
            document.querySelector("#refreshButton").click();
            onClose();
          }
        });
      }
    });
  }

  function backClicked() {
    if (!changeSelection || typeof changeSelection !== "function") {
      return;
    }

    const prList = [];

    content.forEach((prRow) => {
      if (prRow?.props?.purchaseReqId) {
        prList.push(prRow.props.purchaseReqId);
      }
    });

    changeSelection(prList);
  }

  return (
    <div id="createPo">
      <div id="createPoHeader">
        <div id="createPoTitle">
          Create new {type === "createpo" ? "Purchase Order" : "Delivery"}
        </div>
        <div id="createPoClose" onClick={onClose}>
          Close
        </div>
      </div>
      <div id="createPoContent">
        {topSection}
        {subHeader}
        <div id="prList">{content}</div>
        {footer}
      </div>
    </div>
  );
}
