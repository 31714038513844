import API_ENV from "@config";
import "../styles/ui/AttributesDisplay.scss";

import Hooks from "@hooksFile";
import { useEffect, useState } from "react";
import Button from "./Button";
import ViewAttributesModalForm from "forms-NEW/00.Other/ModalForms/ViewAttributesModalForm";
import { Modal, ModalTrigger } from "./Modal";
import StandaloneTable from "features/table/StandaloneTable";

export default function AttributesDisplay({ objectDocTypeId, objectDocId }) {
  const [formData, setFormData] = useState([]);
  const [displayData, setDisplayData] = useState([]);

  const [tableData, setTableData] = useState({});
  const [tableColumns, setTableColumns] = useState([]);

  // useEffect(() => {
  //   console.log("formData:", formData);
  //   // console.log("displayData:", displayData);
  // }, [formData]);

  // * Populating existing formData from API ON COMPONENT LOAD!
  useEffect(() => {
    let isMounted = true;
    Hooks.getData(
      // `${API_ENV}/attributeValue/GetAttributeHdr/{objectDocTypeId}/{objectDocId}`,
      `${API_ENV}/attributeValue/GetAttributeHdr/${objectDocTypeId}/${objectDocId}?getDisplayOnly=false`,
      (data) => {
        // console.log("Existing Attribute Data: ", data);

        if (isMounted) {
          if (data && typeof data === "object" && data?.contents?.length > 0) {
            const transformedFormData = data.contents.map((contentsItem) => {
              return {
                categoryId: contentsItem.attributeCategoryId,
                unitId: contentsItem.attributeUnitId,
                value: contentsItem.value,
                dependencies: {
                  answerTypeRefId: contentsItem
                    ? contentsItem.answerTypeId
                    : "",
                },
              };
            });

            setFormData(
              transformedFormData.length > 0 ? transformedFormData : [],
            );
          }
        }
      },
    );

    return () => {
      isMounted = false;
    };
  }, [objectDocTypeId, objectDocId]);

  // * Makes API calls to get Attribute Category & Unit Names to display
  useEffect(() => {
    let isMounted = true;

    if (formData.length > 0) {
      setDisplayData([]);

      formData.forEach((formItem, index) => {
        Hooks.getData(
          `${API_ENV}/attributeCategory/${formItem.categoryId}`,
          (data) => {
            // console.log("attributeCategory Data: ", data);

            if (isMounted) {
              if (formItem.dependencies.answerTypeRefId !== 4) {
                setDisplayData((prevData) => {
                  const newData = [...prevData];
                  newData[index] = {
                    ...newData[index],
                    categoryName: data.catName || newData[index].categoryName,
                    value: formItem.value, //* Ensure value is copied over
                  };
                  return newData;
                });
              } else {
                setDisplayData((prevData) => {
                  const newData = [...prevData];
                  newData[index] = {
                    ...newData[index],
                    categoryName: data.catName || newData[index].categoryName,
                  };
                  return newData;
                });
              }
            }
          },
        );

        Hooks.getData(`${API_ENV}/attributeUnit/${formItem.unitId}`, (data) => {
          // console.log("attributeUnit Data: ", data);

          if (isMounted) {
            setDisplayData((prevData) => {
              const newData = [...prevData];
              newData[index] = {
                ...newData[index],
                unitName: data.unitName || newData[index].unitName,
              };
              return newData;
            });
          }
        });
      });
    } else {
      setDisplayData([]);
    }

    return () => {
      isMounted = false;
    };
  }, [formData]);

  // * Separate UE for API call to get value name to display
  useEffect(() => {
    formData.forEach((formItem, index) => {
      if (formItem.value) {
        // console.log("test1");
        if (formItem.dependencies.answerTypeRefId === 4) {
          Hooks.getData(
            `${API_ENV}/attributeListChoice/attributeUnitId/${formItem.unitId}`,
            (data) => {
              // console.log("attributeListChoice/attributeUnitId: ", data);

              const match = data.find(
                (item) => item.id === Number(formItem.value),
              );

              // console.log("match", match);

              if (data) {
                setDisplayData((prevData) => {
                  const newData = [...prevData];
                  newData[index] = {
                    ...newData[index],
                    value: match?.listValue || "",
                  };
                  return newData;
                });
              }
            },
          );
        }
      }
    });
  }, [formData]);

  useEffect(() => {
    const timeoutDelay = 75;
    const timeoutId = setTimeout(() => {
      setTableData(displayData);
      setTableColumns([
        { accessorKey: "categoryName", header: "Category" },
        { accessorKey: "unitName", header: "Unit" },
        { accessorKey: "value", header: "Value" },
      ]);
    }, timeoutDelay);

    return () => clearTimeout(timeoutId);
  }, [displayData]);

  return (
    <>
      <div className="attributesDisplayContainer">
        {/*//. New UI for displaying Attributes */}
        <StandaloneTable
          tableData={tableData}
          tableColumns={tableColumns}
          tableContainerType="collapsibleGroupTable"
        />

        {/*//. Old UI Rows  */}
        {/* {displayData.length > 0 &&
          displayData.map((row, index) => (
            <div className="attributeRow" key={index}>
              <label>Category:</label>
              <input
                id="attributeValue"
                className="input extraSmall"
                type="text"
                readOnly
                value={row?.categoryName || ""}
              />

              <label>Unit:</label>
              <input
                id="attributeValue"
                className="input extraSmall"
                type="text"
                readOnly
                value={row?.unitName || ""}
              />

              <label>Value:</label>
              <input
                id="attributeValue"
                className="input extraSmall"
                type="text"
                readOnly
                value={row?.value || ""}
              />
            </div>
          ))} */}

        {!displayData.length > 0 && (
          <>
            <div>No Attributes Found.</div>
          </>
        )}

        <div>
          <ModalTrigger name="AttributesModal">
            <Button
              size={"small"}
              onClick={(e) => {
                e.preventDefault();
                // handleAddTag();
              }}
            >
              {displayData.length > 0 ? "Edit Attributes" : "Add Attributes"}
            </Button>
          </ModalTrigger>
        </div>
      </div>

      <Modal name="AttributesModal" title={"Attributes"}>
        <ViewAttributesModalForm
          objectDocTypeId={objectDocTypeId}
          objectDocId={objectDocId}
          setOutsideFormData={setFormData}
        ></ViewAttributesModalForm>
      </Modal>
    </>
  );
}
