import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import DropdownList from "@ui/DropdownList";
import CollapsibleGroup from "@ui/CollapsibleGroup";
import { useParams } from "react-router-dom";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    partsId: yup.string().required("This field is required"),
    supplierId: yup.string().required("This field is required"),

    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function AddPartsAndSuppliers({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  setFormState,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
    dropdownData2: [],
  });
  const { tableRowId } = useParams();

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      partsId: tableRowId,
      supplierId: "",
    },
  });

  // const { openModal } = useModal();

  // useEffect(() => {
  //   console.log("dropdownData:", dropdownData);
  // }, [dropdownData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown List Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/parts`, (data) => {
      // console.log("useEffect API 1 data:", data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });

    Hooks.getData(`${API_ENV}/suppliers`, (data) => {
      // console.log("useEffect API 2 data:", data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData2: data,
      }));
    });
  }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          // cleanURL();
          reset();

          Hooks.getData(
            `${API_ENV}/partsAndSupplier?PartsID=${tableRowId}`,
            (data) => {
              // console.log("useEffect API 1 data:", data);
              setFormState({ type: "partsupplier", data });
            },
          );

          setIsButtonDisabled(false);
        }

        // console.log("handleSubmitForm data:", data);

        // console.log(dataObject);

        Hooks.sendData(
          data,
          `${API_ENV}/partsAndSupplier`,
          "POST",
          thenFunction,
        );
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, reset, setFormState, tableRowId],
  );

  // const handleChangeForm = useCallback(() => {
  //   setFormState({ type: "formNameHere", data: {} });
  // }, [setFormState]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Submit Form",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      // { text: "Change Form", click: handleChangeForm },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          {/* <SidePanelFormRow>
            <Input
              label={"Input Field"}
              name="field1"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow> */}

          <SidePanelFormRow>
            <DropdownList
              label={"Parts"}
              name="partsId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
              optionName="partno"
              disabled
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Suppliers"}
              name="supplierId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData2}
              optionName="name"
            />
          </SidePanelFormRow>

          {/* <SidePanelFormRow type="full">
            <label>textArea</label>
            <textarea
              {...register("textArea")}
              rows="5"
              className="input left"
            ></textarea>
            {errors.issueDetail && (
              <span className="fieldError">{errors.issueDetail.message}</span>
            )}
          </SidePanelFormRow> */}

          {/* <SidePanelFormRow>
          <Button
            size={"small"}
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal();
            }}
          >
            Open Modal
          </Button>
        </SidePanelFormRow> */}
        </div>
      </CollapsibleGroup>

      {/* <Modal name="Example" title={"Title"}>
       
      </Modal> */}
    </Form>
  );
}
