import API_ENV from "@config";
import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    desc: yup.string().required("This field is required"),
  })
  .required();

export default function ViewAnswerType({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // const [dropdownData, setDropdownData] = useState({
  //   dropdownData1: [],
  // });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      desc: "",
      inspectionFlag: "",
      attributeFlag: "",
    },
  });

  // const { openModal } = useModal();
  const { data: stateData } = state;

  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) =>
  //     console.log("Form values:", values)
  //   );
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: stateData.id,
        // * BOOLEANS need || "" removed!
        desc: stateData.desc || "",
        inspectionFlag: stateData.inspectionFlag,
        attributeFlag: stateData.attributeFlag,
      });
    }
  }, [stateData, reset]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  // useEffect(() => {
  //   // ! TO DO
  //   Hooks.getData(`${API_ENV}/`, (data) => {
  //     // console.log(data);
  //     setDropdownData((prevData) => ({
  //       ...prevData,
  //       dropdownData1: data,
  //     }));
  //   });
  // }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          cleanURL();

          setIsButtonDisabled(false);
        }

        // console.log("apiUrl", apiUrl);
        // console.log("handleSubmitForm data:", data);

        Hooks.sendData(
          data,
          `${API_ENV}/${apiUrl}/${data.id}`,
          "PUT",
          thenFunction,
        );
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, cleanURL, apiUrl],
  );

  const handleArchive = useCallback(() => {
    archive();
  }, [archive]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Submit Form",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <Input
            label={"Answer Type"}
            name="desc"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <Input
            label={"For Inspection?"}
            name="inspectionFlag"
            type="checkbox"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <Input
            label={"For Attribute?"}
            name="attributeFlag"
            type="checkbox"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>
      </div>

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
