import API_ENV from "@config";
import "./attachments.scss";

import React, { useState, useEffect, useCallback } from "react";
import Hooks from "../../../../Hooks";
import Swal from "sweetalert2";

import { FaRegFilePdf } from "react-icons/fa6";
import { HiOutlineDocument, HiOutlinePhoto } from "react-icons/hi2";

export default function Attachments({
  jobId,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  setFormState,
}) {
  const [attachments, updateAttachments] = useState([]);

  const deleteAttachment = useCallback(
    (target, attachmentId) => {
      if (target && target.parentElement) {
        Swal.fire({
          title: `Remove attachment?`,
          text: "Are you sure you wish to remove this attachment? This cannot be undone.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            function thenFunction(response) {
              if (response.ok) {
                target.parentElement.remove();

                Hooks.getData(`${API_ENV}/attachments/job/${jobId}`, (data) => {
                  // console.log(data);
                  setFormState({ type: "attachments", data: { data } });
                });
              }
            }
            Hooks.sendData(
              {},
              `${API_ENV}/attachments/${attachmentId}`,
              "DELETE",
              thenFunction,
            );
          }
        });
      }
    },
    [jobId, setFormState],
  );

  const singleAttachment = useCallback(
    (data) => {
      // console.log("Attachment Data", data);

      function download(target) {
        if (target && !target.className.includes("delete")) {
          fetch(`${API_ENV}/attachments/${data.id}`, {
            method: "get",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: Hooks.getCookie("jwt"),
            },
          })
            .then((response) => response.blob())
            .then((blob) => {
              const _url = window.URL.createObjectURL(blob);
              window.open(_url, "_blank").focus(); // window.open + focus
            });
        }
      }

      let IconComponent;

      if (data.path.includes(".pdf")) {
        IconComponent = FaRegFilePdf;
      } else if (data.path.includes(".jpg") || data.path.includes([".png"])) {
        IconComponent = HiOutlinePhoto;
      } else {
        IconComponent = HiOutlineDocument;
      }

      return (
        <div
          className="singleAttachmentContainer"
          key={data.id}
          attachmentid={data.id}
        >
          <div
            className="singleAttachment"
            onClick={(e) => {
              download(e.target);
            }}
          >
            <IconComponent size={70} className="attachmentImage" />

            <div className="content">
              <div className="contentItem" attachmentid={data.id}>
                <div className="key">Name:</div>
                <div className="text">{data.path.split("\\").pop()}</div>
                <div className="actionContainer">
                  <div
                    className="deleteAttachment button"
                    onClick={(e) => {
                      deleteAttachment(e.target, data.id);
                    }}
                  >
                    ✖
                  </div>
                </div>
              </div>
              <div className="contentItem">
                <div className="key">Date:</div>
                <div className="text">
                  {new Date(data.dateAdded).toDateString()}{" "}
                  {new Date(data.dateAdded).toLocaleTimeString()}
                </div>
              </div>

              <div className="contentItem">
                <div className="key">Visit:</div>
                <div className="text">
                  {data.visit.id || "No visit assigned."}
                </div>
              </div>

              <div className="contentItem">
                <div className="key">Info:</div>
                <div className="text">
                  {" "}
                  {data.description && data.description !== "null"
                    ? data.description
                    : ""}
                </div>
              </div>

              <div className="contentItem">
                <div className="key">Document Tag:</div>
                <div className="text">
                  {data.documentTag.name || "No Document Tag assigned."}
                </div>
              </div>
            </div>
          </div>

          <div className="attachmentButtons"></div>
        </div>
      );
    },
    [deleteAttachment],
  );

  useEffect(() => {
    Hooks.getData(`${API_ENV}/attachments/job/${jobId}`, (data) => {
      if (data && data.length) {
        let array = [];
        data.forEach((item) => {
          array.push(singleAttachment(item));
        });
        updateAttachments(array);
      } else {
        updateAttachments(
          <p className="contentInfo">No attachments for this job</p>,
        );
      }
    });
  }, [jobId, singleAttachment]);

  return <div className="currentAttachments">{attachments}</div>;
}
