import React, { useEffect, useState } from "react";
import SidePanelRow from "../../../sidePanel/SidePanelComponents/SidePanelRow";

export default function ContentForm({ formContents, type }) {
  const [form, updateForm] = useState(null);

  // useEffect(() => {
  //   console.log("formContents:", formContents);
  // }, [formContents]);

  // THIS NEEDS TO BE HERE OTHERWISE OLD formContents DATA WILL BE DISPLAYED THAT SHOULDNT HAVE ANY DATA!
  useEffect(() => {
    updateForm(null);
  }, [formContents]);

  useEffect(() => {
    if (typeof formContents === "string") {
      updateForm(<p></p>);
    } else if (formContents.id) {
      if (type === "resident") {
        updateForm(
          <>
            <SidePanelRow
              title="Primary"
              value={formContents.primary}
              fullData={formContents}
            />
            <SidePanelRow
              title="Title"
              value={formContents.title}
              fullData={formContents}
            />
            <SidePanelRow
              title="Forename"
              value={formContents.forename}
              fullData={formContents}
            />
            <SidePanelRow
              title="Surname"
              value={formContents.surname}
              fullData={formContents}
            />
            <SidePanelRow
              title="Mobile"
              value={formContents.mobile}
              fullData={formContents}
            />
            <SidePanelRow
              title="Email"
              value={formContents.email}
              fullData={formContents}
            />
            <SidePanelRow
              title="Landline"
              value={formContents.landline}
              fullData={formContents}
            />
          </>,
        );
      } else if (type === "assignedjobs") {
        updateForm(
          <>
            <div id="mainForm">
              <SidePanelRow
                title="Contract"
                value={formContents.contractId}
                fullData={formContents}
              />
              <SidePanelRow title="Priority" />
              <SidePanelRow title="Job Type State" fullData={formContents} />
              <SidePanelRow
                title="Engineer"
                value={formContents.engineerRefId}
                fullData={formContents}
              />
              <SidePanelRow title="Invoiced" value={formContents.invoiced} />
              <SidePanelRow
                title="Scheduled Date"
                value={formContents.scheduledDate}
              />
              <SidePanelRow
                title="Target Date"
                value={formContents.targetDate}
              />
              <SidePanelRow
                title="Start Time"
                value={formContents.scheduledStartTime}
              />
              <SidePanelRow
                title="End Time"
                value={formContents.scheduledEndTime}
              />
              <SidePanelRow
                title="Completed"
                value={formContents.completedDate}
              />
              <SidePanelRow
                title="Complete Flag"
                value={formContents.completed_flag}
                fullData={formContents}
              />
              <SidePanelRow title="Reference" value={formContents.reference} />
              <SidePanelRow
                title="Record Created"
                value={formContents.recordCreated}
                optional="noedit"
              />
              <SidePanelRow
                title="Record Modified"
                value={formContents.recordModified}
                optional="noedit"
              />
            </div>
            <SidePanelRow
              title="Notes"
              value={formContents.id}
              optional="noedit"
            />
          </>,
        );
      } else if (type === "equipmentdetails") {
        if (!formContents) {
          updateForm(<p>No contract assigned to property.</p>);
        } else {
          let make,
            model,
            contractName = null;

          try {
            make = formContents.equipmentType.make;
            model = formContents.equipmentType.model;
          } catch (e) {}

          try {
            contractName = formContents.contract.name;
          } catch (e) {}
          // console.log("formContents", formContents);
          updateForm(
            <>
              <SidePanelRow
                title="Equipment Type"
                value={formContents.equipmentTypeRefId}
                fullData={formContents}
              />
              <SidePanelRow title="Name" value={formContents.name} />
              <SidePanelRow title="Make" value={make} />
              <SidePanelRow title="Model" value={model} />
              <SidePanelRow
                title="Last Service"
                value={formContents.lastService}
              />
              <SidePanelRow
                title="Next Service"
                value={formContents.nextService}
              />
              <SidePanelRow
                title="Anniversary Date"
                value={formContents.anniversaryDate}
              />
              <SidePanelRow title="Location" value={formContents.location} />
              <SidePanelRow title="Contract Read Only" value={contractName} />
              <SidePanelRow
                title="Equipment Notes"
                value={formContents.notes}
              />
            </>,
          );
        }
      } else if (type === "visit") {
        updateForm(
          <>
            <SidePanelRow
              title="Visit Number"
              value={formContents.visitNumber}
              fullData={formContents}
            />
            <SidePanelRow
              title="Engineer"
              value={formContents.engineer}
              fullData={formContents}
            />
            <SidePanelRow
              title="Logged"
              value={formContents.loggedAt}
              fullData={formContents}
            />
            <SidePanelRow
              title="Scheduled Date"
              value={formContents.scheduledFor}
              fullData={formContents}
            />
            <SidePanelRow
              title="Estimated Arrival Time"
              value={formContents.estimatedArrivalTime}
              fullData={formContents}
            />
            <SidePanelRow
              title="Actual Arrival Time"
              value={formContents.actualArrivalTime}
              fullData={formContents}
            />
            <SidePanelRow
              title="Departure Time"
              value={formContents.departureTime}
              fullData={formContents}
            />
            <SidePanelRow
              title="Visit State"
              value={formContents.visitState}
              fullData={formContents}
            />
            <SidePanelRow
              title="Completed"
              value={formContents.completedAt}
              fullData={formContents}
            />
            <SidePanelRow
              title="Complete Flag"
              value={formContents.completed_flag}
              fullData={formContents}
            />
            <SidePanelRow
              title="Visit Notes"
              value={formContents.notes}
              fullData={formContents}
            />
          </>,
        );
      } else if (type === "jobparts") {
        // commenting out "staffName" as this isnt used & to remove unused-vars build warning
        // let staffName = "";

        try {
          // staffName = `${formContents.allocateStaff.forename} ${formContents.allocateStaff.surname}`;
        } catch (e) {}

        // console.log(formContents);
        updateForm(
          <>
            <SidePanelRow
              title="ID"
              value={formContents?.parts?.id}
              readOnly={true}
            />
            <SidePanelRow
              title="Part Number"
              value={formContents?.parts?.partno}
              dataId={formContents?.parts?.id}
              readOnly={true}
            />
            <SidePanelRow
              title="Part Description"
              value={formContents?.parts?.description}
              fullData={formContents}
            />
            <SidePanelRow
              title="Quantity"
              value={formContents?.qty}
              fullData={formContents}
            />
            <SidePanelRow
              title="Engineer"
              value={formContents?.qtyFittedFromVan}
              fullData={formContents}
            />
            <SidePanelRow
              title="Date Time Used"
              value={formContents?.qtyRequested}
              fullData={formContents}
            />
          </>,
        );
      } else if (type === "staffcertifications") {
        updateForm(
          <>
            <SidePanelRow
              title="Name"
              value={formContents.name}
              fullData={formContents}
            />
            <SidePanelRow
              title="Description"
              value={formContents.description}
              fullData={formContents}
            />
            <SidePanelRow
              title="Date From"
              value={formContents.dateFrom}
              fullData={formContents}
            />
            <SidePanelRow
              title="Date Expire"
              value={formContents.dateExpire}
              fullData={formContents}
            />
            <SidePanelRow
              title="Cert Number"
              value={formContents.certnumber}
              fullData={formContents}
            />
          </>,
        );
      } else if (type === "staffcontract") {
        updateForm(
          <>
            <SidePanelRow
              title="Name"
              value={formContents.name}
              fullData={formContents}
            />
            <SidePanelRow
              title="Start Date"
              value={formContents.startDate}
              fullData={formContents}
            />
            <SidePanelRow
              title="End Date"
              value={formContents.endDate}
              fullData={formContents}
            />
            <SidePanelRow
              title="Client"
              value={formContents.client}
              fullData={formContents}
            />
            <SidePanelRow
              title="Client"
              value={formContents.client}
              fullData={formContents}
            />
            <SidePanelRow
              title="Equipment Category"
              value={formContents.equipmentCategory}
              fullData={formContents}
            />
            <SidePanelRow
              title="Contact Name"
              value={formContents.contactName}
              fullData={formContents}
            />
            <SidePanelRow
              title="Contact Email"
              value={formContents.contactEmail}
              fullData={formContents}
            />
            <SidePanelRow
              title="Contact Phone"
              value={formContents.contactPhone}
              fullData={formContents}
            />
            <SidePanelRow
              title="Contact Notes"
              value={formContents.contactNotes}
              fullData={formContents}
            />
          </>,
        );
      } else if (type === "scheduling") {
        updateForm(
          <>
            <SidePanelRow title="Look ahead" fullData={formContents} />
            <SidePanelRow
              title="Flexibility"
              value={formContents.client}
              fullData={formContents}
            />
            <SidePanelRow
              title="Period"
              value={formContents.period}
              fullData={formContents}
            />
            <SidePanelRow
              title="MOT"
              value={formContents.mot}
              fullData={formContents}
            />
            <SidePanelRow
              title="Description"
              value={formContents.description}
              fullData={formContents}
            />
            <SidePanelRow
              title="Job Type Schedule"
              value={formContents.jobType}
              fullData={formContents}
            />
            {/* <SidePanelRow
              title="Equipment Category"
              value={formContents.equipmentCategory}
              fullData={formContents}
            /> */}
            <SidePanelRow
              title="ScheduleTimePicker"
              value={formContents.equipmentCategory}
              fullData={formContents}
            />
          </>,
        );
      } else if (type === "supplierparts") {
        updateForm(
          <>
            <SidePanelRow
              title="Parts"
              value={formContents.parts?.partno}
              fullData={formContents}
              readOnly
            />
            <SidePanelRow
              title="Supplier"
              value={formContents?.supplier?.name}
              fullData={formContents}
              readOnly
            />
            <SidePanelRow
              title="Supplier Part No"
              value={formContents?.supplierPartNo}
              fullData={formContents}
            />
            <SidePanelRow
              title="Unit Cost"
              value={formContents?.unitCost}
              fullData={formContents}
            />
            <SidePanelRow
              title="Quantity"
              value={formContents.inventoryQty}
              fullData={formContents}
            />
          </>,
        );
      } else if (type === "stocklocation") {
        updateForm(
          <>
            <SidePanelRow
              title="Aisle"
              value={formContents.aisle}
              fullData={formContents}
            />
            <SidePanelRow
              title="Rack"
              value={formContents?.rack}
              fullData={formContents}
            />
            <SidePanelRow
              title="Level"
              value={formContents?.level}
              fullData={formContents}
            />
          </>,
        );
      } else if (type === "partsupplier") {
        updateForm(
          <>
            <SidePanelRow
              title="Name"
              value={formContents?.supplier?.name}
              fullData={formContents}
            />
            <SidePanelRow
              title="PartNo"
              value={formContents?.parts?.partno}
              fullData={formContents}
            />
            <SidePanelRow
              title="UnitCost"
              value={formContents?.unitCost}
              fullData={formContents}
            />
            <SidePanelRow
              title="Email"
              value={formContents?.supplier?.email}
              fullData={formContents}
            />
            <SidePanelRow
              title="Address"
              value={formContents?.supplier}
              fullData={formContents}
            />
          </>,
        );
      }
    }
  }, [formContents, type]);

  return <div id="contentForm">{form}</div>;
}
