/*

    This file contains functions which are repeatedly used throughout different parts of the app.
    This means that only this function needs to be maintained.

*/

import { parseISO, formatISO, setHours, setMinutes } from "date-fns";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

//! issue with SidePanel transition close where the page follows the SidePanel when closing & you are unable to move horizontally. Use this value to control timing
const TIMEOUT_VALUE = 150;

const Hooks = {
  waitForToken: async () => {
    let token = Hooks.getCookie("jwt");
    // console.log("token immediate", token);
    let attempts = 0;
    const maxAttempts = 50;

    while (!token && attempts < maxAttempts) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for 100ms
      token = Hooks.getCookie("jwt");
      attempts++;
      // console.log("token await attempt:", token);
    }
    // console.log("Final token:", token);
    return token;
  },

  getData: async (url, thenFunction) => {
    if (url && url.length && typeof url === "string") {
      try {
        const token = await Hooks.waitForToken(); // Waits for the token before making the call
        const response = await fetch(url, {
          method: "get",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        const data = await response.json();
        if (thenFunction) {
          thenFunction(data);
        }
      } catch (error) {
        console.error("Hooks.getData() error:", error);
        // toast.error("Hooks.getData() error.");
      }
    }
  },

  sendData: async (
    data,
    url,
    type,
    thenFunction,
    skipSuccessMessage = false,
    customSuccessMessage = "Submit Success!"
  ) => {
    if (document.querySelector(".badInput")) {
      const badInput = document.querySelector(".badInput");
      let badInputKey;
      try {
        badInputKey =
          badInput.parentElement.parentElement.querySelector(
            ".sidePanelKey"
          ).textContent;
      } catch (e) {
        console.log(badInput.parentElement);
        badInputKey =
          badInput.parentElement.parentElement.querySelector("div").textContent;
      }

      Hooks.displayError(
        `Please ensure that field: ${badInputKey} is entered correctly.`
      );
      return;
    }

    if (["post", "put", "delete"].includes(type.toLowerCase())) {
      const response = await fetch(url, {
        method: type,
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Hooks.getCookie("jwt")}`,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
      }).then((response) => {
        response.text().then((text) => {
          if (response.ok) {
            if (skipSuccessMessage) {
              setTimeout(() => {
                if (thenFunction) {
                  thenFunction(response);
                }
              }, TIMEOUT_VALUE);
            } else {
              toast.success(`${customSuccessMessage}`);
              setTimeout(() => {
                if (thenFunction) {
                  thenFunction(response);
                }
              }, TIMEOUT_VALUE);
            }
          } else {
            Swal.fire({
              title: "Error " + response.status,
              text: text,
              icon: "error",
              confirmButtonText: "Ok",
            }).then(() => {});
          }
        });
      });
      return response;
    }
  },

  sendDataWithFile: async (data, postUrl, putUrl, thenFunction) => {
    // File Upload - Current API needs existing ID
    const fileInput = document.querySelector('input[type="file"]');
    const fileData = new FormData();

    try {
      // First, send the JSON POST request
      const postResponse = await fetch(postUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Hooks.getCookie("jwt")}`,
        },
        body: JSON.stringify(data),
      });

      const postText = await postResponse.text();
      if (postResponse.ok) {
        const postData = JSON.parse(postText);
        const id = postData.id; // Adjust this based on your response structure
        console.log("postData", postData);

        // Show success message for the POST request
        toast.success(`Submit Success!`);
        setTimeout(() => {
          if (thenFunction) {
            thenFunction(postResponse);
          }
        }, TIMEOUT_VALUE);

        if (fileInput.files[0]) {
          fileData.append("file", fileInput.files[0]);
          console.log("fileData", fileData);

          const putResponse = await fetch(`${putUrl}${id}`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${Hooks.getCookie("jwt")}`,
            },
            body: fileData,
          });

          const putText = await putResponse.text();
          if (putResponse.ok) {
            toast.success(`File Uploaded Success!`);
            setTimeout(() => {
              if (thenFunction) {
                thenFunction(postResponse);
              }
            }, TIMEOUT_VALUE);
          } else {
            Swal.fire({
              title: "Error " + putResponse.status,
              text: putText,
              icon: "error",
              confirmButtonText: "Ok",
            }).then(() => {
              setTimeout(() => {
                if (thenFunction) {
                  thenFunction(postResponse);
                }
              }, TIMEOUT_VALUE);
            });
            console.error("File upload failed:", putResponse);
          }
        } else {
          console.log("No file selected.");
        }
      } else {
        Swal.fire({
          title: "Error " + postResponse.status,
          text: postText,
          icon: "error",
          confirmButtonText: "Ok",
        }).then(() => {
          setTimeout(() => {
            if (thenFunction) {
              thenFunction(postResponse);
            }
          }, TIMEOUT_VALUE);
        });
        console.error("API call failed:", postResponse);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  },

  sendFileAttachment: async (attachment, url, type, thenFunction) => {
    const fileData = new FormData();
    fileData.append("file", attachment);

    if (["post", "put", "delete"].includes(type.toLowerCase())) {
      const response = await fetch(url, {
        method: type,
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${Hooks.getCookie("jwt")}`,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: fileData,
      }).then((response) => {
        response.text().then((text) => {
          if (response.ok) {
            toast.success(`File Upload Success!`);
            setTimeout(() => {
              if (thenFunction) {
                thenFunction(response);
              }
            }, TIMEOUT_VALUE);
          } else {
            Swal.fire({
              title: "Error " + response.status,
              text: text,
              icon: "error",
              confirmButtonText: "Ok",
            }).then(() => {
              console.error(response);
            });
          }
        });
      });
      return response;
    }
  },

  downloadFile: async (apiUrl, filename) => {
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${Hooks.getCookie("jwt")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // console.log("response", response);

      const blob = await response.blob();
      // console.log("blob", blob);
      // console.log("response", response);

      let modifiedName;
      if (filename.name) {
        // filename.split("\\").pop();
        modifiedName = filename.name.split("\\").pop();
      } else if (!filename.name && typeof filename === "string") {
        modifiedName = filename;
      } else {
        const fileType = blob.type.split("/").pop();
        modifiedName = `attachment${fileType}`;
      }

      // Create a download link for the file
      const url = URL.createObjectURL(blob);
      const a = Object.assign(document.createElement("a"), {
        href: url,
        download: modifiedName,
      });
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a); // Clean up by removing the anchor element from DOM
    } catch (error) {
      console.error(error);
    }
  },

  capitalizeFirstLetter: (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  //* Date Stuff
  formatTime: (isoString) => {
    const date = new Date(isoString);

    // Doing it this way accounts for users local time, but can give discrepancies between two people viewing same time within a form
    // const hours = String(date.getHours()).padStart(2, "0");
    // const minutes = String(date.getMinutes()).padStart(2, "0");

    // Use UTC methods to get the hours and minutes
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  },

  dateToISO: (date) => {
    // console.log("dateToISO date:", date);
    // Check if the date is already in ISO format
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      throw new Error("Invalid date value");
    }
    return parsedDate.toISOString();
  },

  formatISODateToText: (dateTimeString, includeTime = true) => {
    if (!dateTimeString) return "";

    const date = new Date(dateTimeString);

    // Options for the date part
    const dateOptions = { year: "numeric", month: "long", day: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);

    // Options for the time part
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    let result;

    if (includeTime) {
      result = `${formattedDate}, ${formattedTime}`;
    } else {
      result = `${formattedDate}`;
    }

    return result;
  },

  combineDateTime: (dateString, timeString) => {
    // console.log("dateString: ", dateString);
    // console.log("timeString: ", timeString);
    // Parse the date string to a Date object
    const date = parseISO(dateString);

    // Extract hours and minutes from the time string
    const [hours, minutes] = timeString.split(":").map(Number);

    // Set the hours and minutes to the date object
    const combinedDate = setMinutes(setHours(date, hours), minutes);

    // Format the date to an ISO string in UTC without timezone offset
    const utcDate = new Date(
      combinedDate.getTime() - combinedDate.getTimezoneOffset() * 60000
    );
    return formatISO(utcDate, { representation: "complete" });
  },

  getDate: (id) => {
    try {
      return new Date(document.querySelector(id).value).toISOString();
    } catch (e) {
      return null;
    }
  },

  getDateNoTime: (id) => {
    try {
      const date = new Date(document.querySelector(id).value);
      date.setDate(date.getDate() + 1);
      return date.toISOString();
    } catch (e) {
      return null;
    }
  },

  checkText: (string) => {
    if (string && string.length) {
      return string;
    } else {
      return null;
    }
  },

  checkNumber: (string) => {
    if (parseInt(string)) {
      return parseInt(string);
    } else {
      return 0;
    }
  },

  displayError: (message, thenFunction) => {
    Swal.fire({
      title: "Error",
      text: message,
      icon: "error",
      confirmButtonText: "Ok",
    }).then(() => {
      // Delay is needed otherwise there is an issue with SidePanel transition close where the page follows the SidePanel when closing & you are unable to move horizontally
      if (thenFunction) {
        setTimeout(() => {
          thenFunction();
        }, TIMEOUT_VALUE);
      }
    });
  },

  formatDate: (dateObject) => {
    function getTimeEnd(hour) {
      return hour >= 12 ? "PM" : "AM";
    }

    function getMonth(month) {
      switch (month) {
        case 0:
          return "January";
        case 1:
          return "February";
        case 2:
          return "March";
        case 3:
          return "April";
        case 4:
          return "May";
        case 5:
          return "June";
        case 6:
          return "July";
        case 7:
          return "August";
        case 8:
          return "September";
        case 9:
          return "October";
        case 10:
          return "November";
        case 11:
          return "December";
        default:
          break;
      }
    }

    function getDay(day) {
      switch (day) {
        case 1:
          return "Monday";
        case 2:
          return "Tuesday";
        case 3:
          return "Wednesday";
        case 4:
          return "Thursday";
        case 5:
          return "Friday";
        case 6:
          return "Saturday";
        case 0:
          return "Sunday";
        default:
          break;
      }
    }

    const year = dateObject.getFullYear();
    const month = getMonth(dateObject.getMonth());
    const date = dateObject.getDate();
    const day = getDay(dateObject.getDay());
    const time = `${dateObject.getHours()}:${dateObject.getMinutes()} ${getTimeEnd(
      dateObject.getHours()
    )}`;

    return `${day} ${month} ${date} ${year}, ${time}`;
  },

  conditionalString: (string) => {
    if (string && string.length) {
      return string.toLowerCase().trim().split(" ").join("");
    } else {
      return "";
    }
  },

  formatSidePanelKey: (string) => {
    switch (Hooks.conditionalString(string)) {
      case "pounitcost":
        return "Unit Cost";
      case "quantityinstock":
        return "Quantity";
      case "notes":
      case "todo":
      case "address":
        return "";
      case "recordcreated":
      case "recordmodified":
        return string.replace("Record ", "");
      case "loggedat":
      case "bookedat":
        return string.replace(" At", "");
      case "visitstate":
        return "State";
      case "scheduledfor":
        return "Scheduled";
      case "partnumber":
        return "Number";
      case "propertynumber":
        return "Property No";
      case "targetdate":
      case "scheduleddate":
        return string.replace("Date ", "");
      case "certifications":
        return "certification";
      default:
        return string;
    }
  },
  sendTime: (value) => {
    if (!value) {
      console.error("Invalid Time.");
      return;
    }
    const date = new Date();

    date.setHours(parseInt(value.split(":")[0]) + 1);
    date.setMinutes(parseInt(value.split(":")[1]));

    return date.toISOString();
  },

  getSidePanelData: () => {
    try {
      // console.log(
      //   "Hooks.getSidePanelData",
      //   JSON.parse(
      //     document.querySelector("#sidePanelContainer").getAttribute("data")
      //   )
      // );
      return JSON.parse(
        document.querySelector("#sidePanelContainer").getAttribute("data")
      );
    } catch (e) {
      // console.log("getSidePanelData unable to retrieve data");
      return {};
    }
  },

  getSidePanelTitle: () => {
    return document.querySelector("#sidePanelTitle")
      ? document.querySelector("#sidePanelTitle").textContent
      : "";
  },

  waitForElement: (selector) => {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve("success");
      }

      const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          resolve("success");
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  },

  getCookie: (cookieName) => {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");
    const searchName = `${cookieName}=`;

    for (let cookie of cookieArray) {
      // Trim leading whitespace from the cookie
      cookie = cookie.trim();

      // Check if the current cookie string starts with the search name
      if (cookie.startsWith(searchName)) {
        // Return the value part of the name=value pair
        return cookie.substring(searchName.length);
      }
    }

    // Return an empty string if the cookie is not found
    return "";
  },

  setCookie: (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie =
      cname + "=" + cvalue + ";" + expires + ";path=/; SameSite=None; Secure";
  },

  deleteCookies: () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;Secure;SameSite=None`;
    }
  },

  camelCaseString: (string) => {
    if (!string) return;

    return string
      .replace(/\s+/g, "")
      .replace(/^(.)/, (match) => match.toLowerCase())
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toLowerCase() : word.toUpperCase()
      );
  },

  getApiFromTitle: (string) => {
    if (string && string.length) {
      switch (Hooks.conditionalString(string)) {
        case "documenttags":
          return "documentTag";
        case "certifications":
          return "certification";
        case "jobstatetransitions":
          return "JobStateTransition";
        case "processes":
          return "runRequestProc";
        case "purchaseorders":
          return "purchaseOrder";
        case "purchaserequests":
          return "purchaseRequest";
        case "deliveries":
          return "deliveryNote";
        case "partscategory":
          return "partsCategory";
        default:
          return Hooks.conditionalString(string);
      }
    }
  },

  // * Used for converting "Search by" values into the API parameters
  getFilterKey: (string) => {
    if (string && string.length) {
      switch (Hooks.conditionalString(string)) {
        case "storetype":
          return "StoreTypeId";
        case "ticketno":
          return "ticketno";
        case "issue":
          return "issueDetail";
        case "solution":
          return "solution";
        case "createdby":
          return "createdby";
        case "assignedto":
          return "assignedto";
        case "menupath":
          return "menupath";
        case "createdfrom":
          return "createdfrom";
        case "createduntil":
          return "createduntil";
        case "targetfrom":
          return "targetfrom";
        case "targetuntil":
          return "targetuntil";
        case "tags":
          return "tags";
        case "uom":
          return "uom";
        case "sorcode":
          return "sorcode";
        case "shortdesc":
          return "general";
        case "lettername":
          return "lettername";
        case "quantityinstock":
          return "quantityinstock";
        case "inspectiontype":
          return "type";
        case "compulsory":
          return "Compulsory";
        case "equipmentcategoryid":
          return "EquipmentCategoryId";
        case "equipmentsubcategory1id":
          return "EquipmentSubCategory1Id";
        case "equipmentcategory(freetext)":
          return "EquipmentCategory";
        case "equipmentcategory":
          return "EquipmentCategory";
        case "requestdate":
          return "RequestDateFrom";
        case "targetjob":
          return "TargetJobId";
        case "prstate":
          return "prStateId";
        case "part":
          return "PartsNo";
        case "contract":
          return "ContractId";
        case "clientid":
        case "client":
          return "ClientId";
        case "name":
          return "Name";
        case "apigroupid":
          return "ApiGroupId";
        case "apigroupname":
          return "apigroupname";
        case "apiname":
          return "ApiName";
        case "reason":
          return "Reason";
        case "contractid":
          return "ContractId";
        case "certnumber":
          return "CertNumber";
        case "engineer":
          return "EngineerId";
        case "staff":
          return "StaffId";
        case "datefrom":
          return "DateFrom";
        case "dateuntil":
          return "DateUntil";
        case "address":
          return "AddrInfo";
        case "address1":
          return "Address1";
        case "postcode":
          return "PostCode";
        case "email":
          return "Email";
        case "startdatefrom":
        case "startdate":
        case "starttime":
          return "StartDateFrom";
        case "enddateuntil":
          return "EndDateUntil";
        case "equiptypeid":
          return "EquipTypeID";
        case "propertyid":
          return "PropertyID";
        case "make":
          return "Make";
        case "model":
          return "Model";
        case "id":
          return "Id";
        case "scheduledfrom":
          return "ScheduledFrom";
        case "jobstatusrefid":
          return "JobStatusRefId";
        case "jobtyperefid":
          return "JobTypeRefId";
        case "reference":
          return "Reference";
        case "completed_flag":
          return "Completed_Flag";
        case "generalsearch":
          return "GeneralSearch";
        case "jobstateid":
        case "jobstate":
          return "JobStateId";
        case "currentjobstate":
          return "CurrJobStateId";
        case "nextjobstate":
          return "NextJobStateId";
        case "jobtypeid":
        case "jobtype":
          return "JobTypeId";
        case "hassubjobs":
          return "HasSubjobs";
        case "hassubproperty":
          return "HasSubProperty";
        case "quantityfrom":
          return "QuantityFrom";
        case "quantityto":
          return "QuantityTo";
        case "forename":
          return "ForeName";
        case "surname":
          return "SurName";
        case "mobile":
          return "Mobile";
        case "stafftypeid":
          return "StaffTypeId";
        case "warehouseid":
        case "warehouse":
          return "WarehouseID";
        case "partsid":
          return "PartsId";
        case "sourcewarehouseid":
          return "SourceWarehouseID";
        case "destinationwarehouseid":
          return "DestinationWarehouseID";
        case "isvan":
          return "isVan";
        case "general":
          return "GeneralSearch";
        case "jobnumber":
          return "JobNumber";
        case "uprn":
          return "UPRN";

        case "stafftype":
          return "StaffTypeId";
        case "description":
          return "description";
        case "status":
          return "Status";
        case "supplier":
          return "SupplierName";
        case "targetdate":
          return "TargetDateFrom";
        case "orderdate":
          return "OrderDateFrom";
        case "partnumber":
          return "PartNo";
        case "partno":
          return "PartsNo";
        case "stocklocation":
          return "Aisle";
        case "requestedby":
          return "RequestStaffName";
        case "posupplier":
          return "SupplierName";
        case "postate":
          return "poStateId";
        case "deliverynumber":
          return "DeliveryNoteNumber";
        case "deliverydate":
          return "DeliveryDateFrom";
        case "deliverystate":
          return "DeliveryNoteStateId";
        case "suppliername":
          return "SupplierName";
        case "raiseddate":
          return "OrderDateFrom";
        // case is the dropdown value within (Hooks.conditionalString(string)), return is the API param
        case "reportid":
          return "id";
        case "reportname":
          return "ReportName";
        case "reporttype":
          if (["runreportlist"].includes(Hooks.getCookie("last_page"))) {
            return "runReportTypeId";
          } else {
            return "ReportTypeId";
          }
        case "processid":
          return "id";
        case "exportformat":
          return "ReportTypeId";
        case "runby":
          return "ReportTypeId";
        case "apigroup":
          return "ApiGroupId";
        case "staffname":
          return "staffname";
        case "unitname":
          return "unitname";
        case "answertype":
          return "AnswerTypeRefId";
        case "attributecategory":
          return "AttributeCategoryId";
        case "objectdoctype":
          return "AttributeDocTypeId";
        case "isvan?":
          return "isVan";
        case "categorypath":
          return "CategoryPath";
        case "sorlist":
          return "SorListId";

        default:
          console.log(
            "missing string from Hooks.js getFilterKey():",
            Hooks.conditionalString(string)
          );

          alert(
            "Filter not setup for " +
              Hooks.conditionalString(string) +
              " please raise issue"
          );
          break;
      }
    }
  },

  resetKey: (string) => {
    switch (Hooks.conditionalString(string)) {
      case "partssubcategory1":
        return "partsSubCategory1";
      case "partssubcategory2":
        return "partsSubCategory2";
      case "partssubcategory3":
        return "partsSubCategory3";
      case "partssubcategory4":
        return "partsSubCategory4";
      case "costcodes":
      case "costcode":
        return "costCode";
      case "costtypes":
      case "costtype":
        return "costType";
      case "targetjob":
        return "targetJobId";
      case "deliverynotenumber":
        return "deliveryNoteNumber";
      case "deliverydate":
        return "deliveryDate";
      case "deliveries":
        return "deliveryNote";
      case "jobbookedid":
        return "jobBookedId";
      case "purchaserequestid":
        return "purchaseRequestId";
      case "purchaseorderid":
        return "purchaseOrderId";
      case "quantity":
        return "qty";
      case "arrivaldate":
        return "arrivalDate";
      case "partscategory":
      case "partcategories":
        return "partsCategory";
      case "starttime":
        return "scheduledStartTime";
      case "endtime":
        return "scheduledEndTime";
      case "documenttags":
        return "documentTag";
      case "startdate":
        return "startDate";
      case "enddate":
        return "endDate";
      case "contactname":
        return "contactName";
      case "targetdate":
        return "targetDate";
      case "scheduleddate":
        return "scheduledDate";
      case "propertyid":
        return "propertyId";
      case "contractid":
        return "contractId";
      case "jobtype":
        return "jobType";
      case "jobtyperefid":
        return "jobTypeRefId";
      case "jobstate":
        return "jobState";
      case "jobstaterefid":
        return "jobStateRefId";
      case "engineer":
      case "engineerrefid":
        return "engineerRefId";
      case "partnumber":
        return "partno";
      case "isvan?":
        return "isVan";
      case "recordcreated":
        return "recordCreated";
      case "recordmodified":
        return "recordModified";
      case "postcode":
        return "postCode";
      case "todo":
        return "toDo";
      case "loggedat":
        return "loggedAt";
      case "completedat":
        return "completedAt";
      case "scheduledfor":
        return "scheduledFor";
      case "completeddate":
        return "completedDate";
      case "visitState":
        return "visitState";
      case "lastservice":
        return "lastService";
      case "nextservice":
        return "nextService";
      case "quantityinstock":
        return "quantityInStock";
      case "equipmenttype":
        return "equipmentType";
      case "equipmentcategory":
        return "equipmentCategory";
      case "makeedit":
        return "make";
      case "modeledit":
        return "model";
      case "resident":
        return "residents";
      case "cancelreason":
        return "cancelReason";
      case "invoiceno":
        return "invoiceNo";
      case "qcstatus":
        return "qcStatus";
      case "qcdate":
        return "qcDate";
      case "canceldate":
        return "cancelDate";
      case "processes":
        return "runRequestProc";
      case "inspectionset":
      case "inspectionsets":
        return "inspectionSet";
      case "jobstatetransitions":
        return "jobStateTransition";
      case "visitstatetransitions":
        return "visitStateTransition";
      case "visitstate":
        return "visitState";
      case "sorcode":
        return "sorCode";
      case "certifications":
        return "certification";
      case "datefrom":
        return "dateFrom";
      case "dateexpire":
        return "dateExpire";
      case "allocatedby":
        return "allocateStaff";
      case "fittedorrequestedby":
        return "fittedFromVan";
      case "groupname":
        return "groupName";
      case "allowedtoread":
        return "allowToReadDefault";
      case "allowedtoupdate":
        return "allowToUpdateDefault";
      case "allowedtocreate":
        return "allowToCreateDefault";
      case "allowedtodelete":
        return "allowToDeleteDefault";
      case "apigroup":
        return "ApiGroup";
      case "smsupdate":
        return "smsUpdate";
      case "hassubjobs":
        return "hasSubjobs";
      case "hassubproperty":
        return "hasSubProperty";
      case "purchaseorders":
        return "purchaseOrder";
      case "purchaserequests":
        return "purchaseRequest";
      case "purchaserequeststate":
        return "purchaserequeststate";
      default:
        return string && string.length
          ? string.toLowerCase().split(" ").join("")
          : "";
    }
  },

  // . NEW PAGE SETUP --------------------------------------------------------------------
  // Fixes page titles
  formatKey: (key) => {
    if (key && key.length) {
      switch (key.toLowerCase().trim()) {
        case "sorlist":
          return "SOR List";
        case "documentworkflow":
          return "Document Workflow";
        case "id":
          return "ID";
        case "propertyid":
          return "Property ID";
        case "contractid":
          return "Contract";
        case "recordcreated":
          return "Record Created";
        case "recordmodified":
          return "Record Modified";
        case "targetdate":
          return "Target Date";
        case "scheduleddate":
          return "Scheduled Date";
        case "engineerrefid":
          return "Engineer Ref ID";
        case "jobstaterefid":
          return "Job State Ref ID";
        case "clientidrefid":
          return "Client Ref ID";
        case "jobstatus":
          return "Job Status";
        case "jobrefid":
          return "Job Ref ID";
        case "jobtyperefid":
          return "Job Type Ref ID";
        case "jobtype":
          return "Job Type";
        case "stafftyperefid":
          return "Staff Type Ref ID";
        case "stafftype":
          return "Staff Type";
        case "modifiedat":
          return "Modified At";
        case "createdat":
          return "Created At";
        case "traceid":
          return "Trace ID";
        case "clientrefid":
          return "Client Ref ID";
        case "uprn":
          return "UPRN";
        case "enddate":
          return "End Date";
        case "startdate":
          return "Start Date";
        case "address1":
          return "Address 1";
        case "address2":
          return "Address 2";
        case "address3":
          return "Address 3";
        case "address4":
          return "Address 4";
        case "postCode":
          return "Postcode";
        case "isvan":
          return "Is Van ?";
        case "partno":
          return "Part Number";
        case "loggedat":
          return "Logged At";
        case "completedat":
          return "Completed At";
        case "todo":
          return "To Do";
        case "visitstate":
          return "Visit State";
        case "quantityinstock":
          return "Quantity In Stock";
        case "jobnumber":
          return "Job Number";
        case "scheduledate":
          return "Schedule Date";
        case "equipmentcategory":
          return "Equipment Category";
        case "equipmenttype":
          return "Equipment Type";
        case "inspectionsets":
          return "Inspection Sets";
        case "compulsory_flag":
          return "Compulsory Flag";
        case "jobstate":
          return "Job State";
        case "cancelreason":
          return "Cancel Reason";
        case "jobstatetransition":
          return "Job State Transition";
        case "jobstatetransitions":
          return "Job State Transitions";
        case "visitstatetransition":
          return "Visit State Transition";
        case "visitstatetransitions":
          return "Visit State Transitions";
        case "currvisitstate":
          return "Current Visit State";
        case "nextvisitstate":
          return "Next Visit State";
        case "currjobstate":
          return "Current Job State";
        case "nextjobstate":
          return "Next Job State";
        case "inspectiontype":
          return "Inspection Type";
        case "inspectionset":
          return "Inspection Set";
        case "jobcategory":
          return "Job Category";
        case "equipmentsubcategory1":
          return "Equipment Subcategory 1";
        case "equipmentsubcategory2":
          return "Equipment Subcategory 2";
        case "qcstatus":
          return "QC Status";
        case "stockmovements":
          return "Stock Movements";
        case "sourcewarehouse":
          return "Source Warehouse";
        case "destinationwarehouse":
          return "Destination Warehouse";
        case "sor":
          return "SOR";
        case "sorcode":
          return "SOR Code";
        case "datefrom":
          return "Start Date";
        case "dateexpire":
          return "Expiration Date";
        case "certnumber":
          return "Certification Number";
        case "apigroupid":
          return "API Group ID";
        case "groupname":
          return "Group Name";
        case "allowtoreaddefault":
          return "Allow To Read Default";
        case "allowtoupdatedefault":
          return "Allow To Update Default";
        case "allowtocreatedefault":
          return "Allow To Create Default";
        case "allowtodeletedefault":
          return "Allow To Delete Default";
        case "apigroup":
          return "API Group";
        case "smsupdate":
          return "SMS Update";
        case "actionevent":
          return "Action Event";
        case "onhandqty":
          return "On Hand Quantity";
        case "allocatedqty":
          return "Allocated Quantity";
        case "hassubjobs":
          return "Has Sub Jobs?";
        case "hassubproperty":
          return " Has Sub Property";
        case "documenttags":
          return "Document Tags";
        case "documenttag":
          return "Document Tag";
        case "starttime":
          return "Start Time";
        case "endtime":
          return "End Time";
        case "purchaseorder":
          return "Purchase Order";
        case "purchaseorders":
          return "Purchase Orders";
        case "purchaserequest":
          return "Purchase Request";
        case "purchaserequests":
          return "Purchase Requests";
        case "suppliername":
          return "Supplier Name";
        case "requeststaff":
          return "Requested By";
        case "approvalstaff":
          return "Approved By";
        case "raisedate":
          return "Raised Date";
        case "duedate":
          return "Due Date";
        case "requestdate":
          return "Request Date";
        case "targetjob":
          return "Target Job";
        case "purchaserequeststate":
          return "Purchase Request States";
        case "purchaseorderstate":
          return "Purchase Order States";
        case "podetailstate":
          return "Purchase Order Detail States";
        case "deliverynotestate":
          return "Delivery Note States";
        case "dndetailstate":
          return "Delivery Note Detail States";
        case "partscategory":
          return "Part Categories";
        case "targetwarehouse":
          return "Target Warehouse";
        case "stocklocation":
          return "Stock Location";
        case "targetlocation":
          return "Target Location";
        case "goodsrecieving":
          return "Goods Recieved";
        case "supplierparts":
          return "Parts";
        case "costtype":
          return "Cost Types";
        case "costcode":
          return "Cost Codes";
        case "partssubcategory1":
          return "Parts Subcategory 1";
        case "partssubcategory2":
          return "Parts Subcategory 2";
        case "partssubcategory3":
          return "Parts Subcategory 3";
        case "partssubcategory4":
          return "Parts Subcategory 4";
        case "stockcyclecount":
          return "Stock Cycle Count";
        case "runreportlist":
          return "Define Reports";
        // case "runreportasonlinelog":
        //   return "Report Online";
        case "runreportasonlinecsvxls":
          return "Report Online CSV XLS";
        case "runreportasonlinepdf":
          return "Report Online PDF";
        case "runreportasfileoremail":
          return "Report as File or Email";
        case "runreportproc":
          return "View Report Process";
        case "letterprintsetup":
          return "Letter Print Setup";
        case "runcustomprocess":
          return "View Letter Print Process";
        case "tickettype":
          return "Ticket Type";
        case "answertype":
          return "Answer Type";
        case "objectdoctype":
          return "Object Document Type";
        case "attributecategory":
          return "Attribute Category";
        case "attributeunit":
          return "Attribute Unit";
        case "runprogramproc":
          return "View All Program Process";
        case "runschedulermessage":
          return "View Scheduler Process";
        default:
          return key.replace(/(^|\s)\S/g, (letter) => letter.toUpperCase()); // Default option just capitalizes the first letter of the string.
      }
    }
  },

  // . NEW PAGE SETUP --------------------------------------------------------------------
  // This uses the page title without spaces. Ensure there are no symbols that get in the way of creating a key name properly
  tableHeaders: (type) => {
    const headers = {
      clients: [
        "Name",
        "Address 1",
        "Address 2",
        "Address 3",
        "Address 4",
        "Town",
        "County",
        "Postcode",
        "Email",
        "Phone",
      ],
      contracts: [
        "Name",
        "Client",
        "Start Date",
        "End Date",
        "ContactName",
        "ContactEmail",
        "ContactPhone",
        "ContactNotes",
      ],
      properties: [
        // "Selected",
        "UPRN",
        "Client",
        "Address 1",
        "Address 2",
        // "Address 3",
        // "Address 4",
        "Town",
        "County",
        "Postcode",
      ],
      jobs: [
        // "Selected",
        "Job Number",
        "Client",
        "Property",
        "Contract",
        "Reference",
        "Engineer",
        "Job State",
        "Job Type",
        "Priority",
        "Scheduled Date",
        "Target Date",
      ],
      staff: ["Forename", "Surname", "Mobie", "Email", "Staff Type"],
      warehouses: ["Store Type", "Name", "Address1", "Postcode"],
      parts: [
        "partno",
        "description",
        "Category",
        "Sub Category 1",
        "Sub Category 2",
        "Sub Category 3",
        "Sub Category 4",
      ],
      suppliers: [
        "Name",
        "Address 1",
        "Address 2",
        "Address 3",
        "Address 4",
        "Town",
        "County",
        "Postcode",
        "Email",
        "Phone",
      ],
      kits: ["Name", "Quantity In Stock"],
      equipmenttype: [
        "Make",
        "Model",
        "Equipment Category",
        "Equipment Sub Category",
      ],
      equipmentcategory: ["Name"],
      processes: ["Name", "Status", "Start Time", "End Time", "Staff"],
      inspectionsets: [
        "Name",
        "Inspection Type",
        "Compulsory Flag",
        "Equipment Category",
      ],
      priority: ["Name"],
      cancelreason: ["Reason"],
      jobstate: ["Description"],
      jobtype: [
        "Description",
        "Chargeable",
        "Job Category",
        "Can Be Sub Job?",
        "Can Have Sub Job?",
      ],
      visitstate: ["Description"],
      stafftype: ["Description"],
      stock: [
        "Stock ID",
        "Warehouse",
        "State",
        "Aisle",
        "Rack",
        "Level",
        "Part No",
        "Quantity",
        "Job Booked",
        "Purchase Request ID",
      ],
      stockmovements: [
        "Source Warehouse",
        "Destination Warehouse",
        "Parts",
        "Quantity",
      ],
      jobstatetransitions: ["Job Type", "Current Job State", "Next Job State"],
      visitstatetransitions: [
        "Job Type",
        "Current Visit State",
        "Next Visit State",
      ],
      equipmentsubcategory1: ["Name", "Equipment Category"],
      equipmentsubcategory2: [
        "Name",
        "Equipment Subcategory 1",
        "Equipment Category",
      ],
      jobcategory: ["Description"],
      qcstatus: ["Description"],
      sor: ["SOR List", "SOR Code", "Short Desc", "Rate", "UOM", "SOR ID"],
      sorlist: [
        "List ID",
        "Name",
        "Total Records",
        "Create Date",
        "Last Update Date",
      ],
      certifications: [
        "Name",
        "Description",
        "Date From",
        "Date Expire",
        "Cert Number",
        "Staff",
      ],
      role: ["Name", "Description"],
      apigroup: [
        "Group Name",
        "Allow To Read Default",
        "Allow To Update Default",
        "Allow To Create Default",
        "Allow To Delete Default",
      ],
      api: ["API Group", "Menu Path", "API Name", "API Group ID", "API ID"],
      actionevent: ["Name", "Description", "SMS Update", "Client"],
      documenttags: ["Name", "Description"],
      purchaserequests: [
        "ID",
        "Part No",
        "Quantity",
        "State",
        "Target Date",
        "Requested By",
        "Request Date",
        "Target Job",
      ],
      purchaseorders: [
        "ID",
        "Supplier",
        "State",
        "Raised By",
        "Raised Date",
        "Target Date",
      ],
      deliveries: [
        "Delivery ID",
        "Supplier",
        "Delivery Note Number",
        "Delivery Note State",
        "Delivery Date",
      ],
      costcodes: ["ID", "Name", "Description", "Cost Type"],
      costtypes: ["ID", "Name"],
      partcategories: ["Name"],
      partssubcategory1: ["SubCategory 1 Name", "Parent Category Path"],
      partssubcategory2: ["SubCategory 2 Name", "Parent Category Path"],
      partssubcategory3: ["SubCategory 3 Name", "Parent Category Path"],
      partssubcategory4: ["SubCategory 4 Name", "Parent Category Path"],
      stockcyclecount: [
        "Name",
        "Open?",
        "Start Date",
        "End Date",
        "Responsible Staff",
      ],
      van: ["Name", "Van"],
      definereports: [
        "ID",
        "Report Name",
        "Report Type",
        "Query Table",
        "File Name Prefix",
        "API Group",
      ],
      // This uses the page title
      // reportonline: [],
      reportonlinecsvxls: [],
      reportonlinepdf: [],
      reportasfileoremail: [
        "Process ID",
        "Report Name",
        "Run By",
        "Process State",
        "Process Date",
        "Schedule Date",
        "Format",
        "Letter Print",
        "Group",
        "Email Flag",
        "Recur Flag",
      ],
      viewreportprocess: [
        "Process ID",
        "Report Name",
        "Run By",
        "Process State",
        "Process Date",
        "Schedule Date",
        "Format",
        "Group",
        "Email Flag",
        "Recur Flag",
      ],
      viewallprogramprocess: [
        "ID",
        "Name",
        "Process State",
        "Process Message",
        "Query Table",
        "Process Date",
        "Schedule Date",
      ],
      viewschedulerprocess: ["ID", "Log Message", "Log Time"],
      customprocess: [
        "Process ID",
        "Report Name",
        "Export Format",
        "Run By",
        "Schedule Time",
        "Actual Start Date",
        "Send Email To",
      ],
      definescheduler: ["Test1", "Test2", "Test3"],
      letterprintsetup: [
        "Letter Name",
        "Report",
        "Client",
        "Job State",
        "Job Type",
      ],
      viewletterprintprocess: [
        "ID",
        "Report Name",
        "Report Proc ID",
        "Letter Name",
        "Report",
        "Send Date",
        "Job ID",
        "Resident Name",
        "Email",
      ],
      ticket: [
        "Ticket No",
        "Issue",
        "Menu Path",
        "Target Date",
        "Report Date",
        "Created By",
        "Assigned To",
        "Ticket Type",
      ],
      tickettype: ["ID", "Name"],
      answertype: ["ID", "Answer Type", "For Inspection", "For Attribute"],
      objectdocumenttype: ["ID", "Object DocType Name"],
      attributecategory: ["ID", "Category Name", "Document Type"],
      attributeunit: [
        "ID",
        "Unit Name",
        "Category Name",
        "Answer Type",
        "Auto Load?",
        "Document Type",
      ],
      tags: ["ID", "Name"],
      documentworkflow: [
        "Client",
        "Contract",
        "Job Type",
        "Current Job State",
        "Next Job State",
        "Document Tag",
      ],
    };
    // console.log("headers[type]:", headers[type]);
    // console.log("type:", type);
    return headers[type] || [];
  },

  // . NEW PAGE SETUP --------------------------------------------------------------------
  tableKeys: (type) => {
    const keys = {
      clients: [
        "name",
        "address1",
        "address2",
        "address3",
        "address4",
        "town",
        "county",
        "postCode",
        "email",
        "phone",
      ],
      contracts: [
        "name",
        "client",
        "startDate",
        "endDate",
        "contactName",
        "contactEmail",
        "contactPhone",
        "contactNotes",
      ],
      properties: [
        // "subrow",
        // "selected",
        "uprn",
        "client",
        "address1",
        "address2",
        // "address3",
        // "address4",
        "town",
        "county",
        "postCode",
      ],
      jobs: [
        // "selected",
        "jobNumber",
        "client",
        "property",
        "contract",
        "reference",
        "engineer",
        "jobState",
        "jobType",
        "priority",
        "scheduledDate",
        "targetDate",
      ],
      staff: ["forename", "surname", "mobile", "email", "staffType"],
      warehouses: ["storeType", "name", "address1", "postCode"],
      parts: [
        "partno",
        "description",
        "subcategory",
        "subcategory1",
        "subcategory2",
        "subcategory3",
        "subcategory4",
      ],
      suppliers: [
        "name",
        "address1",
        "address2",
        "address3",
        "address4",
        "town",
        "county",
        "postCode",
        "email",
        "phone",
      ],
      kits: ["name", "quantityInStock"],
      equipmenttype: [
        "make",
        "model",
        "equipmentCategory",
        "equipmentSubCategory1",
      ],
      equipmentcategory: ["name"],
      processes: ["name", "status", "startTime", "endTime", "staff"],
      inspectionsets: [
        "name",
        "inspectionType",
        "compulsory_flag",
        "equipmentCategory",
      ],
      priority: ["name"],
      cancelreason: ["reason"],
      jobstate: ["description"],
      jobtype: [
        "description",
        "chargeable",
        "jobCategory",
        "canBeSubJob",
        "canHasSubJob",
      ],
      visitstate: ["description"],
      stafftype: ["description"],
      stockmovements: [
        "sourceWarehouse",
        "destinationWarehouse",
        "parts",
        "quantity",
      ],
      jobstatetransitions: ["jobType", "currJobState", "nextJobState"],
      visitstatetransitions: ["jobType", "currVisitState", "nextVisitState"],
      equipmentsubcategory1: ["name", "equipmentCategory"],
      equipmentsubcategory2: [
        "name",
        "equipmentSubCategory1",
        "equipmentCategory",
      ],
      jobcategory: ["description"],
      qcstatus: ["description"],
      sor: ["sorList", "sorCode", "shortDesc", "rate", "uom", "id"],
      sorlist: [
        "id",
        "name",
        "totalRecord",
        "createDateTime",
        "contentLastUpdateDate",
      ],
      certifications: [
        "name",
        "description",
        "dateFrom",
        "dateExpire",
        "certnumber",
        "staff",
      ],
      role: ["name", "description"],
      apigroup: [
        "groupName",
        "allowToReadDefault",
        "allowToUpdateDefault",
        "allowToCreateDefault",
        "allowToDeleteDefault",
      ],
      api: ["apiGroup2", "menuPath", "name", "apiGroupId", "id"],
      actionevent: ["name", "description", "smsUpdate", "client"],
      documenttags: ["name", "description"],
      purchaserequeststate: [""],
      purchaserequests: [
        "id",
        "partno",
        "quantity",
        "purchaseRequestState",
        "targetDate",
        "requestStaff",
        "requestDate",
        "targetJob",
      ],
      purchaseorders: [
        "id",
        "supplier",
        "purchaseOrderState",
        "raisedByStaff",
        "raisedDate",
        "targetDate",
      ],
      deliveries: [
        "id",
        "supplier",
        "deliveryNoteNumber",
        "deliveryNoteState",
        "deliveryDate",
      ],
      costcodes: ["id", "name", "description", "costType"],
      costtypes: ["id", "name"],
      partscategory: ["name"],
      partcategories: ["name"],
      partssubcategory1: ["name", "parentFullPath"],
      partssubcategory2: ["name", "parentFullPath"],
      partssubcategory3: ["name", "parentFullPath"],
      partssubcategory4: ["name", "parentFullPath"],
      stockcyclecount: [
        "name",
        "isOpen",
        "startDate",
        "endDate",
        "responsibleStaff",
      ],
      stockvan: [
        "id",
        "stockState",
        "partsRefId",
        "qty",
        "jobBookedId",
        "purchaseRequestId",
      ],
      stock: [
        "id",
        "warehouse",
        "stockState",
        "aisle",
        "rack",
        "level",
        "partno",
        "qty",
        "jobbooked",
        "purchaseRequestId",
      ],
      stockwarehouse: [
        "id",
        "warehouse",
        "stockState",
        "aisle",
        "rack",
        "level",
        "partno",
        "qty",
        "jobbooked",
        "purchaseRequestId",
      ],
      definereports: [
        "id",
        "name",
        "reportType",
        "queryTable",
        "fileNamePrefix",
        "apiGroup",
      ],
      // reportonline - use tableKeysData,
      // reportonlinecsvxls: [],
      // reportonlinepdf: [],
      reportasfileoremail: [
        "id",
        "reportName", // filterValues used...
        "reportRunBy", // filterValues used...
        "processState", // filterValues used...
        "actualStartTime",
        "scheduleStartTime",
        "exportFormat", // filterValues used...
        "letterName", // filterValues used...
        "apiGroup", // filterValues used...
        "sendEmailFlag",
        "recuringFlag",
      ],
      viewreportprocess: [
        "id",
        "reportName", // filterValues used...
        "reportRunBy", // filterValues used...
        "processState", // filterValues used...
        "actualStartTime",
        "scheduleStartTime",
        "exportFormat", // filterValues used...
        "apiGroup", // filterValues used...
        "sendEmailFlag",
        "recuringFlag",
      ],
      viewallprogramprocess: [
        "id",
        "runProgramListName",
        "processState",
        "processMessage",
        "queryTable",
        "actualStartTime",
        "scheduleStartTime",
      ],
      viewschedulerprocess: ["id", "logMessage", "logTime"],
      customprocess: [
        "id",
        "reportName", // filterValues used...
        "reportRunBy", // filterValues used...
        "exportFormat", // filterValues used...
        "scheduleStartTime",
        "actualStartTime",
        "sendEmailTo",
      ],
      letterprintsetup: [
        "letterName",
        "reportName",
        "client",
        "jobState",
        "jobType",
      ],
      viewletterprintprocess: [
        "id",
        "reportName",
        "reportProcId",
        "letterName",
        "report",
        "sendDate",
        "jobId",
        "residentName",
        "email",
      ],
      ticket: [
        "ticketNo",
        "issueDetail",
        "menuPath",
        "targetDate",
        "reportDate",
        "createdBy",
        "assignedTo",
        "ticketType",
      ],
      tickettype: ["id", "name"],
      answertype: ["id", "desc", "inspectionFlag", "attributeFlag"],
      objectdocumenttype: ["id", "docTypeName"],
      attributecategory: ["id", "catName", "docTypeName"],
      attributeunit: [
        "id",
        "unitName",
        "catName",
        "answerType",
        "isAutoLoad",
        "docTypeName",
      ],
      tags: ["id", "name"],
      documentworkflow: [
        "client",
        "contract",
        "jobTypeDWF",
        "currJobStateDWF",
        "nextJobStateDWF",
        "documentTag",
      ],
    };
    // console.log("keys[type]:", keys[type]);
    // console.log("type:", type);
    return keys[type] || [];
  },
};

export default Hooks;
