import API_ENV from "@config";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";
import CollapsibleGroup from "@ui/CollapsibleGroup";
import FileInput from "@ui/FileInput";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    description: yup.string().required("This field is required"),
    // visitId: yup.string().required("This field is required"),
    documentTagId: yup.string().required("This field is required"),

    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function AddUploadFile({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  setFormState,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
    dropdownData2: [],
  });

  const [photo, setPhoto] = useState();
  const fileInputRef = useRef(null);

  function handleFileChange(event) {
    const file = event.target.files[0];
    setPhoto(file);
  }

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      description: "",
      visitId: "",
      documentTagId: "",
    },
  });

  const jobData = Hooks.getSidePanelData();

  // const { openModal } = useModal();

  // useEffect(() => {
  //   // console.log("dropdownData:", dropdownData);
  //   console.log("jobData:", jobData);
  // }, [jobData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/visits?jobIDs=${jobData.id}`, (data) => {
      console.log("useEffect API 1 data:", data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });

    Hooks.getData(
      `${API_ENV}/documentTag?jobtypeId=${jobData.jobTypeRefId}`,
      (data) => {
        // console.log("useEffect API 1 data:", data);
        setDropdownData((prevData) => ({
          ...prevData,
          dropdownData2: data,
        }));
      },
    );
  }, [jobData.id, jobData.jobTypeRefId]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          cleanURL();
          reset();

          setIsButtonDisabled(false);
        }

        // console.log("handleSubmitForm data:", data);

        let url;

        if (data.visitId) {
          url = `${API_ENV}/attachments/${jobData.id}?description=${data.description}&documenttagid=${data.documentTagId}&visitid=${data.visitId}`;
        } else {
          url = `${API_ENV}/attachments/${jobData.id}?description=${data.description}&documenttagid=${data.documentTagId}`;
        }

        Hooks.sendFileAttachment(photo, url, "POST", thenFunction);
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, cleanURL, reset, jobData.id, photo],
  );

  // const handleChangeForm = useCallback(() => {
  //   setFormState({ type: "formNameHere", data: {} });
  // }, [setFormState]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Submit Form",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      // { text: "Change Form", click: handleChangeForm },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <FileInput ref={fileInputRef} onChange={handleFileChange} />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Desciption"}
              name="description"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Visit"}
              name="visitId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Document Tag"}
              name="documentTagId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData2}
            />
          </SidePanelFormRow>

          {/* <SidePanelFormRow type="full">
            <label>textArea</label>
            <textarea
              {...register("textArea")}
              rows="5"
              className="input left"
            ></textarea>
            {errors.issueDetail && (
              <span className="fieldError">{errors.issueDetail.message}</span>
            )}
          </SidePanelFormRow> */}

          {/* <SidePanelFormRow>
          <Button
            size={"small"}
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal();
            }}
          >
            Open Modal
          </Button>
        </SidePanelFormRow> */}
        </div>
      </CollapsibleGroup>

      {/* <Modal name="Example" title={"Title"}>
       
      </Modal> */}
    </Form>
  );
}
