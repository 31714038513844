import API_ENV from "@config";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Hooks from "../../../Hooks";
import SubmittedRow from "./SubmittedRow";

export default function SubmittedPrList({
  type,
  confirm,
  data,
  selected,
  changeView,
  onClose,
}) {
  const filter = useRef(null);
  const filterKey = useRef(null);
  const filterInputRef = useRef(null);
  const view = useRef(null);

  const selectedIds = useRef(selected || []);

  const [filterInput, changeInput] = useState(
    <input id="filterTextInput" type="text" />,
  );
  const [content, updateContent] = useState();

  const [filterDisplay, updateFilter] = useState();
  const [footer, updateFooter] = useState();
  const [header, updateHeader] = useState();

  function rowClicked(target, isChecked) {
    if (!target || !target.current || !target.current.getAttribute("dataid")) {
      return;
    }

    const purchaseReqId = target.current.getAttribute("dataid");
    if (isChecked && !selectedIds.current.includes("dataid")) {
      selectedIds.current.push(parseInt(purchaseReqId));
    } else {
      const index = selectedIds.current.indexOf(parseInt(purchaseReqId));
      if (index > -1) {
        selectedIds.current.splice(index, 1);
      }
    }
  }

  const titleText = useMemo(() => {
    return {
      prlist: "Purchase Requests",
      createdelivery: "Purchase Order Details",
    };
  }, []);

  function filterTypeChanged(target) {
    if (!target) {
      return;
    }

    if (target === "Target Date") {
      changeInput(
        <div id="prDateInputs">
          <input type="date" id="targetDateFrom" />
          to
          <input type="date" ref={filterInputRef} id="targetDateTill" />
        </div>,
      );
    } else {
      changeInput(<input id="filterTextInput" type="text" />);
    }
  }

  const filterSearch = useCallback(() => {
    const key = Hooks.conditionalString(filterKey.current.value);
    let value;

    if (document.querySelector("#filterTextInput")) {
      value = document.querySelector("#filterTextInput").value;
    }

    if (!key) {
      return;
    }

    if (!value && document.querySelector("#filterTextInput")) {
      Hooks.displayError("Please enter a value to filter by.");
      return;
    }

    let url = "";

    switch (key) {
      case "part":
        url = `${API_ENV}/purchaseRequest?prStateId=2&PartsNo=${value}`;
        break;
      case "requestedby":
        url = `${API_ENV}/purchaseRequest?prStateId=2&RequestStaffName=${value}`;
        break;
      case "supplier":
        url = `${API_ENV}/purchaseRequest?prStateId=2&SupplierName=${value}`;
        break;
      case "targetdate":
        const from = new Date(
          document.querySelector("#targetDateFrom").value,
        ).toISOString();
        const till = new Date(
          document.querySelector("#targetDateTill").value,
        ).toISOString();

        url = `${API_ENV}/purchaseRequest?prStateId=2&TargetDateFrom=${from}&TargetDateUntil=${till}`;
        break;
      default:
        break;
    }

    Hooks.getData(url, (data) => {
      const array = [header];
      // console.log(array)

      if (data && data.length) {
        data.forEach((item) => {
          array.push(
            <SubmittedRow
              onClick={rowClicked}
              type={type}
              key={`submittedPr_${item.id}`}
              data={item}
              selected={selected}
              submitted={selectedIds}
            />,
          );
        });

        updateContent(array);
      } else {
        updateContent(
          <p>
            There are currently{" "}
            <span style={{ color: "red", fontWeight: "bold" }}>
              no submitted Purchase Requests
            </span>{" "}
            to select from.
          </p>,
        );
      }
    });
    // eslint-disable-next-line
  }, [filterKey, type, selected, selectedIds, updateContent]);

  useEffect(() => {
    function closeClicked() {
      if (typeof onClose === "function") {
        updateContent([]);
        onClose();
      }
    }

    function reset() {
      const urls = {
        prlist: `${API_ENV}/purchaseRequest?prStateId=2`,
        createdelivery: `${API_ENV}/poDetail?PurchaseOrderId=${
          Hooks.getSidePanelData()?.id
        }`,
      };

      Hooks.getData(urls[type], (data) => {
        if (data && data.length) {
          const array = [];
          data.forEach((item) => {
            array.push(
              <SubmittedRow
                onClick={rowClicked}
                type={type}
                key={`submittedPr_${item.id}`}
                data={item}
                selected={selected}
                submitted={selectedIds}
              />,
            );
          });
          updateContent(array);
        } else {
          updateContent(
            <p>
              There are currently{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                no submitted Purchase Requests
              </span>{" "}
              to select from.
            </p>,
          );
        }
      });
    }

    const headerList = {
      prlist: [
        "Selected",
        "ID",
        "Part",
        "Quantity",
        "Supplier",
        "Warehouse",
        "Job",
        "Requested By",
        "Requested Date",
        "Supplier",
        "Target Date",
      ],
      createdelivery: [
        "Selected",
        "ID",
        "Part",
        "Quantity",
        "Unit Cost",
        "Target Stock Location",
      ],
    };

    function getHeader(headerArray) {
      if (!headerArray || !headerArray.length) {
        return;
      }

      const array = [];
      headerArray.forEach((item) => {
        array.push(<div>{item}</div>);
      });

      return (
        <div key={array.join("-")} className="submittedPrRow titleRow">
          {array}
        </div>
      );
    }

    if (type === "prlist") {
      updateHeader(getHeader(headerList[type]));
      updateFilter(
        <div id="submittedPrSubheader">
          <div id="viewPrType">
            <div>View</div>
            <select
              ref={view}
              onChange={() => {
                changeView(view);
              }}
            >
              <option>Submitted PRs</option>
              <option>All PRs</option>
            </select>
          </div>
          <div id="submittedPrFilter" ref={filter}>
            <div>Filter by</div>
            <select
              ref={filterKey}
              onChange={(e) => {
                filterTypeChanged(e.target.value);
              }}
            >
              <option>Part</option>
              <option>Requested By</option>
              <option>Supplier</option>
              <option>Target Date</option>
            </select>
            <div>for</div>
            {filterInput}
            <div id="searchButton" onClick={filterSearch}>
              Search
            </div>
            <div id="resetButton" onClick={reset}>
              Clear Filter
            </div>
          </div>
        </div>,
      );
      updateFooter(
        <div id="submittedPrFooter">
          <div
            className="button okButton"
            onClick={() => {
              // console.log(selectedIds.current);
              confirm("createpo", selectedIds.current);
            }}
          >
            Continue to Purchase Order
          </div>
          <div className="button close" onClick={closeClicked}>
            Cancel
          </div>
        </div>,
      );
    } else if (type === "createdelivery") {
      updateHeader(getHeader(headerList[type]));
      updateFooter(
        <div id="submittedPrFooter">
          <div
            className="button okButton"
            onClick={() => {
              confirm("delivery", selectedIds.current);
            }}
          >
            Continue to Delivery
          </div>
          <div className="button close" onClick={closeClicked}>
            Cancel
          </div>
        </div>,
      );
    }

    const array = [];
    if (data && data.length) {
      data.forEach((item) => {
        array.push(
          <SubmittedRow
            onClick={rowClicked}
            type={type}
            key={`submittedPr_${item.id}`}
            data={item}
            selected={selected}
            submitted={selectedIds}
          />,
        );
      });
      updateContent(array);
    } else {
      updateHeader();
      updateContent(
        <p>
          There are currently{" "}
          <span style={{ color: "red", fontWeight: "bold" }}>
            no submitted {titleText[type]}
          </span>{" "}
          to select from.
        </p>,
      );
    }
  }, [
    type,
    data,
    confirm,
    changeView,
    filterInput,
    selected,
    onClose,
    titleText,
    filterSearch,
  ]);

  return (
    <div id="submittedPrList" key={`${type}_${data?.id}`}>
      <div id="submittedPrHeader">{`Selected Submitted ${titleText[type]}`}</div>
      {filterDisplay}
      <p>
        Please select one or more submitted {titleText[type]} to create a{" "}
        {type === "prlist" ? "Purchase Order" : "Delivery"} with.
      </p>

      <div id="submittedPrContent">
        {header}
        {content}
      </div>
      {footer}
    </div>
  );
}
