import React from "react";

export default function SelectionList({ title, children }) {
  return (
    <div className="chooseList">
      <div className="listHeader">{title}</div>
      <div className="listItems">{children}</div>
    </div>
  );
}
