import API_ENV from "@config";
import "../../styles/features/Table.scss";

import React, { useEffect, useState } from "react";
import Hooks from "../../Hooks";
import HR from "../../ui/HR";
import FormRow from "../../ui/FormRow";
import Button from "../../ui/Button";
import Form from "../../ui/Form";
import Row from "../../ui/Row";
import Heading from "../../ui/Heading";

const initialFormData = [
  {
    id: "",
    keyName: "",
    mappedField: "",
    fieldType: "",
    mappedTable: "",
    isSearchFlag: "",
    isDisplayFlag: "",
    displayOrder: "",
    sourceApiTableId: "",
    dependencies: {},
  },
];

const initialDropdownValues = {
  mappedField: [],
  mappedTable: [],
};

export default function EditColumn({ onClose, reportId, reportType }) {
  const [formData, setFormData] = useState(initialFormData);

  const [confirmationScreen, setConfirmationScreen] = useState(false);
  const [dropdownData, setDropdownData] = useState(initialDropdownValues);

  // useEffect(() => {
  //   // console.log("formData:", formData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [formData, dropdownData]);

  useEffect(() => {
    Hooks.getData(
      `${API_ENV}/runReportlist/runonline/runnow/${reportId}`,
      (data) => {
        // console.log("Edit Colmn Data:", data);
        setFormData(data);
      },
    );
  }, [reportId]);

  // Handle Functions
  // const handleAddRow = () => {
  //   setFormData([
  //     ...formData,
  //     {
  //       keyName: "",
  //       mappedField: "",
  //       fieldType: "",
  //       mappedTable: "",
  //       isSearchFlag: "",
  //       isDisplayFlag: "",
  //       displayOrder: "",
  //       dependencies: {},
  //     },
  //   ]);
  // };

  const handleInputChange = (e, index, field) => {
    const updatedData = [...formData];
    const { type, checked, value } = e.target;
    // console.log("value:", value);

    // If the input field is a checkbox, update its checked property
    if (type === "checkbox") {
      updatedData[index][field] = checked;
    } else {
      // For other input fields, update their values
      updatedData[index][field] = value;

      // If the field is "Mapped Field" update "Field Type" based on the selected option
      if (field === "mappedField") {
        // Find the selected mappedField option within the dropdownData
        const selectedMappedField = dropdownData.mappedField.find(
          (item) => item.mappedField === value,
        );

        if (selectedMappedField) {
          // Sets the propertyType from the found item
          updatedData[index].fieldType = selectedMappedField.propertyType;
        }
      }

      // If the field is "Mapped Table" update "Mapped Table ID" and "Mapped Table Name"
      if (field === "mappedTable") {
        const selectedMappedTable = dropdownData.mappedTable.find(
          (item) => item.id === Number(value),
        );
        // console.log("selectedMappedTable:", selectedMappedTable);

        if (selectedMappedTable) {
          updatedData[index].mappedTable = selectedMappedTable.name;
          updatedData[index].sourceApiTableId = selectedMappedTable.id;
        } else {
          updatedData[index].mappedTable = "";
          updatedData[index].sourceApiTableId = 0;
        }
      }
      // console.log("updatedData:", updatedData);
    }
    setFormData(updatedData);
  };

  // const handleDeleteRow = (index) => {
  //   const updatedData = [...formData];
  //   updatedData.splice(index, 1);
  //   setFormData(updatedData);
  // };

  function handleSaveFilter() {
    setDropdownData(true);
    const contents = formData.map((data) => ({
      id: data.id,
      keyName: data.keyName,
      mappedField: data.mappedField,
      mappedTable: data.mappedTable,
      propertyType: data.fieldType,
      isSearchFlag: data.isSearchFlag,
      isDisplayFlag: data.isDisplayFlag,
      displayOrder: data.displayOrder,
      runReportListId: reportId,
      sourceApiTableId: data.sourceApiTableId,
    }));
    // console.log("contents", contents);

    const dataObject = {
      reportId: reportId,
      columnList: contents,
      // apis: dropdownData.mappedTable,
    };
    // console.log("dataObject:", dataObject);

    function thenFunction(response) {
      if (response.ok) {
        setConfirmationScreen(false);
        setDropdownData(false);
      }
    }
    Hooks.sendData(
      dataObject,
      `${API_ENV}/runReportColumnField/alltogether/${reportId}`,
      "PUT",
      thenFunction,
    );
  }

  // currently is no alltogether DELETE API
  // function handleDeleteFilter() {
  //   function thenFunction(response) {
  //     if (response.ok) {
  //       setFormData(initialFormData);
  //       setConfirmationScreen(false);
  //     }
  //   }
  //   Hooks.sendData(
  //     reportId,
  //     `${API_ENV}/runReportColumnField/alltogether/${reportId}`,
  //     "DELETE",
  //     thenFunction
  //   );
  // }

  return (
    <Form type="modalForm">
      {!confirmationScreen && (
        <table className="table">
          <thead>
            <tr className="headerRow">
              <th className="headerCell">Job Num</th>
              <th className="headerCell">Client</th>
              <th className="headerCell">Address 1</th>
              <th className="headerCell">Resident</th>
              <th className="headerCell">Email</th>
              <th className="headerCell">Postcode</th>
              {/* <th className="headerCell">Delete</th> */}
            </tr>
          </thead>

          <tbody>
            {formData.map((row, index) => (
              <tr className="tableRow" key={index}>
                <td>
                  <input
                    type="text"
                    className="input extraSmall"
                    value={row.jobNum}
                    onChange={(e) => handleInputChange(e, index, "keyName")}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="input extraSmall"
                    value={row.clientName}
                    readOnly
                    onChange={(e) => handleInputChange(e, index, "mappedField")}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="input extraSmall"
                    value={row.address1}
                    readOnly
                    onChange={(e) => handleInputChange(e, index, "fieldType")}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="input extraSmall"
                    value={row.residentName}
                    readOnly
                    onChange={(e) => handleInputChange(e, index, "fieldType")}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="input extraSmall"
                    value={row.email}
                    readOnly
                    onChange={(e) => handleInputChange(e, index, "fieldType")}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="input extraSmall"
                    value={row.postcode}
                    readOnly
                    onChange={(e) => handleInputChange(e, index, "fieldType")}
                  />
                </td>

                {/* Delete Button */}
                {/* <td>
                <button
                  className="deleteFilterRowBtn"
                  onClick={() => handleDeleteRow(index)}
                >
                  <HiTrash />
                </button>
              </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {reportId && (
        <>
          {confirmationScreen && (
            <>
              <Row>
                <Heading as="h5">
                  Are you sure you want to {confirmationScreen}?
                </Heading>
              </Row>

              <HR />

              {confirmationScreen === "save" ? (
                <Row type="center">
                  <Button
                    size="small"
                    type="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      setConfirmationScreen(false);
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="small"
                    type="danger"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSaveFilter();
                    }}
                  >
                    Save
                  </Button>
                </Row>
              ) : null}

              {/* {confirmationScreen === "delete" ? (
                <Row type="center">
                  <Button
                    size="small"
                    type="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      setConfirmationScreen(false);
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="small"
                    type="danger"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteFilter();
                    }}
                  >
                    Delete
                  </Button>
                </Row>
              ) : null} */}
            </>
          )}
        </>
      )}

      {!confirmationScreen && (
        <>
          <HR />

          <FormRow position={"center"}>
            <Button
              size={"small"}
              onClick={(e) => {
                e.preventDefault();
                setConfirmationScreen("save");
              }}
            >
              Save
            </Button>

            {/* <Button
              size={"small"}
              onClick={(e) => {
                e.preventDefault();
                setConfirmationScreen("delete");
              }}
            >
              Delete
            </Button> */}
          </FormRow>
        </>
      )}
    </Form>
  );
}
