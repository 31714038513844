import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import "./calendarfilter.scss";
import CheckboxList from "./CheckboxList";
import Hooks from "../../../../Hooks";

export default function CalendarFilter({ onSearch, onClose }) {
  const [engineers, updateEngineers] = useState();
  const [contracts, updateContracts] = useState();
  const [jobCategories, updateJobCategories] = useState();
  const [jobTypes, updateJobTypes] = useState();

  useEffect(() => {
    Hooks.getData(`${API_ENV}/staff`, (data) => {
      updateEngineers(data);
    });
    Hooks.getData(`${API_ENV}/contracts`, (data) => {
      updateContracts(data);
    });
    Hooks.getData(`${API_ENV}/jobCategory`, (data) => {
      updateJobCategories(data);
    });
    Hooks.getData(`${API_ENV}/jobType`, (data) => {
      updateJobTypes(data);
    });
  }, []);

  return (
    <div id="calendarFilter">
      <div id="cfHeader">
        <div id="cfTitle">Calendar Filters</div>
        <div id="cfClose" onClick={onClose}>
          Close
        </div>
      </div>
      <div id="cfColumnHeaders">
        <div>Engineers</div>
        <div>Contracts</div>
        <div>Job Categories</div>
        <div>Job Types</div>
      </div>
      <div id="cfContent">
        <CheckboxList type="engineers" data={engineers} />
        <CheckboxList type="contracts" data={contracts} />
        <CheckboxList type="jobcategories" data={jobCategories} />
        <CheckboxList type="jobtypes" data={jobTypes} />
      </div>
      <div id="cfFooter">
        <div id="cfSearch" onClick={onSearch}>
          Search
        </div>
      </div>
    </div>
  );
}
