import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import { Modal, useModal } from "../../../ui/Modal";
import Hooks from "../../../Hooks";
import Form from "../../../ui/Form";
import SidePanelFormRow from "../../../ui/SidePanelFormRow";
import Input from "../../../ui/Input";
import DropdownList from "../../../ui/DropdownList";

// Form Validation Schema
const schema = yup
  .object({
    letterName: yup.string().required("Letter Name is required"),
    report: yup.string().required("Report is required"),
    client: yup.string().required("Client is required"),
    enabled: yup.string().required("Enabled is required"),
    jobState: yup.string().required("Job State is required"),
    jobType: yup.string().required("Job Type is required"),
  })
  .required();

export default function AddLetterPrintSetup({
  setFooterState,
  cleanURL,
  refreshTable,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    reports: [],
    clients: [],
    jobState: [],
    jobType: [],
  });
  // const { openModal } = useModal();

  // useEffect(() => {
  //   console.log("data:", data);
  // }, [data]);

  const {
    control,
    handleSubmit,
    // setValue,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      letterName: "",
      report: "",
      client: "",
      enabled: "",
      jobState: "",
      jobType: "",
    },
  });

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log(values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // UseEffect Hooks
  // Dropdown list Data
  useEffect(() => {
    Hooks.getData(
      `${API_ENV}/runReportlist?RunReportTypeId=1&limit=0&showarchive=false`,
      (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          reports: data,
        }));
      },
    );
    Hooks.getData(`${API_ENV}/clients?limit=0&showarchive=false`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        clients: data,
      }));
    });
    Hooks.getData(`${API_ENV}/jobState?limit=0&showarchive=false `, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        jobState: data,
      }));
    });
    Hooks.getData(`${API_ENV}/jobType?limit=0&showarchive=false `, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        jobType: data,
      }));
    });
  }, []);

  // HANDLE Functions
  const handleAddForm = useCallback(
    async (data) => {
      setIsButtonDisabled(true);

      function thenFunction(response) {
        refreshTable();
        cleanURL();

        setIsButtonDisabled(false);
      }

      let dataToSend = {
        letterName: data.letterName,
        runReportListId: data.report,
        clientRefId: data.client,
        jobStateRefId: data.jobState,
        jobTypeRefId: data.jobType,
        enableFlag: !!data.enabled,
      };

      // console.log(dataToSend);

      Hooks.sendData(
        dataToSend,
        `${API_ENV}/LetterPrintSetup`,
        "POST",
        thenFunction,
      );
    },
    [refreshTable, cleanURL],
  );

  // const handleButton2 = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Add",
        click: handleSubmit(handleAddForm),
        disabled: isButtonDisabled,
      },
      // { text: "Button2", click: handleSubmit(handleButton2) },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleAddForm,
    // handleButton2,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <Input
            label={"Letter Name"}
            name="letterName"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Report"}
            name="report"
            control={control}
            errors={errors}
            dropdownData={dropdownData.reports}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Client"}
            name="client"
            control={control}
            errors={errors}
            dropdownData={dropdownData.clients}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <Input
            label={"Enabled"}
            name="enabled"
            type="checkbox"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Job State"}
            name="jobState"
            control={control}
            errors={errors}
            dropdownData={dropdownData.jobState}
            optionName={"description"}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Job Type"}
            name="jobType"
            control={control}
            errors={errors}
            dropdownData={dropdownData.jobType}
            optionName={"description"}
          />
        </SidePanelFormRow>
      </div>

      {/* <Modal name="Example" title={"Title"}></Modal> */}
    </Form>
  );
}
