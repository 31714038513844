import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Hooks from "../../../Hooks";
import API_ENV from "@config";

export default function PurchaseReqRow({ type, data, poSupplier }) {
  // commenting out "supplierList" as this isnt used & to remove unused-vars build warning
  const [, /*supplierList*/ updateSuppliers] = useState();
  const [unitCost, updateUnitCost] = useState();
  const [inventoryQty, updateQty] = useState();

  function removePr(target) {
    Swal.fire({
      title: "Remove Purchase Request?",
      text: "Are you sure you wish to remove this Purchase Request?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        target.parentElement.parentElement.remove();
      }
    });
  }

  useEffect(() => {
    const partId = data?.partsRefId;
    const supplierId = poSupplier || data?.targetSupplierId;

    Hooks.getData(
      `${API_ENV}/partsAndSupplier/FindCostQtybyPartSupplier/${partId}/${supplierId}`,
      (unitCost) => {
        if (!unitCost || !unitCost.unitCost || !unitCost.inventoryQty) {
          updateUnitCost("No cost found.");
          updateQty("No inventory found.");
          return;
        }

        updateUnitCost(`£${unitCost.unitCost}`);
        updateQty(unitCost?.inventoryQty);
      },
    );

    Hooks.getData(`${API_ENV}/suppliers`, (suppliers) => {
      if (!suppliers || !suppliers.length) {
        return;
      }

      const chosenSupplier = data?.targetSupplier;
      const supplierArray = [];

      suppliers.forEach((supplier) => {
        if (!supplier || !supplier.id || !supplier.name) {
          return;
        }

        if (supplier.name !== chosenSupplier.name) {
          supplierArray.push(
            <option value={supplier.id}>{supplier.name}</option>,
          );
        }
      });

      updateSuppliers(supplierArray);
    });
  }, [data, poSupplier]);

  if (type === "createpo") {
    return (
      <div className="purchaseReqRow" dataid={data?.id}>
        <div>{data?.id}</div>
        <div>{data?.parts?.partno}</div>
        <div>{data?.quantity}</div>
        <div>{data?.targetSupplier?.name}</div>
        <div>{data?.targetWarehouse?.name}</div>
        <div>{unitCost}</div>
        <div>{inventoryQty}</div>
        <div>
          <div
            className="removePr"
            onClick={(e) => {
              removePr(e.target);
            }}
          >
            Remove
          </div>
        </div>
      </div>
    );
  } else if (type === "createdelivery") {
    const stockLocation = data?.destinationStockLocation;
    const stockLocationText = stockLocation
      ? `${stockLocation?.aisle} | ${stockLocation?.rack} | ${stockLocation?.level}`
      : "No Location Found.";
    return (
      <div className="purchaseReqRow" dataid={data?.id}>
        <div>{data?.id}</div>
        <div>{data?.parts?.partno}</div>
        <div>{data?.orderQuantity}</div>
        <div>{`£${data?.unitPrice}`}</div>
        <div>{data?.destinationWarehouse?.name}</div>
        <div>{stockLocationText}</div>
        <div>
          <div
            className="removePr"
            onClick={(e) => {
              removePr(e.target);
            }}
          >
            Remove
          </div>
        </div>
      </div>
    );
  }
}
