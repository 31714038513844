import API_ENV from "@config";
import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";

import CollapsibleGroup from "@ui/CollapsibleGroup";
import DropdownList from "@ui/DropdownList";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    sorCode: yup.string().required("This field is required"),
    shortDesc: yup.string().required("This field is required"),
    // mediumDesc: yup.string().required("This field is required"),
    // uomId: yup.string().required("This field is required"),
    rate: yup.string().required("This field is required"),
    sorListId: yup.string().required("This field is required"),

    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function ViewSOR({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      sorCode: "",
      shortDesc: "",
      mediumDesc: "",
      uomId: "",
      rate: "",
      sorListId: "",
    },
  });

  // const { openModal } = useModal();
  const { data: stateData } = state;

  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: stateData.id,
        // * BOOLEANS need || "" removed!
        sorCode: stateData.sorCode || "",
        shortDesc: stateData.shortDesc || "",
        mediumDesc: stateData.mediumDesc || "",
        uomId: stateData.uomId || "",
        rate: stateData.rate || "",
        sorListId: stateData.sorListId || "",
      });
    }
  }, [stateData, reset]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/uom`, (data) => {
      // console.log("useEffect API 1 data:", data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });

    Hooks.getData(`${API_ENV}/sorList`, (data) => {
      // console.log("useEffect API 1 data:", data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData2: data,
      }));
    });
  }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          cleanURL();

          setIsButtonDisabled(false);
        }

        // console.log("apiUrl", apiUrl);
        // console.log("handleSubmitForm data:", data);

        Hooks.sendData(
          data,
          `${API_ENV}/${apiUrl}/${data.id}`,
          "PUT",
          thenFunction,
        );
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, cleanURL, apiUrl],
  );

  const handleArchive = useCallback(() => {
    archive(stateData.id);
  }, [archive, stateData.id]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Submit Form",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <Input
              label={"SOR Code"}
              name="sorCode"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Short Description"}
              name="shortDesc"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          {/* <SidePanelFormRow>
            <Input
              label={"Medium Description"}
              name="mediumDesc"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow> */}

          <SidePanelFormRow type="full">
            <label>Medium Description</label>
            <textarea
              {...register("mediumDesc")}
              rows="6"
              className="input left"
            ></textarea>
            {errors.issueDetail && (
              <span className="fieldError">{errors.issueDetail.message}</span>
            )}
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"UOM"}
              name="uomId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Rate"}
              name="rate"
              type="number"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"SOR List"}
              name="sorListId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData2}
            />
          </SidePanelFormRow>

          {/* <SidePanelFormRow>
            <DropdownList
              label={"Dropdown List"}
              name="field2"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
            />
          </SidePanelFormRow> */}

          {/* <SidePanelFormRow>
          <Button
            size={"small"}
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal();
            }}
          >
            Open Modal
          </Button>
        </SidePanelFormRow> */}
        </div>
      </CollapsibleGroup>

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
