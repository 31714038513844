import React, { useEffect, useState } from "react";

export default function CheckboxList({ data, type }) {
  const [content, updateContent] = useState();

  function checkboxItem(data, type) {
    function getText(data, type) {
      switch (type) {
        case "engineers":
          return `${data?.forename} ${data?.surname}`;
        case "jobtypes":
        case "jobcategories":
          return data?.description;
        case "contracts":
          return data?.name;
        default:
          return "Error Loading Text";
      }
    }

    return (
      <div className="checkboxItem" dataid={data?.id}>
        <div>{getText(data, type)}</div>
        <div>
          <input type="checkbox" />
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (data && data.length && typeof data !== "string") {
      const array = [];
      data.forEach((item) => {
        array.push(checkboxItem(item, type));
      });
      updateContent(array);
    }
  }, [data, type]);
  //added "type" to dependency array, appears to be working fine without causing infinite loop

  return <div className={`checkboxList ${type}List`}>{content}</div>;
}
