import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import Hooks from "../../Hooks";

import { HiTrash } from "react-icons/hi2";
import Form from "../../ui/Form";
import Row from "../../ui/Row";
import Heading from "../../ui/Heading";
import HR from "../../ui/HR";
import FormRow from "../../ui/FormRow";
import Button from "../../ui/Button";

const initialFormData = [
  {
    user: "",
    fullName: "",
    email: "",
  },
];

export default function EmailFilter({
  onSearch,
  onClose,
  reportId,
  reportTypeId,
  runNow,
  runAtDateFormatted,
}) {
  const [confirmationScreen, setConfirmationScreen] = useState(false);
  const [sentConfirmation, setSentConfirmation] = useState(false);

  const [formData, setFormData] = useState(initialFormData);
  const [userOptions, setUserOptions] = useState([]);

  // useEffect(() => {
  //   console.log("formData", formData);
  //   // console.log("userOptions", userOptions);
  //   // console.log("runNow", runNow);
  //   // console.log("runAtDateFormatted", runAtDateFormatted);
  // }, [formData]);

  useEffect(() => {
    // populating user drop down list data
    Hooks.getData(
      `${API_ENV}/staff?SortColumnNum=1&SortByOrder=asc`,
      (data) => {
        // console.log(data);
        setUserOptions(data);
      },
    );
  }, [reportId]);

  useEffect(() => {
    if (userOptions) {
      // Were populating the fields with the information if it exists here
      Hooks.getData(
        `${API_ENV}/runReportEmail/runReportId/${reportId}`,
        (data) => {
          // console.log(data);

          if (typeof data === "object") {
            // Find users via email & then populate formData
            const foundUsers = Object.entries(data.contents).map(
              ([name, email]) => {
                const foundUser = userOptions.find(
                  (user) => user.email === email,
                );
                // console.log("name:", name);
                if (foundUser) {
                  return {
                    user: foundUser.id,
                    fullName: `${foundUser.forename} ${foundUser.surname}`,
                    email: foundUser.email,
                  };
                } else {
                  return {
                    user: 0,
                    fullName: name,
                    email: email,
                  };
                }
              },
            );
            // console.log("Found users:", foundUsers);

            // Filter out entries with empty values
            // const filteredUsers = foundUsers.filter(
            //   (user) =>
            //     user.user !== "" || user.fullName !== "" || user.email !== ""
            // );
            // console.log("filteredUsers:", filteredUsers);

            setFormData(foundUsers);
            // setFormData(filteredUsers);
          } else {
            return;
          }
        },
      );
    }
  }, [reportId, userOptions]);

  const handleAddRow = () => {
    setFormData([...formData, { user: "", fullName: "", email: "" }]);
  };

  const handleDeleteRow = (index) => {
    // Prevent deleting the first row
    // if (index === 0) {
    //   return;
    // }
    const updatedData = [...formData];
    updatedData.splice(index, 1);
    setFormData(updatedData);
  };

  const handleInputChange = (e, index, field) => {
    const updateEmailForUser = (index, selectedUserId) => {
      const userToInt = Number(selectedUserId);
      const selectedUser = userOptions.find((user) => user.id === userToInt);
      // console.log("selectedUser", selectedUser);

      if (selectedUser) {
        const updatedData = [...formData];
        updatedData[index].email = selectedUser.email;
        updatedData[index].fullName =
          `${selectedUser.forename} ${selectedUser.surname}`;
        setFormData(updatedData);
      }
    };

    const updatedData = [...formData];
    updatedData[index][field] = e.target.value;

    // If the field being changed is "user", update the email as well
    if (field === "user") {
      updateEmailForUser(index, e.target.value);
    }

    setFormData(updatedData);
  };

  function handleSaveFilter() {
    const contents = formData.map((data) => ({
      [data.fullName]: `${data.email}`,
    }));
    // console.log("contents", contents);

    const filterObject = {
      runReportListId: reportId,
      contents: Object.assign({}, ...contents),
    };
    // console.log("filterObject", filterObject);

    function thenFunction(response) {
      if (response.ok) {
        setConfirmationScreen(false);
      }
    }

    Hooks.sendData(
      filterObject,
      `${API_ENV}/runReportEmail`,
      "POST",
      thenFunction,
    );
  }

  // function handleSubmitNow() {
  //   let apiUrl = `${API_ENV}/runReportlist/runReportAsSendEmail/${reportId}/${runNow}`;

  //   if (reportTypeId) {
  //     apiUrl += `?ReportTypeID=${reportTypeId}`;
  //   }
  //   if (runNow === false) {
  //     apiUrl += `&ScheduleAt=${runAtDateFormatted}`;
  //   }

  //   Hooks.getData(apiUrl, (data) => {
  //     // console.log(data);
  //     setConfirmationScreen(false);

  //     const content = (
  //       <>
  //         <h3>Email Send Information</h3>
  //         <p>{data}</p>
  //         <button onClick={() => setSentConfirmation(false)}>Ok</button>
  //       </>
  //     );

  //     setSentConfirmation(content);
  //   });
  // }

  return (
    <Form type="modalForm">
      {!confirmationScreen && !sentConfirmation && (
        <>
          <Row>
            <Heading as="h5">
              Click "Add" & select a user or select "Custom Email" & enter a
              name & email manually.
            </Heading>
          </Row>

          <HR />

          <Row>
            <Button
              size={"small"}
              onClick={(e) => {
                e.preventDefault();
                handleAddRow();
                setConfirmationScreen(false);
                setSentConfirmation(false);
              }}
            >
              Add Row
            </Button>
          </Row>

          {formData.map((row, index) => (
            <FormRow position={"center"} key={index}>
              <label htmlFor={`user_${index}`}>User:</label>
              <select
                id={`user_${index}`}
                className="select extraSmall"
                value={row.user}
                onChange={(e) => handleInputChange(e, index, "user")}
              >
                {/* <option value="">Select option</option> */}
                <option value={0}>Custom Email</option>
                {Array.isArray(userOptions)
                  ? userOptions.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.forename} {item.surname}
                      </option>
                    ))
                  : null}
              </select>

              <label htmlFor={`fullName_${index}`} className="rowBlock">
                Full Name:
              </label>
              <input
                id={`fullName_${index}`}
                className="input extraSmall"
                type="text"
                value={row.fullName}
                onChange={(e) => handleInputChange(e, index, "fullName")}
              />

              <label htmlFor={`email_${index}`} className="rowBlock">
                Email Address:
              </label>
              <input
                id={`email_${index}`}
                className="input extraSmall"
                type="text"
                value={row.email}
                onChange={(e) => handleInputChange(e, index, "email")}
              />

              <Button type="danger" onClick={() => handleDeleteRow(index)}>
                <HiTrash />
              </Button>

              {/* {index !== 0 ? (
                  <button
                    className="deleteFilterRowBtn"
                    onClick={() => handleDeleteRow(index)}
                  >
                    <HiTrash />
                  </button>
                ) : (
                  <button
                    className="deleteFilterRowBtn"
                    style={{ visibility: "hidden" }}
                    onClick={() => handleDeleteRow(index)}
                  >
                    <HiTrash />
                  </button>
                )} */}
            </FormRow>
          ))}
        </>
      )}

      {sentConfirmation && (
        <div className="afSaveContainer2">{sentConfirmation}</div>
      )}

      {confirmationScreen && (
        <>
          <Row>
            <Heading as="h5">
              Are you sure you want to {confirmationScreen}?
            </Heading>
          </Row>

          <HR />

          {confirmationScreen === "save" ? (
            <Row type="center">
              <Button
                size="small"
                type="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmationScreen(false);
                }}
              >
                Cancel
              </Button>

              <Button
                size="small"
                type="danger"
                onClick={(e) => {
                  e.preventDefault();
                  handleSaveFilter();
                }}
              >
                Save
              </Button>
            </Row>
          ) : null}

          {/* {confirmationScreen === "submit" ? (
            <>
              <Row>
                <Heading as="h5">
                  If you have added new emails OR are setting up emails for a
                  report for the first time, <br /> you must first use the
                  "Save" button to save the emails into the system before
                  submitting.
                </Heading>
              </Row>

              <Heading as="h5">
                Are you sure you want to {confirmationScreen} this list of
                emails to this report?
              </Heading>

              <HR />

              <Row type="center">
                <Button
                  size="small"
                  type="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmationScreen(false);
                  }}
                >
                  Cancel
                </Button>

                <Button
                  size="small"
                  type="danger"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmitNow();
                  }}
                >
                  Submit Now
                </Button>
              </Row>
            </>
          ) : null} */}
        </>
      )}

      {!confirmationScreen && (
        <>
          <HR />

          <FormRow position={"center"}>
            <Button
              size={"small"}
              onClick={(e) => {
                e.preventDefault();
                setConfirmationScreen("save");
                setSentConfirmation(false);
              }}
            >
              Save
            </Button>
            {/* <Button
              size={"small"}
              onClick={(e) => {
                e.preventDefault();
                setConfirmationScreen("submit");
                setSentConfirmation(false);
              }}
            >
              Submit
            </Button> */}
          </FormRow>
        </>
      )}
    </Form>
  );
}
