import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import Hooks from "../../../Hooks";
import SidePanelRow from "../../sidePanel/SidePanelComponents/SidePanelRow";

export default function ViewPr({ purchaseReqId, searchClicked }) {
  const [data, updateData] = useState({
    parts: {},
    targetSupplier: {},
    requestStaff: {},
  });

  function row(key, text, type) {
    if (type === "new") {
      return (
        <SidePanelRow
          key={`${type}_${key}_${text}`}
          title={key}
          value={text}
          fullData={data}
          searchClicked={searchClicked}
        />
      );
    } else {
      return (
        <SidePanelRow
          key={`${type}_${key}_${text}`}
          title={key}
          value={text}
          readOnly={true}
        />
      );
    }
  }

  useEffect(() => {
    Hooks.getData(`${API_ENV}/purchaseRequest/${purchaseReqId}`, (data) => {
      if (data) {
        updateData(data);
      }
    });
  }, [purchaseReqId]);

  if (!data || !data.id) {
    return <></>;
  }
  // 1 means new PR, 2 means submitted PR.
  if (data?.purchaseRequestStateId === 2) {
    return (
      <React.Fragment>
        {row("Part Number", data?.parts?.partno)}
        {row("Quantity", data?.quantity, "new")}
        {row("Target Date", new Date(data?.targetDate).toDateString(), "new")}
        {row("Target Job", data?.targetJobId, "new")}
        {row("Preferred Supplier", data?.targetSupplier?.name, "new")}
        {row("Preferred Warehouse", data?.targetWarehouse?.name, "new")}
        {row(
          "Requested By",
          `${data?.requestStaff?.forename} ${data?.requestStaff?.surname}`,
        )}
        {row("Requested Date", new Date(data?.requestDate).toDateString())}
        {row("Reason", data?.reason, "new")}
      </React.Fragment>
    );
  } else {
    const stockAllocated = data?.importToPartsAllocationFlag
      ? "Stock has been allocated to this Purchase Request"
      : "No stock has been allocated to this Purchase Request";

    return (
      <React.Fragment>
        <p className="prText">
          This Purchase Request has been {data?.purchaseRequestState?.name}{" "}
          meaning that no further changes can be made.
        </p>
        <p className="prText">{stockAllocated}</p>
        {row("Part Number", data?.parts?.partno)}
        {row("Quantity", data?.quantity)}
        {row("Target Date", new Date(data?.targetDate).toDateString())}
        {row("Target Job", data?.targetJobId)}
        {row("Target Warehouse", data?.targetWarehouse?.name)}
        {row("Target Supplier", data?.targetSupplier?.name)}
        {row(
          "Requested By",
          `${data?.requestStaff?.forename} ${data?.requestStaff?.surname}`,
        )}
        {row("Reason", data?.reason)}
      </React.Fragment>
    );
  }
}
