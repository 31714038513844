import React, { useState, useEffect } from "react";
import Hooks from "../../../Hooks";

export default function SearchResults({
  target,
  itemClicked,
  results,
  type,
  onClose,
}) {
  const [content, updateContent] = useState();
  // console.log(type);

  useEffect(() => {
    const array = [];
    function getText(item) {
      switch (type) {
        case "stocklocation":
          return `Aisle: ${item?.aisle} => Rack: ${item?.rack} => Level: ${item?.level}`;
        case "supplierparts":
          return `${item?.parts?.partno} - ${item?.parts?.description}`;
        case "parts":
          return item?.partno;
        case "targetjob":
          return `${item?.id} - ${item?.property?.address1} ${item?.property?.address2} ${item?.property?.postCode}`;
        case "targetwarehouse":
        case "targetsupplier":
          return item?.name;
        case "targetlocation":
          return item?.address1 + " " + item?.postCode;
        case "targetapprover":
          return item?.forename + " " + item?.surname;
        default:
          break;
      }
    }

    if (results && results.length && typeof results !== "string") {
      if (type === "supplierparts") {
        const exsitingItems = [];
        results.forEach((item) => {
          if (!exsitingItems.includes(item?.parts?.partno)) {
            exsitingItems.push(item?.parts?.partno);
            array.push(
              <div
                className="searchResult"
                data={item}
                onClick={() => {
                  itemClicked(item, type, target);
                }}
              >
                {getText(item)}
              </div>,
            );
          }
        });
      } else {
        results.forEach((item) => {
          array.push(
            <div
              className="searchResult"
              data={item}
              onClick={() => {
                itemClicked(item, type, target);
              }}
            >
              {getText(item)}
            </div>,
          );
        });
      }
    } else if (typeof results === "object") {
      array.push(
        <div
          className="searchResult"
          data={results}
          onClick={() => {
            itemClicked(results, type, target);
          }}
        >
          {getText(results)}
        </div>,
      );
    } else {
      array.push(<div className="searchResult">No results to show.</div>);
    }

    updateContent(array);
  }, [itemClicked, results, type, onClose, target]);
  //added "target" to dependency array, appears to be working fine without causing infinite loop

  return (
    <div id="searchResultsPopup">
      <div id="searchResultsHeader">
        <div id="searchResultsTitle">
          Search Results for {Hooks.formatKey(type)}
        </div>
        <div id="searchResultsClose" onClick={onClose}>
          X
        </div>
      </div>
      <div id="searchResultsContent">{content}</div>
    </div>
  );
}
