import "../styles/ui/Button.scss";

import {
  HiArrowPath,
  HiMagnifyingGlass,
  HiMiniPlus,
  HiOutlineArchiveBox,
  HiOutlineEye,
  HiOutlineFunnel,
  HiOutlineXMark,
} from "react-icons/hi2";

import React from "react";

const ICONS = {
  HiMiniPlus: HiMiniPlus,
  HiArrowPath: HiArrowPath,
  HiOutlineFunnel: HiOutlineFunnel,
  HiOutlineArchiveBox: HiOutlineArchiveBox,
  HiMagnifyingGlass: HiMagnifyingGlass,
  HiOutlineEye: HiOutlineEye,
  HiOutlineXMark: HiOutlineXMark,
};

const Button = React.forwardRef(
  (
    {
      size = "medium",
      type = "primary",
      icon,
      iconSize = 20,
      onClick,
      children,
      dataTip,
      ...props
    },
    ref,
  ) => {
    const IconComponent = ICONS[icon]; // Assumes ICONS is defined somewhere in your code

    return (
      <button
        ref={ref}
        className={`button ${size} ${type}`}
        onClick={onClick}
        data-tip={dataTip}
        {...props}
      >
        {IconComponent && (
          <div className="buttonIcon">
            <IconComponent size={iconSize} />
          </div>
        )}
        <span className="buttonChildren">{children}</span>
      </button>
    );
  },
);

export default Button;
