import API_ENV from "@config";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DateTimePicker from "@generic/DateTimePicker";
import CollapsibleGroup from "@ui/CollapsibleGroup";
import Button from "@ui/Button";
import FileInput from "@ui/FileInput";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    name: yup.string().required("This field is required"),
    // totalRecord: yup.number().required("This field is required"),
    // createDateTime: yup.string().required("This field is required"),
    // contentLastUpdateDate: yup.string().required("This field is required"),

    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function ViewSORList({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
  setFormState,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [fileAttachment, setFileAttachment] = useState();
  const fileInputRef = useRef(null);

  // const [dropdownData, setDropdownData] = useState({
  //   dropdownData1: [],
  // });
  // const [contentListData, setContentListData] = useState([]);

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // field1: "",
    },
  });

  const id = watch("id");
  const name = watch("name");

  // const { openModal } = useModal();
  const { data: stateData } = state;

  // * UseEffect Hooks: Tracking
  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  //* Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: stateData.id,
        // * BOOLEANS need || "" removed!
        name: stateData.name || "",
        totalRecord: stateData.totalRecord || "",
        createDateTime: stateData.createDateTime || "",
        contentLastUpdateDate: stateData.contentLastUpdateDate || "",
      });
    }
  }, [stateData, reset]);

  // * UseEffect Hooks: Form/API
  // Dropdown List Data
  // useEffect(() => {
  //   Hooks.getData(`${API_ENV}/`, (data) => {
  //     // console.log("useEffect API 1 data:", data);
  //     setDropdownData((prevData) => ({
  //       ...prevData,
  //       dropdownData1: data,
  //     }));
  //   });
  // }, []);

  // HANDLE Functions
  function handleFileChange(event) {
    const file = event.target.files[0];
    setFileAttachment(file);
  }

  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          cleanURL();

          //. Go back to previous form after submission
          // Hooks.getData(`${API_ENV}/`, (data) => {
          //   // console.log(data);
          //   setFormState({ type: "formGoesHere", data: { data } });
          // });
          setIsButtonDisabled(false);
        }

        // console.log("apiUrl", apiUrl);
        // console.log("handleSubmitForm data:", data);

        Hooks.sendData(
          data,
          `${API_ENV}/${apiUrl}/${data.id}`,
          "PUT",
          thenFunction,
        );
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, cleanURL, apiUrl],
  );

  const handleArchive = useCallback(() => {
    archive(stateData.id);
  }, [archive, stateData.id]);

  const uploadPhotoTest = useCallback(
    (e) => {
      e.preventDefault();
      // console.log("uploadPhotoTest");

      if (!fileAttachment) {
        console.log("No file selected");
        return;
      }

      // console.log("uploadPhotoTest, Photo attachment:", photo);

      Hooks.sendFileAttachment(
        fileAttachment,
        `${API_ENV}/sorList/uploadfile/${id}`,
        "PUT",
      );
    },
    [fileAttachment, id],
  );

  const handleDownloadFile = useCallback(() => {
    const url = `${API_ENV}/sorList/downloadfile/${id}`;
    // console.log("url", url);

    Hooks.downloadFile(url, name);
  }, [id, name]);

  // const handleChangeForm = useCallback(() => {
  //   setFormState({ type: "formNameHere", data: {} });
  // }, [setFormState]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Submit Form",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      { text: "Download File", click: handleDownloadFile },
      // { text: "Change Form", click: handleChangeForm },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleDownloadFile,
    handleSubmitForm,
    handleArchive,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <Input
              label={"Name"}
              name="name"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Total Records"}
              name="totalRecord"
              type="number"
              control={control}
              errors={errors}
              readOnly
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Create Date</label>
            <Controller
              name="createDateTime"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                  readOnly
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Last Update Date</label>
            <Controller
              name="contentLastUpdateDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                  readOnly
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <FileInput ref={fileInputRef} onChange={handleFileChange} />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Button onClick={uploadPhotoTest}>Upload File</Button>
          </SidePanelFormRow>
        </div>
      </CollapsibleGroup>
    </Form>
  );
}
