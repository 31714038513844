import API_ENV from "@config";
import "./inspections.scss";
import React, { useEffect, useRef, useState } from "react";
import Hooks from "../../../../Hooks";
import Swal from "sweetalert2";
import FileInput from "@ui/FileInput";
import Button from "@ui/Button";

export default function Inspections({ data, answered }) {
  const [inspectionData, setInspectionData] = useState();
  const [inspectionSetData, setInspectionSetData] = useState(null);
  const [selectedTab, setSelectedTab] = useState("job");

  const [totalQuestions, setTotalQuestions] = useState(0);
  const [completedQuestions, setCompletedQuestions] = useState(0);

  const [photo, setPhoto] = useState(null);
  const fileInputRef = useRef(null);

  // useEffect(() => {
  //   console.log("data prop: ", data);
  //   // console.log("inspectionSetData: ", inspectionSetData);
  //   // console.log("photo: ", photo);
  // }, [data, inspectionSetData]);

  // * Initial data setting from data prop
  useEffect(() => {
    if (data) {
      setInspectionData(data);
      setInspectionSetData(data[0].inspectionSet);
      setTotalQuestions(data.length);
      setCompletedQuestions(data[0].inspection.totalcomplete);
    }
  }, [data]);

  function tabClicked(type) {
    setSelectedTab(type);
    setInspectionData(null);
    setInspectionSetData(null);
    setTotalQuestions(0);

    const jobId = Hooks.getSidePanelData().id;
    if (jobId) {
      const urls = {
        job: `${API_ENV}/inspectionQuestionWithAnswer?JobId=${jobId}&InspectionTypeID=1`,
        visit: `${API_ENV}/inspectionQuestionWithAnswer?JobId=${jobId}&InspectionTypeID=2`,
        equipment: `${API_ENV}/inspectionQuestionWithAnswer?JobId=${jobId}&InspectionTypeID=3`,
      };

      Hooks.getData(urls[type], (data) => {
        // console.log(`${type} Inspection data: `, data);

        if (data && data.length) {
          setInspectionData(data);
          setInspectionSetData(data[0].inspectionSet);
          setTotalQuestions(data.length);
          setCompletedQuestions(data[0].inspection.totalcomplete);
        }
      });
    }
  }

  function viewInfo(item) {
    if (item) {
      Swal.fire({
        title: `Inspection set (ID=${item.id}).`,
        text: `Type: ${item.inspectionType.name}, Created: ${new Date(
          item.inspection.createDate,
        ).toDateString()}`,
        icon: "info",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }
  }

  function handlePhotoAttachment(event) {
    const file = event.target.files[0];
    setPhoto(file);
  }

  function handleUploadPhoto(id) {
    if (!photo) {
      console.log("No file selected");
      return;
    }

    Hooks.sendFileAttachment(
      photo,
      `${API_ENV}/inspectionQuestionWithAnswer/importimage/${id}`,
      "PUT",
    );
  }

  function handleDownloadPhoto(id) {
    Hooks.downloadFile(
      `${API_ENV}/inspectionQuestionWithAnswer/${id}`,
      (data) => {
        // console.log(data);
      },
    );
  }

  const handleChange = (e, item, field) => {
    const { value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setInspectionData((prevData) =>
      prevData.map((el) =>
        el.id === item.id ? { ...el, [field]: newValue } : el,
      ),
    );
  };

  function handleInputSelector(item) {
    // console.log("handleInputSelector item:", item);

    function getDropdown(answer, choice) {
      let array = [<option>- Select -</option>];
      for (let key in choice) {
        if (choice[key] !== answer) {
          array.push(<option className="option">{choice[key]}</option>);
        }
      }
      return array;
    }

    switch (Hooks.conditionalString(item.answerType.desc)) {
      case "yes/no":
        return (
          <select
            key="yesNoSelect"
            className="select"
            value={item.answer || ""}
            onChange={(e) => handleChange(e, item, "answer")}
          >
            <option className="option">- Select -</option>
            <option className="option">Yes</option>
            <option className="option">No</option>
          </select>
        );

      case "freetext":
        return (
          <input
            key={Math.random()}
            className="answerInput"
            type="text"
            plabeholder="Enter answer"
            value={item.answer || ""}
            onChange={(e) => handleChange(e, item, "answer")}
          />
        );

      case "dropdownlist":
        return (
          <select
            key={Math.random()}
            className="answerInput"
            value={item.answer || ""}
            onChange={(e) => handleChange(e, item, "answer")}
          >
            {getDropdown("", item.answerChoice)}
          </select>
        );

      case "tickbox":
        // ! Needs fixing
        for (let key in item.answerChoice) {
          return (
            <div className="checkedInput" key={item.answerChoice[key]}>
              <div className="checkedInputKey">{item.answerChoice[key]}</div>
              <div className="checkedInputValue">
                <input
                  className="answerInput"
                  type="checkbox"
                  value={item.answer || ""}
                  onChange={(e) => handleChange(e, item, "answer")}
                />
              </div>
            </div>
          );
        }
        break;
      case "photo":
        return (
          <div className="answerInput" key={Math.random()}>
            <label>Upload Photo</label>
            <FileInput ref={fileInputRef} onChange={handlePhotoAttachment} />
            {photo && <span>{photo.name}</span>}

            <Button
              size="medium"
              onClick={(e) => {
                e.preventDefault();
                handleUploadPhoto(item.id);
              }}
            >
              Upload Photo
            </Button>

            <Button
              size="medium"
              onClick={(e) => {
                e.preventDefault();
                handleDownloadPhoto(item.id);
              }}
            >
              Download Photo
            </Button>
          </div>
        );

      default:
        break;
    }
  }

  return (
    <div id="inspectionsContainer">
      <div id="inspectionTabs">
        <div
          id="jobTab"
          className={`inspectionTab ${
            selectedTab === "job" ? "currentInspectionTab" : ""
          }`}
          onClick={() => {
            tabClicked("job");
          }}
        >
          Job Level
        </div>
        <div
          id="visitTab"
          className={`inspectionTab ${
            selectedTab === "visit" ? "currentInspectionTab" : ""
          }`}
          onClick={() => {
            tabClicked("visit");
          }}
        >
          Visit Level
        </div>
        <div
          id="equipmentTab"
          className={`inspectionTab ${
            selectedTab === "equipment" ? "currentInspectionTab" : ""
          }`}
          onClick={() => {
            tabClicked("equipment");
          }}
        >
          Equipment Level
        </div>
      </div>

      <div id="inspectionInfo">
        <div>Total Questions: {totalQuestions}</div>
        <div>Completed Questions: {completedQuestions}</div>
        <div>
          <div id="yellowSquare"></div> = Compulsory
        </div>
      </div>

      <div id="inspectionsContent">
        {inspectionSetData && (
          <div className="inspectionDetails">
            {inspectionSetData.name}

            <div
              className="inspectionInfo"
              onClick={() => {
                viewInfo(inspectionData[0]);
              }}
            >
              🛈
            </div>
          </div>
        )}

        {inspectionData ? (
          inspectionData.map((item) => (
            <div
              className="answeredQuestion"
              key={item.id}
              questionid={item.id}
              questiondata={JSON.stringify(item)}
            >
              <div className="questionView">
                <div
                  className={
                    item.compulsory_flag
                      ? "questionRow compulsory"
                      : "questionRow"
                  }
                >
                  {item.question}
                </div>

                <div className="questionRow answers">
                  {handleInputSelector(item)}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text">No questions to show.</div>
        )}
      </div>
    </div>
  );
}
