import API_ENV from "@config";
import React, { useState, useEffect, useRef } from "react";
import "./deliveryLines.scss";
import Hooks from "../../../Hooks";
import SearchField from "../PurchaseRequests/SearchField";

import OrderDetailsItem from "../PurchaseOrders/OrderDetailsItem";
import AddLine from "./AddLine";
import SidePanelRow from "../../sidePanel/SidePanelComponents/SidePanelRow";

export default function DeliveryLines({
  lines,
  searchForPart,
  selectedPart,
  selectedLine,
  changeLine,
  updatePopup,
  refresh,
}) {
  const [lineItems, updateItems] = useState();
  const [dnDetails, updateDetails] = useState();
  const [displayForm, setDisplay] = useState("none");
  // commenting out "updateValue" as it's not used & to remove unused-vars build warning
  const [lineValue /*updateValue*/] = useState(
    lines && lines[0]
      ? {
          part: lines[0]?.parts,
          qty: lines[0]?.quantityRecieved,
          price: lines[0]?.unitPrice,
          desc: lines[0]?.desc,
        }
      : { part: "", qty: "", price: "", desc: "" },
  );

  const partSearch = useRef(null);
  const quantity = useRef(null);
  const price = useRef(null);
  const description = useRef(null);

  const [part, updatePart] = useState({
    partId: selectedLine?.parts?.id || 0,
    partNo: selectedLine?.parts?.partno || "",
  });

  useEffect(() => {
    updatePart({
      partId: selectedLine?.parts?.id || 0,
      partNo: selectedLine?.parts?.partno || "",
    });
    document
      .querySelector("#sidePanelContainer")
      .setAttribute("lineno", selectedLine?.id);
  }, [selectedLine]);

  useEffect(() => {
    if (!selectedPart) {
      return;
    }
    updatePart(selectedPart);

    if (!partSearch || !partSearch.current) {
      console.error("An error occured finding the part search element.");
      return;
    }
    partSearch.current.value = "";
  }, [selectedPart]);

  useEffect(() => {
    if (!lines || !lines.length) {
      setDisplay("none");
      return;
    }

    setDisplay("flex");

    if (!Hooks.getSidePanelData() || !Hooks.getSidePanelData()?.id) {
      return;
    }

    Hooks.getData(
      `${API_ENV}/dnDetail?DeliveyNoteId=${
        Hooks.getSidePanelData().id
      }&DeliveyNoteLineId=${lines[0].id}`,
      (data) => {
        if (!data) {
          return;
        }

        updateDetails(
          data.map((item) => {
            return <OrderDetailsItem type="delivery" data={item} />;
          }),
        );
      },
    );
  }, [lines, searchForPart, selectedPart]);

  useEffect(() => {
    function lineItem(line, index) {
      const className = index === 0 ? "lineItem selectedLine" : "lineItem";
      //
      function lineClicked(target) {
        if (!target) {
          return;
        }

        document.querySelectorAll(".selectedLine").forEach((item) => {
          item.classList.remove("selectedLine");
        });

        target.classList.add("selectedLine");

        changeLine(line);
        document
          .querySelector("#sidePanelContainer")
          .setAttribute("lineno", line?.id);

        Hooks.getData(
          `${API_ENV}/dnDetail?DeliveyNoteId=${
            Hooks.getSidePanelData().id
          }&DeliveyNoteLineId=${line.id}`,
          (data) => {
            if (!data) {
              return;
            }

            updateDetails(
              data.map((item) => {
                return <OrderDetailsItem type="delivery" data={item} />;
              }),
            );
          },
        );
      }

      return (
        <div
          className={className}
          lineno={line.id}
          onClick={(e) => {
            lineClicked(e.target);
          }}
        >
          {`${line.lineNo}) ${line.parts.partno} x ${line.quantityReceived}`}
        </div>
      );
    }

    if (!lines || !lines.length) {
      return;
    }

    updateItems(
      lines.map((line, index) => {
        return lineItem(line, index);
      }),
    );
  }, [lines, changeLine]);

  function modifyLine() {
    if (!selectedLine || !selectedLine.id) {
      return;
    }

    const line = selectedLine;

    // line.partsRefId = ''
    line.quantityReceived = document.querySelector(
      "#receivedquantityInput",
    ).value;
    line.unitPrice = document.querySelector("#unitpriceInput").value;
    line.desc = document.querySelector("#descriptionInput").value;

    Hooks.sendData(line, `${API_ENV}/dnLine/${line.id}`, "PUT");
  }

  function addLine() {
    updatePopup(
      <AddLine updatePopup={updatePopup} searchForPart={searchForPart} />,
    );
  }

  function deleteLine() {
    const line = document.querySelector(".selectedLine").getAttribute("lineno");
    Hooks.sendData({}, `${API_ENV}/dnLine/${line}`, "DELETE", () => {
      document.querySelector(".currentTab").click();
    });
  }

  function search() {
    const value = partSearch?.current.value;

    if (!value) {
      return;
    }

    Hooks.getData(`${API_ENV}/parts/search?searchstring=${value}`, (data) => {
      searchForPart(data);
    });
  }

  return (
    <div id="doLines">
      <div id="doLinesHeader">Delivery Order Lines</div>
      <div id="lineItems">{lineItems}</div>
      <div id="doLinesForm">
        <div id="linesForm" key={lineValue} style={{ display: displayForm }}>
          <div className="partLineSearch">
            <div className="sidePanelKey">Search for Part</div>
            <SearchField
              ref={partSearch}
              type="searchForPart"
              placeholder="part"
              dataId={part?.partId}
              defaultText={part?.partNo}
              searchClicked={search}
            />
          </div>
          <SidePanelRow
            ref={quantity}
            title="Received Quantity"
            value={selectedLine?.quantityReceived}
          />
          <SidePanelRow
            ref={price}
            title="Unit Price"
            value={selectedLine?.unitPrice}
          />
          <SidePanelRow
            ref={description}
            title="Description"
            value={selectedLine?.desc}
          />
        </div>

        <div className="dnLineFooter">
          <div className="modifyLine" onClick={addLine}>
            Add Line
          </div>
          <div className="modifyLine" onClick={modifyLine}>
            Save Line
          </div>
          <div className="modifyLine deleteLine" onClick={deleteLine}>
            Delete Line
          </div>
        </div>

        <div
          style={{ textAlign: "center", marginTop: "10px", fontWeight: "600" }}
        >
          Delivery Details
        </div>
        <div id="detailsForm">{dnDetails}</div>
      </div>
    </div>
  );
}
