import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DropdownList from "../../../../ui/DropdownList";
import SidePanelFormRow from "../../../../ui/SidePanelFormRow";
import Input from "../../../../ui/Input";
import Form from "../../../../ui/Form";
import Hooks from "../../../../Hooks";

const schema = yup
  .object({
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
    smsUpdate: yup.bool().required("SMS Update is required"),
    clientId: yup.string().required("Client is required"),
  })
  .required();

export default function ViewActionEvent({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      smsUpdate: "",
      clientId: "",
    },
  });

  const { data } = state;
  // const { openModal } = useModal();

  // useEffect(() => {
  //   console.log("data:", data);
  // }, [data]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log(values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/clients`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });
  }, []);

  // Fill form via state
  useEffect(() => {
    if (data) {
      reset({
        id: data.id,
        name: data.name,
        description: data.description,
        smsUpdate: data.smsUpdate,
        clientId: data.client.id,
      });
    }
  }, [data, reset]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          cleanURL();

          setIsButtonDisabled(false);
        }

        console.log("dataSubmit", data);

        Hooks.sendData(
          data,
          `${API_ENV}/${apiUrl}/${data.id}`,
          "PUT",
          thenFunction,
        );
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, cleanURL, apiUrl],
  );

  const handleArchive = useCallback(() => {
    archive();
  }, [archive]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Submit Form",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleSubmit(handleOpenModal) },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <Input label={"Name"} name="name" control={control} errors={errors} />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <Input
            label={"Description"}
            name="description"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"SMS Update"}
            name="smsUpdate"
            control={control}
            errors={errors}
            dropdownData={[
              { id: true, name: "Yes" },
              { id: false, name: "No" },
            ]}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Client"}
            name="clientId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
          />
        </SidePanelFormRow>
      </div>

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
