import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import Hooks from "../../Hooks";

import { HiTrash } from "react-icons/hi2";
import Row from "../../ui/Row";
import Button from "../../ui/Button";
import Form from "../../ui/Form";
import FormRow from "../../ui/FormRow";
import Heading from "../../ui/Heading";
import HR from "../../ui/HR";
import DropdownListOldNonRHF from "@ui/DropdownListOldNonRHF";
import InputOldNonRHF from "@ui/InputOldNonRHF";

const initialFormData = [
  {
    keyName: "",
    operator: "",
    value: "",
    dependencies: {
      propertyType: "",
      sourceApiTableId: "",
      apiId: "",
    },
  },
];

const initialOrderData = {
  orderBy: "0",
  letterPrintSetupId: "0",
  savedFilters: "0",
};

const initialDynamicDropdownOptions = {
  operatorDropdownValues: {
    string: [],
    number: [],
    boolean: [],
    dateTime: [],
  },
  valueDropdownValues: {
    api: [],
  },
};
const initialFilterInfo = {
  filterDeleteId: "",
  filterDeleteName: "",
  filterSaveId: "",
  filterSaveName: "",
  selectedFilterId: "",
  selectedFilterName: "",
  savedFilters: [],
  saveAs: 0,
};

export default function ReportFilter({
  setOutsideFormData,
  reportId,
  reportTypeId,
  selectedFilterId,
  setOutsideFormData2,
}) {
  const [formData, setFormData] = useState(initialFormData);
  const [orderData, setOrderData] = useState(initialOrderData);
  const [error, setError] = useState(null);

  const [filterInfo, setFilterInfo] = useState(initialFilterInfo);

  const [dropdownData, setDropdownData] = useState({
    orderByOptions: [],
    letterPrintOptions: [],
  });
  const [confirmationScreen, setConfirmationScreen] = useState(false);
  const [dynamicDropdownOptions, setDynamicDropdownOptions] = useState(
    initialDynamicDropdownOptions,
  );

  // useEffect(() => {
  //   // console.log("orderByOptions:", orderByOptions);
  //   // console.log("orderData:", orderData);
  //   console.log("formData:", formData);
  //   // console.log("filterInfo:", filterInfo);
  //   // console.log("selectedFilterId:", selectedFilterId);
  //   // console.log("dynamicDropdownOptions:", dynamicDropdownOptions);
  //   // console.log("reportId:", reportId);
  //   // console.log("reportTypeId:", reportTypeId);
  //   // console.log("error:", error);
  // }, [formData]);

  // Populating order by & key name dropdown lists here. Both use dropdownData.orderByOptions.
  useEffect(() => {
    if (reportId) {
      Hooks.getData(
        `${API_ENV}/runReportColumnField/reportid/${reportId}?onlySearchable=true`,
        (data) => {
          // console.log("orderByOptions: ", data);
          setDropdownData((prevData) => ({
            ...prevData,
            orderByOptions: data,
          }));
        },
      );

      // Saved Filters
      Hooks.getData(
        `${API_ENV}/runReportfilter/ShowOrderByAndSearchFilterMessage/${reportId}`,
        (data) => {
          // console.log("savedFilters: ", data);

          let matchingFilter;

          // Check if selectedFilterId is present in the data
          if (selectedFilterId) {
            matchingFilter = data.find(
              (filter) => filter.id === selectedFilterId,
            );

            // If a matching filter is found, remove it and place it at the start of the array
            if (matchingFilter) {
              data = data.filter((filter) => filter.id !== selectedFilterId);
              data.unshift(matchingFilter);
            }
          }

          // console.log("data:", data);
          // console.log("matchingFilter:", matchingFilter);

          setFilterInfo((prevData) => ({
            ...prevData,
            savedFilters: data,
            selectedFilterId: matchingFilter?.id || "",
            selectedFilterName: matchingFilter?.filterName || "",
          }));
        },
      );
    }
  }, [reportId, selectedFilterId]);

  useEffect(() => {
    if (reportTypeId === 1) {
      Hooks.getData(`${API_ENV}/LetterPrintSetup/${reportId}`, (data) => {
        // console.log(data);
        if (data && typeof data === "object" && data.id && data.letterName) {
          setDropdownData((prevData) => ({
            ...prevData,
            letterPrintOptions: [
              {
                id: data.id,
                letterName: data.letterName,
              },
            ],
          }));
        }
      });
    }
  }, [reportId, reportTypeId]);

  //* Populating the formData fields with the information if it exists here
  useEffect(() => {
    let url;
    if (dropdownData.orderByOptions) {
      if (filterInfo.selectedFilterName) {
        url = `${API_ENV}/runReportfilter/runReportId/${reportId}?FilterName=${filterInfo.selectedFilterName}`;
      } else {
        url = `${API_ENV}/runReportfilter/runReportId/${reportId}`;
      }

      Hooks.getData(
        url,
        (data) => {
          // console.log("Report Filter Settings from API:", data);

          if (data && typeof data === "object" && data.contents) {
            setOrderData(() => ({
              orderBy: data.sortColumnFieldId,
              letterPrintSetupId: data.letterPrintSetupId || "",
              letterPrintSetup: data.letterPrintSetup.letterName || "",
            }));

            const transformedFormData = data.contents.map((contentsItem) => {
              const orderByOption = dropdownData.orderByOptions.find(
                (orderByItem) => orderByItem.keyName === contentsItem.keyName,
              );

              // console.log("orderByOption:", orderByOption);

              return {
                keyName: contentsItem.keyName,
                operator: contentsItem.operator,
                value: contentsItem.value,
                dependencies: {
                  propertyType: orderByOption ? orderByOption.propertyType : "",
                  sourceApiTableId: orderByOption
                    ? orderByOption.sourceApiTableId
                    : "",
                  apiId: orderByOption ? orderByOption.api.id : "",
                },
              };
            });

            // console.log("transformedFormData: ", transformedFormData);

            setFormData(
              transformedFormData.length > 0
                ? transformedFormData
                : initialFormData,
            );
          } else {
            // Handle cases where data.contents is undefined or data structure is unexpected
            // console.error("Unexpected data structure:", data);
            setFormData(initialFormData);
          }
        },
        (error) => {
          // Optional: handle errors if the API fails
          console.error("API error:", error);
        },
      );
    }
  }, [reportId, dropdownData.orderByOptions, filterInfo.selectedFilterName]);

  // API calls for dropdown data - API calls cant be made witin the renderField functions as you cant return JSX out of the Hooks.getData callback function.
  // renderOperatorField API calls
  useEffect(() => {
    if (formData) {
      formData.forEach((row, index) => {
        if (row.dependencies.propertyType === "String") {
          Hooks.getData(
            `${API_ENV}/runReportColumnField/GetOperator/String`,
            (data) => {
              // console.log(data);
              setDynamicDropdownOptions((prevData) => ({
                ...prevData,
                operatorDropdownValues: {
                  ...prevData.operatorDropdownValues,
                  string: data,
                },
              }));
            },
          );
        } else if (
          row.dependencies.propertyType === "Number" &&
          row.dependencies.sourceApiTableId === 0
        ) {
          Hooks.getData(
            `${API_ENV}/runReportColumnField/GetOperator/Number`,
            (data) => {
              // console.log(data);
              setDynamicDropdownOptions((prevData) => ({
                ...prevData,
                operatorDropdownValues: {
                  ...prevData.operatorDropdownValues,
                  number: data,
                },
              }));
            },
          );
        } else if (
          row.dependencies.propertyType === "Number" &&
          row.dependencies.sourceApiTableId > 0
        ) {
          // console.log("yes");
          Hooks.getData(
            `${API_ENV}/runReportColumnField/GetOperator/Boolean`,
            (data) => {
              // console.log(data);
              setDynamicDropdownOptions((prevData) => ({
                ...prevData,
                operatorDropdownValues: {
                  ...prevData.operatorDropdownValues,
                  boolean: data,
                },
              }));
            },
          );
        } else if (row.dependencies.propertyType === "Boolean") {
          Hooks.getData(
            `${API_ENV}/runReportColumnField/GetOperator/Boolean`,
            (data) => {
              // console.log(data);
              setDynamicDropdownOptions((prevData) => ({
                ...prevData,
                operatorDropdownValues: {
                  ...prevData.operatorDropdownValues,
                  boolean: data,
                },
              }));
            },
          );
        } else if (row.dependencies.propertyType === "DateTime") {
          Hooks.getData(
            `${API_ENV}/runReportColumnField/GetOperator/DateTime`,
            (data) => {
              // console.log(data);
              setDynamicDropdownOptions((prevData) => ({
                ...prevData,
                operatorDropdownValues: {
                  ...prevData.operatorDropdownValues,
                  dateTime: data,
                },
              }));
            },
          );
        }
      });
    }
  }, [formData]);

  //*  API call for the renderValueField API JSX condition
  useEffect(() => {
    if (formData) {
      formData.forEach((row, index) => {
        if (
          row.dependencies.propertyType === "Number" &&
          row.dependencies.sourceApiTableId > 0
        ) {
          Hooks.getData(
            `${API_ENV}/runReportColumnField/RetrieveTableData/${row.dependencies.sourceApiTableId}`,
            (data) => {
              // console.log("runReportColumnField/RetrieveTableData: ", data);

              const result = Object.keys(data).map((key) => ({
                id: key,
                name: data[key],
              }));

              // console.log("result", result);

              if (data) {
                setDynamicDropdownOptions((prevData) => ({
                  ...prevData,
                  valueDropdownValues: {
                    ...prevData.valueDropdownValues,
                    api: result,
                  },
                }));
              } else {
                setError(data);
                console.error(data);
              }
            },
          );
        }
      });
    }
  }, [formData]);

  function renderOperatorField(propertyType, sourceApiTableId, index) {
    // console.log("propertyType", propertyType);
    // console.log("sourceApiTableId", sourceApiTableId);

    if (propertyType === "String" || propertyType === "") {
      return dynamicDropdownOptions.operatorDropdownValues.string.map(
        (item) => (
          <option key={item.id} className={"option"} value={item.operatorSign}>
            {item.operatorSign}
          </option>
        ),
      );
    } else if (propertyType === "Number" && sourceApiTableId === 0) {
      return dynamicDropdownOptions.operatorDropdownValues.number.map(
        (item) => (
          <option key={item.id} className={"option"} value={item.operatorSign}>
            {item.operatorSign}
          </option>
        ),
      );
    } else if (propertyType === "Number" && sourceApiTableId > 0) {
      return dynamicDropdownOptions.operatorDropdownValues.boolean.map(
        (item) => (
          <option key={item.id} className={"option"} value={item.operatorSign}>
            {item.operatorSign}
          </option>
        ),
      );
    } else if (propertyType === "Boolean") {
      return dynamicDropdownOptions.operatorDropdownValues.boolean.map(
        (item) => (
          <option key={item.id} className={"option"} value={item.operatorSign}>
            {item.operatorSign}
          </option>
        ),
      );
    } else if (propertyType === "DateTime") {
      return dynamicDropdownOptions.operatorDropdownValues.dateTime.map(
        (item) => (
          <option key={item.id} className={"option"} value={item.operatorSign}>
            {item.operatorSign}
          </option>
        ),
      );
    }
  }

  //* Function to render JSX of the value field based on formData.map(row) "row.dependencies.propertyType", "row.dependencies.sourceApiTableId" & "index"
  function renderValueField(propertyType, sourceApiTableId, index) {
    if (propertyType === "String" || propertyType === "") {
      return (
        <input
          id={`value_${index}`}
          type="text"
          className="input extraSmall"
          value={formData[index].value}
          onChange={(e) => handleInputChange(e, index, "value")}
        />
      );
    } else if (propertyType === "Number" && sourceApiTableId === 0) {
      return (
        <input
          id={`value_${index}`}
          type="number"
          className="input extraSmall"
          value={formData[index].value}
          onChange={(e) => handleInputChange(e, index, "value")}
        />
      );
    } else if (propertyType === "Number" && sourceApiTableId > 0) {
      // * This is rendering the JSX of dynamicDropdownOptions.valueDropdownValues.api
      return (
        <select
          id={`value_${index}`}
          className="select extraSmall"
          value={formData[index].value}
          onChange={(e) => handleInputChange(e, index, "value")}
        >
          <option value="">Select option</option>
          {dynamicDropdownOptions.valueDropdownValues.api.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      );
    } else if (propertyType === "Boolean") {
      return (
        <select
          id={`value_${index}`}
          className="select extraSmall"
          value={formData[index].value}
          onChange={(e) => handleInputChange(e, index, "value")}
        >
          <option value="">Select option</option>
          <option value="True">True</option>
          <option value="False">False</option>
        </select>
      );
    } else if (propertyType === "DateTime") {
      return (
        <input
          id={`value_${index}`}
          type="date"
          className="input small center"
          value={formData[index].value}
          onChange={(e) => handleInputChange(e, index, "value")}
        />
      );
    }
  }

  // Handle Functions
  const handleAddRow = () => {
    // setError(null);
    setFormData([
      ...formData,
      {
        keyName: "",
        operator: "",
        value: "",
        dependencies: {
          propertyType: "",
          sourceApiTableId: "",
          apiId: "",
        },
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    // Prevent deleting the first row
    // if (index === 0) {
    //   return;
    // }

    const updatedData = [...formData];
    updatedData.splice(index, 1);
    setFormData(updatedData);
  };

  const handleInputChange = (e, index, field) => {
    const updatedData = [...formData];
    updatedData[index][field] = e.target.value;

    // Change other fields within formData based on custom attributes we set in the dropdown array
    if (field === "keyName") {
      const selectedOption = e.target.options
        ? e.target.options[e.target.selectedIndex]
        : null;

      const selectedPropertyType = selectedOption
        ? selectedOption.getAttribute("data-propertytype")
        : "";

      const selectedSourceApiTableId = selectedOption
        ? Number(selectedOption.getAttribute("data-sourceapitableid"))
        : "";

      updatedData[index].dependencies.propertyType = selectedPropertyType;
      updatedData[index].dependencies.sourceApiTableId =
        selectedSourceApiTableId;

      updatedData[index].value = ""; // Clears the Value input field when keyname changes
      setError(null);
    }

    setFormData(updatedData);
  };

  // Saving
  function handleSaveFilter() {
    const contents = formData.map((item) => ({
      keyName: item.keyName,
      operator: item.operator,
      value: item.value,
    }));
    // console.log("contents", contents);

    let filterObject;

    if (reportTypeId === 1) {
      //PDF - enable Job Number
      filterObject = {
        filterName: filterInfo.filterSaveName,
        staffId: Hooks.getCookie("userId"),
        sortColumnFieldId: orderData.orderBy,
        letterPrintSetupId: orderData.letterPrintSetupId || 0,
        runReportListId: reportId,
        contents: contents,
      };
    } else {
      // Non-PDF - dont send Job Number
      filterObject = {
        filterName: filterInfo.filterSaveName,
        staffId: Hooks.getCookie("userId"),
        sortColumnFieldId: orderData.orderBy,
        runReportListId: reportId,
        contents: contents,
      };
    }
    // console.log("filterObject", filterObject);

    function thenFunction(response) {
      if (response.ok) {
        Hooks.getData(
          `${API_ENV}/runReportfilter/ShowOrderByAndSearchFilterMessage/${reportId}`,
          (data) => {
            // console.log("savedFilters: ", data);
            setFilterInfo((prevData) => ({
              ...prevData,
              savedFilters: data,
            }));

            setOutsideFormData2((prevData) => ({
              ...prevData,
              savedFilters: data,
              selectedFilterId: data[0].id,
              selectedFilterName: data[0].filterName,
            }));
          },
        );

        Hooks.getData(
          `${API_ENV}/runReportfilter/ShowOrderByAndSearchFilterMessage/${reportId}`,
          (data) => {
            // console.log(data);
            if (data && data.length > 0) {
              const firstItem = data[0];

              if (setOutsideFormData) {
                setOutsideFormData((prevData) => ({
                  ...prevData,
                  letterPrint: firstItem.letterPrintSetup.letterName,
                  orderBy: firstItem.displayOrderBy,
                  searchFilter: firstItem.displaySearchFilter,
                  dependencies: {
                    ...prevData.dependencies,
                    letterPrintId: firstItem.letterPrintSetupId,
                  },
                }));
              }
            }
          },
        );
        setConfirmationScreen(false);
      }
    }

    Hooks.sendData(
      filterObject,
      `${API_ENV}/runReportfilter`,
      "POST",
      thenFunction,
    );
  }

  function handleDeleteFilter() {
    function thenFunction(response) {
      if (response.ok) {
        Hooks.getData(
          `${API_ENV}/runReportfilter/ShowOrderByAndSearchFilterMessage/${reportId}`,
          (data) => {
            // console.log("savedFilters: ", data);
            setFilterInfo((prevData) => ({
              ...prevData,
              savedFilters: data,
            }));

            setOutsideFormData2((prevData) => ({
              ...prevData,
              savedFilters: data,
              selectedFilterId: data[0].id,
              selectedFilterName: data[0].filterName,
            }));
          },
        );

        if (setOutsideFormData) {
          setOutsideFormData((prevData) => ({
            ...prevData,
            letterPrint: "",
            orderBy: "",
            searchFilter: "",
          }));
        }

        setOrderData(initialOrderData);
        setFormData(initialFormData);
        setConfirmationScreen(false);
      }
    }

    Hooks.sendData(
      reportId,
      `${API_ENV}/runReportfilter/runReportId/${reportId}?FilterName=${filterInfo.filterDeleteName}`,
      "DELETE",
      thenFunction,
    );
  }

  return (
    <Form type="modalForm">
      {!reportId && (
        <FormRow type="horizontal">
          <p>
            Please select a report using the dropdown list provided to enable
            this filter.
          </p>
        </FormRow>
      )}

      {reportId && (
        <>
          {!confirmationScreen && (
            <>
              {/* //* Filter Selection */}
              <Row>
                <Heading as="h2">Filter Selection:</Heading>
                <Row></Row>
              </Row>

              <FormRow position={"center"}>
                <label>Saved Filters:</label>
                <select
                  className="select extraSmall"
                  value={filterInfo.selectedFilterId}
                  onChange={(e) => {
                    const selectedOption =
                      e.target.options[e.target.selectedIndex];
                    const selectedFilterName =
                      selectedOption.getAttribute("data-filtername");
                    setFilterInfo((prevData) => ({
                      ...prevData,
                      selectedFilterId: Number(e.target.value),
                      selectedFilterName: selectedFilterName,
                    }));
                  }}
                >
                  {/* <option value="">Select option</option> */}
                  {Array.isArray(filterInfo.savedFilters)
                    ? filterInfo.savedFilters.map((item) => (
                        <option
                          key={item.id}
                          value={item.id}
                          data-filtername={item.filterName}
                        >
                          {item.filterName}
                        </option>
                      ))
                    : null}
                </select>
              </FormRow>

              <HR />

              {/* //* Filter Settings */}
              <Row>
                <Heading as="h2">Filter Settings:</Heading>
                <Row></Row>
              </Row>

              <Row>
                <Heading as="h5">Order Criteria:</Heading>
              </Row>

              <FormRow position={"center"}>
                <DropdownListOldNonRHF
                  label={"Order By:"}
                  size="extraSmall"
                  customKeyName={"orderBy"}
                  dropdownData={dropdownData.orderByOptions}
                  formData={orderData}
                  setFormData={setOrderData}
                  optionName={"keyName"}
                  firstOptionValue={0}
                />

                {reportTypeId === 1 && (
                  <>
                    <DropdownListOldNonRHF
                      label={"Letter Print:"}
                      size="extraSmall"
                      customKeyName={"letterPrintSetupId"}
                      dropdownData={dropdownData.letterPrintOptions}
                      formData={orderData}
                      setFormData={setOrderData}
                      optionName={"letterName"}
                    />
                  </>
                )}
              </FormRow>

              <HR />

              <Row>
                <Heading as="h5">Search Criteria:</Heading>
                <Button
                  size={"small"}
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddRow(e);
                    setConfirmationScreen(false);
                  }}
                >
                  Add Row
                </Button>
              </Row>

              {formData.map((row, index) => (
                <FormRow position={"center"} key={index}>
                  <label htmlFor={`keyName_${index}`}>Key Name:</label>
                  <select
                    className="select extraSmall"
                    value={row.keyName}
                    onChange={(e) => handleInputChange(e, index, "keyName")}
                  >
                    <option value="">Select option</option>
                    {Array.isArray(dropdownData.orderByOptions)
                      ? dropdownData.orderByOptions.map((item) => (
                          <option
                            key={item.id}
                            value={item.keyName}
                            data-propertytype={item.propertyType}
                            data-sourceapitableid={item.sourceApiTableId}
                          >
                            {item.keyName}
                          </option>
                        ))
                      : null}
                  </select>

                  {!error && (
                    <>
                      <label htmlFor={`operator_${index}`}>Operator:</label>
                      <select
                        className="select extraSmall"
                        value={row.operator}
                        onChange={(e) =>
                          handleInputChange(e, index, "operator")
                        }
                      >
                        <option value="">Select option</option>
                        {renderOperatorField(
                          row.dependencies.propertyType,
                          row.dependencies.sourceApiTableId,
                          index,
                        )}
                      </select>

                      <label htmlFor={`value_${index}`}>Value:</label>
                      {renderValueField(
                        row.dependencies.propertyType,
                        row.dependencies.sourceApiTableId,
                        index,
                      )}
                    </>
                  )}

                  <Button
                    type="danger"
                    // size={"small"}
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteRow(index);
                    }}
                  >
                    <HiTrash />
                  </Button>
                </FormRow>
              ))}
            </>
          )}
        </>
      )}

      {error && (
        <>
          <HR />

          <div>
            <p>
              Error: {"An error occurred, please make a different selection."}
            </p>
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </div>
        </>
      )}

      {reportId && (
        <>
          {confirmationScreen && (
            <>
              {/* //* Save Filter Screen */}
              {confirmationScreen === "save" && (
                <>
                  <Row>
                    <Heading as="h5">
                      Please select if you are saving a new or existing filter
                      profile.
                    </Heading>
                  </Row>

                  <FormRow position={"center"}>
                    <label>Save As:</label>
                    <select
                      className="select extraSmall"
                      value={filterInfo.saveAs}
                      onChange={(e) => {
                        setFilterInfo((prevData) => ({
                          ...prevData,
                          saveAs: Number(e.target.value),
                        }));
                      }}
                    >
                      <option value="0">New Profile</option>
                      <option value="1">Existing Profile</option>
                    </select>
                  </FormRow>

                  {filterInfo.saveAs === 0 ? (
                    <FormRow position={"center"}>
                      <InputOldNonRHF
                        label={"Filter Name:"}
                        size="small"
                        customKeyName={"filterSaveName"}
                        formData={filterInfo}
                        setFormData={setFilterInfo}
                      />
                    </FormRow>
                  ) : (
                    <FormRow position={"center"}>
                      <label>Existing Filters:</label>
                      <select
                        className="select extraSmall"
                        value={filterInfo.filterSaveId}
                        onChange={(e) => {
                          const selectedOption =
                            e.target.options[e.target.selectedIndex];
                          const selectedFilterName =
                            selectedOption.getAttribute("data-filtername");

                          setFilterInfo((prevData) => ({
                            ...prevData,
                            filterSaveId: Number(e.target.value),
                            filterSaveName: selectedFilterName,
                          }));
                        }}
                      >
                        {/* <option value="">Select option</option> */}
                        {Array.isArray(filterInfo.savedFilters)
                          ? filterInfo.savedFilters.map((item) => (
                              <option
                                key={item.id}
                                value={item.id}
                                data-filtername={item.filterName}
                              >
                                {item.filterName}
                              </option>
                            ))
                          : null}
                      </select>
                    </FormRow>
                  )}

                  <HR />

                  <Row type="center">
                    <Button
                      size="small"
                      type="secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmationScreen(false);
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      size="small"
                      type="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSaveFilter();
                      }}
                    >
                      Save
                    </Button>
                  </Row>
                </>
              )}

              {/* //* Delete Filter Screen */}
              {confirmationScreen === "delete" && (
                <>
                  <Row>
                    <Heading as="h5">
                      Please select which filter profile you want to delete.
                    </Heading>
                  </Row>

                  <FormRow position={"center"}>
                    <label>Existing Filters:</label>
                    <select
                      className="select extraSmall"
                      value={filterInfo.filterDeleteId}
                      onChange={(e) => {
                        const selectedOption =
                          e.target.options[e.target.selectedIndex];
                        const selectedFilterName =
                          selectedOption.getAttribute("data-filtername");

                        setFilterInfo((prevData) => ({
                          ...prevData,
                          filterDeleteId: Number(e.target.value),
                          filterDeleteName: selectedFilterName,
                        }));
                      }}
                    >
                      <option value="">Select option</option>
                      {Array.isArray(filterInfo.savedFilters)
                        ? filterInfo.savedFilters.map((item) => (
                            <option
                              key={item.id}
                              value={item.id}
                              data-filtername={item.filterName}
                            >
                              {item.filterName}
                            </option>
                          ))
                        : null}
                    </select>
                  </FormRow>

                  <HR />

                  <Row type="center">
                    <Button
                      size="small"
                      type="secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmationScreen(false);
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      size="small"
                      type="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDeleteFilter();
                      }}
                    >
                      Delete
                    </Button>
                  </Row>
                </>
              )}
            </>
          )}
        </>
      )}

      {/* //* Save & Delete Buttons */}
      {!confirmationScreen && (
        <>
          <HR />

          <FormRow position={"center"}>
            <Button
              size={"small"}
              onClick={(e) => {
                e.preventDefault();
                setConfirmationScreen("save");
                setFilterInfo((prevData) => ({
                  ...prevData,
                  saveAs: 0,
                  filterSaveId: "",
                  filterSaveName: "",
                }));
              }}
            >
              Save Filter
            </Button>

            <Button
              size={"small"}
              onClick={(e) => {
                e.preventDefault();
                setConfirmationScreen("delete");
                setFilterInfo((prevData) => ({
                  ...prevData,
                  filterDeleteId: "",
                  filterDeleteName: "",
                }));
              }}
            >
              Delete Filter
            </Button>
          </FormRow>
        </>
      )}
    </Form>
  );
}
