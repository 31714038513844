import API_ENV from "@config";
import React, { useEffect } from "react";

export default function JobNotes({ jobId }) {
  // commenting these two useState as they arent being used & to remove unused-vars build warning
  // const [notes, updateNotes] = useState([]);
  // const [todo, updateTodo] = useState([]);

  useEffect(() => {
    fetch(`${API_ENV}/notes/job/${jobId}`)
      .then((response) => response.json())
      .then((data) => {});
    fetch(`${API_ENV}/todo/job/${jobId}`)
      .then((response) => response.json())
      .then((data) => {});
  }, [jobId]);

  return <p>{jobId} WIP</p>;
}
