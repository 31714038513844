import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";
import DateTimePicker from "@generic/DateTimePicker";
import CollapsibleGroup from "@ui/CollapsibleGroup";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // ! TO DO
    // * BOOLEANS need to be yup.bool()
    field1: yup.string().required("This field is required"),
    // For conditional fields which only appear based on another fields value
    field2: yup.string().when("field1", {
      is: "value",
      then: () => yup.string().required("This field is required"),
      otherwise: () => yup.string().notRequired(),
    }),
  })
  .required();

export default function ViewProgramProcess({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // ! TO DO
      field1: "",
    },
  });

  // const { openModal } = useModal();
  const { data: stateData } = state;

  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: stateData.id,
        // ! TO DO
        // * BOOLEANS need || "" removed!
        field1: stateData.field1 || "",
      });
    }
  }, [stateData, reset]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    // ! TO DO
    Hooks.getData(`${API_ENV}/`, (data) => {
      // console.log("useEffect API 1 data:", data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });
  }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          cleanURL();

          setIsButtonDisabled(false);
        }

        // console.log("apiUrl", apiUrl);
        // console.log("handleSubmitForm data:", data);

        Hooks.sendData(
          data,
          `${API_ENV}/${apiUrl}/${data.id}`,
          "PUT",
          thenFunction,
        );
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, cleanURL, apiUrl],
  );

  const handleArchive = useCallback(() => {
    archive(stateData.id);
  }, [archive, stateData.id]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Submit Form",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          {/* // ! TO DO */}
          <SidePanelFormRow>
            <Input
              label={"Input Field"}
              name="field1"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Dropdown List"}
              name="field2"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Dropdown List 2"}
              name="field3"
              control={control}
              errors={errors}
              dropdownData={[
                { id: 1, name: "PDF" },
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Start Date</label>
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          {/* <SidePanelFormRow>
          <Button
            size={"small"}
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal();
            }}
          >
            Open Modal
          </Button>
        </SidePanelFormRow> */}
        </div>
      </CollapsibleGroup>

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
