import "../styles/ui/HeaderMenu.scss";

import DarkModeToggle from "./DarkModeToggle";
import LogoutButton from "./LogoutButton";

export default function HeaderMenu() {
  return (
    <ul className="headerMenu">
      <li>
        <DarkModeToggle />
      </li>
      {/* <li>
        <ButtonIcon>
          <HiOutlineUser />
        </ButtonIcon>
      </li> */}
      <li>
        <LogoutButton />
      </li>
    </ul>
  );
}
