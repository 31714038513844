import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import Hooks from "../../../Hooks";
import "./stock.scss";
import NewDropdown from "../../generic/NewDropdown";

export default function StockPopup({ type, closeClicked }) {
  const [content, updateContent] = useState();
  const [stockLocationUrl, updateStockLocation] = useState();

  useEffect(() => {
    if (!type) {
      return;
    }

    function warehouseChanged(warehouse) {
      if (!warehouse || !warehouse?.id) {
        return;
      }

      updateStockLocation(
        `${API_ENV}/stockLocation?WarehouseID=${warehouse.id}`,
      );
    }

    function popupInput(key) {
      let input = (
        <input id={`${Hooks.conditionalString(key)}Input`} type="text" />
      );
      let formattedKey = Hooks.conditionalString(key);
      const data = Hooks.getSidePanelData();

      if (formattedKey === "fromwarehouse/stocklocation") {
        input = (
          <input
            id={`${Hooks.conditionalString(key)}Input`}
            type="text"
            value={data?.warehouse?.name}
          />
        );
      } else if (formattedKey === "selectedstock") {
        input = (
          <input
            id={`${Hooks.conditionalString(key)}Input`}
            type="text"
            value={data.id}
          />
        );
      } else if (
        formattedKey.includes("warehouse") ||
        formattedKey.includes("van")
      ) {
        input = (
          <NewDropdown
            type="warehouse"
            onChange={(e) => {
              warehouseChanged(e);
            }}
          />
        );
      } else if (formattedKey.includes("stocklocation")) {
        input = (
          <NewDropdown type="stocklocation" customUrl={stockLocationUrl} />
        );
      }

      return (
        <div className="popupInput">
          <div className="popupKey">{key}</div>
          {input}
        </div>
      );
    }

    switch (type) {
      case "1": // Allocate Stock for PR
        updateContent(
          <React.Fragment>
            {popupInput("Selected Stock")}
            {popupInput("Purchase Request ID")}
          </React.Fragment>,
        );
        break;
      case "2": // Move Stock to Van
        updateContent(
          <React.Fragment>
            {popupInput("Selected Stock")}
            {popupInput("Van Name")}
          </React.Fragment>,
        );
        break;
      case "3": // Add Allocated Parts from Van to Job
        updateContent(
          <React.Fragment>
            {popupInput("Selected Stock")}
            {popupInput("From Van")}
            {popupInput("Job")}
          </React.Fragment>,
        );
        break;
      case "4": // Return Parts from Job to Van
        updateContent(<React.Fragment>{/* TBC */}</React.Fragment>);
        break;
      case "5": // Return Parts from van to Warehouse
        updateContent(
          <React.Fragment>
            {popupInput("Selected Stock")}
            {popupInput("From Van")}
            {popupInput("Warehouse")}
            {popupInput("Stock Location")}
          </React.Fragment>,
        );
        break;
      case "6": // Assign Job to a stock in Van
        updateContent(
          <React.Fragment>
            {popupInput("Selected Stock")}
            {popupInput("From Van")}
            {popupInput("Assigned Job")}
          </React.Fragment>,
        );
        break;
      case "7": // Move Stock between Warehouse
        updateContent(
          <React.Fragment>
            {popupInput("Selected Stock")}
            {popupInput("From Warehouse / Stock Location")}
            {popupInput("To Warehouse")}
            {popupInput("Stock Location")}
          </React.Fragment>,
        );
        break;
      default:
        return;
    }
  }, [type, stockLocationUrl]);

  function confirm(type) {
    function close(response) {
      if (response.ok && typeof closeClicked === "function") {
        closeClicked();
      }
    }

    switch (type) {
      case "1":
        const prId = document.querySelector("#purchaserequestidInput").value;

        if (!prId) {
          return;
        }

        Hooks.sendData(
          {},
          `${API_ENV}/purchaseRequest/AllocateStockforPR/${prId}`,
          "PUT",
          (response) => {
            close(response);
          },
        );
        break;
      case "2":
        const vanId = document.querySelector("#vannameInput").value;

        if (!vanId) {
          return;
        }

        Hooks.sendData(
          {},
          `${API_ENV}/stock/MoveStockToVan/${vanId}`,
          "PUT",
          (response) => {
            close(response);
          },
        );
        break;
      case "3":
        // ${API_ENV}/stock/AddAllocatedPartsFromVanToJob/vanid/jobid
        break;
      case "4":
        // ${API_ENV}/stock/MergeStock
        break;
      case "5":
        const van = document
          .querySelector("#warehouseDropdown")
          .getAttribute("data");
        const stockLocationId = document
          .querySelector("#stocklocationDropdown")
          .getAttribute("data");
        const stock = document.querySelector("#selectedstockInput").value;

        Hooks.sendData(
          {},
          `${API_ENV}/${stock}/ReturnPartsFromVanToWH/${van}/${stockLocationId}`,
          "PUT",
          (response) => {
            close(response);
          },
        );
        break;
      case "6":
        const vanid = document
          .querySelector("#warehouseDropdown")
          .getAttribute("data");
        const jobId = document.querySelector("#assignedjobInput").value;
        Hooks.sendData(
          {},
          `${API_ENV}/stock/AddAllocatedPartsFromVanToJob/${vanid}/${jobId}`,
          "PUT",
          (response) => {
            close(response);
          },
        );
        break;
      case "7":
        const stockLocation = document
          .querySelector("#stocklocationDropdown")
          .getAttribute("data");
        Hooks.sendData(
          {},
          `${API_ENV}/stock/ChangeStockLocationForStock/${stockLocation}`,
          "PUT",
          (response) => {
            close(response);
          },
        );
        break;
      default:
        break;
    }
  }

  return (
    <div id="stockPopup">
      <div id="popupContent">{content}</div>
      <div id="popupFooter">
        <div
          className="popupButton"
          onClick={() => {
            confirm(type);
          }}
        >
          Confirm
        </div>
        <div className="popupButton" onClick={closeClicked}>
          Close
        </div>
      </div>
    </div>
  );
}
