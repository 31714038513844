import "../styles/ui/SessionExpired.scss";

import React from "react";

import Button from "./Button";

export default function SessionExpired({ buttonClicked }) {
  return (
    <div className="sessionContainer">
      <div className="sessionPopup">
        <div className="expiredText">
          Your session has expired please login again.
        </div>

        <Button size={"large"} onClick={buttonClicked}>
          OK
        </Button>
      </div>
    </div>
  );
}
