import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";
import DateTimePicker from "@generic/DateTimePicker";
import SearchBar from "@ui/SearchBar";
import CollapsibleGroup from "@ui/CollapsibleGroup";

const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    property: yup.string().required("Select a property to fill this field."),
    clientName: yup.string().required("Select a property to fill this field."),
    jobTypeRefId: yup.string().required("This field is required"),
    jobStateRefId: yup.string().required("This field is required"),
    // cancelReasonRefId: yup.string().required("This field is required"),
    contractId: yup.string().required("This field is required"),
    // engineerRefId: yup.string().required("This field is required"),
    priorityId: yup.string().required("This field is required"),
    // reference: yup.string().required("This field is required"),
    // scheduledDate: yup.string().required("This field is required"),
    targetDate: yup.string().required("This field is required"),
    // scheduledStartTime: yup.string().required("This field is required"),
    // scheduledEndTime: yup.string().required("This field is required"),

    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function AddJob({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    jobTypeData: [],
    jobStateData: [],
    contractsData: [],
    engineerData: [],
    priorityData: [],
    cancelReasonData: [],
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    // register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      //* Hidden values
      clientRefId: "",
      propertyId: "",
      //* Displayed values
      property: "", // Not in POST data to send
      clientName: "", // Not in POST data to send
      contractId: "",
      engineerRefId: "",
      jobTypeRefId: "",
      jobStateRefId: 0,
      cancelReasonRefId: "",
      reference: "",
      priorityId: "",
      targetDate: "",
      scheduledDate: "",
      scheduledStartTime: "",
      scheduledEndTime: "",
    },
  });
  const clientRefId = watch("clientRefId");
  const propertyId = watch("propertyId");
  const contractId = watch("contractId");
  const jobTypeRefId = watch("jobTypeRefId");
  const jobStateRefId = watch("jobStateRefId");
  const scheduledDate = watch("scheduledDate");

  // const { openModal } = useModal();

  // useEffect(() => {
  //   // console.log("dropdownData:", dropdownData);
  // }, [dropdownData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log(values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    //* Contract - OLD
    // if (clientRefId) {
    //   Hooks.getData(`${API_ENV}/contracts?ClientId=${clientRefId}`, (data) => {
    //     // console.log("contracts", data);
    //     setDropdownData((prevData) => ({
    //       ...prevData,
    //       contractsData: data,
    //     }));
    //   });
    // }

    //* Contract - New
    if (propertyId) {
      Hooks.getData(
        `${API_ENV}/contract/properties/${propertyId}?show_expired_contract=true`,
        (data) => {
          // console.log("contracts", data);
          setDropdownData((prevData) => ({
            ...prevData,
            contractsData: data,
          }));
        }
      );
    }

    //* Engineer
    if (contractId) {
      Hooks.getData(`${API_ENV}/engineer/contract/${contractId}`, (data) => {
        // console.log("Engineers", data);
        setDropdownData((prevData) => ({
          ...prevData,
          engineerData: data,
        }));
      });
    }

    //* Job Type
    if (contractId) {
      Hooks.getData(`${API_ENV}/jobType?ContractId=${contractId}`, (data) => {
        // console.log("jobType", data);
        setDropdownData((prevData) => ({
          ...prevData,
          jobTypeData: data,
        }));
      });
    }

    //* Job State
    if (jobTypeRefId) {
      Hooks.getData(`${API_ENV}/jobState/jobType/${jobTypeRefId}/0`, (data) => {
        console.log("jobState", data);
        setDropdownData((prevData) => ({
          ...prevData,
          jobStateData: data,
        }));
      });
    }

    //* Priority
    Hooks.getData(`${API_ENV}/priority`, (data) => {
      // console.log("priority", data);
      if (data.length > 0) {
        setDropdownData((prevData) => ({
          ...prevData,
          priorityData: data,
        }));
      }
    });

    //* Cancel Reason
    if (jobStateRefId === 8) {
      Hooks.getData(
        `${API_ENV}/contractsAndCancelReason?contractIds=${contractId}&showarchive=false`,
        (data) => {
          // console.log("cancelReason", data);
          setDropdownData((prevData) => ({
            ...prevData,
            cancelReasonData: data,
          }));
        }
      );
    }
  }, [clientRefId, contractId, jobTypeRefId, jobStateRefId, propertyId]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          cleanURL();
          reset();

          setIsButtonDisabled(false);
        }

        let modifiedApiUrl = apiUrl;
        if (apiUrl.includes("add")) {
          modifiedApiUrl = apiUrl.replace("add", "");
        }

        // remove property & clientName as it isn't within API's POST object
        const { id, property, clientName, cancelReasonRefId, ...restData } =
          data;

        // I need to check if cancelReasonRefId has a value & if it doesn't, remove it from data
        if (!cancelReasonRefId || cancelReasonRefId === "") {
          delete restData.cancelReasonRefId;
        } else {
          restData.cancelReasonRefId = cancelReasonRefId;
        }

        // Ensure scheduledDate, scheduledStartTime, and scheduledEndTime have appropriate values
        const scheduledDate = data.scheduledDate || null;

        const scheduledStartTime = scheduledDate
          ? Hooks.combineDateTime(
              scheduledDate,
              data.scheduledStartTime || "00:00"
            )
          : null;

        const scheduledEndTime = scheduledDate
          ? Hooks.combineDateTime(
              scheduledDate,
              data.scheduledEndTime || "00:00"
            )
          : null;

        const transformedData = {
          ...restData,
          scheduledDate,
          scheduledStartTime,
          scheduledEndTime,
        };

        // console.log("data to send", data);
        // console.log("transformedData to send", transformedData);

        Hooks.sendData(
          transformedData,
          `${API_ENV}/${modifiedApiUrl}`,
          "POST",
          thenFunction
        );
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, cleanURL, apiUrl, reset]
  );

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Submit Form",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      // { text: "Open Modal", click: handleSubmit(handleOpenModal) },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SearchBar title={"Property"} type={"property"} setValue={setValue} />

          <SidePanelFormRow type="full">
            <Input
              label={"Property"}
              name="property"
              control={control}
              errors={errors}
              readOnly
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Client"}
              name="clientName"
              control={control}
              errors={errors}
              readOnly
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Contract"}
              name="contractId"
              control={control}
              errors={errors}
              disabled={!clientRefId}
              dropdownData={dropdownData.contractsData}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Engineer"}
              name="engineerRefId"
              control={control}
              errors={errors}
              disabled={!contractId}
              dropdownData={dropdownData.engineerData}
              optionNames={["forename", "surname"]}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Job Type"}
              name="jobTypeRefId"
              control={control}
              errors={errors}
              disabled={!contractId}
              dropdownData={dropdownData.jobTypeData}
              optionName="description"
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Job State"}
              name="jobStateRefId"
              control={control}
              errors={errors}
              disabled={!jobTypeRefId}
              dropdownData={dropdownData.jobStateData}
              optionName="description"
              disableFirstOption={true}
            />
          </SidePanelFormRow>

          {jobStateRefId === 8 && (
            <SidePanelFormRow>
              <DropdownList
                label={"Cancel Reason"}
                name="cancelReasonRefId"
                control={control}
                errors={errors}
                disabled={!jobStateRefId}
                dropdownData={dropdownData.cancelReasonData}
              />
            </SidePanelFormRow>
          )}

          <SidePanelFormRow>
            <Input
              label={"WO"}
              name="reference"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Priority"}
              name="priorityId"
              firstOptionValue={0}
              control={control}
              errors={errors}
              dropdownData={dropdownData.priorityData}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Target Date</label>
            <Controller
              name="targetDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Scheduled Date</label>
            <Controller
              name="scheduledDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Start Time"}
              name="scheduledStartTime"
              type="time"
              control={control}
              errors={errors}
              disabled={!scheduledDate}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"End Time"}
              name="scheduledEndTime"
              type="time"
              control={control}
              errors={errors}
              disabled={!scheduledDate}
            />
          </SidePanelFormRow>
        </div>
      </CollapsibleGroup>

      {/* <Modal name="Example" title={"Title"}>
       
      </Modal> */}
    </Form>
  );
}
