import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import DropdownList from "@ui/DropdownList";
import DateTimePicker from "@generic/DateTimePicker";
import CollapsibleGroup from "@ui/CollapsibleGroup";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    engineerRefId: yup.string().required("This field is required"),
    estimatedArrivalTime: yup.string().required("This field is required"),
    // actualArrivalTime: yup.string().required("This field is required"),
    // departureTime: yup.string().required("This field is required"),
    // loggedAt: yup.string().required("This field is required"),
    scheduledFor: yup.string().required("This field is required"),
    // notes: yup.string().required("This field is required"),

    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function AddVisit({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  setFormState,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      engineerRefId: "",
      estimatedArrivalTime: "",
      actualArrivalTime: "",
      departureTime: "",
      // loggedAt: "",
      scheduledFor: "",
      notes: "",
    },
  });

  // const { openModal } = useModal();

  const sidePanelData = Hooks.getSidePanelData();

  // * UseEffect Hooks: Tracking
  // useEffect(() => {
  //   // console.log("dropdownData:", dropdownData);
  //   console.log("sidePanelData:", sidePanelData);
  // }, [sidePanelData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks: Form/API
  // Dropdown List Data
  useEffect(() => {
    Hooks.getData(
      `${API_ENV}/contractsAndEngineers?contractIds=${sidePanelData.contractId}&showarchive=false`,
      (data) => {
        // console.log("useEffect API 1 data:", data);
        setDropdownData((prevData) => ({
          ...prevData,
          dropdownData1: data,
        }));
      }
    );
  }, [sidePanelData.contractId]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          // cleanURL();
          reset();

          //. Go back to previous form after submission
          Hooks.getData(
            `${API_ENV}/visits?jobIDs=${Hooks.getSidePanelData().id}`,
            (data) => {
              // console.log(data);
              setFormState({ type: "visit", data: { data } });
            }
          );

          setIsButtonDisabled(false);
        }

        const dataObject = {
          ...data,
          jobRefId: Hooks.getSidePanelData().id,
          visitStateRefId: 1, //Default is unbooked for new visit.
          visitNumber: 0,
        };

        // console.log("apiUrl", apiUrl);
        // console.log("modifiedApiUrl", modifiedApiUrl);
        // console.log("handleSubmitForm data:", data);
        // console.log("handleSubmitForm dataObject:", dataObject);

        Hooks.sendData(dataObject, `${API_ENV}/visits`, "POST", thenFunction);
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, reset, setFormState]
  );

  // const handleChangeForm = useCallback(() => {
  //   setFormState({ type: "formNameHere", data: {} });
  // }, [setFormState]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    // cleanURL();
    reset();
  }, [reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Add Visit To Job",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      // { text: "Change Form", click: handleChangeForm },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <DropdownList
              label={"Engineer"}
              name="engineerRefId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
              optionNames={["forename", "surname"]}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Estimated Arrival Time</label>
            <Controller
              name="estimatedArrivalTime"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          {/* <SidePanelFormRow>
            <label>Logged</label>
            <Controller
              name="loggedAt"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow> */}

          <SidePanelFormRow>
            <label>Scheduled</label>
            <Controller
              name="scheduledFor"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Actual Arrival Time</label>
            <Controller
              name="actualArrivalTime"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label>Departure Time</label>
            <Controller
              name="departureTime"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow>

          {/* <SidePanelFormRow type="full">
            <label>Notes</label>
            <textarea
              {...register("notes")}
              rows="5"
              className="input left"
            ></textarea>
            {errors.issueDetail && (
              <span className="fieldError">{errors.issueDetail.message}</span>
            )}
          </SidePanelFormRow> */}
        </div>
      </CollapsibleGroup>
    </Form>
  );
}
