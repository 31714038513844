import "./SearchFilters.scss";
import "../../styles/features/AdvancedFilter.scss";

import React, { useState } from "react";
import Hooks from "../../Hooks";

import FilterRow from "./AdvancedFilterComponents/FilterRow";
import API_ENV from "@config";

export default function AdvancedFilter({ onSearch, onClose }) {
  console.log("AdvancedFilter Triggered");

  const [content, updateContent] = useState([
    <FilterRow key="filterRow_1" text="Search by" />,
  ]);

  function addClicked() {
    const length = document.querySelectorAll(".filterRow").length + 1;
    updateContent(
      content.concat(<FilterRow key={`filterRow_${length}`} text="and" />),
    );
  }

  function onSave() {
    document.querySelector("#afContent").style.display = "none";
    document.querySelector("#afSaveContainer").style.display = "flex";
  }

  function confirmSave() {
    function getContents() {
      let object = {};
      if (document.querySelector(".filterRow")) {
        document.querySelectorAll(".filterRow").forEach((row) => {
          object[row.querySelector(".filterRowType ").value] =
            row.querySelector(".filterRowInput").value;
        });
      }
      return object;
    }

    let filterObject = {
      name: document.querySelector("#afSaveEnterName").value,
      contents: getContents(),
    };

    function thenFunction(response) {
      if (response.ok) {
        saveCancelClicked();
      }
    }

    Hooks.sendData(filterObject, `${API_ENV}/jobfilter`, "POST", thenFunction);
  }

  function saveCancelClicked() {
    document.querySelector("#afContent").style.display = "block";
    document.querySelector("#afSaveContainer").style.display = "none";
  }

  return (
    <div id="afContainer" className="afContainer">
      <div id="afHeader" className="afHeader">
        <div id="afTitle" className="afTitle">
          <h3> Advanced Filter </h3>
        </div>
        <div id="afHeaderButtons" className="afHeaderButtons">
          <div id="afAdd" className="afButton" onClick={addClicked}>
            Add
          </div>
          <div id="afSearch" className="afButton" onClick={onSearch}>
            Search
          </div>
          <div id="afSave" className="afButton" onClick={onSave}>
            Save
          </div>
          <div id="afExit" className="afButton" onClick={onClose}>
            Exit
          </div>
        </div>
      </div>

      <div id="afContent" className="afContent">
        {content}
      </div>
      <div id="afSaveContainer" className="afSaveContainer">
        <input
          type="text"
          id="afSaveEnterName"
          className="afSaveEnterName"
          placeholder="Enter name"
        />
        <div id="afSaveButtons" className="afSaveButtons">
          <div onClick={confirmSave}> Confirm </div>
          <div onClick={saveCancelClicked}> Cancel </div>
        </div>
      </div>
    </div>
  );
}
