import React, { useEffect, useState } from "react";
import DateTimePicker from "../../generic/DateTimePicker";
import Hooks from "../../../Hooks";
import "./ViewPresetFilter.scss";
import API_ENV from "@config";

export default function ViewPresetFilter({ filterId, onClose }) {
  const [content, updateContent] = useState([]);
  const [title, updateTitle] = useState("");

  function clearPopup() {
    document.querySelectorAll(".popupRow input").forEach((input) => {
      input.value = "";
    });
  }

  function applyClicked() {
    function getContents() {
      let object = {};
      if (document.querySelector(".popupRow")) {
        document.querySelectorAll(".popupRow").forEach((row) => {
          const key = Hooks.getFilterKey(
            row.querySelector(".popupKey").textContent,
          );
          let value;
          if (key.includes("date")) {
            value = new Date(
              row.querySelector(".popupInput input").value,
            ).toISOString();
          } else {
            value = row.querySelector(".popupInput input").value;
          }
          object[key] = value;
        });
      }
      return object;
    }

    const object = {
      id: filterId,
      name: document.querySelector("#nameInput").value,
      contents: getContents(),
    };

    Hooks.sendData(object, `${API_ENV}/jobfilter/${filterId}`, "PUT");
  }

  useEffect(() => {
    if (filterId) {
      function getContent(data) {
        let contents = [];

        for (let key in data.contents) {
          let input = (
            <input
              id={`${key}Input`}
              type="text"
              key={data.contents[key]}
              defaultValue={data.contents[key]}
            />
          );
          if (Hooks.conditionalString(key).includes("date")) {
            input = (
              <DateTimePicker
                id={`${key}Input`}
                classValue="popupDate"
                value={data.contents[key]}
              />
            );
          }
          contents.push(
            <div className="popupRow" key={key}>
              <div className="popupKey" key={key}>
                {key}
              </div>
              <div className="popupInput">{input}</div>
            </div>,
          );
        }

        return (
          <React.Fragment>
            <div className="popupRowName">
              <div className="popupKey">Name</div>
              <input
                id="nameInput"
                type="text"
                key={data.name}
                defaultValue={data.name}
              />
            </div>
            <div className="popupSubtitle">Filter values</div>
            <div id="popupContents">{contents}</div>
          </React.Fragment>
        );
      }

      Hooks.getData(`${API_ENV}/jobfilter/${filterId}`, (data) => {
        updateContent(getContent(data[0]));
        updateTitle("Viewing filter: " + data[0].name);
      });
    }
  }, [filterId]);

  return (
    <div id="presetFilterView">
      <div id="presetFilterHeader">
        <div id="popupTitle">{title}</div>
        <div id="popupClose" onClick={onClose}>
          ✖
        </div>
      </div>
      <div id="presetFilterContent">{content}</div>
      <div id="presetFilterFooter">
        <div className="button" onClick={applyClicked}>
          Apply Changes
        </div>
        <div className="button" onClick={clearPopup}>
          Clear
        </div>
      </div>
    </div>
  );
}
