import API_ENV from "@config";
import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "../../../../Hooks";
import Form from "../../../../ui/Form";
import SidePanelFormRow from "../../../../ui/SidePanelFormRow";
import Input from "../../../../ui/Input";
import DropdownList from "../../../../ui/DropdownList";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    name: yup.string().required("This field is required"),
    abbr: yup.string().required("This field is required"),
    equipmentCategoryRefId: yup.string().required("This field is required"),
  })
  .required();

export default function AddEquipmentSubcategory1({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      abbr: "",
      equipmentCategoryRefId: "",
    },
  });

  // const { openModal } = useModal();

  // useEffect(() => {
  //   console.log("dropdownData:", dropdownData);
  // }, [dropdownData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log(values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/equipmentCategory`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });
  }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          cleanURL();
          reset();

          setIsButtonDisabled(false);
        }

        let modifiedApiUrl = apiUrl;
        if (apiUrl.includes("add")) {
          modifiedApiUrl = apiUrl.replace("add", "");
        }

        // console.log("apiUrl", apiUrl);
        // console.log("modifiedApiUrl", modifiedApiUrl);
        console.log("data", data);

        Hooks.sendData(
          data,
          `${API_ENV}/${modifiedApiUrl}`,
          "POST",
          thenFunction,
        );
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, cleanURL, apiUrl, reset],
  );

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      {
        text: "Submit Form",
        click: handleSubmit(handleSubmitForm),
        disabled: isButtonDisabled,
      },
      // { text: "Open Modal", click: handleSubmit(handleOpenModal) },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <Input label={"Name"} name="name" control={control} errors={errors} />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <Input
            label={"Abbreviation"}
            name="abbr"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Equipment Category"}
            name="equipmentCategoryRefId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
          />
        </SidePanelFormRow>
      </div>

      {/* <Modal name="Example" title={"Title"}>
       
      </Modal> */}
    </Form>
  );
}
