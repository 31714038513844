import "../styles/ui/Select.scss";

import React from "react";
import { useController } from "react-hook-form";

const DropdownList = ({
  label,
  size = "medium",

  name,
  control,
  rules,
  defaultValue = "",
  errors,
  dropdownData,
  optionName = "name",
  optionNames,
  firstOption = "Select option",
  firstOptionValue = "",
  disableFirstOption = false,
  customOnChange,
  ...rest
}) => {
  const useControllerProps = control
    ? { name, control, rules, defaultValue }
    : {};

  const {
    field: { onChange, onBlur, value, ref } = {},
    fieldState: { error: rhfError } = {},
  } = useController(useControllerProps);

  const handleChange = (e) => {
    let parsedValue;

    // Check if the value is a number
    if (!isNaN(e.target.value) && e.target.value !== "") {
      parsedValue = parseInt(e.target.value, 10);
    } else if (e.target.value === "true" || e.target.value === "false") {
      // Check if the value is a boolean string and convert it to a boolean
      parsedValue = e.target.value === "true";
    } else {
      // Otherwise, keep it as a string
      parsedValue = e.target.value;
    }

    if (customOnChange) {
      customOnChange(parsedValue);
    }

    if (onChange) {
      onChange(parsedValue);
    }
  };

  const selectProps = control
    ? { ref, onChange: handleChange, onBlur, value: value ?? "" }
    : { defaultValue, onChange: handleChange };

  const errorMessage = errors && errors[name]?.message;

  const getOptionText = (item) => {
    return optionNames
      ? optionNames.map((key) => item[key]).join(" ")
      : item[optionName];
  };

  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <select
        className={`select ${size}`}
        name={name}
        {...selectProps}
        {...rest}
      >
        {!disableFirstOption && (
          <option value={firstOptionValue}>{firstOption}</option>
        )}

        {Array.isArray(dropdownData) &&
          dropdownData.length > 0 &&
          dropdownData[0] !== null &&
          dropdownData.map((item) => (
            <option className={"option"} key={item.id} value={item.id}>
              {optionNames ? getOptionText(item) : item[optionName]}
            </option>
          ))}
      </select>

      {(rhfError || errorMessage) && (
        <p className="fieldError">
          {(rhfError && rhfError.message) || errorMessage}
        </p>
      )}
    </>
  );
};

export default DropdownList;
