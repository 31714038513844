import API_ENV from "@config";
import React, { useEffect, useState } from "react";

import Hooks from "../../../Hooks";
import OrderDetailsitem from "./OrderDetailsItem";
import SidePanelRow from "../../sidePanel/SidePanelComponents/SidePanelRow";

export default function ViewPO({ purchaseOrderId, searchClicked }) {
  const [data, updateData] = useState();
  const [poDetails, updateDetails] = useState();

  useEffect(() => {
    if (purchaseOrderId) {
      Hooks.getData(
        `${API_ENV}/purchaseOrder/${purchaseOrderId}`,
        (purchaseOrder) => {
          updateData(purchaseOrder);
        },
      );

      Hooks.getData(
        `${API_ENV}/poDetail?PurchaseOrderId=${purchaseOrderId}`,
        (details) => {
          if (details && details.length && typeof details !== "string") {
            const array = [];
            details.forEach((item, index) => {
              array.push(
                <OrderDetailsitem
                  type="purchaseorder"
                  key={`details_item_${index}`}
                  data={item}
                  searchClicked={searchClicked}
                />,
              );
            });
            updateDetails(array);
          } else {
            updateDetails(
              <p>Could not display details for this purchase order.</p>,
            );
          }
        },
      );
    }
  }, [purchaseOrderId, searchClicked]);
  //added "searchClicked" to dependency array, appears to be working fine without causing infinite loop

  return (
    <React.Fragment>
      <SidePanelRow
        title="Raised By"
        value={`${data?.raisedByStaff?.forename} ${data?.raisedByStaff?.surname}`}
        readOnly
      />
      <SidePanelRow
        title="Purchase Order State"
        value={data?.purchaseOrderState?.name}
      />
      <SidePanelRow title="Supplier" fullData={data} value={data?.supplier} />
      <SidePanelRow title="Target Date" value={data?.targetDate} />
      <SidePanelRow title="Raised Date" value={data?.orderDate} />

      <div id="poDetails">
        <h3>Purchase Order Details</h3>
        <div id="poDetailsContent">{poDetails}</div>
      </div>
    </React.Fragment>
  );
}
