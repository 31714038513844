import React from "react";
import Button from "@ui/Button";
import Heading from "@ui/Heading";
import Row from "@ui/Row";
import { useMoveBack } from "@hooks/useMoveBack";
import HR from "@ui/HR";

function Error({ error, errorInfo }) {
  const handleReload = () => {
    window.location.reload();
  };

  const moveBack = useMoveBack();

  return (
    <>
      <Row>
        <Heading as="h1">
          {error
            ? error instanceof Error
              ? error.message
              : String(error)
            : "Error: 404 - Page/Resource Not Found!"}
        </Heading>
      </Row>

      {error && errorInfo && (
        <Row>
          <Heading as="h4">Stack Trace:</Heading>
          <pre>{errorInfo.componentStack}</pre>
        </Row>
      )}

      <Row type="horizontal" justifyContent="center">
        <Button onClick={moveBack} size="large">
          &larr; Go back
        </Button>
        <Button onClick={handleReload} size="large">
          &#x21BB; Refresh Page
        </Button>
      </Row>

      <HR />
    </>
  );
}

export default Error;
