import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import Hooks from "../../../../Hooks";
import NestedList from "../../../../ui/NestedList";
import "../../../../styles/ui/NestedList.scss";
import InputOldNonRHF from "@ui/InputOldNonRHF";

const initialFormValues = {
  partsCategoryId: "",
  partsSubCategory1Id: "",
  partsSubCategory2Id: "",
  partsSubCategory3Id: "",
  partsSubCategory4Id: "",
};

const initialDropdownValues = {
  partsByLevel: [],
  partsCategoryList: [],
  partsSubCategory1List: [],
  partsSubCategory2List: [],
  partsSubCategory3List: [],
  partsSubCategory4List: [],
};

export default function PartsBySubCategoryLevel({
  setFooterState,
  cleanURL,
  state,
}) {
  const [formData, setFormData] = useState(initialFormValues);
  const [dropdownData, setDropdownData] = useState(initialDropdownValues);

  // useEffect(() => {
  //   // console.log("state:", state);
  //   console.log("formData:", formData);
  //   console.log("dropdownData:", dropdownData);
  // }, [state, data, formData, dropdownData]);

  const fetchData = useCallback(
    (url, updateField) => {
      try {
        Hooks.getData(url, (data) => {
          setDropdownData((prevData) => ({
            ...prevData,
            [updateField]: data,
          }));
        });
      } catch (error) {
        console.error(`Error fetching ${updateField}:`, error);
      }
    },
    [setDropdownData],
  );

  // UseEffect Hooks
  // Dropdownlist Data
  useEffect(() => {
    fetchData(`${API_ENV}/partsCategory`, "partsCategoryList");
  }, [fetchData]);

  useEffect(() => {
    const urlBase = `${API_ENV}`;
    const apiMapping = {
      partsCategoryId: {
        endpoint: "partsSubCategory1",
        queryParam: "PartsCategoryID",
      },
      partsSubCategory1Id: {
        endpoint: "partsSubCategory2",
        queryParam: "PartsSubCategory1ID",
      },
      partsSubCategory2Id: {
        endpoint: "partsSubCategory3",
        queryParam: "PartsSubCategory2ID",
      },
      partsSubCategory3Id: {
        endpoint: "partsSubCategory4",
        queryParam: "PartsSubCategory3ID",
      },
    };

    Object.entries(apiMapping).forEach(([key, { endpoint, queryParam }]) => {
      if (formData[key]) {
        const url = `${urlBase}/${endpoint}?${queryParam}=${formData[key]}`;
        fetchData(url, `${endpoint}List`);
        fetchData(`${urlBase}/parts?${key}=${formData[key]}`, "partsByLevel");
      }
    });
  }, [formData, fetchData]);

  // HANDLE Functions
  const handleItemClick = useCallback(
    (id, field) => {
      // Update form data and reset subsequent fields if necessary
      const newFormData = { ...formData, [field]: id };
      Object.keys(initialFormValues).forEach((key) => {
        if (key > field) {
          newFormData[key] = "";
        }
      });
      setFormData(newFormData);

      // Reset dropdowns based on the current selection
      // Determine the level of the clicked item
      const levelClicked = Object.keys(initialFormValues).indexOf(field);

      // Reset dropdown data for all subcategories below the level clicked
      const newDropdownData = { ...initialDropdownValues };
      Object.keys(initialDropdownValues).forEach((key, index) => {
        // Since the partsCategoryList is at index 1, adjust the index comparison accordingly
        if (index > levelClicked + 1) {
          // Adjust the index as per your state structure if needed
          newDropdownData[key] = [];
        } else if (index <= levelClicked + 1) {
          newDropdownData[key] = dropdownData[key];
        }
      });
      setDropdownData(newDropdownData);
    },
    [formData, setFormData, setDropdownData, dropdownData],
  );

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([{ text: "Close", click: closeFunction }]);
  }, [setFooterState, closeFunction]);

  return (
    <>
      <div className="formContainer">
        <div className="listSection">
          <NestedList
            data={dropdownData.partsCategoryList}
            onItemClick={(item) => handleItemClick(item.id, "partsCategoryId")}
            selectedItemId={formData.partsCategoryId}
            title={"Main Category"}
          />
          {dropdownData.partsSubCategory1List.length > 0 && (
            <NestedList
              data={dropdownData.partsSubCategory1List}
              onItemClick={(item) =>
                handleItemClick(item.id, "partsSubCategory1Id")
              }
              selectedItemId={formData.partsSubCategory1Id}
              title={"SubCategory 1"}
            />
          )}
          {dropdownData.partsSubCategory2List.length > 0 && (
            <NestedList
              data={dropdownData.partsSubCategory2List}
              onItemClick={(item) =>
                handleItemClick(item.id, "partsSubCategory2Id")
              }
              selectedItemId={formData.partsSubCategory2Id}
              title={"SubCategory 2"}
            />
          )}
          {dropdownData.partsSubCategory3List.length > 0 && (
            <NestedList
              data={dropdownData.partsSubCategory3List}
              onItemClick={(item) =>
                handleItemClick(item.id, "partsSubCategory3Id")
              }
              selectedItemId={formData.partsSubCategory3Id}
              title={"SubCategory 3"}
            />
          )}
          {dropdownData.partsSubCategory4List.length > 0 && (
            <NestedList
              data={dropdownData.partsSubCategory4List}
              selectedItemId={formData.partsSubCategory4Id}
              title={"SubCategory 4"}
            />
          )}
        </div>

        <div className="formTitle">
          <h4>Parts List</h4>
        </div>

        {Array.isArray(dropdownData.partsByLevel) &&
        dropdownData.partsByLevel.length > 0 ? (
          dropdownData.partsByLevel.map((item, index) => (
            <React.Fragment key={index}>
              <div className="formRow">
                <InputOldNonRHF
                  label={"Part No"}
                  formData={formData}
                  setFormData={setFormData}
                  value={item.partno}
                />
              </div>
              <div className="formRow">
                <InputOldNonRHF
                  label={"Description"}
                  formData={formData}
                  setFormData={setFormData}
                  value={item.description}
                />
              </div>
            </React.Fragment>
          ))
        ) : (
          <p className="text">
            No Parts found! Please try another category from the list above.
          </p>
        )}
      </div>
    </>
  );
}
