import React from "react";

export default function DisplayStatistic({ text, value }) {
  return (
    <div className="statisticWidget">
      <div className="statisticTitle">{text}</div>
      <div className="statisticValue">{value}</div>
    </div>
  );
}
