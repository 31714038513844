import API_ENV from "@config";
import React, { useEffect, useRef, useState } from "react";

import Row from "@ui/Row";
import Form from "@ui/Form";
import FormRow from "@ui/FormRow";
import Heading from "@ui/Heading";
import HR from "@ui/HR";
import Button from "@ui/Button";
import { HiTrash } from "react-icons/hi2";
import Hooks from "@hooksFile";

const initialFormData = [
  {
    attributeCategoryId: "",
    attributeUnitId: "",
    value: "",

    dependencies: {
      answerTypeRefId: "",
    },

    unitOptions: [],
  },
];

const initialDynamicDropdownOptions = {
  valueDropdownValues: {
    api: {},
  },
};

// ! IMPORTANT INFORMATION!
// objectDocTypeId for APIs is hardcoded value found on the Object Document Type page
// objectDocTypeId =  1 = Job
// objectDocTypeId =  2 = Equipment
// objectDocTypeId =  3 = Property
// objectDocTypeId =  4 = Staff
// objectDocId =  ID of Job/Equipment/Property/Staff

export default function ViewAttributesModalForm({
  objectDocTypeId,
  objectDocId,
  setOutsideFormData,
}) {
  const [formData, setFormData] = useState(initialFormData);
  const [dropdownData, setDropdownData] = useState({
    categoryOptions: [],
  });

  const [confirmationScreen, setConfirmationScreen] = useState(false);
  const [dynamicDropdownOptions, setDynamicDropdownOptions] = useState(
    initialDynamicDropdownOptions,
  );
  const [attributeId, setAttributeId] = useState();
  const [categoryIds, setCategoryIds] = useState([]);

  const prevFormDataRef = useRef(formData); // useRef to store the previous formData state

  // useEffect(() => {
  //   console.log("formData:", formData);
  //   // console.log("dropdownData:", dropdownData);
  //   // console.log("attributeId:", attributeId);
  //   // console.log("objectDocTypeId:", objectDocTypeId);
  //   // console.log("objectDocId:", objectDocId);
  // // console.log("dynamicDropdownOptions:", dynamicDropdownOptions);
  // }, [formData]);

  // * Populating existing formData from API
  useEffect(() => {
    Hooks.getData(
      // `${API_ENV}/attributeValue/GetAttributeHdr/{objectDocTypeId}/{objectDocId}`,
      `${API_ENV}/attributeValue/GetAttributeHdr/${objectDocTypeId}/${objectDocId}?getDisplayOnly=false`,
      (data) => {
        // console.log("Existing Attribute Data: ", data);
        setAttributeId(data.id);
        setFormData(initialFormData);

        if (data && typeof data === "object" && data.contents.length > 0) {
          const transformedFormData = data.contents.map((contentsItem) => {
            return {
              attributeCategoryId: contentsItem.attributeCategoryId,
              attributeUnitId: contentsItem.attributeUnitId,
              value: contentsItem.value,
              dependencies: {
                answerTypeRefId: contentsItem ? contentsItem.answerTypeId : "",
              },
              unitOptions: [],
            };
          });

          setFormData(
            transformedFormData.length > 0
              ? transformedFormData
              : initialFormData,
          );

          setCategoryIds(
            transformedFormData.map((row) => row.attributeCategoryId),
          ); // Set initial category IDs
        } else {
          // Handle cases where data.contents is undefined or data structure is unexpected
          // console.error("Unexpected data structure:", data);
          setFormData(initialFormData);
        }
      },
      (error) => {
        // Optional: handle errors if the API fails
        console.error("API error:", error);
      },
    );
  }, [objectDocTypeId, objectDocId]);

  // * Populating dropdown lists here
  // . Category Dropdown Options API Call
  useEffect(() => {
    Hooks.getData(`${API_ENV}/attributeCategory?showarchive=false`, (data) => {
      // console.log("Category Dropdown data: ", data);
      setDropdownData((prevData) => ({
        ...prevData,
        categoryOptions: data,
      }));
    });
  }, []);

  // . Unit Dropdown Options API Call
  // Fetches unit options when formData changes and attributeCategoryId is present
  useEffect(() => {
    categoryIds.forEach((categoryId, index) => {
      if (categoryId) {
        Hooks.getData(
          `${API_ENV}/attributeUnit?AttributeCategoryId=${categoryId}&showarchive=false`,
          (data) => {
            // console.log("Unit Options data: ", data);
            const updatedFormData = [...prevFormDataRef.current]; // This ensures that state updates don't trigger an infinite loop.
            if (updatedFormData[index].unitOptions !== data) {
              updatedFormData[index].unitOptions = data;
              setFormData(updatedFormData);
            }
          },
        );
      }
    });
  }, [categoryIds]);

  // This ensures that state updates don't trigger an infinite loop.
  useEffect(() => {
    prevFormDataRef.current = formData;
  }, [formData]);

  // Handle Functions
  const handleAddRow = () => {
    setFormData([
      ...formData,
      {
        attributeCategoryId: "",
        attributeUnitId: "",
        value: "",
        dependencies: {
          answerTypeRefId: "",
        },
        unitOptions: [],
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    // Prevent deleting the first row
    // if (index === 0) {
    //   return;
    // }

    const updatedData = [...formData];
    updatedData.splice(index, 1);
    setFormData(updatedData);
  };

  //*  API call for the renderValueField API JSX condition
  useEffect(() => {
    if (formData) {
      formData.forEach((row, index) => {
        if (row.dependencies.answerTypeRefId === 4) {
          Hooks.getData(
            `${API_ENV}/attributeListChoice/attributeUnitId/${row.attributeUnitId}`,
            (data) => {
              // console.log("attributeListChoice/attributeUnitId: ", data);

              if (data) {
                //* Old
                // setDynamicDropdownOptions((prevData) => ({
                //   ...prevData,
                //   valueDropdownValues: {
                //     ...prevData.valueDropdownValues,
                //     api: data,
                //   },
                // }));

                //* New
                setDynamicDropdownOptions((prevData) => ({
                  ...prevData,
                  valueDropdownValues: {
                    ...prevData.valueDropdownValues,
                    api: {
                      ...prevData.valueDropdownValues.api,
                      [index]: data, // Store the data for this specific row's index
                    },
                  },
                }));
              } else {
                console.error(data);
              }
            },
          );
        }
      });
    }
  }, [formData]);

  //* Function to render JSX of the value field based on formData.map(row) "row.dependencies.answerTypeRefId" & "index"
  function renderValueField(answerTypeRefId, index) {
    // console.log("answerTypeRefId: ", answerTypeRefId);
    if (answerTypeRefId === 1) {
      // Yes / No
      return (
        <select
          id={`value_${index}`}
          className="select extraSmall"
          value={formData[index].value}
          onChange={(e) => handleInputChange(e, index, "value")}
        >
          <option value="">Select option</option>
          <option value="True">True</option>
          <option value="False">False</option>
        </select>
      );
    } else if (answerTypeRefId === 2) {
      // Free Text
      return (
        <input
          id={`value_${index}`}
          type="text"
          className="input extraSmall"
          value={formData[index].value}
          onChange={(e) => handleInputChange(e, index, "value")}
        />
      );
    } else if (answerTypeRefId === 3) {
      // Checkbox
      return (
        <input
          id={`value_${index}`}
          type="checkbox" // ! Will prob have to adjust handleInputChange for checkboxes
          className="input extraSmall"
          value={formData[index].value}
          onChange={(e) => handleInputChange(e, index, "value")}
        />
      );
    } else if (answerTypeRefId === 4) {
      //* Dropdown List from API call
      return (
        <select
          id={`value_${index}`}
          className="select extraSmall"
          value={formData[index].value}
          onChange={(e) => handleInputChange(e, index, "value")}
        >
          <option value="">Select option</option>
          {(dynamicDropdownOptions.valueDropdownValues.api[index] || []).map(
            (item, index) => (
              <option key={index} value={item.id}>
                {item.listValue}
              </option>
            ),
          )}
        </select>
      );
    } else if (answerTypeRefId === 5) {
      // Number
      return (
        <input
          id={`value_${index}`}
          type="number"
          className="input extraSmall"
          value={formData[index].value}
          onChange={(e) => handleInputChange(e, index, "value")}
        />
      );
    } else if (answerTypeRefId === 6) {
      // Date
      return (
        <input
          id={`value_${index}`}
          type="date"
          className="filterRowInput rowBlock"
          value={formData[index].value}
          onChange={(e) => handleInputChange(e, index, "value")}
        />
      );
    } else if (answerTypeRefId === 7) {
      // Photo
      return (
        <input
          id={`value_${index}`}
          type="text" // ! Placeholder, this needs to be setup!
          className="filterRowInput rowBlock"
          value={formData[index].value}
          onChange={(e) => handleInputChange(e, index, "value")}
        />
      );
    } else {
      return (
        <input
          id={`value_${index}`}
          type="text"
          className="input extraSmall"
          value={formData[index].value}
          onChange={(e) => handleInputChange(e, index, "value")}
        />
      );
    }
  }

  const handleInputChange = (e, index, field) => {
    const updatedFormData = [...formData];
    updatedFormData[index][field] = e.target.value;

    if (field === "attributeCategoryId") {
      const attributeCategoryId = e.target.value;

      Hooks.getData(
        `${API_ENV}/attributeUnit?AttributeCategoryId=${attributeCategoryId}&showarchive=false`,
        (data) => {
          const updatedFormDataWithUnits = [...updatedFormData];
          updatedFormDataWithUnits[index].unitOptions = data;

          // Clear the unit and value when category changes
          updatedFormDataWithUnits[index].attributeUnitId = "";
          updatedFormDataWithUnits[index].value = "";

          setFormData(updatedFormDataWithUnits);
        },
      );
    }

    if (field === "attributeUnitId") {
      const selectedUnit = updatedFormData[index].unitOptions.find(
        (unit) => unit.id === Number(e.target.value),
      );

      updatedFormData[index].value = "";

      if (selectedUnit) {
        updatedFormData[index].dependencies.answerTypeRefId =
          selectedUnit.answerTypeRefId;
      }
    }

    setFormData(updatedFormData);
  };

  // Saving
  function handleSaveFilter() {
    function thenFunction(response) {
      if (response.ok) {
        setConfirmationScreen(false);

        Hooks.getData(
          `${API_ENV}/attributeValue/GetAttributeHdr/${objectDocTypeId}/${objectDocId}?getDisplayOnly=false`,
          (data) => {
            // console.log("Existing Attribute Data: ", data);

            if (data && typeof data === "object" && data.contents.length > 0) {
              const transformedFormData = data.contents.map((contentsItem) => {
                return {
                  categoryId: contentsItem.attributeCategoryId,
                  unitId: contentsItem.attributeUnitId,
                  value: contentsItem.value,
                  dependencies: {
                    answerTypeRefId: contentsItem
                      ? contentsItem.answerTypeId
                      : "",
                  },
                };
              });

              setOutsideFormData(
                transformedFormData.length > 0 ? transformedFormData : [],
              );
            }
          },
        );
      }
    }

    const contents = formData.map((item) => ({
      attributeUnitId: item.attributeUnitId,
      value: item.value,
    }));

    let dataObject;
    dataObject = {
      id: attributeId,
      // objectDocTypeId: objectDocTypeId,
      // objectDocId: objectDocId,
      contents: contents,
    };

    // console.log("dataObject:", dataObject);

    Hooks.sendData(
      dataObject,
      `${API_ENV}/attributeValue`,
      "POST",
      thenFunction,
    );
  }

  function handleDeleteFilter() {
    function thenFunction(response) {
      if (response.ok) {
        setFormData(initialFormData);
        setConfirmationScreen(false);
      }
    }

    Hooks.sendData(
      attributeId,
      // `${API_ENV}/attributeValue/{attributeHdrid}`,
      `${API_ENV}/attributeValue/${attributeId}`,
      "DELETE",
      thenFunction,
    );
  }

  return (
    <Form type="modalForm">
      {!confirmationScreen && (
        <>
          <Row>
            <Heading as="h5">Edit Attributes:</Heading>
            <Button
              size={"small"}
              onClick={(e) => {
                e.preventDefault();
                handleAddRow(e);
                setConfirmationScreen(false);
              }}
            >
              Add Row
            </Button>
          </Row>

          {formData.map((row, index) => (
            <FormRow position={"center"} key={index}>
              <label>Category:</label>
              <select
                className="select extraSmall"
                value={row.attributeCategoryId}
                onChange={(e) =>
                  handleInputChange(e, index, "attributeCategoryId")
                }
              >
                <option value="">Select option</option>
                {Array.isArray(dropdownData.categoryOptions)
                  ? dropdownData.categoryOptions.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.catName}
                      </option>
                    ))
                  : null}
              </select>

              <label>Unit:</label>
              <select
                className="select extraSmall"
                value={row.attributeUnitId}
                onChange={(e) => handleInputChange(e, index, "attributeUnitId")}
              >
                <option value="">Select option</option>
                {Array.isArray(row.unitOptions)
                  ? row.unitOptions.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.unitName}
                      </option>
                    ))
                  : null}
              </select>

              <label htmlFor={`value_${index}`}>Value:</label>
              {renderValueField(row.dependencies.answerTypeRefId, index)}

              <Button
                type="danger"
                // size={"small"}
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteRow(index);
                }}
              >
                <HiTrash />
              </Button>
            </FormRow>
          ))}
        </>
      )}

      {confirmationScreen && (
        <>
          <Row>
            <Heading as="h5">
              Are you sure you want to {confirmationScreen}?
            </Heading>
          </Row>

          <HR />

          {confirmationScreen === "save" ? (
            <Row type="center">
              <Button
                size="small"
                type="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmationScreen(false);
                }}
              >
                Cancel
              </Button>

              <Button
                size="small"
                type="danger"
                onClick={(e) => {
                  e.preventDefault();
                  handleSaveFilter();
                }}
              >
                Save
              </Button>
            </Row>
          ) : null}

          {confirmationScreen === "delete" ? (
            <Row type="center">
              <Button
                size="small"
                type="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmationScreen(false);
                }}
              >
                Cancel
              </Button>

              <Button
                size="small"
                type="danger"
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteFilter();
                }}
              >
                Delete
              </Button>
            </Row>
          ) : null}
        </>
      )}

      {!confirmationScreen && (
        <>
          <HR />

          <FormRow position={"center"}>
            <Button
              size={"small"}
              onClick={(e) => {
                e.preventDefault();
                setConfirmationScreen("save");
              }}
            >
              Save Attributes
            </Button>

            <Button
              size={"small"}
              onClick={(e) => {
                e.preventDefault();
                setConfirmationScreen("delete");
              }}
            >
              Delete Attributes
            </Button>
          </FormRow>
        </>
      )}
    </Form>
  );
}
