import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Hooks from "../../../Hooks";
import "./assignedApis.scss";
import Button from "@ui/Button";

const RowItem = ({ data, onUpdate }) => {
  const { id, allowToRead, allowToUpdate, allowToCreate, allowToDelete } = data;
  const apiGroupName = data.api?.apiGroup?.groupName || "";
  const apiName = data.api?.name || "";
  const apiMenuPath = data.api?.menuPath || "";

  const [permissions, setPermissions] = useState({
    allowToRead,
    allowToUpdate,
    allowToCreate,
    allowToDelete,
  });

  const handlePermissionChange = (permission) => (event) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [permission]: event.target.checked,
    }));
  };

  const handleUpdateClick = () => {
    onUpdate(data, permissions);
  };

  return (
    <tr key={id}>
      <td>{apiGroupName}</td>
      <td>{apiName}</td>
      <td className="apiMenuPath">{apiMenuPath}</td>
      <td>
        <div className="permissionsInput">
          {["read", "update", "create", "delete"].map((perm) => (
            <div className="checkboxRow" key={perm}>
              <div>
                Allowed To {perm.charAt(0).toUpperCase() + perm.slice(1)}
              </div>
              <input
                className={perm}
                type="checkbox"
                checked={
                  permissions[
                    `allowTo${perm.charAt(0).toUpperCase() + perm.slice(1)}`
                  ]
                }
                onChange={handlePermissionChange(
                  `allowTo${perm.charAt(0).toUpperCase() + perm.slice(1)}`,
                )}
              />
            </div>
          ))}
        </div>
      </td>
      <td>
        <div className="buttonsContainer">
          <Button
            size="small"
            // size="medium"
            onClick={handleUpdateClick}
          >
            Update
          </Button>
          <Button
            size="small"
            // size="medium"
            type="danger"
            onClick={() => console.log("Delete clicked")}
          >
            Delete
          </Button>
        </div>
      </td>
    </tr>
  );
};

RowItem.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

const AssignedApis = ({ data }) => {
  const [apis, setApis] = useState([]);

  // console.log("AssignedApis data:", data);

  useEffect(() => {
    if (Array.isArray(data) && data.length) {
      setApis(data);
    } else {
      setApis([]);
    }
  }, [data]);

  const handleUpdate = (apiData, permissions) => {
    const { id, apiId } = apiData;

    const newData = {
      id,
      roleId: Hooks.getSidePanelData().id,
      apiId,
      ...permissions,
    };

    Hooks.sendData(newData, `${API_ENV}/roleToApi/${id}`, "PUT").then(
      (response) => {
        if (response.ok) {
          console.log("API permissions updated successfully.");
        } else {
          console.error("Failed to update API permissions.");
        }
      },
    );
  };

  return (
    <div className="assignedApisContainer">
      <table>
        <thead>
          <tr>
            <th>Group Name</th>
            <th>API Name</th>
            <th>Menu Path</th>
            <th>Permissions</th>
            <th>Update/Delete</th>
          </tr>
        </thead>
        <tbody>
          {apis.length > 0 ? (
            apis.map((api) => (
              <RowItem key={api.id} data={api} onUpdate={handleUpdate} />
            ))
          ) : (
            <tr>
              <td colSpan="5" id="noApi">
                No apis assigned to this role.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

AssignedApis.propTypes = {
  data: PropTypes.array.isRequired,
};

export default AssignedApis;
