import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import "./contents.scss";
import Hooks from "../../../Hooks";

function CalendarBlock(string, key) {
  // Add a key parameter
  function clicked() {
    alert("Click");
  }

  if (string) {
    return (
      <div key={key} className="calendarBlock" onClick={clicked}>
        <div className="contentBlock">Visit</div>
      </div>
    );
  } else {
    return <div key={key} className="calendarBlock"></div>;
  }
}

export default function CalendarRow({ type, engineer, date }) {
  const [blocks, updateBlocks] = useState([]);
  useEffect(() => {
    const blockQuantity = {
      d: 24,
      w: 5,
      f: 7,
      4: 28,
      m: 31,
    };

    function loadBlocks(scheduledHours) {
      const array = [
        <div
          key="engineer-block"
          className="calendarBlock"
        >{`${engineer.forename} ${engineer.surname}`}</div>,
      ];
      for (let i = 0; i < blockQuantity[type]; i++) {
        // Pass a unique key for each CalendarBlock
        if (scheduledHours.length && scheduledHours.includes(i)) {
          array.push(CalendarBlock("yes", `block-${i}`));
        } else {
          array.push(CalendarBlock(null, `block-${i}`));
        }
      }
      return array;
    }

    // Assuming the value of typeSelection is already handled in Calendar component
    const typeString = type;
    Hooks.getData(
      `${API_ENV}/visitcalendar/querybydate?EngineerId=${
        engineer.id
      }&ScheduleDateFrom=${
        date.toISOString().split("T")[0]
      }T00:00:00.000Z&QueryType=${typeString}`,
      (visitData) => {
        const scheduledHours = [];
        if (typeof visitData !== "string") {
          for (let i = 0; i < visitData.length; i++) {
            if (visitData[i] && visitData[i].scheduleHour) {
              scheduledHours.push(visitData[i].scheduleHour);
            }
          }
        }
        updateBlocks(
          <div key={`row-${engineer.id}`} className="calendarRow">
            {loadBlocks(scheduledHours || [])}
          </div>, // Ensure the row itself also has a unique key
        );
      },
    );
  }, [type, engineer, date]);

  return blocks;
}
