import React, { useEffect, useState } from "react";

export default function TextAreaInput({
  defaultValue,
  className,
  id,
  placeholder,
}) {
  const [value, updateValue] = useState(defaultValue);

  useEffect(() => {
    updateValue(defaultValue);
  }, [defaultValue]);

  return (
    <textarea
      id={id}
      key={value}
      rows={6}
      defaultValue={value}
      placeholder={placeholder}
      style={{ resize: "none" }}
    />
  );
}
