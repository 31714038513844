import { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import Spinner from "../ui/Spinner";

export default function Logout() {
  const { logout, isLoading } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  return <>{isLoading && <Spinner />}</>;
}
