import Hooks from "@hooksFile";
import "../styles/ui/ListItem.scss";
import Button from "./Button";
import React from "react";

export default function ListItem({ data, type, onDelete }) {
  const renderContent = (item) => {
    // console.log("Check Item Data here:", item);

    switch (type) {
      case "jobSorHistory":
        return (
          <>
            <p>Version: {item.versionNum}</p>
            <p>SOR Code: {item.sor.sorCode}</p>
            <p>Qty: {item.qty}</p>
            <p>Log Action: {item.logAction}</p>
            <p>Log DateTime: {Hooks.formatISODateToText(item.logDateTime)}</p>
            <p>
              Log User: {item.logStaff.forename} {item.logStaff.surname}
            </p>
            <p>Ref JobSor ID: {item.refId}</p>
          </>
        );
      case "jobType":
        return (
          <>
            <p>Job Type: {item.description}</p>
            <p>Details: {item.detail}</p>
            <p>Job Category: {item.jobCategory.description}</p>
          </>
        );
      case "engineer":
        return (
          <>
            <p>
              Engineer: {item.forename} {item.surname}
            </p>
          </>
        );
      // add more cases here
      default:
        return <p>No data available for the selected type.</p>;
    }
  };

  return (
    <div className="ListItemContainer">
      {Array.isArray(data) && data.length > 0 ? (
        data.map((item, index) => (
          <div key={item.id} className="ListItem">
            <div className="ListDetails">{renderContent(item)}</div>

            <Button
              type="danger"
              onClick={(e) => {
                e.preventDefault();
                onDelete(index);
              }}
            >
              Delete
            </Button>
          </div>
        ))
      ) : (
        <p>{data}</p>
      )}
    </div>
  );
}
