import API_ENV from "@config";
import "./SearchFilters.scss";

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Hooks from "../../Hooks";

import Button from "../../ui/Button";
import Select from "../../ui/Select";
import BasicInput from "../../ui/BasicInput";

export default forwardRef(function BasicFilter({ type, onSearch }, ref) {
  const [input, setInput] = useState(null);
  const [input2, setInput2] = useState(null); // State for the second input
  const filterSearch = useRef(null);

  // useEffect(() => {
  //   // console.log("type:", type);
  //   console.log("input:", input);
  //   console.log("input2:", input2);
  // }, [input, input2]);

  const pagesForDoubleSearch = useMemo(() => ["documentworkflow"], []);

  // Determines for each page what the user should be able to filter by.
  const filterKeys = useMemo(
    () => ({
      api: ["API Group Name", "Api Name", "Menu Path"],
      apigroup: ["Name"],
      role: ["Name", "Description"],
      cancelreason: ["Reason"],
      clients: ["Name", "Address1", "PostCode", "Email"],
      contracts: ["Name", "Client", "Start Date"],
      equipmentcategory: ["Name"],
      equipmentsubcategory1: ["Name", "Equipment Category ID"],
      equipmentsubcategory2: [
        "Name",
        "Equipment Category ID",
        "Equipment Subcategory 1 ID",
      ],
      equipmenttype: ["Make", "Model", "Equipment Category (Free Text)"],
      inspectionsets: [
        "Name",
        "Inspection Type",
        "Compulsory",
        "Equipment Category ID",
      ],
      jobs: [
        "General",
        "Job Number",
        "UPRN",
        "Contract",
        "Reference",
        "Engineer",
        "Job State",
        "Job Type",
        "Scheduled From",
        "Target From",
        "Tags",
      ],
      jobstate: ["Description"],
      jobtype: ["Description"],
      kits: ["Name"],
      parts: ["Part Number", "Description"],
      priority: ["No Filter"],
      processes: ["Name", "Status", "Start Time", "Staff"],
      properties: ["UPRN", "Client", "Address", "PostCode", "Tags"],
      staff: ["Forename", "Surname", "Mobile", "Email", "Staff Type", "Tags"],
      suppliers: ["Name", "Address", "Postcode", "Email"],
      warehouses: ["Name", "Store Type"],
      stock: ["Warehouse", "Part Number", "Stock Location"],
      jobstatetransitions: ["Job Type", "Current Job State", "Next Job State"],
      visitstatetransitions: [
        "Job Type",
        "Current Visit State",
        "Next Job State",
        "Sequence",
      ],
      visitstate: ["Description"],
      purchaserequeststate: [""],
      purchaseorderstate: [""],
      podetailstate: [""],
      deliverynotestate: [""],
      dndetailstate: [""],
      costtype: [""],

      jobcategory: ["Description"],
      qcstatus: ["Description"],
      stockmovements: [
        "Source Warehouse",
        "Destination Warehouse",
        "Parts",
        "Quantity",
        "Date",
        "Type",
      ],
      sor: ["SOR Code", "SOR List", "Desription", "UOM"],
      sorlist: ["SOR Code", "Desription", "UOM"],
      certifications: [
        "Description",
        "Start Date",
        "Expiration Date",
        "Certification Number",
        "Staff",
      ],
      stafftype: ["Description"],
      actionevent: ["Name", "Client", "Description"],
      documenttags: ["Name", "Description"],
      purchaserequests: [
        "ID",
        "Part No",
        "PR State",
        "Target Date",
        "Requested By",
        "Request Date",
        "Target Job",
      ],
      purchaseorders: [
        "ID",
        "PO Supplier",
        "PO State",
        "Requested By",
        "Raised Date",
        "Target Date",
      ],
      deliveries: [
        "Delivery Number",
        "Supplier Name",
        "Delivery Date",
        "Delivery State",
      ],
      partscategory: ["Name"],
      partssubcategory1: ["Name", "Category Path"],
      partssubcategory2: ["Name", "Category Path"],
      partssubcategory3: ["Name", "Category Path"],
      partssubcategory4: ["Name", "Category Path"],
      costcode: ["Name", "Description", "Cost Type"],
      stockcyclecount: [""],

      runreportlist: ["Report ID", "Name", "Report Type"],
      runreportasonlinecsvxls: [
        "Process ID",
        "Report Name",
        "API Group",
        "Export Format",
        "Run By",
      ],
      runreportasonlinepdf: [
        "Process ID",
        "Report Name",
        "API Group",
        "Export Format",
        "Run By",
      ],
      runreportasfileoremail: [
        "Process ID",
        "Report Name",
        "API Group",
        "Export Format",
        "Staff Name",
      ],
      runreportproc: [
        "Process ID",
        "Report Name",
        "API Group",
        "Export Format",
        "Staff Name",
      ],
      runprogramproc: [""],
      runschedulermessage: [""],
      runcustomprocess: [
        "Process ID",
        "Report Name",
        "API Group",
        "Export Format",
        "Run By",
      ],
      letterprintsetup: [
        "Letter Name",
        "Report Name",
        "Client",
        "Job State",
        "Job Type",
      ],
      runschedulersetup: [""],
      ticket: [
        "Ticket No",
        "Issue",
        "Solution",
        "Created By",
        "Assigned To",
        "Menu Path",
        "Created From",
        "Created Until",
        "Target From",
        "Target Until",
      ],
      tickettype: [""],
      answertype: [""],
      objectdoctype: [""],
      attributecategory: [""],
      attributeunit: [
        "Unit Name",
        "Answer Type",
        "Attribute Category",
        "Object Doc Type",
      ],
      tags: [""],
      documentworkflow: [
        "Client",
        "Contract",
        "Job Type",
        "Current Job State",
        "Next Job State",
        "Document Tag",
      ],
    }),
    [],
  );

  // console.log("filterKeys key name for this page:", type);

  function keyUpSearch(event) {
    // 13 is the code for pressing the enter key.
    if (event.keyCode === 13 && filterSearch && filterSearch.current) {
      filterSearch.current.click();
    }
  }

  function getDefaultFilterKeys(isSecondSearch = false) {
    if (!type || !filterKeys[type]) {
      console.error(
        `There was an error loading the filter: ${type} keys for this page. Add a key & array to filterKeys object. (Can be an empty string till actual search params are found)`,
      );
      return;
    }

    return filterKeys[type].map((key, index) => {
      // For the second search, make the second option selected
      if (isSecondSearch && index === 1) {
        return (
          <option
            className="option"
            key={`filterOption_${key}`}
            value={key}
            selected
          >
            {key}
          </option>
        );
      }

      // Default behavior for the first search or other options
      return (
        <option className="option" key={`filterOption_${key}`} value={key}>
          {key}
        </option>
      );
    });
  }

  //* This calls API from Dropdown function
  const fetchSelect = useCallback((url, type, isSecondSearch = false) => {
    // console.log("url", url);
    // console.log("type", type);

    Hooks.getData(url, (data) => {
      // . Checkout dropdown list data here!
      // console.log("Search Dropdown List Data: ", data);

      if (!data || !data.length) {
        return;
      }

      const array = [];

      // modify to get data displaying correctly in second dropdown list
      data.forEach((item) => {
        if (["engineer", "staff", "runby"].includes(type)) {
          array.push(
            <option
              key={item.id}
              value={item.id}
            >{`${item.forename} ${item.surname}`}</option>,
          );
        } else if (
          [
            "client",
            "equipmentcategory",
            "equipmentcategoryid",
            "equipmentsubcategory1id",
            "supplier",
            "prstate",
            "postate",
            "deliverystate",
            "exportformat",
            "reporttype",
            "storetype",
            "sorlist",
          ].includes(type)
        ) {
          array.push(
            <option key={item.id} value={item.id}>
              {item.name}
            </option>,
          );
        } else if (["contract"].includes(type)) {
          array.push(
            <option key={item.id} value={item.id}>
              {item.contractClient}
            </option>,
          );
        } else if (["apigroup"].includes(type)) {
          array.push(
            <option key={item.id} value={item.id}>
              {item.groupName}
            </option>,
          );
        } else if (["answertype"].includes(type)) {
          array.push(
            <option key={item.id} value={item.id}>
              {item.desc}
            </option>,
          );
        } else if (["attributecategory"].includes(type)) {
          array.push(
            <option key={item.id} value={item.id}>
              {item.catName}
            </option>,
          );
        } else if (["objectdoctype"].includes(type)) {
          array.push(
            <option key={item.id} value={item.id}>
              {item.docTypeName}
            </option>,
          );
        } else if (
          [
            "jobtype",
            "jobstate",
            "currentjobstate",
            "nextjobstate",
            "stafftype",
          ].includes(type)
        ) {
          array.push(
            <option key={item.id} value={item.id}>
              {item.description}
            </option>,
          );
        } else {
          array.push(
            <option key={item.id} value={item.id}>
              {item.desc}
            </option>,
          );
        }
      });

      // Create the dropdown for input or input2 based on isSecondSearch
      const selectInput = (
        <select
          id={isSecondSearch ? "basicSearchQuery2" : "basicSearchQuery"}
          className="select extraSmall"
        >
          {array.length ? array : <option value={0}>No Values</option>}
        </select>
      );

      if (isSecondSearch) {
        setInput2(selectInput); // Update input2 if it's the second search
      } else {
        setInput(selectInput); // Update input if it's the first search
      }
    });
  }, []);

  const dropDownChanged = useCallback(
    (dropDown, isSecondSearch = false) => {
      const value = Hooks.conditionalString(dropDown.value);
      // console.log("dropDown", dropDown);
      // console.log("value", value);

      const dateInputs = [
        "scheduledfrom",
        "createdfrom",
        "createduntil",
        "targetfrom",
        "targetuntil",
        "startdate",
        "starttime",
        "targetdate",
        "orderdate",
        "raiseddate",
        "deliverydate",
      ];
      const dropdownInputs = [
        "stafftype",
        "contract",
        "engineer",
        "jobstate",
        "jobtype",
        "client",
        "equipmentcategory",
        "equipmentcategoryid",
        "equipmentsubcategory1id",
        "currentjobstate",
        "nextjobstate",
        "staff",
        "supplier",
        "warehouse",
        "prstate",
        "postate",
        "deliverystate",
        "apigroup",
        "exportformat",
        "runby",
        "answertype",
        "attributecategory",
        "objectdoctype",
        "reporttype",
        "isvan?",
        "storetype",
        "sorlist",
      ];

      // Clear the input before setting a new one
      // setInput(null);
      // console.log("dropDownChanged value: ", value);

      // Clear the input before setting a new one
      if (isSecondSearch) {
        setInput2(null);
      } else {
        setInput(null);
      }

      setTimeout(() => {
        // points the dropdown input to correct API
        if (dropdownInputs.includes(value)) {
          const urls = {
            contract: `${API_ENV}/contracts?showarchive=false`,
            engineer: `${API_ENV}/staff?showarchive=false`,
            staff: `${API_ENV}/staff?showarchive=false`,
            jobstate: `${API_ENV}/jobState?showarchive=false`,
            jobtype: `${API_ENV}/jobType?showall=true&showarchive=false`,
            client: `${API_ENV}/clients?showarchive=false`,
            equipmentcategory: `${API_ENV}/equipmentCategory?showarchive=false`,
            equipmentcategoryid: `${API_ENV}/equipmentCategory?showarchive=false`,
            equipmentsubcategory1id: `${API_ENV}/EquipmentSubCategory1?showarchive=false`,
            stafftype: `${API_ENV}/staffType?showarchive=false`,
            supplier: `${API_ENV}/suppliers?showarchive=false`,
            prstate: `${API_ENV}/purchaseRequestState?showarchive=false`,
            postate: `${API_ENV}/purchaseOrderState?showarchive=false`,
            deliverystate: `${API_ENV}/deliveryNoteState?showarchive=false`,
            apigroup: `${API_ENV}/ApiGroup?isRootLevel=true?showarchive=false`,
            exportformat: `${API_ENV}/runReportType/all?showarchive=false`,
            runby: `${API_ENV}/staff?showarchive=false`,
            answertype: `${API_ENV}/answerType?showarchive=false`,
            attributecategory: `${API_ENV}/attributecategory?showarchive=false`,
            objectdoctype: `${API_ENV}/objectdoctype?showarchive=false`,
            reporttype: `${API_ENV}/runReportType/all?showarchive=false`,
            storetype: `${API_ENV}/storeType?showarchive=false`,
            sorlist: `${API_ENV}/sorlist?showarchive=false`,
          };

          if (value === "currentjobstate" || value === "nextjobstate") {
            fetchSelect(urls.jobstate, value, isSecondSearch);
          } else {
            if (urls[value]) {
              fetchSelect(urls[value], value, isSecondSearch);
            } else if (value === "isvan?") {
              const yesOrNoInput = (
                <select
                  id={isSecondSearch ? "basicSearchQuery2" : "basicSearchQuery"}
                  className="select extraSmall"
                >
                  <option key={Math.random()} value={true}>
                    Yes
                  </option>
                  <option key={Math.random()} value={false}>
                    No
                  </option>
                </select>
              );

              if (isSecondSearch) {
                setInput2(yesOrNoInput);
              } else {
                setInput(yesOrNoInput);
              }
            }
          }
        } else if (dateInputs.some((substring) => value.includes(substring))) {
          const dateInput = (
            <div className="bfRangeSelect">
              <BasicInput
                id="basicSearchDate1"
                size="extramall"
                textAlign={"center"}
                onKeyUp={(event) => keyUpSearch(event)}
                type="date"
              />
              <p>to</p>
              <BasicInput
                id="basicSearchDate2"
                size="extramall"
                textAlign={"center"}
                type="date"
              />
            </div>
          );

          if (isSecondSearch) {
            setInput2(dateInput);
          } else {
            setInput(dateInput);
          }
        } else {
          const inputField = (
            <BasicInput
              id={isSecondSearch ? "basicSearchQuery2" : "basicSearchQuery"}
              size="extramall"
              textAlign={"center"}
              placeholder="Element to search for"
              onKeyUp={(event) => keyUpSearch(event)}
            />
          );

          if (isSecondSearch) {
            setInput2(inputField);
          } else {
            setInput(inputField);
          }
        }
      }, 0);
    },
    [fetchSelect],
  );

  // Handle input changes based on page type or selected filter
  useEffect(() => {
    if (type) {
      // Set input dynamically based on the page type or filter for the first input
      dropDownChanged({ value: filterKeys[type][0] }, false);
      dropDownChanged({ value: filterKeys[type][1] }, true);
    }
  }, [type, dropDownChanged, filterKeys]);

  return (
    <>
      <div id="basicSearch" ref={ref}>
        <div className="searchContainer">
          <div className="searchInputContainer">
            <p className="searchText">Search by</p>

            <Select
              type="extraSmall"
              id="searchInputDrop"
              onChange={(element) => {
                dropDownChanged(element.target, false);
              }}
            >
              {getDefaultFilterKeys(false)}
            </Select>

            <p className="searchText">for</p>

            {input}
          </div>

          {Hooks.getCookie("last_page").includes(pagesForDoubleSearch) && (
            <div className="searchInputContainer">
              <p className="searchText">Search by</p>

              <Select
                type="extraSmall"
                id="searchInputDrop2"
                onChange={(element) => {
                  dropDownChanged(element.target, true);
                }}
              >
                {getDefaultFilterKeys(true)}
              </Select>

              <p className="searchText">for</p>

              {input2}
            </div>
          )}
        </div>

        <Button
          id="basicFilterSearchButton"
          size={"extraSmall"}
          icon={"HiMagnifyingGlass"}
          ref={filterSearch}
          onClick={onSearch}
        >
          Search
        </Button>
      </div>
    </>
  );
});
