import API_ENV from "@config";
import React, { useState } from "react";

import Hooks from "../../../Hooks";
import SidePanelRow from "./../../sidePanel/SidePanelComponents/SidePanelRow";

export default function AddDeliveryDetail() {
  const [poDetails, updatePoDetails] = useState();

  function onChange(purchaseOrder) {
    console.log(purchaseOrder);
    if (!purchaseOrder) {
      return;
    }

    const stockLocation = purchaseOrder?.destinationStockLocation;
    document.querySelector("#powarehouse").value =
      purchaseOrder?.destinationWarehouse?.name;
    document.querySelector("#polocation").value =
      `${stockLocation?.aisle} ${stockLocation?.rack} ${stockLocation?.level}`;

    Hooks.getData(
      `${API_ENV}/poDetail?PurchaseOrderId=${purchaseOrder?.id}`,
      (poDetails) => {
        if (!poDetails || !poDetails.length || typeof poDetails === "string") {
          updatePoDetails(
            <div id="noPoDetail">
              No purchase order details could be found for this purchase order
            </div>,
          );
        }

        updatePoDetails(
          <div id="poDetailList">
            {poDetails.map((detail) => {
              return (
                <div className="poDetailItem">{`${detail?.lineNo}) ${detail?.parts?.description}`}</div>
              );
            })}
          </div>,
        );
      },
    );
  }

  return (
    <>
      <SidePanelRow title="Warehouse Location" />
      <SidePanelRow long={true} title="Purchase Order" onChange={onChange} />
      <SidePanelRow title="PO Warehouse" readOnly={true} />
      <SidePanelRow title="PO Location" readOnly={true} />
      <SidePanelRow title="Description" />
      {poDetails}
    </>
  );
}
