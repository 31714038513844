import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import Hooks from "../../../../Hooks";
import InspectionAnswers from "./InspectionAnswers";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";

export default function AddInspectionQuestion() {
  const [answerTypes, setAnswerTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [answers, setAnswers] = useState(null);

  // useEffect(() => {
  //   console.log("answers: ", answers);
  // }, [answers]);

  useEffect(() => {
    Hooks.getData(
      `${API_ENV}/answerType?InspectionFlag=true&showarchive=false`,
      (data) => {
        if (data && data.length) {
          const options = data.map((item, index) => (
            <option value={item.id} key={index}>
              {item.desc}
            </option>
          ));

          setAnswerTypes(options);
          setSelectedType(data[0].desc);
        }
      },
    );
  }, []);

  // Populating the answer when answerTypes is selected & on component load.
  useEffect(() => {
    if (selectedType) {
      handleTypeChange({
        target: { options: [{ text: selectedType }], selectedIndex: 0 },
      });
    }
  }, [selectedType, answerTypes]);

  const handleTypeChange = (e) => {
    const selectedText = e.target.options[e.target.selectedIndex].text;

    setSelectedType(selectedText);

    switch (Hooks.conditionalString(selectedText)) {
      case "freetext":
        setAnswers(
          <input
            id="answerInput"
            className="input medium"
            type="text"
            value="Free text"
            readOnly
          />,
        );
        break;
      case "yes/no":
        setAnswers(
          <select id="answerInput" className="select medium">
            <option>Yes</option>
            <option>No</option>
          </select>,
        );
        break;
      case "dropdownlist":
      case "tickbox":
        setAnswers(<InspectionAnswers type={selectedText} />);
        break;
      default:
        setAnswers(null);
        break;
    }
  };

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <label>Question</label>
          <input
            id="questionInput"
            className="input medium"
            type="text"
            maxLength="80"
          ></input>
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label>Answer Type</label>

          <select
            id="answertypeInput"
            className="select medium"
            onChange={handleTypeChange}
          >
            {answerTypes}
          </select>
        </SidePanelFormRow>

        <SidePanelFormRow type="full">
          <label>Answers</label>
          {answers}
        </SidePanelFormRow>
      </div>
    </Form>
  );
}
