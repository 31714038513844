import React, { useEffect, useState } from "react";
import Button from "../../../ui/Button";

function getColour(text) {
  const redButtons = ["archive", "create jobs", "delete", "add all contracts"];

  if (text.toLowerCase().includes("close")) {
    return "secondary";
  } else if (
    redButtons.some((buttonText) => text.toLowerCase().includes(buttonText))
  ) {
    return "danger";
  }
}

export default function FooterButtons({ state, loading = false }) {
  // console.log(state)
  const [buttonState, updateState] = useState([]);

  useEffect(() => {
    if (!state || !state.length) {
      return;
    }

    updateState(
      state.map((item, index) => {
        return (
          <Button
            // size="small"
            key={`SidePanelFooterButton ${index}`}
            onClick={item.click}
            type={getColour(item?.text.toLowerCase())}
            disabled={item.disabled || loading} // Disable the button if loading or explicitly disabled
          >
            {item.text}
          </Button>
        );
      }),
    );
  }, [state, loading]);

  return buttonState;
}
