import React, { useState, useEffect } from "react";
import "./poStyles.scss";

export default function ValidatePo({ data, onClose }) {
  const [content, updateContent] = useState();

  useEffect(() => {
    if (!data || !data.message || !data.message.length) {
      return;
    }

    const array = [];
    data.message.forEach((message) => {
      array.push(<div className="poValidationItem">{message}</div>);
    });
    updateContent(array);
  }, [data]);

  return (
    <div id="poValidationPopup">
      <div id="poValidationHeader">
        <div>Validation Information</div>
        <div
          id="close"
          onClick={() => {
            onClose();
            document.querySelector(".currentTab").click();
          }}
        >
          Close
        </div>
      </div>
      <div id="poValidationContent">{content}</div>
    </div>
  );
}
