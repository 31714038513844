import "../styles/ui/SearchBar.scss";

import API_ENV from "@config";
import Hooks from "@hooksFile";
import React, { useRef, useState, useCallback } from "react";
import { useParams } from "react-router-dom";

export default function SearchBar({
  title,
  type,
  setValue,
  outsideFormData,
  setOutsideFormData,
  onClick,
  popup,
  objectDocTypeId,
  objectDocId,
  jobSorData,
}) {
  const [content, updateContent] = useState([]);
  const searchBar = useRef(null);
  const { tableRowId } = useParams();
  // const contractId = Hooks.getSidePanelData().contractId;

  // useEffect(() => {
  //   console.log("loadedData: ", loadedData);
  // }, []);

  //* This updates the parent component/outside form
  const handleSearchItemClick = useCallback(
    (data) => {
      data = JSON.parse(data);
      // console.log("handlePropertyClick data: ", data);

      //! This was in TypeSearchOldNonRHF component, this should be removed upon form refactoring.
      const updateElement = (selector, attribute, value) => {
        const element = document.querySelector(selector);
        if (element) element.setAttribute(attribute, value);
      };

      switch (type) {
        case "part":
          // setValue("id", data.id);

          //! Existing from TypeSearchOldNonRHF
          if (popup) {
            updateElement("#typeSearch input", "dataid", data?.id);
            document.querySelector("#typeSearch input").value = data?.partno;
            updateContent([]);
            return;
          }
          updateElement("#partid", "data", data?.id);
          updateElement("#partid", "value", data?.id);
          updateElement("#partnumber", "value", data?.partno);
          updateElement("#partdescription", "value", data?.description);

          const unitCost = document.querySelector("#pounitcost");
          const supplierId = Hooks.getSidePanelData()?.supplierRefId;
          if (unitCost) {
            Hooks.getData(
              `${API_ENV}/partsAndSupplier/FindCostQtybyPartSupplier/${data?.id}/${supplierId}`,
              (cost) => {
                if (cost?.unitCost) {
                  unitCost.value = cost.unitCost;
                }
              },
            );
          }
          break;

        case "purchaseorder":
          // setValue("id", data.id);

          //! Existing from TypeSearchOldNonRHF
          updateElement("#selectedorder", "dataid", data.id);
          updateElement("#selectedorder", "value", `${data.id} - ${data.desc}`);
          break;

        case "property":
          setValue("id", data.id);
          setValue("property", data?.addrInfo || "");
          // setValue("postCode", data?.postCode || "");
          setValue("clientName", data?.client.name || "");
          setValue("clientRefId", data?.clientRefId || "");
          setValue("propertyId", data?.id || "");
          break;

        case "tags":
          function thenFunction(response) {
            // * Update the parent form with newly POST Tag
            Hooks.getData(
              `${API_ENV}/tagsDoc/byObject/${objectDocTypeId}/${objectDocId}`,
              (data) => {
                // console.log("Attempt 1: ", data);
                setOutsideFormData([...data]);
              },
            );
          }

          Hooks.sendData(
            {},
            `${API_ENV}/tagsDoc/oneByone?TagId=${data.id}&ObjectDocId=${objectDocId}&ObjectDocTypeId=${objectDocTypeId}`,
            "POST",
            thenFunction,
            true,
          );
          break;

        case "sorlist":
          setValue("id", data.id);
          setValue("name", data.name);
          setValue(
            "createDateTime",
            Hooks.formatISODateToText(data.createDateTime),
          );
          break;

        case "jobsor":
          const newData = {
            id: data.id,
            // job: "",
            // jobRefId: "",
            qty: 1,
            sor: {
              id: data.id,
              shortDesc: data.shortDesc,
              mediumDesc: data.mediumDesc,
              rate: data.rate,
              sorCode: data.sorCode,
              uom: {
                id: data.uom.id,
                name: data.uom.name,
                archived: data.uom.archived,
              },
              uomId: data.uomId,
            },
            sorRefId: data.id,
            subTotal: 1 * data.rate,
            // versionNum: "",
            stagingType: "create",
          };

          const isExistingEntry = outsideFormData.find(
            (item) => item.id === newData.id,
          );

          if (isExistingEntry) {
            // console.log("Match found, dont add");
            updateContent([]);
          } else {
            // console.log("No match found, add to list");
            // setOutsideFormData((prevData) => [...prevData, newData]);

            onClick(newData);
          }

          break;

        default:
          break;
      }

      // Clears the text from search bar upon click
      if (searchBar.current) {
        searchBar.current.value = "";
      }

      // Clears the search results upon click
      updateContent([]);
    },
    [
      type,
      objectDocId,
      objectDocTypeId,
      outsideFormData,
      setOutsideFormData,
      setValue,
      popup,
      onClick,
    ],
  );

  //* This calls API when typing characters in search bar
  const onType = useCallback(
    (value) => {
      // Make no search results appear until number of characters is met to reduce API calls
      if (value.length < 2) {
        updateContent([]);
        return;
      }

      const urls = {
        property: (value) =>
          `${API_ENV}/properties?AddrInfo=${value}&limit=10&showarchive=false`,
        part: (value) =>
          `${API_ENV}/parts?PartNo=${value}&limit=10&showarchive=false`,
        purchaseorder: () =>
          `${API_ENV}/poDetail/dnLine/${document
            .querySelector("#sidePanelContainer")
            .getAttribute("lineno")}`,

        tags: (value) => `${API_ENV}/tags?TagsValue=${value}&showarchive=false`,
        sorlist: (value) =>
          `${API_ENV}/SorList?Name=${value}&showarchive=false`,
        jobsor: (value) =>
          `${API_ENV}/sor/ForAddOnly/${tableRowId}/${jobSorData.id}?General=${value}`,
      };

      // console.log("urls[type]", urls[type](value));

      Hooks.getData(urls[type](value), (data) => {
        // console.log("data:", data);

        if (!data || !data.length) {
          updateContent([]);
          return;
        }

        const array = data.map((item, index) => {
          const itemKey = `${type}-${item.id || index}`;

          const text = {
            property: `${item?.addrInfo}`,
            part: item?.partno,
            purchaseorder: `${item?.id} - ${item?.desc}`,

            tags: `${item?.name}`,
            sorlist: `${item?.name}`,
            jobsor: `${item?.sorCode} | ${item?.shortDesc}`,
          };

          return (
            <div
              key={itemKey}
              data={JSON.stringify(item)}
              onClick={(e) =>
                handleSearchItemClick(e.target.getAttribute("data"))
              }
              value={item.id}
            >
              {text[type]}
            </div>
          );
        });

        updateContent(array);
      });
    },
    [type, handleSearchItemClick, tableRowId, jobSorData],
  );

  return (
    <div className="searchBarAutoFill">
      <label>{title} Finder</label>
      <input
        ref={searchBar}
        type="text"
        placeholder={`Search for ${title} here...`}
        className="input medium"
        onChange={(e) => onType(e.target.value)}
      />
      {content && <div className="searchBarResults">{content}</div>}
    </div>
  );
}
