import "../styles/ui/Form.scss";

function Form({ children, type = "regularForm", ...props }) {
  return (
    <form className={`form ${type}`} {...props}>
      {children}
    </form>
  );
}

export default Form;
