import API_ENV from "@config";
import "./SearchFilters.scss";

import React, { useState, useEffect } from "react";
import Hooks from "../../Hooks";
import Swal from "sweetalert2";
import ReactTooltip from "react-tooltip";

import Select from "../../ui/Select";
import Button from "../../ui/Button";

export default function PresetFilters({ onSearch, onView }) {
  const [options, updateOptions] = useState([
    <option key="presetFilterSelect">- Select -</option>,
  ]);

  function fetchOptions() {
    Hooks.getData(
      `${API_ENV}/jobfilter/User/${Hooks.getCookie("userId")}`,
      (data) => {
        if (data && data.length) {
          let array = [
            <option value={null} key="presetFilterSelect">
              - Select -
            </option>,
          ];
          data.forEach((option) => {
            array.push(
              <option value={option.id} key={`${option.id}_${option.name}`}>
                {option.name}
              </option>,
            );
          });
          updateOptions(array);
        }
      },
    );
  }

  function deleteClicked() {
    const filterId = document.querySelector("#presetFilterSelect").value;
    if (!isNaN(filterId)) {
      Swal.fire({
        title: "Delete filter?",
        text: "Are you sure you wish to delete this filter?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          function thenFunction(response) {
            if (response.ok) {
              fetchOptions();
            }
          }

          Hooks.sendData(
            {},
            `${API_ENV}/jobfilter/${filterId}`,
            "DELETE",
            thenFunction,
          );
        }
      });
    }
  }

  useEffect(() => {
    fetchOptions();
  }, [onSearch]);

  return (
    <>
      <ReactTooltip
        className="tooltip"
        effect="solid"
        type="light"
        place="bottom"
      />

      <div id="presetFilterContainer">
        <p className="searchText">Saved filters</p>

        <Select
          id="presetFilterSelect"
          onChange={() => {
            onSearch();
          }}
        >
          {options}
        </Select>

        <Button
          id="presetView"
          size={"small"}
          icon={"HiOutlineEye"}
          data-tip="View/modify the selected saved filter settings."
          onClick={onView}
        >
          View
        </Button>

        <Button
          id="presetDelete"
          size={"small"}
          icon={"HiOutlineXMark"}
          data-tip="Delete saved filter."
          onClick={deleteClicked}
        >
          Delete
        </Button>
      </div>
    </>
  );
}
