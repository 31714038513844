import React, { useEffect, useState } from "react";
import PartListItem from "./PartListItem";
import PartCatItem from "./PartCatItem";
import "./partsearch.scss";

export default function PartList({ content, select, onClose, type, leaf }) {
  const [listData, setListData] = useState([]);
  const [isLeafDisplay, setIsLeafDisplay] = useState(false);

  // useEffect(() => {
  //   console.log("listData:", listData);
  //   console.log("isLeafDisplay:", isLeafDisplay);
  // }, [listData, isLeafDisplay]);

  useEffect(() => {
    if (!content || typeof content === "string") {
      setListData(null); // Use null to indicate no parts to show
    } else if (content.parts) {
      setListData(content.parts);
      setIsLeafDisplay(false);
    } else if (content.partsCategory) {
      setListData(content.partsCategory);
      setIsLeafDisplay(true);
    } else {
      setListData([]);
      setIsLeafDisplay(false);
    }
  }, [content]);

  return (
    <div className="partList">
      <div className="partListHeader">
        <div className="partListTitle">Part List</div>
        <button className="close" onClick={onClose}>
          Close
        </button>
      </div>
      <div className="partListContainer">
        <div className="partCategories">
          <div className="partListTitle">
            <h2>Categories</h2>
          </div>
          {listData === null ? (
            <div className="partListText">No parts to show</div>
          ) : isLeafDisplay ? (
            listData.map((partCat, index) => (
              <PartCatItem
                key={index}
                itemData={partCat}
                select={select}
                leaf={leaf}
              />
            ))
          ) : (
            listData.map((part, index) => (
              <PartListItem
                key={index}
                itemData={part}
                select={select}
                type={type}
              />
            ))
          )}
          {isLeafDisplay && leaf && leaf.length > 0 && (
            <div className="leafSection">
              {leaf.map((leafItem, index) => (
                <button
                  key={index}
                  className="partSelect leafSection"
                  onClick={() => select(leafItem)}
                  style={{ color: "yellow" }}
                >
                  {leafItem.partno}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
