import "./attachments.scss";

import React from "react";
import Hooks from "../../../../Hooks";
import { HiOutlinePhoto } from "react-icons/hi2";
import API_ENV from "@config";

export default function ReportAttachments({ reportData, fileFormat = true }) {
  // console.log("reportDataArray", reportDataArray);

  function download(item) {
    // console.log("item:", item);

    if (item.genFilePath) {
      const genFilePathToURL = encodeURIComponent(item.genFilePath).replace(
        /%5C/g,
        "%5C%5C",
      );
      const url = `${API_ENV}/runReportProc/openfile3/${genFilePathToURL}`;

      fetch(url, {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: Hooks.getCookie("jwt"),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = Object.assign(document.createElement("a"), {
            href: url,
            // download: `${reportData.runReportList.name}${reportData.runReportType.outputFormat}`,
            download: `${item.fileName}`,
          });
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(url);
        });
    } else {
      console.erro("Unable to download, check ReportAttachments.js");
    }
  }

  return (
    <>
      {reportData &&
        reportData.genFilePathArray.map((item) => (
          <div className="currentReportAttachments" key={item.id}>
            <div className="ReportAttachmentContainer">
              <div
                className="reportAttachment"
                onClick={(e) => {
                  download(item);
                }}
              >
                <HiOutlinePhoto className="reportAttachmentImage" />

                <div className="reportContent">
                  <div className="reportContentItem">
                    <div className="key">Name</div>
                    <div className="text">{item.fileName}</div>
                  </div>

                  <div className="reportContentItem">
                    <div className="key">Date</div>
                    <div className="text">
                      {new Date(reportData.actualStartTime).toDateString()}{" "}
                      {new Date(
                        reportData.actualStartTime,
                      ).toLocaleTimeString()}
                    </div>
                  </div>

                  <div className="reportContentItem">
                    <div className="key">Run By</div>
                    <div className="text">
                      {`${reportData.staff.forename} ${reportData.staff.surname}`}
                    </div>
                  </div>

                  {fileFormat && (
                    <div className="reportContentItem">
                      <div className="key">File format</div>
                      <div className="text">
                        {reportData.runReportType.outputFormat}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="attachmentButtons"></div>
            </div>
          </div>
        ))}
    </>
  );
}
