import API_ENV from "@config";
import React, { useEffect, useState, forwardRef, useRef } from "react";
import Hooks from "../../Hooks";
import "./generic.scss";
import { HiChevronDoubleDown } from "react-icons/hi2";

export default forwardRef(function NewDropdown(
  { type, topValue, customUrl, onChange, isBool },
  ref,
) {
  const [contents, updateContents] = useState(
    <div className="dropdownItem">No Value</div>,
  );
  const [dropdownValue, updateValue] = useState(topValue?.value || "");
  const sidePanelId = Hooks.getSidePanelData()?.id;
  const [title, updateTitle] = useState(() => {
    try {
      return topValue.label;
    } catch (e) {
      return "";
    }
  });

  const isMounted = useRef(true);

  // useEffect(() => {
  //   // console.log(Hooks.getSidePanelData());
  //   // console.log("Type: ", type);
  //   // console.log("sidePanelId: ", sidePanelId);
  //   console.log("topValue: ", topValue);
  // }, [topValue]);

  function itemClicked(item, title, parent) {
    parent.style.display = "none";

    // console.log("item: ", item);

    if (item.jobTypeId) {
      updateValue(item.jobTypeId);
    } else {
      updateValue(item.id);
    }

    updateTitle(title);
  }

  function dropdownClicked(target) {
    if (!target) {
      return;
    }

    const contents = target.querySelector(".dropdownContents");
    if (!contents) {
      return;
    }

    const display = contents.style.display;

    contents.style.display = display === "flex" ? "none" : "flex";

    // Hide all of the other dropdown contents.
    document.querySelectorAll(".dropdownContents").forEach((dropdown) => {
      if (
        dropdown.parentElement !== target &&
        dropdown.style.display === "flex"
      ) {
        dropdown.style.display = "none";
      }
    });
  }

  useEffect(() => {
    isMounted.current = true;

    const data = {
      client: {
        url: `${API_ENV}/clients`,
        displayKeys: ["name"],
      },
      engineer: {
        url: `${API_ENV}/staff`,
        displayKeys: ["forename", "surname"],
      },
      jobtype: {
        url: `${API_ENV}/jobType`,
        displayKeys: ["description"],
      },
      jobtypeschedule: {
        url: `${API_ENV}/contractsAndJobType?contractIds=${sidePanelId}&showarchive=false`,
        displayKeys: ["description"],
      },
      jobstate: {
        url: `${API_ENV}/jobState`,
        displayKeys: ["description"],
      },
      documenttag: {
        url: `${API_ENV}/documentTag`,
        displayKeys: ["name"],
      },
      visit: {
        url: `${API_ENV}/visits?jobIDs=${sidePanelId}`,
        displayKeys: ["id"],
      },
      contract: {
        url: `${API_ENV}/contracts`,
        displayKeys: ["name"],
      },
      contracttoproperty: {
        // url: `${API_ENV}/contract/client/${sidePanelId}`, // Old
        url: `${API_ENV}/contract/properties/foradd/${sidePanelId}`, // New
        displayKeys: ["name"],
      },
      jobcontract: {
        url: "",
        displayKeys: ["name"],
      },
      jobcontract2: {
        url: "",
        displayKeys: ["name"],
      },
      equipmentcategory: {
        url: `${API_ENV}/equipmentCategory`,
        displayKeys: ["name"],
      },
      equipmentsubcategory: {
        url: "",
        displayKeys: ["name"],
      },
      supplier: {
        url: `${API_ENV}/suppliers`,
        displayKeys: ["name"],
      },
      preferredsupplier: {
        url: `${API_ENV}/suppliers`,
        displayKeys: ["name"],
      },
      targetsupplier: {
        url: `${API_ENV}/suppliers`,
        displayKeys: ["name"],
      },
      warehouse: {
        url: `${API_ENV}/warehouses`,
        displayKeys: ["name"],
      },
      deliverynotestate: {
        url: `${API_ENV}/deliveryNoteState`,
        displayKeys: ["name"],
      },
      preferredwarehouse: {
        url: `${API_ENV}/warehouses?isVan=false`,
        displayKeys: ["name"],
      },
      priority: {
        url: `${API_ENV}/priority`,
        displayKeys: ["name"],
      },
      costtype: {
        url: `${API_ENV}/costType`,
        displayKeys: ["name"],
      },
      qcstatus: {
        url: `${API_ENV}/qcStatus`,
        displayKeys: ["description"],
      },
      stockaction: {
        url: `${API_ENV}/stockAction?isVan=false`,
        displayKeys: ["name"],
      },
      stockactionvan: {
        url: `${API_ENV}/stockAction?isVan=true`,
        displayKeys: ["name"],
      },
      stocklocation: {
        url: `${API_ENV}/stockLocation?WarehouseID=1`,
        displayKeys: ["aisle", "rack", "level"],
      },
      partssubcategory1: {
        url: `${API_ENV}/partsSubCategory1`,
        displayKeys: ["name"],
      },
      partssubcategory2: {
        url: `${API_ENV}/partsSubCategory2`,
        displayKeys: ["name"],
      },
      partssubcategory3: {
        url: `${API_ENV}/partsSubCategory3`,
        displayKeys: ["name"],
      },
      partssubcategory4: {
        url: `${API_ENV}/partsSubCategory4`,
        displayKeys: ["name"],
      },
      partscategory: {
        url: `${API_ENV}/partsCategory`,
        displayKeys: ["name"],
      },
      purchaseorder: {
        url: `${API_ENV}/poDetail/dnLine/${document
          .querySelector("#sidePanelContainer")
          .getAttribute("lineno")}`,
        displayKeys: [],
      },
      settype: {
        url: `${API_ENV}/inspectionType`,
        displayKeys: ["name"],
      },
      stafftype: {
        url: `${API_ENV}/staffType`,
        displayKeys: ["description"],
      },
      apigroup: {
        url: `${API_ENV}/ApiGroup`,
        displayKeys: ["groupName"],
      },
      currentvisitstate: {
        url: `${API_ENV}/visitState`,
        displayKeys: ["description"],
      },
      nextvisitstate: {
        url: `${API_ENV}/visitState`,
        displayKeys: ["description"],
      },
      role: {
        url: `${API_ENV}/role`,
        displayKeys: ["name"],
      },
      visitstate: {
        url: `${API_ENV}/visitState`,
        displayKeys: ["description"],
      },
      apigrouproot: {
        url: `${API_ENV}/ApiGroup?isRootLevel=true`,
        displayKeys: ["groupName"],
      },
      reportname: {
        url: `${API_ENV}/runReportlist/access?apigroupid=0`,
        displayKeys: ["name"],
      },
      exportformat: {
        url: `${API_ENV}/runReportType?isPDF=false`,
        displayKeys: ["name"],
      },
    };
    // console.log(dropdownValue)

    function loadContents(items, data) {
      // SEE DATA FLOW HERE!
      // console.log({ items: items, data: data });

      function getTitle(data, item) {
        const text = [];
        if (type === "podetails") {
          text.push(
            `${item?.lineNo}) ${item?.parts?.partno} x ${item?.orderQuantity}`,
          );
        } else if (type === "purchaseorder") {
          text.push(
            `Purchase #${item?.id} - Line ${item?.lineNo} - ${item?.parts?.partno} X ${item?.orderQuantity}`,
          );
        } else if (type === "jobtypeschedule") {
          text.push(item.jobType.description);
        } else if (type === "preferredsupplier") {
          text.push(item?.supplier?.name || item?.name);
        } else {
          const keys = data.displayKeys;

          keys.forEach((key) => {
            text.push(item[key]);
          });
        }

        return text.join(" ");
      }

      const array = [];

      if (items && items.length && typeof items !== "string") {
        items.forEach((item) => {
          const title = getTitle(data, item);
          array.push(
            <div
              className="dropdownItem"
              key={`${item.id}_${title}`}
              value={item.id}
              onClick={(e) => {
                itemClicked(item, title, e.target.parentElement);
                if (onChange) {
                  onChange(item);
                }
              }}
            >
              {getTitle(data, item)}
            </div>,
          );
        });
        updateContents(array);
      }
    }

    function loadDropdown(data) {
      if (type === "scheduling") {
        const contents = [
          { name: "Month(s)", value: "M" },
          { name: "Year(s)", value: "Y" },
          { name: "Week(s)", value: "W" },
          { name: "Day(s)", value: "D" },
        ];
        const array = [];
        contents.forEach((item) => {
          // console.log("loadDropdown Items", item);
          array.push(
            <div
              className="dropdownItem"
              key={item.value}
              value={item.value}
              onClick={(e) => {
                itemClicked(
                  { id: item.value },
                  item.name,
                  e.target.parentElement,
                );
                // onChange();
              }}
            >
              {item.name}
            </div>,
          );
        });
        updateContents(array);
      } else if (isBool) {
        const array = [];
        const selectedValue =
          topValue &&
          topValue.value !== undefined &&
          topValue.label !== "[object Undefined]"
            ? (topValue.value + "").replace(/(^|\s)\S/g, (letter) =>
                letter.toUpperCase(),
              )
            : "False";
        const formattedSelected = Hooks.conditionalString(selectedValue);
        const otherValue = formattedSelected === "true" ? "False" : "True";
        const formattedOther = Hooks.conditionalString(otherValue);

        // console.log("selectedValue", selectedValue);
        // console.log("formattedSelected", formattedSelected);
        // console.log("otherValue", otherValue);
        // console.log("formattedOther", formattedOther);

        updateTitle(selectedValue);

        array.push(
          <div
            className="dropdownItem"
            key={formattedSelected}
            value={formattedSelected}
            onClick={(e) => {
              itemClicked(
                { id: formattedSelected },
                selectedValue,
                e.target.parentElement,
              );
              onChange();
            }}
          >
            {selectedValue}
          </div>,
        );

        array.push(
          <div
            className="dropdownItem"
            key={formattedOther}
            value={formattedOther}
            onClick={(e) => {
              itemClicked(
                { id: formattedOther },
                otherValue,
                e.target.parentElement,
              );
              onChange();
            }}
          >
            {otherValue}
          </div>,
        );

        updateContents(array);
      } else if (
        ((data && data.url) || customUrl) &&
        Hooks.conditionalString(customUrl) !== "nourl"
      ) {
        // This is where the API call happens for each dropdown to grab the data. The calls are made for all drop downs in a form as soon as the add button is clicked.

        Hooks.getData(customUrl || data.url, (items) => {
          // console.log("NewDropdown API Call Data: ", items);
          if (isMounted.current) {
            // Update state only if the component is still mounted
            if (items && items.length) {
              loadContents(items, data);
            } else {
              updateContents(
                <div className="dropdownItem" key="No Values" value={0}>
                  No Value
                </div>,
              );
            }
          }
        });
      }
    }

    loadDropdown(data[type]);
    // console.log("data[type]", data[type]);

    // Cleanup function to set the flag to false when the component unmounts
    return () => {
      isMounted.current = false;
    };
  }, [type, topValue, customUrl, isBool, onChange, sidePanelId]);

  return (
    <div
      id={`${type}Dropdown`}
      ref={ref}
      className="dropdown"
      data={dropdownValue.toString()}
      onClick={() => {
        dropdownClicked(ref?.current);
      }}
    >
      <div
        className="dropdownTitle"
        key={`${type}_${topValue}`}
        // commented this out to stop console error everytime you click on a dropdown list. This component is wrapped in a forwardRef() but no ref is being provided as a prop in SidePanelRow.js. Why?
        // onClick={() => { dropdownClicked(ref.current);}} //console error: Uncaught TypeError: ref is null
      >
        <div
          className="title"
          onClick={(e) => {
            dropdownClicked(e.target.parentElement.parentElement);
          }}
        >
          {title}
        </div>
        <HiChevronDoubleDown />
      </div>
      <div className="dropdownContents">{contents}</div>
    </div>
  );
});
