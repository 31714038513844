import API_ENV from "@config";
import React, { useCallback, useEffect } from "react";
import Hooks from "../../../Hooks";

import ReportAttachments from "./Attachments/ReportAttachments";
import Form from "./../../../ui/Form";
import SidePanelFormRow from "./../../../ui/SidePanelFormRow";
import Button from "./../../../ui/Button";
import InputOldNonRHF from "@ui/InputOldNonRHF";
import CollapsibleGroup from "@ui/CollapsibleGroup";

export default function ViewReport({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  isViewReportProcess,
}) {
  const { data } = state;

  const runReportAtValue = `${new Date(data.actualStartTime).toDateString()}
  ${new Date(data.actualStartTime).toLocaleTimeString()}`;

  // useEffect(() => {
  //   console.log("data", data);
  // }, [data]);

  const handleDownloadReport = useCallback(async () => {
    try {
      const apiUrl = `${API_ENV}/runReportProc/openfile/${data.id}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${Hooks.getCookie("jwt")}`,
        },
      });

      if (!response.ok) {
        console.log(response);
        throw new Error("Network response was not ok");
      }
      // console.log(response);

      const blob = await response.blob();

      // Create a download link for the file
      const url = URL.createObjectURL(blob);
      const a = Object.assign(document.createElement("a"), {
        href: url,
        download: `${data.runReportList.name}${data.runReportType.outputFormat}`,
      });
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      refreshTable();
    } catch (error) {
      console.error(error);
    }
  }, [
    data.id,
    data.runReportList.name,
    data.runReportType.outputFormat,
    refreshTable,
  ]);

  function handleCancelRecurSchedule() {
    let test = "test";
    function thenFunction() {}

    Hooks.sendData(
      test,
      `${API_ENV}/runReportProc/CancelSchedule/${data.id}`,
      "DELETE",
      thenFunction,
    );
  }

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  useEffect(() => {
    if (
      data.runProcessState.name === "COMPLETED" ||
      data.runProcessState.name === "EMAILSENT" ||
      data.runProcessState.name === "COMPLETED"
    ) {
      setFooterState([
        { text: "Download Report", click: handleDownloadReport },
        { text: "Close", click: closeFunction },
      ]);
    } else {
      setFooterState([{ text: "Close", click: closeFunction }]);
    }
  }, [
    data.runProcessState.name,
    setFooterState,
    handleDownloadReport,
    closeFunction,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <InputOldNonRHF
              label={"Process ID"}
              type={"text"}
              value={data.id}
              readOnly
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label htmlFor="genFilePathArray">Report Name</label>
            <input
              label={"Report Name"}
              className="input extraSmall"
              type={"text"}
              value={
                data.genFilePathArray && data.genFilePathArray.length > 0
                  ? data.genFilePathArray
                      .map((item, index) => `File ${index}: "${item.fileName}"`)
                      .join("\n")
                  : "No value"
              }
              readOnly
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <InputOldNonRHF
              label={"Run By"}
              type={"text"}
              value={`${data.staff.forename} ${data.staff.surname}`}
              readOnly
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <InputOldNonRHF
              label={"Report State"}
              type={"text"}
              value={data.runProcessState.name}
              readOnly
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <InputOldNonRHF
              label={"Scheduled At"}
              type={"text"}
              value={new Date(data.scheduleStartTime).toDateString()}
              readOnly
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <InputOldNonRHF
              label={"Run Report At"}
              type={"text"}
              value={runReportAtValue}
              readOnly
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label htmlFor="emailSentTo">Email Sent To</label>
            <textarea
              id="emailSentTo"
              className="input extraSmall"
              type="text"
              readOnly
              value={data.sendEmailTo || "No value"}
            ></textarea>
          </SidePanelFormRow>

          {/* {isViewReportProcess ? (
            <SidePanelFormRow>
              <label htmlFor="genFilePathArray">Gen File Path Array</label>
              <textarea
                id="genFilePathArray"
                className="input extraSmall"
                type="text"
                readOnly
                value={
                  data.genFilePathArray && data.genFilePathArray.length > 0
                    ? data.genFilePathArray
                        .map(
                          (item) =>
                            `Job ${item.id}: Filename: "${item.fileName}", File Path: "${item.genFilePath}",`
                        )
                        .join("\n")
                    : "No value"
                }
              ></textarea>
            </SidePanelFormRow>
          ) : (
            <SidePanelFormRow></SidePanelFormRow>
          )} */}

          <SidePanelFormRow>
            <label htmlFor="recurringDetail">Recurring Detail</label>
            <textarea
              id="recurringDetail"
              className="input extraSmall"
              type="text"
              readOnly
              value={data.recuringDetail || "No value"}
            ></textarea>
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Button
              onClick={handleCancelRecurSchedule}
              style={{ marginLeft: "50px" }}
            >
              Cancel Recurring Schedule
            </Button>
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label htmlFor="queryFilter">Query Filter</label>
            <textarea
              id="queryFilter"
              className="input extraSmall"
              type="text"
              readOnly
              value={data.processMessage || "No value"}
            ></textarea>
          </SidePanelFormRow>

          <SidePanelFormRow>
            <label htmlFor="displayMessage">Display Message</label>
            <textarea
              id="displayMessage"
              className="input extraSmall"
              type="text"
              readOnly
              value={data.displayMessage || "No value"}
            ></textarea>
          </SidePanelFormRow>
        </div>
      </CollapsibleGroup>

      <CollapsibleGroup title="Attachments">
        <ReportAttachments reportData={data} fileFormat={false} />
      </CollapsibleGroup>
    </Form>
  );
}
