import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import DropdownList from "@ui/DropdownList";

import CollapsibleGroup from "@ui/CollapsibleGroup";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // ! TO DO
    // * BOOLEANS need to be yup.bool()
    jobTypeId: yup.string().required("This field is required"),
    // contractClientId: yup.string().required("This field is required"),

    // For conditional fields which only appear based on another fields value
    contractClientId: yup.string().when("applyToAllContractsFlag", {
      is: false,
      then: () => yup.string().required("This field is required"),
      otherwise: () => yup.string().notRequired(),
    }),
  })
  .required();

export default function ViewDocumentWorkflow({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
  setFormState,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });
  // const [contentListData, setContentListData] = useState([]);

  const [formRows] = useState([
    {
      jobStateTransitionId: "",
      documentTagId: "",
    },
  ]);

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      jobTypeId: "",
      contractClientId: "",
      applyToAllContractsFlag: false,
    },
  });

  const jobTypeId = watch("jobTypeId");
  const contractClientId = watch("contractClientId");
  const applyToAllContractsFlag = watch("applyToAllContractsFlag");

  // const { openModal } = useModal();
  const { data: stateData } = state;

  // * UseEffect Hooks: Tracking
  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  //* Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: stateData.id,
        // * BOOLEANS need || "" removed!
        jobTypeId: stateData.jobStateTransition.jobType.id || "",
        contractClientId: stateData.contract.id || "",
        applyToAllContractsFlag: stateData.applyToAllContractsFlag || "",
        jobStateTransitionId: stateData.jobStateTransitionId || "",
        documentTagId: stateData.documentTagId || "",
      });
    }
  }, [stateData, reset]);

  // * UseEffect Hooks: Form/API
  // Dropdown List Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/jobType`, (data) => {
      // console.log("useEffect API 1 data:", data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });

    Hooks.getData(`${API_ENV}/documentTag`, (data) => {
      // console.log("useEffect API 1 data:", data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData4: data,
      }));
    });

    if (jobTypeId) {
      Hooks.getData(`${API_ENV}/contracts?JobTypeId=${jobTypeId} `, (data) => {
        // console.log("useEffect API 2 data:", data);
        setDropdownData((prevData) => ({
          ...prevData,
          dropdownData2: data,
        }));
      });
    }

    if (jobTypeId) {
      Hooks.getData(
        `${API_ENV}/jobStateTransition?JobTypeId=${jobTypeId} `,
        (data) => {
          // console.log("useEffect API 3 data:", data);

          const transformedData = data.map((item) => ({
            id: item.id,
            name: `${item.currJobState.description} -> ${item.nextJobState.description}`,
          }));

          setDropdownData((prevData) => ({
            ...prevData,
            dropdownData3: transformedData,
          }));
        },
      );
    }
  }, [jobTypeId]);

  // HANDLE Functions
  // const handleAddRow = useCallback((e) => {
  //   e.preventDefault();
  //   setFormRows((prevRows) => [
  //     ...prevRows,
  //     {
  //       jobStateTransitionId: "",
  //       documentTagId: "",
  //     },
  //   ]);
  // }, []);

  // const handleDeleteRow = useCallback((e) => {
  //   e.preventDefault();
  //   setFormRows((prevRows) => prevRows.slice(0, -1));
  // }, []);

  // const handleDropdownListChange = useCallback((index, fieldName, value) => {
  //   setFormRows((prevRows) => {
  //     const updatedRows = [...prevRows];
  //     updatedRows[index] = {
  //       ...updatedRows[index],
  //       [fieldName]: value, // Dynamically update the field
  //     };
  //     return updatedRows;
  //   });
  // }, []);

  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setIsButtonDisabled(true);

        function thenFunction(response) {
          refreshTable();
          cleanURL();
          reset();

          setIsButtonDisabled(false);
        }

        const dataObject = formRows;

        // console.log("handleSubmitForm data:", data);
        // console.log("handleSubmitForm dataObject:", dataObject);

        Hooks.sendData(
          dataObject,
          `${API_ENV}/documentWorkflow/masscreateupdate/${contractClientId}`,
          "POST",
          thenFunction,
        );
      } catch (e) {
        console.error("Error occurred while submitting form:", e);
        setIsButtonDisabled(false);
      }
    },
    [refreshTable, cleanURL, reset, contractClientId, formRows],
  );

  const handleArchive = useCallback(() => {
    archive(stateData.id);
  }, [archive, stateData.id]);

  // const handleChangeForm = useCallback(() => {
  //   setFormState({ type: "formNameHere", data: {} });
  // }, [setFormState]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      // { text: "Submit Form", click: handleSubmit(handleSubmitForm), disabled: isButtonDisabled, },
      // { text: "Change Form", click: handleChangeForm },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    // handleOpenModal,
    isButtonDisabled,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <DropdownList
              label={"Job Type"}
              name="jobTypeId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
              optionName="description"
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Contract / Client"}
              name="contractClientId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData2}
              optionName="contractClient"
              disabled={applyToAllContractsFlag}
            />
          </SidePanelFormRow>

          {/* <SidePanelFormRow>
            <Input
              label={"All Contract?"}
              name="applyToAllContractsFlag"
              type="checkbox"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow> */}

          {/* <SidePanelFormRow></SidePanelFormRow> */}

          <SidePanelFormRow>
            <DropdownList
              label={`Job State Change`}
              name={`jobStateTransitionId`}
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData3}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={`Document Tag`}
              name={`documentTagId`}
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData4}
            />
          </SidePanelFormRow>

          {/* <SidePanelFormRow type="full">
            <div className="buttonsGroup">
              <Button size="small" onClick={handleAddRow}>
                Add Row
              </Button>

              <Button
                size="small"
                type="danger"
                onClick={handleDeleteRow}
                disabled={formRows.length < 2}
              >
                Delete Row
              </Button>
            </div>
          </SidePanelFormRow>

          {formRows.map((row, index) => (
            <React.Fragment key={index}>
              <SidePanelFormRow>
                <DropdownList
                  label={`Job State Change`}
                  name={`jobStateTransitionId_${index}`}
                  control={control}
                  errors={errors}
                  dropdownData={dropdownData.dropdownData3}
                  customOnChange={(value) =>
                    handleDropdownListChange(
                      index,
                      "jobStateTransitionId",
                      value
                    )
                  }
                />
              </SidePanelFormRow>

              <SidePanelFormRow>
                <DropdownList
                  label={`Document Tag`}
                  name={`documentTagId_${index}`}
                  control={control}
                  errors={errors}
                  dropdownData={dropdownData.dropdownData4}
                  customOnChange={(value) =>
                    handleDropdownListChange(index, "documentTagId", value)
                  }
                />
              </SidePanelFormRow>
            </React.Fragment>
          ))} */}
        </div>
      </CollapsibleGroup>
    </Form>
  );
}
