import React, { forwardRef } from "react";
import "../styles/ui/Row.scss";

const Row = forwardRef(
  ({ type = "vertical", justifyContent, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={`row ${type} ${justifyContent ? justifyContent : ""}`}
        {...props}
      >
        {children}
      </div>
    );
  },
);

export default Row;
