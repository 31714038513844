import API_ENV from "@config";
import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";

import { useParams } from "react-router-dom";
import AssignedItems from "features/forms/Jobs/AssignedItems";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // ! TO DO
    // * BOOLEANS need to be yup.bool()
    field1: yup.string().required("This field is required"),

    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();
export default function AssignedUsers({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
  setFormState,
}) {
  // const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // const [dropdownData, setDropdownData] = useState({
  //   dropdownData1: [],
  // });

  const [contentListData, setContentListData] = useState([]);

  const { tableRowId } = useParams();

  const {
    // control,
    // handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    // formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // ! TO DO
      field1: "",
    },
  });

  // const sorRefId = watch("sorRefId");

  // const { openModal } = useModal();
  const { data: stateData } = state;

  // * UseEffect Hooks: Tracking
  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // Fill form via state
  useEffect(() => {
    if (contentListData && contentListData.length > 0) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: contentListData[0].id,
        // ! TO DO
        // * BOOLEANS need || "" removed!
        field1: contentListData[0]?.field1 || "",
      });
    }
  }, [contentListData, reset]);

  // * UseEffect Hooks: Form/API
  // contentListData
  useEffect(() => {
    // API GET contentList data
    // jobType/contract/${Hooks.getSidePanelData().id}
    Hooks.getData(`${API_ENV}/staffToRole?RoleId=${tableRowId}`, (data) => {
      // console.log("contentListData", data);
      setContentListData(data);
    });
  }, [tableRowId]);

  // Dropdown List Data
  // useEffect(() => {
  //   // ! TO DO
  //   Hooks.getData(`${API_ENV}/`, (data) => {
  //     // console.log("useEffect API 1 data:", data);
  //     setDropdownData((prevData) => ({
  //       ...prevData,
  //       dropdownData1: data,
  //     }));
  //   });
  // }, []);

  // HANDLE Functions
  // const handleSubmitForm = useCallback(
  //   async (data) => {

  // setIsButtonDisabled(true);
  //     function thenFunction(response) {
  //       refreshTable();
  //       // cleanURL();
  //       Hooks.getData(`${API_ENV}/jobSor/job/${tableRowId}`, (data) => {
  //         setContentListData(data);
  //       });

  // setIsButtonDisabled(false);
  //     }

  //     const dataObject = {};

  //     // console.log("handleSubmitForm data:", data);
  //     // console.log("handleSubmitForm dataObject:", dataObject);

  //     Hooks.sendData(
  //       dataObject,
  //       `${API_ENV}/jobsor/${data.id}`,
  //       "PUT",
  //       thenFunction
  //     );
  //   },
  //   [refreshTable, tableRowId]
  // );

  const handleAddJobType = useCallback(() => {
    setFormState({ type: "adduserstorole", data: {} });
  }, [setFormState]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
    reset();
  }, [cleanURL, reset]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      // { text: "Submit Form", click: handleSubmit(handleSubmitForm), disabled: isButtonDisabled, },
      { text: "Add Users To Role", click: handleAddJobType },
      // { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleAddJobType,
    // handleSubmit,
    // handleSubmitForm,
    // handleArchive,
    // handleOpenModal,
    // isButtonDisabled,
  ]);

  // function targetClicked(e, item) {
  //   // Directly use the passed item object to update the form
  //   // No need to parse eventData from the event.target.getAttribute
  //   // console.log("targetClicked item: ", item);

  //   reset({
  //     // ID here is needed for PUT call in handleSubmitForm
  //     id: item?.id,
  //     // * BOOLEANS need || "" removed!
  //     // * Date values & others need to be null if there is no data!
  //     field1: item?.field1 || "",
  //   });
  // }

  return (
    <Form>
      <div className="contentListContainer">
        {/* <ContentList
          data={contentListData}
          type={"contractjobtypes"}
          clicked={targetClicked}
        /> */}

        <AssignedItems
          data={stateData}
          type={state?.type}
          key={state?.type}
          setFormState={setFormState}
        />
      </div>

      {/* <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <Input
              label={"Field1"}
              name="field1"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          {/* <SidePanelFormRow>
            <DropdownList
              label={"Dropdown List"}
              name="field2"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
            />
          </SidePanelFormRow> */}

      {/* <SidePanelFormRow>
            <DropdownList
              label={"Dropdown List 2"}
              name="field3"
              control={control}
              errors={errors}
              dropdownData={[
                { id: 1, name: "PDF" },
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
            />
          </SidePanelFormRow> */}

      {/* <SidePanelFormRow>
            <label>Start Date</label>
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow> */}

      {/* <SidePanelFormRow type="full">
            <label>textArea</label>
            <textarea
              {...register("textArea")}
              rows="5"
              className="input left"
            ></textarea>
            {errors.issueDetail && (
              <span className="fieldError">{errors.issueDetail.message}</span>
            )}
          </SidePanelFormRow> */}

      {/* <SidePanelFormRow>
          <Button
            size={"small"}
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal();
            }}
          >
            Open Modal
          </Button>
        </SidePanelFormRow> 
        </div>
      </CollapsibleGroup> */}

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
