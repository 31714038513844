import React from "react";
import Hooks from "../../../Hooks";
import Dropdown from "../../generic/Dropdown";

export default function BigDropdown({ title }) {
  return (
    <div className="bigdropdown">
      <div className="bigdropdownKey">{title}</div>
      <Dropdown
        id={Hooks.conditionalString(title) + "Input"}
        type={Hooks.conditionalString(title)}
      />
    </div>
  );
}
