import React, { useEffect, useState } from "react";
import "./timeDropdown.scss";
import NewDropdown from "../../../generic/NewDropdown";

export default function TimeDropdown({ data, type }) {
  const [value, updateValue] = useState({ window: 1, unit: "M" });

  const topValue = {
    M: { value: "M", label: "Month(s)" },
    Y: { value: "Y", label: "Year(s)" },
    W: { value: "W", label: "Week(s)" },
    D: { value: "D", label: "Day(s)" },
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    updateValue({ window: data?.window, unit: data?.unit });
  }, [data, type]);

  return (
    <div id={`${type}Input`} className="schedulerRow" defaultValue={1}>
      <input
        key={value?.window}
        type="number"
        min={1}
        defaultValue={value.window}
      />
      <NewDropdown type="scheduling" topValue={topValue[data?.unit]} />
    </div>
  );
}
